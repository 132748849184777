import React, { Component } from "react";
import {Form} from 'react-bootstrap';


class Vat extends Component {
  handleChange = (e) => {
    let {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value);
  }

  render (){
    let {index, vat} = this.props;
    vat = +vat;
    //console.log(index + " : " + vat);
    return (        
      <Form.Control style={{display: 'inline'}} 
      id={index} 
      name={'vat'}  
      as="select" 
      onChange={this.handleChange}>
          <option selected={vat === 0 && true} value='0'>0 %</option>
          <option selected={vat === 12 && true} value='12'>12 %</option>
          <option selected={vat === 15 && true} value='15'>15 %</option>
          <option selected={vat === 25 && true} value='25'>25 %</option>
      </Form.Control>
    );
  }
}


export default Vat;
