import React, { Component } from "react";
import TimelineDocumentCard from '../Timeline/TimelineDocumentCard';
import LoggedInNewDocument from "../PageNavigation/MenuContainer";
import {serverRouteParser, requestObjectGenerator} from '../ServerRoutes/serverRoute';
import TimelineAxis from "./TimelineAxis";
import TimelineAxisMobile from "./TimelineAxisMobile";
import TimelineSidebar from "../Hud/HudContainer";
import './../timeline.css';

class Timeline extends Component {

  groupDocumentsByYearCreateDate(documents){
    let yearGroup = {};
      for (let i=0; i <= documents.length-1; i++)
      {
        let dte = new Date(documents[i].createdate);
        let year = dte.getFullYear();
        if (isNaN(year))
        {
          if (yearGroup[documents[i].content.days[0].slice(6,10)] === undefined)
          {
            yearGroup[documents[i].content.days[0].slice(6,10)] = [];
            yearGroup[documents[i].content.days[0].slice(6,10)].push(documents[i]);
          }
          else
          {
            if (typeof +documents[i].content.days[0].slice(6,10) == 'number')
            {
              yearGroup[documents[i].content.days[0].slice(6,10)].push(documents[i]);
            }
          }
          if (i === documents.length-1)
          {
            return(yearGroup);
          }
        }
        else 
        {
          if (yearGroup[year] === undefined)
          {
            yearGroup[year] = [];
            yearGroup[year].push(documents[i]);
          }
          else
          {
            if (typeof +year == 'number')
            {
              yearGroup[year].push(documents[i]);
            }
          }
          if (i === documents.length-1)
          {
            return(yearGroup);
          }
        }
      }
  }

  verifyDocumentType(document){
    return document.type;
  }
   
  
  countDocumentWeekCreateDate(documents){
    Date.prototype.getWeek = function() {
            var date = new Date(this.getTime());
            //console.log(date);
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            //console.log(date);
            var week1 = new Date(date.getFullYear(), 0, 4);
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
          }
    let weekGroup = {};
      for (let i=0; i <= documents.length-1; i++)
      {
        let content = documents[i].content;
        let firstDayFromDocument = new Date(documents[i].createdate);
        //console.log("Date sliced: " + (content.days[0].slice(0,2) + " - " + content.days[0].slice(3,5) + " - " + content.days[0].slice(6,10)));
        //console.log('First DFD ' + document.did + ' : ' + firstDayFromDocument);
        let lastDayFromDocument = new Date(documents[i].createdate);
        //console.log('Last DFD ' + document.did + ' : ' + lastDayFromDocument);
        let week =[firstDayFromDocument.getWeek(),lastDayFromDocument.getWeek()]
        if (weekGroup[week[0]] === undefined)
        {
          weekGroup[week[0]] = [];
          weekGroup[week[0]].push(documents[i]);
        }
        else
        {
          weekGroup[week[0]].push(documents[i]);
        }
        if (i === documents.length-1)
        {

          //console.log(weekGroup);
          return(weekGroup);
        }
      }
  }

  groupDocumentsByYear(documents){
    let yearGroup = {};

      for (let i=0; i <= documents.length-1; i++)
      {
        if(documents[i].type === 'timelist')
        { 
          if (yearGroup[documents[i].content.days[0].slice(6,10)] === undefined)
          {
            yearGroup[documents[i].content.days[0].slice(6,10)] = [];
            yearGroup[documents[i].content.days[0].slice(6,10)].push(documents[i]);
          }
          else
          {
            if (typeof +documents[i].content.days[0].slice(6,10) == 'number')
            {
              yearGroup[documents[i].content.days[0].slice(6,10)].push(documents[i]);
            }
          }
        }
        if(documents[i].type === 'faktura')
        { 
          /*if (yearGroup[documents[i].content.days[0].slice(6,10)] === undefined)
          {
            yearGroup[documents[i].content.days[0].slice(6,10)] = [];
            yearGroup[documents[i].content.days[0].slice(6,10)].push(documents[i]);
          }
          else
          {
            if (typeof +documents[i].content.days[0].slice(6,10) == 'number')
            {
              yearGroup[documents[i].content.days[0].slice(6,10)].push(documents[i]);
            }
          }*/
        }   
        if(documents[i].type === 'tilbud')
        { 
          /*if (yearGroup[documents[i].content.days[0].slice(6,10)] === undefined)
          {
            yearGroup[documents[i].content.days[0].slice(6,10)] = [];
            yearGroup[documents[i].content.days[0].slice(6,10)].push(documents[i]);
          }
          else
          {
            if (typeof +documents[i].content.days[0].slice(6,10) == 'number')
            {
              yearGroup[documents[i].content.days[0].slice(6,10)].push(documents[i]);
            }
          }*/
        }        
        if (i === documents.length-1)
        {
          return(yearGroup);
        }
      }
  }
   
  
  countDocumentWeek(documents){
    Date.prototype.getWeek = function() {
            var date = new Date(this.getTime());
            //console.log(date);
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            //console.log(date);
            var week1 = new Date(date.getFullYear(), 0, 4);
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
          }
    let weekGroup = {};
      for (let i=0; i <= documents.length-1; i++)
      {
        let content = documents[i].content;
        console.log("Date: " + content.days[0]);
        let weekArray = content.days.map((day, index)=>(
          new Date(day.slice(6,10), +day.slice(3,5)-1, day.slice(0,2))
        ));
        weekArray = weekArray.map((day,index)=>(day.getWeek()));
        //console.log(content.days);
        //console.log(weekArray);
        for(let j=weekArray.length-1; j>=0; j--)
        {
          if (j>0 && weekArray[j]===weekArray[j-1])
          {
            weekArray.splice(j,1);
          }
        }
        for(let j=weekArray.length-1; j>=0; j--)
        {
          //console.log(weekArray[j]);
          if (weekGroup[''+weekArray[j]] === undefined)
          {
            //console.log('undefined - tworze nowy ' + documents[i].content.days[0])
            weekGroup[''+weekArray[j]] = [];
            weekGroup[''+weekArray[j]].push(documents[i]);
          }
          if (!(weekGroup[''+weekArray[j]].includes(documents[i])))
          {
            //console.log('nie istnieje - dodaję')
            weekGroup[''+weekArray[j]].push(documents[i]);
          }
        }
        if (i === documents.length-1)
        {
          //documents[i]['week']=weekGroup;
          console.log(weekGroup);
          return(weekGroup);
        }
      }
  }



  render (){
    let documents = this.props.documents ? this.props.documents : [];
    let yearGroup = {};
    if(documents.length > 0 && !this.props.groupByCreateDate)
    {//Coverage Group
      yearGroup = this.groupDocumentsByYear(documents);
      for (const [key, value] of Object.entries(yearGroup)) {
        yearGroup[key] = this.countDocumentWeek(yearGroup[key]);
      }
    }
    if(documents.length > 0 && this.props.groupByCreateDate)
    {//Creation Date Group
      yearGroup = this.groupDocumentsByYearCreateDate(documents);
      for (const [key, value] of Object.entries(yearGroup)) {
        yearGroup[key] = this.countDocumentWeekCreateDate(yearGroup[key]);
      }
    }
    const {hud, view, zoom,openFullPreview,groupByCreateDate,documentOptions, lang} = this.props;
    return (

        <div id='timeline-background'>
            {<TimelineAxisMobile lang={lang} hud={hud} view={view} zoom={zoom} openFullPreview={openFullPreview} groupByCreateDate={groupByCreateDate} documentOptions={documentOptions} documents={yearGroup}/>}
            {
              //window.innerHeight < window.innerWidth && <TimelineAxis zoom={this.props.zoom} openFullPreview={this.props.openFullPreview} groupByCreateDate={this.props.groupByCreateDate} documentOptions={this.props.documentOptions} documents={yearGroup}/>
            }
        </div>  
    );
  }
}

export default Timeline;
