import React, { Component } from "react";
import { sumUpTimer, timeToText} from "../Features/time_functions";
import StatsKnob from "./StatsKnob";
//import * as d3 from "d3";

import '../stats.css';
import { chooseLanguage } from "../Lang/langpack";
//import BarChart from "./BarChart";

class ClientStats extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }


  render (){
    const {documents, details, settings} = {...this.props};
    let documentStats = [];
    documentStats[0] = documents.length;
    documentStats[1] = {
      timelist: 0,
      faktura: 0,
      kreditnota: 0,
      tilbud: 0,
    };
    const docStats = documents.map((doc,index)=>{
      documentStats[1][doc.type]++;
    });
    let personalStat =  {};
    const stats = documents.map((doc,index)=>{
      if (doc.type === 'timelist')
      {
        const sumUp = {username: doc.content.username, timer: sumUpTimer(doc.content)};
        if (personalStat[sumUp.username] )
        {
          let counter = personalStat[sumUp.username]['timelist'];
          counter = counter+1;
          personalStat[sumUp.username]['timelist'] = counter;
          let worktime = personalStat[sumUp.username]['worktime'];
          worktime = worktime + sumUp.timer;
          personalStat[sumUp.username]['worktime'] = worktime;   
        }
        if (!personalStat[sumUp.username])
        {
          personalStat[sumUp.username] = {
            timelist: 1,
            faktura: 0,
            tilbud: 0,
            kontrakt: 0,
            worktime: sumUp.timer,
            sumhours: 0,
            summinutes: 0,
          }
        }     
        return sumUp;
      }
    });
    let idx = 0;
    let names = []; 
    let projectSumTime = 0;
    for (const [key, value] of Object.entries(personalStat)) {
      names[idx] = key;
      idx = idx+1;
      let sumtime = projectSumTime;
      sumtime = sumtime + value.worktime;
      projectSumTime = sumtime;
    }
    //console.log(personalStat)
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      tilbud: chooseLanguage('tilbud',  langinterface),
      timelist: chooseLanguage('timelist',  langinterface),
      kreditnota: chooseLanguage('kreditnota',  langinterface),
      faktura: chooseLanguage('faktura',  langinterface),  
      documentStats: chooseLanguage('documentStats',  langinterface),
      workerStats: chooseLanguage('workerStats',  langinterface),
      total: chooseLanguage('total',  langinterface), 
    }   
    return (  
        <div>
            <p className="project-section-header">{translation.workerStats}</p>
            {projectSumTime > 0 && <div className="stats-knob-panel">
            <StatsKnob type={'h'} value={timeToText(projectSumTime)} username={'project-total'} count={0}/>
              {names.map((username, idx)=>(
                  <StatsKnob key={idx} type={'h'} value={timeToText(personalStat[username].worktime)} username={username} count={personalStat[username].timelist}/>
              ))}
            </div>}
            <p className="project-section-header">{translation.documentStats}</p>
            <div className="stats-knob-panel">
              <StatsKnob type={''} value={documentStats[0]} username={'project-total'} limitExpected={false} count={0}/>
              <StatsKnob type={''} value={documentStats[1]['timelist']} username={translation.timelist} limitExpected={false} count={0}/>
              <StatsKnob type={''} value={documentStats[1]['faktura']} username={translation.faktura} limitExpected={false} count={0}/>
              <StatsKnob type={''} value={documentStats[1]['kreditnota']} username={translation.kreditnota} limitExpected={false} count={0}/>
              <StatsKnob type={''} value={documentStats[1]['tilbud']} username={translation.tilbud} limitExpected={false} count={0}/>
            </div>
        </div>
    );
  }
}

export default ClientStats;
