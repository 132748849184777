import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';

class PopupNewTask extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){
    let props = this.props;
    return (
        <div className="popup-new-task">
          <div>
            <Form.Control style={{width: '60%', display: 'inline'}} id='newProjectName' type="text" value={this.state.newTaskValue !== '' ? this.state.newTaskValue : ''}  placeholder='add task' onChange={this.saveTask} required/>
            <img src='./plus.svg' 
            id='newTask' 
            onClick={this.addTask}
            alt='add task'
            style={{width: '3vw', display: 'inline', marginLeft: '1.3vw', cursor: 'pointer', filter: 'invert(1)'}}/>
          </div>
        </div>
    );
  }
}

export default PopupNewTask;
