import React, { Component } from "react";
import { Button, Form} from 'react-bootstrap';


class PopupInfo extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){

    return (
        <div className="popup-info">
          <div>
            <p>{this.props.content}</p>
          </div>
        </div>
    );
  }
}

export default PopupInfo;
