import React, { Component } from "react";


class HelpButton extends Component {0

  click = (event) => {
    window.open("https://www.tutorial.jobbleder.no/", "_blank");
  }

  render (){
    return (
      <div id="help-button" className="system-button">
        <div className="clickable-panel" onClick={this.click}></div>
        <div>
        ?
        </div>
      </div>
    );
  }
}

export default HelpButton;
