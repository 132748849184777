import React, { Component } from "react";
import { Container, Row, Form, Col} from 'react-bootstrap';
import TimelistHeader from './TimelistHeader';
import TimelistRow from './TimelistRow';
import TimelistBasis from './TimelistBasis';
import {serverRouteParser, requestObjectGenerator} from '../ServerRoutes/serverRoute';
import { localizeDateStr, dateToSlashString } from "../Features/dateFeatures";
import DocumentExportOptions from "../FormComponents/DocumentExportOptions";
import TimelistRowMobile from "./TimelistRowMobile";
import ClientData from "../FormComponents/ClientData";
import ClientDataLocked from "../FormComponents/ClientDataLocked";
import { chooseLanguage } from "../Lang/langpack";


class NewTimelistWizardStepWorker extends Component {
  saveBasis = (event) => {
    let record = event.target.id,
      value = event.target.value;
      this.props.writeBasisToState(record, value);
  }
    render (){
      let {isValid, loggedname, writeBasisToState, lang} = this.props;
      const {langinterface} = lang;
      const translation = {
        workerName: chooseLanguage('workerName', langinterface),
      }
      return (
        <Form.Group id="document-fieldset" as={Row} className="justify-content-center">
          <Form.Label column xs={12} lg={3}><h4>{translation.workerName}</h4></Form.Label>
          <Col xs={10} lg={3}>
          <Form.Control id='loggedname' type="text" placeholder={translation.workerName} value={loggedname} onChange={this.saveBasis} required/>
          {!isValid.username && <div>
              Please input Name.
            </div>}
          </Col>
        </Form.Group>
      );
    }
  }


export default NewTimelistWizardStepWorker;
