import React, { Component } from "react";
import { chooseLanguage } from "../Lang/langpack";



class ProjectOptions extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }


  removeProject = () => {
    const {lang, editSetting, pid} = this.props;
    const {modifyProjectSetting} = editSetting;
    const today = new Date();
    modifyProjectSetting (pid, 'remove', 
    {
      removalDate: today,
      status: true,
    });
  }

  archiveProject = () => {
    const {lang, editSetting, pid} = this.props;
    const {modifyProjectSetting} = editSetting;
    const today = new Date();
    modifyProjectSetting (pid, 'archive', 
    {
      archivingDate: today,
      status: true,
    });
  }

  unremoveProject = () => {
    const {lang, editSetting, pid} = this.props;
    const {modifyProjectSetting} = editSetting;
    const today = new Date();
    modifyProjectSetting (pid, 'remove', 
    {
      removalDate: today,
      status: false,
    });
  }

  unarchiveProject = () => {
    const {lang, editSetting, pid} = this.props;
    const {modifyProjectSetting} = editSetting;
    const today = new Date();
    modifyProjectSetting (pid, 'archive', 
    {
      archivingDate: today,
      status: false,
    });
  }

  render (){
    const {lang, editSetting, archived, removed, isEmpty, showPopup} = this.props;
    const isArchived = archived ? archived.status : false;
    const isRemoved = removed ? removed.status : false;
    const {langinterface} = lang;
    const translation = {
      projectOptions: chooseLanguage('projectOptions',  langinterface),
      archiveProject: chooseLanguage('archiveProject',  langinterface),
      removeProject: chooseLanguage('removeProject',  langinterface), 
      backToActiveProjects: chooseLanguage('backToActiveProjects', langinterface),
      removeProjectPermanently: chooseLanguage('removeProjectPermanently', langinterface),
      archiveProjectConfirm: chooseLanguage('archiveProjectConfirm',  langinterface),
      removeProjectConfirm: chooseLanguage('removeProjectConfirm',  langinterface), 
      removeProjectPermanentlyConfirm: chooseLanguage('removeProjectPermanentlyConfirm', langinterface),
    }
    return (  
        <div className="project-details">
          <div className="project-section">
              <p className="project-section-header">{translation.projectOptions}</p>
              {
                <div className="project-section-no-details">
                  {!isArchived && !isRemoved && !isEmpty && <p onClick={()=>showPopup('confirm', translation.archiveProjectConfirm, this.archiveProject)}>{translation.archiveProject}</p>}
                  {!isArchived && !isRemoved && isEmpty && <p onClick={()=>showPopup('confirm', translation.removeProjectConfirm, this.removeProject)}>{translation.removeProject}</p>}
                </div>
              }
              {
                <div className="project-section-no-details">
                  {isArchived && !isRemoved && <p onClick={this.unarchiveProject}>{translation.backToActiveProjects}</p>}
                  {!isArchived && isRemoved && <p onClick={this.unremoveProject}>{translation.backToActiveProjects}</p>}
                </div>                
              }
              {
                <div className="project-section-no-details">
                  {isEmpty && isRemoved && <p onClick={()=>showPopup('confirm', translation.removeProjectConfirm, this.removeProjectPermanently)}>{translation.removeProjectPermanently}</p>}
                </div>                
              }
          </div>         
        </div>
    );
  }
}

export default ProjectOptions;
