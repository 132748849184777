import React, { Component } from "react";
import AddNewProject from "./AddNewProject";
import { chooseLanguage } from "../Lang/langpack";


class NewProjectIco extends Component {
  constructor () {
	  super();
	  this.state = { 
        createNewProject: false,
    }
    this.openNewProjectForm = this.openNewProjectForm.bind(this);
    this.closeNewProjectForm = this.closeNewProjectForm.bind(this);
  }

  openNewProjectForm(){
    this.setState({
        createNewProject: true
    })
  }
  closeNewProjectForm(){
    this.setState({
        createNewProject: false
    })
  }
 
  openNewProjectPopup =() => {
    this.props.showPopup('newProject', '');
  }

  componentDidMount() {

  }

  render (){
    const type = this.props.type ? this.props.type : false; 
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      newProject: chooseLanguage('newProject',  langinterface),
    }
    return (
      <div id='project-ico-set'>
            {!this.state.createNewProject && 
              <div>
                <div onClick={this.openNewProjectPopup} style={{backgroundImage: 'url(/new_project.svg', width: '3rem', height: '3rem', margin: '1rem 1rem 0 1rem', cursor: 'pointer', zIndex: 9, position: 'relative'}}></div>
                <div className='ico-label'>{translation.newProject}</div>
              </div>
            }
            {this.state.createNewProject && <AddNewProject closeNewProjectForm={this.closeNewProjectForm} createNewProject={this.props.createNewProject}/>}
        </div>  
    );
  }
}

export default NewProjectIco;
