import React, { Component } from "react";
//import { Row, Col, Form} from 'react-bootstrap';


class PopupAd extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){

    return (
        <div className="popup-ad">
          <div>
            <div>1 Miesiąc</div>
            <div>
              <p>Tworzenie dokumentów</p>
              <p></p>
              <p></p>
              <p></p>
            </div>
            <div>60kr</div>
            <div>Wybierz</div>
          </div>
          <div>
          <div>1 Rok</div>
            <div>
              <p>Tworzenie dokumentów</p>
              <p></p>
              <p></p>
              <p></p>
            </div>
            <div>500kr</div>
            <div>Wybierz</div>
          </div>
          <div>
          <div>2 Lata</div>
            <div>
              <p>Tworzenie dokumentów</p>
              <p></p>
              <p></p>
              <p></p>
            </div>
            <div>800kr</div>
            <div>Wybierz</div>
          </div>
          <div>
            <div>Zobacz demo</div>
            <div>
              <p>Tworzenie dokumentów</p>
              <p></p>
              <p></p>
              <p></p>
            </div>
            <div>0kr</div>
            <div>Wybierz</div>
          </div>
        </div>
    );
  }
}

export default PopupAd;
