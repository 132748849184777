import React, { Component } from "react";
import { FormControl } from 'react-bootstrap';


class TimeComponent extends Component {

  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveTime;
    returnValue(name, id, value );
    
  }

  render (){
    let {index, time, record} = {...this.props};
    return (
        <FormControl id={index} name={record} type='time' value={time} step="1800" onChange={this.handleChange}></FormControl>
    );
  }
}

export default TimeComponent;
