import React, { Component } from "react";


class CreatorButton extends Component {0

  click = (event) => {

  }

  render (){
    const {color, mode, buttonAction} = this.props;
    const modeStyle = mode === 'creator' ? 'page' : 'creator';
    return (
      <div id={modeStyle+"-button"} style={{filter: 'invert('+color+')'}} className="system-button">
        <div className="clickable-panel" onClick={buttonAction}></div>
        <div>
        
        </div>
      </div>
    );
  }
}

export default CreatorButton;
