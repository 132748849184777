import React, { Component } from "react";
import { requestObjectGenerator, serverRouteParser } from "../ServerRoutes/serverRoute";
import ClientItem from "./ClientItem";
import ProjectItem from "./ProjectItem";
import DocumentItem from "./DocumentItem";
import { chooseLanguage } from "../Lang/langpack";


class SearchResults extends Component {
  constructor () {
	  super();
	  this.state = {

    }
  }
  
  
  componentDidMount() { 
  }

  render (){
    const searchterm = this.props.searchterm ? this.props.searchterm : '';
    let projects = this.props.projects ? this.props.projects : [];
    let clients = this.props.clients ? this.props.clients : [];
    let documents = this.props.documents ? this.props.documents : [];
    function checkForSearchTerm(searchterm, text)
    {
      let textToCheck = new String(text).toLowerCase();
      let textToSearch = new String(searchterm).toLowerCase();
      let result = textToCheck.includes(textToSearch);
      return result;
    }

    function checkForSearchTermInInvoice(searchterm, content)
    {
      let invoice = content.content;
      let client = content.client;
      let clientChecklist = ['address','clientname','email', 'post'];
      //let invoiceChecklist = ['description'];
      let clientResult = clientChecklist.map((checkpoint, index)=>{return checkForSearchTerm(searchterm, client[checkpoint])});
      let invoiceResult = invoice.map((line, index)=>{return checkForSearchTerm(searchterm, line.description)});
      const result = clientResult.includes(true) || invoiceResult.includes(true) ? true : false;
      return result; 
    }

    function checkForSearchTermInTimelist(searchterm, content)
    {
      let timelist = content.daydescription;
      let project = content.project;
      let username = content.username;
      let timelistResult = timelist.map((line, index)=>{return checkForSearchTerm(searchterm, line)});
      const result = timelistResult.includes(true) || checkForSearchTerm(searchterm, project) || checkForSearchTerm(searchterm, username)? true : false;
      return result; 
    }

    function checkForSearchTermInDocument(searchterm, doc)
    {
      const type = doc.type;
      switch(type)
      {
        case 'faktura': 
        {
          const result = checkForSearchTermInInvoice(searchterm, doc.content);
          return result;
        }
        break;
        case 'tilbud': 
        {
          const result = checkForSearchTermInInvoice(searchterm, doc.content);
          return result;
        }
        break;
        case 'timelist': 
        {
          const result = checkForSearchTermInTimelist(searchterm, doc.content);
          return result;
        }
        break;
        default:
          return false;
        break;
      }

    }

    clients = clients && clients.filter((client)=>checkForSearchTerm(searchterm, client.clientname) || checkForSearchTerm(searchterm, client.address) || checkForSearchTerm(searchterm, client.email) || checkForSearchTerm(searchterm, client.post));
    projects = projects && projects.filter((project)=> checkForSearchTerm(searchterm, project.name))
    documents = documents && documents.filter((document)=> checkForSearchTermInDocument(searchterm, document));
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      projects: chooseLanguage('projects', langinterface),
      documents: chooseLanguage('documents', langinterface),
      clients: chooseLanguage('clients', langinterface),
    }

    return (
      <>
      <div id='search-results'>
        {clients.length > 0 && <p id='search-list-header'>{translation.clients+":"}</p>}
        {clients.length > 0 && clients.map((project, index) => <ClientItem clientController={this.props.clientController} displaySearchResult={this.props.displaySearchResult} client={project}/>)}
        {projects.length > 0 && <p id='search-list-header'>{translation.projects+":"}</p>}
        {projects.length > 0 && projects.map((project, index) => <ProjectItem projectController={this.props.projectController} displaySearchResult={this.props.displaySearchResult} project={project}/>)}
        {documents.length > 0 && <p id='search-list-header'>{translation.documents+":"}</p>}
        {documents.length > 0 && documents.map((document, index) => <DocumentItem lang={lang} openFullPreview={this.props.openFullPreview} document={document}/>)}
        </div>  
                
      </>
    );
  }
}

export default SearchResults;
