import React, { Component } from "react";
import {Form} from 'react-bootstrap';


class BreakIsPaidComponent extends Component {

  handleChange = (e) => {
    const {id, name } = e.target,
    value = e.target.checked ? true : false,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }

  render (){
    let {index, paidbreak, placeholder} = {...this.props};
    placeholder = placeholder ? placeholder : 'paid';
    return (  
        <div style={{'display':'inline-block'}}>      
            <Form.Control 
                type='checkbox'
                id={index}
                name={'loggedpaidbreak'}
                onChange={this.handleChange}
                checked={paidbreak}

            >
              
            </Form.Control>
            <Form.Label htmlFor='loggedpaidbreak'>{placeholder}</Form.Label>
        </div>
    );
  }
}

export default BreakIsPaidComponent;

