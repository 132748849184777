import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';

class UserCompanyData extends Component {
  constructor () {
	  super();
	  this.state = {
          mva: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.saveBasis = this.saveBasis.bind(this);
  }

  componentDidMount() {
  }

  saveBasis(event){
    let record = event.target.id,
      value = event.target.value;
      this.props.writeClientToState(record, value);
  }

  handleChange(){
      this.setState({
          mva: true,
      })
  }

  render (){
    const fakturadetails = this.props.fakturadetails ? this.props.fakturadetails : {};

    return (
      <div className="user-company-data">
       <h4>{fakturadetails.companyname}</h4>
       <h4>{fakturadetails.mva}{" MVA"}</h4>
       <h4>{fakturadetails.address}</h4>
       <h4>{fakturadetails.postnr}{' '}{fakturadetails.post}</h4>
       <h4>KONTO NR: {fakturadetails.kontonr}</h4>
      </div>
    );
  }
}

export default UserCompanyData;
