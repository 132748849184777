import React, { Component } from "react";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.js';
import $ from 'jquery';
import MainContainer from "./MainContainer";

class App extends Component {


  render (){
    return (
      <MainContainer/>
    );
  }
}

export default App;
