import React, { Component } from "react";
import { requestObjectGenerator, serverRouteParser } from "../ServerRoutes/serverRoute";
import SearchResults from "./SearchResults";




class SearchComponent extends Component {
  constructor () {
	  super();
	  this.state = {

    }
  }

  componentDidMount() { 

  }

  render (){
    const {clients,documents,projects, lang} = {...this.props};
    
    return (
            <SearchResults lang={lang} clientController={this.props.clientController} projectController={this.props.projectController} openFullPreview={this.props.openFullPreview} showPopup={this.props.showPopup} editDocument={this.props.editDocument} repeatDocument={this.props.repeatDocument} displaySearchResult={this.props.displaySearchResult} searchterm={this.props.searchterm} projects={projects} clients={clients} documents={documents}/>
    );
  }
}

export default SearchComponent;
