import React, { Component } from "react";

import { chooseLanguage } from "../Lang/langpack";
import { breaktimeToString, sumUpDay } from "../Features/time_functions";
import { Form } from "react-bootstrap";


class DaysList extends Component {
  constructor () {
	  super();
	  this.state = {
        daysToImport: [],
    }
  }

  selectDay = (event) => {
    let {days} = {...this.props};
    let {daysToImport} = this.state;
    const {target} = event;
    let index = target.id;
    index = +index;
    let alreadyImported = daysToImport.indexOf(days[index]);
    if (alreadyImported === -1)
    {
        daysToImport = daysToImport.concat(days[index]);
        this.setState({daysToImport});
    }
    if (alreadyImported !== -1)
    {
        delete daysToImport[alreadyImported];
        this.setState({daysToImport});
    }    
  }

  importDays = () => {
    let {daysToImport} = this.state;
    const {importDays} = this.props;
    importDays(daysToImport);
    let {importDaysWindowController} = {...this.props};
    const {close} = importDaysWindowController;
    close();
  }



  render (){
    let {days, lang, importDaysWindowController} = {...this.props};
    const {open, close} = importDaysWindowController;
    const {langinterface} = lang;
    const translation = {
        workerName: chooseLanguage('workerName', langinterface),
        project: chooseLanguage('project', langinterface),
        year: chooseLanguage('year', langinterface),
        week: chooseLanguage('week', langinterface),
        day: chooseLanguage('day', langinterface),
        in: chooseLanguage('in', langinterface),
        out: chooseLanguage('out', langinterface),
        break: chooseLanguage('break', langinterface),
        description: chooseLanguage('description', langinterface),
        timer: chooseLanguage('timer', langinterface),
        km: chooseLanguage('km', langinterface),
        extra: chooseLanguage('extra', langinterface),
        importDays: chooseLanguage('importDays', langinterface),
        total: chooseLanguage('total', langinterface),
        faktura: chooseLanguage('faktura', langinterface),
        timelist: chooseLanguage('timelist', langinterface),
        kreditnota: chooseLanguage('kreditnota', langinterface),
        tilbud: chooseLanguage('tilbud', langinterface),
      }
    let {daysToImport} = this.state;
    let backgroundImage = 'time_plus.svg';
    return (
    <div className="popup-background">
      <div className='popup-window'>
        {<div className='popup-topbeam' style={{backgroundImage: 'url(./'+backgroundImage}}><h1>{translation.importDays}</h1></div>}
        {<div className='popup-close-button' onClick={close}>X</div>}
                
        <div id='addday-content-preview-header' >
        <div>
        </div>
        <div>{translation.day}</div>
        <div>{translation.in + ' - ' + translation.out}</div>
        <div>{translation.break}</div>
        <div>{translation.timer}</div>
        <div>{translation.km}</div>
        <div>{translation.description}</div>
        </div>
        {days.map((day, index)=>(
        <div id='addday-content-preview' style={{display: 'grid', borderColor: day.extra && day.extra[index] ? "#F00" : "#000"}} key={index}>
            <div>
            <Form.Control
            type='checkbox'
            id={+index}
            name={'add'}
            onChange={this.selectDay}
            checked={daysToImport.includes(day) ? true : false}/>
            </div>
            <div>{day.loggedday[0].slice(0,5)}</div>
            <div>{day.loggedin[0]+' - '+day.loggedout[0]}</div>
            <div>{breaktimeToString(day.loggedbreak[0])}</div>
            <div>{sumUpDay(day.loggedday[0], day.loggedin[0], day.loggedout[0], day.loggedpaidbreak[0], day.loggedbreak[0])}</div>
            <div>{day.km ? day.km[0] : ''}</div>
            <div>{day.loggeddaydescription[0]}</div>
        </div>
        ))}
        <div className="popup-buttons">
            <img src='./ok.svg' 
            id='accept'
            onClick={this.importDays}
            alt='accept'
            style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
            <img src='./abort.svg' 
            id='abort' 
            onClick={close}
            alt='abort'
            style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
        </div>
      </div>
    </div>
    );
  }
}

export default DaysList;
