import React, { Component } from "react";
import Cookies from 'universal-cookie';
import RegisterNewUserPanel from '../UserPanel/RegisterNewUserPanel';
import {serverRouteParser, requestObjectGenerator, serverConnect} from "../ServerRoutes/serverRoute";

class RegisterUserContainer extends Component {
  constructor () {
	  super();
	  this.state = {
        username: '',
        usermail: '',
        userpass: '',
        passrepeat: '',
    }
    this.writeToState = this.writeToState.bind(this);
 }

  writeToState(position, value){
    this.setState({
      [position]: value
    })
  }


  registerUser = () => {
    const {username,usermail,userpass, passrepeat} = this.state;
    const {createNewUser} = this.props;
    if (username && usermail && userpass &&(userpass === passrepeat))
    {
        const user = {username,usermail,userpass, passrepeat};
        createNewUser(user);
    }
  }


  componentDidMount(){

  }

  render (){
    const state = this.state;
    const {lang, closeRegister} = this.props;
    return (
        <div className='register-panel'>
        {!state.logged && <RegisterNewUserPanel 
            registerUser={this.registerUser}
            writeToState={this.writeToState}
            closeRegister={closeRegister}
            lang={lang}
        />}
        </div>
    );
  }
}

export default RegisterUserContainer;
