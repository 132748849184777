import React, { Component } from "react";


class ClientItem extends Component {
  constructor () {
	  super();
	  this.state = {
      hoverItem: false
    }
    this.hoverClient = this.hoverClient.bind(this);
    this.unhoverClient = this.unhoverClient.bind(this);
  }

  hoverClient(){
    this.setState({
      hoverItem: true
    })
  }
  unhoverClient(){
    this.setState({
      hoverItem: false
    })
  }

  openClient = (e) =>
  {
    let id = e.target.id;
    //console.log(id);
    
    const open = this.props.clientController.open; 
    open(id);
    const {clearList} = {...this.props};
    clearList();
  }
 
 
  componentDidMount() {

  }

  render (){
    const {client, index} = {...this.props};
    
    return (
      <div key={index} id='client-list-item' onMouseEnter={this.hoverClient} onMouseLeave={this.unhoverClient}>
      <div>
        <div>
          {client.clientname}{//client.mva && '- NO'+client.mva+'MVA'
          }
        </div>
        <div>
          {client.address + ', ' + client.postnr + ' ' + client.post}
        </div>
        </div>
      <div className="client-settings">
        <img src='./client.svg' 
        alt='See details'
        style={{
          width: '2rem', 
          display: 'inline', 
          marginLeft: '1.3vw', 
          cursor: 'pointer',
          filter: this.state.hoverItem ? 'invert(0)' : 'invert(1)'
        }}/>                             
        {this.state.hoverItem && 
        <div className="clickable-panel"
        id={client.cid}
        onClick={this.openClient}></div>}
      </div>
    </div>   
    );
  }
}

export default ClientItem;
