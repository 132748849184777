import React, { Component } from "react";
import {Container, Card, DropdownButton, Dropdown} from 'react-bootstrap';
import DocumentPreview from "./DocumentPreview";
import './../timeline.css';
import DocumentOptions from "./DocumentOptions";
class DocumentListItem extends Component {
  constructor () {
	  super();
	  this.state = {
      previewFile: false,
    }
    this.editDocument = this.editDocument.bind(this);
    this.repeatDocument = this.repeatDocument.bind(this);    
    this.deleteDocument = this.deleteDocument.bind(this);
    this.regenerateDocument = this.regenerateDocument.bind(this);
    this.previewFile = this.previewFile.bind(this);
  }

  componentDidMount() {
  }

  editDocument(event) {
    let did = event.target.id;
    this.props.editDocument(did, false)
  }

  repeatDocument(event) {
    let did = event.target.id;
    this.props.editDocument(did, true)
  }


  deleteDocument(event) {
    let target = event.target;
    let did = target.id;
    let uid = target.name;
    this.props.deleteDocument(did, uid);
  }
  
  regenerateDocument(event) {
    let target = event.target;
    let did = target.id;
    let uid = target.name;
    this.props.regenerateDocument(did, uid);
  }
  

  previewFile(event){
    const did = event.target.getAttribute('id');
    //const pid = event.target.getAttribute('pid');
    console.log(did);   
    console.log(this.props.documents);   
    const { hud } = this.props;
    const document = this.props.documents && this.props.documents.filter(document => document.did === did);
    console.log(document);
    hud.previewFile.open(document[0]);
  }


    
  verifyDocumentTypeAndColor(doc){
    let type = doc.type;
    switch (type) {
      case 'timelist':
        return 'rgb(255, 127, 39)';
      break;
      case 'faktura':
        return '#0000cc';
      break;
      case 'kreditnota':
        return '#0000cc';
      break;
      case 'tilbud':
        return '#003333';
      break;
      default:
        return '#FFF';     
    } 
  }

  verifyDocumentTypeAndIco(doc){
    let type = doc.type;
    switch (type) {
      case 'timelist':
        return 'url(./timelist.svg)';
      break;
      case 'faktura':
        return 'url(./faktura.svg)';
      break;
      case 'kreditnota':
        return 'url(./kreditnota.svg)';
      break;
      case 'tilbud':
        return 'url(./tilbud.svg)';
      break;
      default:
        return 'url(./documents.svg)';
    } 
  }

  verifyHeaderType(documentFile){
    let type = documentFile.type;
    switch (type) {
      case 'timelist':
        return 'Timelist ' + documentFile.content.timelistnr;
      break;
      case 'faktura':
        return 'Faktura ' + documentFile.content.fakturanr;
       break;
       case 'kreditnota':
        return 'Kreditnota - Faktura ' + documentFile.content.fakturanr;
       break;
      case 'tilbud':
        return 'Tilbud ' + documentFile.content.tilbudnr;
      break;
      default:
      return 'Jobbleder'
  }
}

  render (){
    let doc = this.props.document;
    let content = doc.content;
    const date = new Date(doc.createdate);
    let dateString = (date.getDate() > 9 ? date.getDate() : '0'+date.getDate()) + '/' + (date.getMonth() > 8 ? (+1+date.getMonth()) : '0'+(+1+date.getMonth())) + '/' + date.getFullYear(); 
  
    return (  
      <div className="document-list-item" >
        <div><div id={doc.did} name={doc.uid}  className="document-list-item-ico" style={{backgroundImage: this.verifyDocumentTypeAndIco(doc)}} onClick={this.previewFile}></div></div>
        <div id={doc.did} className="document-list-item-header" onClick={this.previewFile}>
            <h3 id={doc.did}>{this.verifyHeaderType(doc)}</h3>
        </div> 
{window.innerHeight < window.innerWidth && <div>
          {dateString}
        </div> }
            {
              //this.state.previewFile && <DocumentPreview leftMove={100} previewFile={this.previewFile} document={doc} />\
            }       
      </div>
    );
  }
}

export default DocumentListItem;
