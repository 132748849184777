import React, { Component } from "react";
import MenuBarFull from "./MenuBarFull";
import MenuBarSimplyfy from "./MenuBarSimplyfy";

import './../menu.css';

class MenuContainer extends Component {
 
  render (){
    const props = {...this.props};
    return (
    <>
      {
        <MenuBarFull 
          clients={props.clients}
          createNewProject={props.createNewProject}
          createNewClient={props.createNewClient}
          documents={props.documents}
          editDocument={props.editDocument}
          openFullPreview={props.openFullPreview} 
          showPopup={props.showPopup}
          createNewDocument={props.createNewDocument}
          openMenu={props.openMenu}
          clientController={props.clientController}
          projectController={props.projectController}
          projects={props.projects}
          tasks={props.tasks}
          uid={props.uid}
          logout={props.logout}
          lang={props.lang}
      />}
      {
      <MenuBarSimplyfy
         closeMenu={props.closeMenu}
         openMenu={props.openMenu}
         clients={props.clients}
          createNewProject={props.createNewProject}
          createNewClient={props.createNewClient}
          documents={props.documents}
          editDocument={props.editDocument}
          openFullPreview={props.openFullPreview} 
          showPopup={props.showPopup}
          createNewDocument={props.createNewDocument}
          clientController={props.clientController}
          projectController={props.projectController}
          projects={props.projects}
          tasks={props.tasks}
          uid={props.uid}
          logout={props.logout}
          lang={props.lang}
      />}
    </>
    );
  }
}

export default MenuContainer;
