const sumUpTimer = (timelist) => {
	let sumUpTimer = 0;
	//let sumKm = 0;
	for (let i=0; i<timelist.days.length; i++)
	{
		let start = new Date('2001-01-01T' + timelist.in[i] + ':00');
		let end = new Date ('2001-01-01T' + timelist.out[i] + ':00');
		//console.log(start.toString() + ' ' + end.toString());
		let difference = end - start;
		difference = difference / 60 / 60 /1000;
		let hours, minutes;
		//console.log('full time: ' + difference);
		if(timelist.breakpaid[i] === false)
		{
			let breakDec = timelist.breaktime[i]/60;
			//console.log('break: ' + breakDec);
			difference = difference - breakDec;
		}
		hours = Math.floor(difference / 1);
		minutes = difference % 1;
		minutes = minutes * 60;
		minutes = minutes.toString();
		if (minutes.length === 1)
		{
			minutes = '0' + minutes;
		}
		//console.log(hours+':'+minutes);
		//console.log('full time - break : ' + difference);

		sumUpTimer = sumUpTimer + difference;

		//console.log(hours+':'+minutes);
	}

	return( sumUpTimer);
}

const sumUpDay = (day, intime, outtime, breakpaid, breaktime) => {
    let startDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ intime + ':00';
    let endDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ outtime + ':00';
    let start = new Date(startDateString);
    let end = new Date (endDateString);
    end = end > start ? end : end.setDate(end.getDate()+1);

    let difference = end - start;
    difference = difference / 60 / 60 /1000;
    let hours, minutes;
    //console.log('full time: ' + difference);
    if(breakpaid === false)
    {
      let breakDec = breaktime/60;
      //console.log('break: ' + breakDec);
      difference = difference - breakDec;
    }
    hours = Math.floor(difference / 1);
    minutes = difference % 1;
    minutes = minutes * 60;
    minutes = minutes.toString();
    if (minutes.length === 1)
    {
      minutes = '0' + minutes;
    }
    return ''+hours+':'+minutes;
  }

const timeToText = (sumUpTimer) => {
    let hours = Math.floor(sumUpTimer / 1);
	let minutes = sumUpTimer % 1;
	minutes = minutes * 60;
    minutes = minutes.toString();
    if (minutes.length === 1)
    {
        minutes = '0' + minutes;
    }
    let resultToDisplay = ''+hours+':'+minutes;
	return(resultToDisplay);
}

const breaktimeToString = (breaktime) => {
    switch (breaktime){
      case '0': 
        return '0:00';
      break;
      case '15': 
        return '0:15';
      break;
      case '30': 
        return '0:30';
      break;
      case '45': 
      return '0:45';
      break;
      case '60': 
        return '1:00';
      break;
      case '120': 
        return '2:00';
      break;
      default: 
       return '0:00';
    }
  }

export {sumUpDay, sumUpTimer, timeToText, breaktimeToString};