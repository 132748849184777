import React, { Component } from "react";

import Timeline from "./Timeline";
import DocumentList from "./DocumentList";
import DocumentListCard from "./DocumentListCard";
import DemoBookingsCalendarContainer from "../Calendar/DemoBookingsCalendarContainer";

class LoggedInDocumentsDisplay extends Component {
  constructor () {
	  super();
	  this.state = {
    }

  }

  render (){
    const {lang, view} = this.props;
    //console.log(documents);
    return (
        <div id='document-display'>
            {(view==='timeline' || view==='calendar') && <Timeline 
            lang={lang}
            hud={this.props.hud}
            openFullPreview={this.props.openFullPreview}
            groupByCreateDate={this.props.groupByCreateDate}
            handleViewChange={this.props.handleViewChange}
            projects={this.props.projects}
            zoom={this.props.zoom}
            documents={this.props.documents}
            documentOptions={this.props.documentOptions}
            uid={this.props.uid} view={view}/>}
            {view==='list' && <DocumentList 
            hud={this.props.hud}
            openFullPreview={this.props.openFullPreview}
            handleViewChange={this.props.handleViewChange}
            projects={this.props.projects}
            documents={this.props.documents}
            documentOptions={this.props.documentOptions}
            uid={this.props.uid}
            view={this.state.view}
            lang={lang}
            />}
            {view==='cards' && <DocumentListCard 
            openFullPreview={this.props.openFullPreview}
            handleViewChange={this.props.handleViewChange}
            projects={this.props.projects}
            documents={this.props.documents}
            documentOptions={this.props.documentOptions}
            uid={this.props.uid}
            view={this.state.view}
            lang={lang}
            />}
        
        </div>
    );
  }
}

export default LoggedInDocumentsDisplay;
