import React, { Component } from "react";
import {Form} from 'react-bootstrap';


class BreakValueComponent extends Component {

  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }

  render (){
    let {index, breakValue} = this.props;
    //console.log(index + " : " + breakValue);
    return (        
      <Form.Control style={{display: 'inline'}} 
      id={index} 
      name={'loggedbreak'}  
      as="select" 
      onChange={this.handleChange}>
          <option selected={breakValue === '0' && true} value='0'>0 min</option>
          <option selected={breakValue === '15' && true} value='15'>15 min</option>
          <option selected={breakValue === '30' && true} value='30'>30 min</option>
          <option selected={breakValue === '45' && true} value='45'>45 min</option>
          <option selected={breakValue === '60' && true} value='60'>60 min</option>
          <option selected={breakValue === '120' && true} value='120'>120 min</option>
      </Form.Control>
    );
  }
}

export default BreakValueComponent;
