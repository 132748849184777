import React, { Component } from "react";
import { chooseLanguage } from "../Lang/langpack";


class AllClientsIco extends Component {
  constructor () {
	  super();
	  this.state = { 
    }
  }

  openMenu = () => {
    let callback = this.props.openMenu;
    let {clearList} = {...this.props};
    clearList();
    callback('clients');
  }

  render (){
    const type = this.props.type ? this.props.type : false;
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      allClients: chooseLanguage('allClients',  langinterface),
    } 
    return (
      <div id='project-ico-set'>
        <div>
            <div onClick={this.openMenu} style={{backgroundImage: 'url(/partners.svg', width: '3rem', height: '3rem', margin: '1rem 1rem 0 1rem', cursor: 'pointer'}}></div>
            <div className='ico-label'>{translation.allClients}</div>
        </div>
      </div>  
    );
  }
}

export default AllClientsIco;
