import React, { Component } from "react";
import { Row, Col, Form, FormLabel} from 'react-bootstrap';

class RangeComponent extends Component {
  
  handleChange = (e) => {
    let {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    value = +value;
    returnValue(name, id, value );
  }

  render (){
    let {index, value, record} = {...this.props};
    return (
        <>
            <Form.Control
            id={index}
            name={record} 
            type="range" 
            min={1}
            max={100}
            value={value} 
            placeholder={record} 
            onChange={this.handleChange} 
            required/>
             <Form.Label className="text-right d-lg-block"><h4>{value + ' '}</h4></Form.Label>
        </>

    );
  }
}

export default RangeComponent;
