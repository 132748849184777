import React, { Component } from "react";
import {Tabs, Tab} from 'react-bootstrap';
import SettingKnob from "./SettingKnob";
import { chooseLanguage } from "../Lang/langpack";


class LoggedInSettingsDocumentSettings extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }

 
  render (){
    const {editSetting, agrees, tilbuddetails, fakturadetails, timelistdetails} = {...this.props};
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      tilbud: chooseLanguage('tilbud',  langinterface),
      timelist: chooseLanguage('timelist',  langinterface),
      faktura: chooseLanguage('faktura',  langinterface),  
      document: chooseLanguage('document',  langinterface),
      expiryDate: chooseLanguage('expiryDate',  langinterface), 
      interfaceLang: chooseLanguage('interfaceLang',  langinterface),
      jobblederSettings: chooseLanguage('jobblederSettings',  langinterface),
      nr: chooseLanguage('nr', langinterface),
      printGiro: chooseLanguage('printGiro', langinterface),
      hourprice: chooseLanguage('hourprice', langinterface),
      kmprice: chooseLanguage('kmprice', langinterface),
      in: chooseLanguage('in', langinterface),
      out: chooseLanguage('out', langinterface),
      break: chooseLanguage('break', langinterface),
      breakIsPaid: chooseLanguage('breakIsPaid', langinterface),
      km: chooseLanguage('km', langinterface),
      vat: chooseLanguage('mva', langinterface),
    }
    return (
        <div className="settings-document-settings">
            <div className="tilbud-settings">  
                <h3>{translation.tilbud}</h3>
                <div className="knob-panel">
                <SettingKnob type='number' editSetting={editSetting} setting={{value: tilbuddetails.tilbudnr, setting: 'tilbudnr', option: translation.tilbud + ' ' + translation.nr}}/>
                <SettingKnob type='range' editSetting={editSetting} setting={{value: tilbuddetails.tilbuddue, setting: 'tilbuddue', option: translation.expiryDate}}/>
            </div>
            </div>
            <div className="faktura-settings">  
                <h3>{translation.faktura}</h3>
                <div className="knob-panel">
                <SettingKnob type='number' editSetting={editSetting} setting={{value: fakturadetails.fakturanr, setting: 'fakturanr', option: translation.faktura + ' ' + translation.nr}}/>
                <SettingKnob type='range' editSetting={editSetting} setting={{value: fakturadetails.paymentdue, setting: 'fakturadue', option: translation.expiryDate}}/>
                <SettingKnob type='toggle' editSetting={editSetting} setting={{value: fakturadetails.giro, setting: 'giro', option: translation.printGiro}}/>
                <SettingKnob type='number' editSetting={editSetting} setting={{value: fakturadetails.hourprice, setting: 'hourprice', option: translation.hourprice}}/>
                <SettingKnob type='number' editSetting={editSetting} setting={{value: fakturadetails.kmprice, setting: 'kmprice', option: translation.kmprice}}/>
                <SettingKnob type='vat' editSetting={editSetting} setting={{value: fakturadetails.vat, setting: 'vat', option: translation.vat}}/>
            </div>
            </div>
            <div className="timelist-settings">  
                <h3>{translation.timelist}</h3>
                <div className="knob-panel">
                <SettingKnob type='number' editSetting={editSetting} setting={{value: timelistdetails.timelistnr, setting: 'timelistnr', option: translation.timelist + ' ' + translation.nr}}/>
                <SettingKnob type='time' editSetting={editSetting} setting={{value: timelistdetails.in, setting: 'timelistin', option: translation.in}}/>
                <SettingKnob type='time' editSetting={editSetting} setting={{value: timelistdetails.out, setting: 'timelistout', option: translation.out}}/>
                <SettingKnob type='breaktime' editSetting={editSetting} setting={{value: timelistdetails.breaktime, setting: 'timelistbreaktime', option: translation.break}}/>       
                <SettingKnob type='toggle' editSetting={editSetting} setting={{value: timelistdetails.breakpaid, setting: 'timelistbreakpaid', option: translation.breakIsPaid}}/>
                <SettingKnob type='toggle' editSetting={editSetting} setting={{value: timelistdetails.km, setting: 'km', option: translation.km}}/>
                </div>
            </div>
        </div>
    );
  }
}

export default LoggedInSettingsDocumentSettings;
