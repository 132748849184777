import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';
import DateComponent from "./DateComponent";
import { chooseLanguage } from "../Lang/langpack";

class TilbudDatesModule extends Component {

  render (){
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      issueDate: chooseLanguage('issueDate', langinterface),
      dueDate: chooseLanguage('dueDate', langinterface),
    }
    return (
      <>
      <Form.Group as={Row} className="justify-content-md-center" id="document-fieldset">
        <Form.Label className='text-right' column xs={3} lg={2}><h4>{translation.issueDate}:</h4></Form.Label>
        <Col lg={4} xs={9}>
          <DateComponent
              lang={lang}
              saveDate={this.props.saveDate} 
              day={this.props.tilbudissue} 
              index={'tilbudissue'}
          />
        </Col>
        <Form.Label className='text-right' column xs={3} lg={2}><h4>{translation.dueDate}:</h4></Form.Label>
        <Col xs={9} lg={4}>
          <DateComponent
            lang={lang}
            saveDate={this.props.saveDate} 
            day={this.props.tilbuddue} 
            index={'tilbuddue'}
          />
        </Col>
      </Form.Group>
    </>
    );
  }
}

export default TilbudDatesModule;
