import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';
import { postkode } from "../Documents/postkode";
import AutocompleteComponent from './AutocompleteComponent';
import { chooseLanguage } from "../Lang/langpack";

class AddressComponent extends Component {

  onChange = e => {
    const userInput = e.currentTarget.value;
    this.props.savePost(userInput);
  };

  render (){
      let {postnr, post, lang} = this.props;
      const {langinterface} = lang;
      const translation = {
        post: chooseLanguage('post', langinterface),
        postcode: chooseLanguage('postnr', langinterface),
      }
    return (
      <>
        <Form.Label style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} className='text-center d-lg-block' column xs={0} lg={3}><h4>{translation.post}</h4></Form.Label>
        <Col lg={3} xs={6}>
        <Form.Control id='postnr' placeholder={translation.postcode} type='text' value={postnr} onChange={this.onChange} maxLength={4} required/>
        </Col>
        <Col lg={6} xs={6}>
        <Form.Control style={{cursor: 'not-allowed'}} value={post} id='post' type="text" placeholder={translation.post}/>
        </Col>
      </>
    );
  }
}

export default AddressComponent;
