import React, { Component } from "react";
import { Button} from 'react-bootstrap';


class PopupWarning extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){
    const content = this.props.content;
    return (
      <div className="popup-warning">
      <div>
        <p>{this.props.content}</p>
      </div>
    </div>
    );
  }
}

export default PopupWarning;
