import React, { Component } from "react";
import {Tabs, Tab} from 'react-bootstrap';
import SettingKnob from "./SettingKnob";
import { chooseLanguage } from "../Lang/langpack";


class CompanySettings extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }




  render (){
    let fakturadetails = this.props.fakturadetails;
    const {editSetting} = {...this.props};
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      businessName: chooseLanguage('businessName',  langinterface),
      postnr: chooseLanguage('postnr',  langinterface),
      kontonr: chooseLanguage('kontonr',  langinterface),  
      address: chooseLanguage('address',  langinterface),
      mva: chooseLanguage('mva',  langinterface),
      post: chooseLanguage('post',  langinterface), 
      invoiceSettings: chooseLanguage('invoiceSettings',  langinterface),
    }
    return ( 
      <div className="settings-document-settings">
            <h3>{translation.invoiceSettings}</h3>
            <div className="knob-panel">
              <SettingKnob  type='text' editSetting={editSetting} setting={{value: fakturadetails.companyname, setting: 'companyname', option: translation.businessName}}/>
              <SettingKnob  type='mva' editSetting={editSetting} setting={{value: fakturadetails.mva, setting: 'mva', option: translation.mva}}/>
              <SettingKnob  type='text' editSetting={editSetting} setting={{value: fakturadetails.address, setting: 'companyaddress', option: translation.address}}/>
              <SettingKnob  type='postnr' editSetting={editSetting} setting={{value: fakturadetails.postnr, setting: 'companypostnr', option: translation.postnr}}/>
              <SettingKnob  type='post' setting={{value: fakturadetails.post, setting: 'companypost', option: translation.post}}/>
              <SettingKnob  type='konto' editSetting={editSetting} setting={{value: fakturadetails.kontonr, setting: 'companykontonr', option: translation.kontonr}}/>

          </div>
        </div>

    );
  }
}

export default CompanySettings;
