import React, { Component } from "react";
import DocumentOptions from "./DocumentOptions";
import {Container, Row, Col} from 'react-bootstrap';
import CloseButton from "../FormComponents/CloseButton";
import FullDocumentPreviewProject from "./FullDocumentPreviewProject";
import { dateToSlashString } from "../Features/dateFeatures";
import { chooseLanguage } from "../Lang/langpack";
import { index } from "d3";

class FullDocumentPreview extends Component {
  constructor () {
	  super();
	  this.state = {
      sumUpTimer: 0,
    }
  }

  openClient = (e) =>
  {
    let id = e.target.id;
    console.log(id);
    
    if (id !== '0')
    {
      const open = this.props.clientController.open; 
      open(id);
      this.props.closeFullPreview();
    }
    if (id === '0')
    {
      this.props.showPopup('warning','There is no data found about client.');
    }
  }

  componentDidMount() {

  }

  

  getWeekOfDate(date){
    //console.log(date);
    Date.prototype.getWeek = function() {
      var date = new Date(this.getTime());
      //console.log(date);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
      //console.log(date);
      var week1 = new Date(date.getFullYear(), 0, 4);
      return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }
    let dte= new Date(date.slice(6,10), +date.slice(3,5)-1, date.slice(0,2));
    let week = dte.getWeek();
    return week;
  }
  getYearOfDate(date){
    //console.log("data do konwersji: " + date);
    let dte= new Date(date.slice(6,10), +date.slice(3,5)-1, date.slice(0,2));
    //console.log(dte);
    const fullYear = dte.getFullYear();
    //console.log(fullYear);
    return fullYear;
  }


  getYear(date){
    let dte = new Date(date);
    return dte.getFullYear();
  }


  verifyHeaderType = (documentFile) => {
    let type = documentFile.type;
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      timelist: chooseLanguage('timelist', langinterface),
      faktura: chooseLanguage('faktura', langinterface),
      tilbud: chooseLanguage('tilbud', langinterface),
      kreditnota: chooseLanguage('kreditnota', langinterface),
    }
    switch (type) {
      case 'timelist':
        return '' + translation.timelist + ' ' + documentFile.content.timelistnr;
      break;
      case 'kreditnota':
        return '' + translation.kreditnota + ' - ' + translation.faktura + ' ' + documentFile.content.fakturanr;
       break;
      case 'faktura':
        return '' + translation.faktura + ' ' + documentFile.content.fakturanr;
       break;
      case 'tilbud':
        return '' + translation.tilbud + ' ' + documentFile.content.tilbudnr;
      break;
      default:
      return 'Jobbleder'
  }
}

sumUpFaktura = (content) => {
  //console.log(content);
  let totalPrice = 0;
  let mvaCalculation = [0,0,0,0];
  let mvaBase = [0,0,0,0]
  content.map((position, index)=>
  {
    let basePrice = +position.netprice;
    let amount = +position.multiplier;
    let sumPrice = basePrice * amount;
    let mva = +position.vat;
    let mvaPrice = sumPrice * (mva/100);
    let total = sumPrice + mvaPrice;
    totalPrice = totalPrice + total;
    switch (mva) {
      case 0: 
        mvaCalculation[0] = mvaCalculation[0]+mvaPrice;
        mvaBase[0] = mvaBase[0] + sumPrice;
      break;
      case 12: 
        mvaCalculation[1] = mvaCalculation[1]+mvaPrice;
        mvaBase[1] = mvaBase[1] + sumPrice;
      break;				
      case 15: 
        mvaCalculation[2] = mvaCalculation[2]+mvaPrice;
        mvaBase[2] = mvaBase[2] + sumPrice;
      break;			
      case 25: 
        mvaCalculation[3] = mvaCalculation[3]+mvaPrice;
        mvaBase[3] = mvaBase[3] + sumPrice;
      break;			
    }
  });
  const result = {totalPrice, mvaCalculation, mvaBase};
  return result;
}

sumUpTimelist = (daysArray, intimeArray, outtimeArray, breakpaidArray, breaktimeArray, kmArray, extraArray) => {
  let sumUpTimer = 0;
  let sumUpExtraTimer = 0;
  let sum = {
    regular: {
      hours: 0,
      minutes: 0,
      km: 0,
    },
    extra: {
      hours: 0,
      minutes: 0,
      km: 0,
    },     
  };
  breakpaidArray && breakpaidArray.map((isPaid, i)=>{
    let day = daysArray[i];
    let intime = intimeArray[i];
    let outtime = outtimeArray[i];
    let startDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ intime + ':00';
    let endDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ outtime + ':00';
    let start = new Date(startDateString);
    let end = new Date (endDateString);
    end = end > start ? end : end.setDate(end.getDate()+1);

    ////console.log(start.toString() + ' ' + end.toString());
    let difference = end - start;
			difference = difference / 60 / 60 /1000;
      let km = kmArray && kmArray[i] ? parseInt(kmArray[i]) : 0;
			//console.log('full time: ' + difference);
			if(!isPaid)
			{
				let breakDec = breaktimeArray[i]/60;
				//console.log('break: ' + breakDec);
				difference = difference - breakDec;
			}
      if(extraArray && extraArray[i])
      {
        sum.extra.km =  sum.extra.km + km;
        sumUpExtraTimer = sumUpExtraTimer + difference;        
      }
      if((extraArray && !extraArray[i]) || !extraArray)
      {
        sum.regular.km = sum.regular.km + km;
        sumUpTimer = sumUpTimer + difference;
      }
    })
    
    sum.regular.hours = Math.floor(sumUpTimer / 1);
		sum.regular.minutes = sumUpTimer % 1;
		sum.regular.minutes = sum.regular.minutes * 60;
    sum.regular.minutes = sum.regular.minutes.toFixed(0);
		sum.regular.minutes = sum.regular.minutes.toString();
		if (sum.regular.minutes.length === 1)
		{
			sum.regular.minutes = '0' + sum.regular.minutes;
		}
    sum.extra.hours = Math.floor(sumUpExtraTimer / 1);
		sum.extra.minutes = sumUpExtraTimer % 1;
		sum.extra.minutes = sum.extra.minutes * 60;
    sum.extra.minutes = sum.extra.minutes.toFixed(0);
		sum.extra.minutes = sum.extra.minutes.toString();
		if (sum.extra.minutes.length === 1)
		{
			sum.extra.minutes = '0' + sum.extra.minutes;
		}

    return sum;
  }

  sumUpDay = (day, intime, outtime, breakpaid, breaktime) => {
    let startDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ intime + ':00';
    let endDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ outtime + ':00';
    let start = new Date(startDateString);
    let end = new Date (endDateString);
    end = end > start ? end : end.setDate(end.getDate()+1);

    let difference = end - start;
    difference = difference / 60 / 60 /1000;
    let hours, minutes;
    //console.log('full time: ' + difference);
    if(breakpaid === false)
    {
      let breakDec = breaktime/60;
      //console.log('break: ' + breakDec);
      difference = difference - breakDec;
    }
    hours = Math.floor(difference / 1);
    minutes = difference % 1;
    minutes = minutes * 60;
    minutes = minutes.toFixed(0);
    minutes = minutes.toString();
    if (minutes.length === 1)
    {
      minutes = '0' + minutes;
    }
    return ''+hours+':'+minutes;
  }
  breaktimeToString = (breaktime) => {
    switch (breaktime){
      case '0': 
        return '0:00';
      break;
      case '15': 
        return '0:15';
      break;
      case '30': 
        return '0:30';
      break;
      case '45': 
      return '0:45';
      break;
      case '60': 
        return '1:00';
      break;
      case '120': 
        return '2:00';
      break;
      default: 
       return '';
    }
  }

  numberWithSpaces = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
  }

  openProjectWindow = (e) => {
    const {closeFullPreview, projectController} = this.props;
    const pid = e.target.getAttribute('pid');
    projectController.open(pid);
    closeFullPreview();
  }
    
  /*verifyDocumentType(documentFile){
    let type = documentFile.type;
    let sumUp, totalPrice, mvaBase, mvaCalculation;
    //console.log(type);
    switch (type) {
      case 'timelist':
        const week = this.getWeekOfDate(documentFile.content.days[0]) === this.getWeekOfDate(documentFile.content.days[documentFile.content.days.length-1]) ? this.getWeekOfDate(documentFile.content.days[0]) : ''+this.getWeekOfDate(documentFile.content.days[0]) + ' - ' + this.getWeekOfDate(documentFile.content.days[documentFile.content.days.length-1]);
        const year = this.getYearOfDate(documentFile.content.days[0]) === this.getYearOfDate(documentFile.content.days[documentFile.content.days.length-1]) ? this.getYearOfDate(documentFile.content.days[0]) : ''+this.getYearOfDate(documentFile.content.days[0]) + ' - ' + this.getYearOfDate(documentFile.content.days[documentFile.content.days.length-1]);
        sumUp = this.sumUpTimelist(documentFile.content.in, documentFile.content.out, documentFile.content.breakpaid, documentFile.content.breaktime, documentFile.content.km, documentFile.content.extra);
        const timelistPreview = 
        <> 
            <div id='timelist-data-preview' >
              <div>NAVN</div>
              <div>PROJEKT</div>
              <div>UKE</div>
              <div>ÅR</div>
            </div>
            <div id='timelist-data-preview' >
              <div>{documentFile.content.username}</div>
              <FullDocumentPreviewProject closeFullPreview={this.props.closeFullPreview} projectController={this.props.projectController} pid={documentFile.pid} projectName={documentFile.content.project}/>
              <div>{week}</div>
              <div>{year}</div>
            </div>
            <div id='timelist-content-mini-preview-header' >
              <div>{'DAY'}</div>
              <div>{'IN - OUT'}</div>
              <div>{'BREAK'}</div>
              <div>{'TIMER'}</div>
              <div>{'KM'}</div>
              <div>{'DESCRIPTION'}</div>
              
            </div>
          {documentFile.content.days.map((day, index)=>(
            <div id='timelist-content-mini-preview' style={{borderColor: documentFile.content.extra && documentFile.content.extra[index] ? "#F00" : "#000"}} key={index}>
              <div>{day.slice(0,5)}</div>
              <div>{documentFile.content.in[index]+' - '+documentFile.content.out[index]}</div>
              <div>{this.breaktimeToString(documentFile.content.breaktime[index])}</div>
              <div>{this.sumUpDay(documentFile.content.in[index], documentFile.content.out[index], documentFile.content.breakpaid[index], documentFile.content.breaktime[index])}</div>
              <div>{documentFile.content.km ? documentFile.content.km[index] : ''}</div>
              <div>{documentFile.content.daydescription[index]}</div>
            </div>
          ))}
            <div id='timelist-sumup'>
              <div>{'TIMER: '}</div>
              <div>{''+sumUp.regular.hours+ ':'+sumUp.regular.minutes}</div>
              <div>{'KM: '}</div>
              <div>{''+sumUp.regular.km}</div>
              <div>{'EXTRA TIMER: '}</div>
              <div>{''+sumUp.extra.hours+ ':'+sumUp.extra.minutes}</div>
              <div>{'EXTRA KM: '}</div>
              <div>{''+sumUp.extra.km}</div>
            </div>
        </>;

        return timelistPreview;
      break;
      case 'kreditnota':
        sumUp = this.sumUpFaktura(documentFile.content.content);
        mvaBase= sumUp.mvaBase;
        mvaCalculation= sumUp.mvaCalculation;
        totalPrice = +-sumUp.totalPrice;
        const kreditnotaPreview = 
        <> 
        <div id='faktura-data-preview'>
            <div>{documentFile.content.companyname}</div>
            <div>{documentFile.content.address}</div>
            <div>{documentFile.content.postnr + ' ' + documentFile.content.post}</div>
            <div>{'org nr: NO'+documentFile.content.mva+'MVA'}</div> 
          </div>  
          <div id='faktura-data-preview' >
           <div className="client-card-link" id={documentFile.content.client.cid ? documentFile.content.client.cid : '0'} onClick={this.openClient}>{documentFile.content.client.clientname}</div>
            <div>{documentFile.content.client.address}</div>
            <div>{documentFile.content.client.postnr + ' ' + documentFile.content.client.post}</div>
            <div>{documentFile.content.client.mva ? 'org nr: NO'+documentFile.content.client.mva+'MVA' : ''}</div> 
          </div>         
          <div id='faktura-content-preview' >
            <div>{'VARENR'}</div>
            <div>{'DESCRIPTION'}</div>
            <div>{'NET'}</div>
            <div>{'AMOUNT'}</div>
            <div>{'MVA'}</div> 
            <div>{'SUM'}</div>
          </div>
        {documentFile.content.content.map((line, index)=>(
          <div id='faktura-content-preview' key={index}>
          <div>{index+1+'.'}</div>
            <div>{line.description}</div>
            <div>{this.numberWithSpaces((+line.netprice).toFixed(2))}</div>
            <div>{this.numberWithSpaces((+line.multiplier).toFixed(2))}</div>
            <div>{line.vat+'%'}</div>
            <div>{this.numberWithSpaces((+line.netprice*+line.multiplier + (+line.netprice*+line.multiplier)*(+line.vat/100)).toFixed(2))}</div>
          </div>
        ))}
        <div id='timelist-sumup-mini'>
            <div>{'TOTAL: '}</div>
            <div>{''+this.numberWithSpaces(totalPrice.toFixed(2))}</div>
            {mvaBase[0]!==0 &&
            <>
            <div>{'MVA grunnlag 0%: '}</div><div>{this.numberWithSpaces((+mvaBase[0]).toFixed(2))}</div>
            <div>{'MVA 0%: '}</div><div>{this.numberWithSpaces((+mvaCalculation[0]).toFixed(2))}</div>
            </>
            }
            {mvaBase[1]!==0 &&
            <>
            <div>{'MVA grunnlag 12%: '}</div><div>{this.numberWithSpaces((+mvaBase[1]).toFixed(2))}</div>
            <div>{'MVA 12%: '}</div><div>{this.numberWithSpaces((+mvaCalculation[1]).toFixed(2))}</div>
            </>
            }
            {mvaBase[2]!==0 &&
            <>
            <div>{'MVA grunnlag 15%: '}</div><div>{this.numberWithSpaces((+mvaBase[2]).toFixed(2))}</div>
            <div>{'MVA 15%: '}</div><div>{this.numberWithSpaces((+mvaCalculation[2]).toFixed(2))}</div>
            </>
            }
            {mvaBase[3]!==0 &&
            <>
            <div>{'MVA grunnlag 25%: '}</div><div>{this.numberWithSpaces((+mvaBase[3]).toFixed(2))}</div>
            <div>{'MVA 25%: '}</div><div>{this.numberWithSpaces((+mvaCalculation[3]).toFixed(2))}</div>
            </>
            }
          </div>
          
      </>;
        return kreditnotaPreview;
      break;
      case 'faktura':
        sumUp = this.sumUpFaktura(documentFile.content.content);
        mvaBase= sumUp.mvaBase;
        mvaCalculation= sumUp.mvaCalculation;
        totalPrice = +sumUp.totalPrice;
        const fakturaPreview = 
        <> 
        <div id='faktura-data-preview' >
            <div>{documentFile.content.companyname}</div>
            <div>{documentFile.content.address}</div>
            <div>{documentFile.content.postnr + ' ' + documentFile.content.post}</div>
            <div>{'org nr: NO'+documentFile.content.mva+'MVA'}</div> 
          </div>  
          <div id='faktura-data-preview' >
           <div className="client-card-link" id={documentFile.content.client.cid ? documentFile.content.client.cid : '0'} onClick={this.openClient}>{documentFile.content.client.clientname}</div>
            <div>{documentFile.content.client.address}</div>
            <div>{documentFile.content.client.postnr + ' ' + documentFile.content.client.post}</div>
            <div>{documentFile.content.client.mva ? 'org nr: NO'+documentFile.content.client.mva+'MVA' : ''}</div> 
          </div>         
          <div id='faktura-content-preview' >
            <div>{'VARENR'}</div>
            <div>{'DESCRIPTION'}</div>
            <div>{'NET'}</div>
            <div>{'AMOUNT'}</div>
            <div>{'MVA'}</div> 
            <div>{'SUM'}</div>
          </div>
        {documentFile.content.content.map((line, index)=>(
          <div id='faktura-content-preview' key={index}>
          <div>{index+1+'.'}</div>
            <div>{line.description}</div>
            <div>{this.numberWithSpaces((+line.netprice).toFixed(2))}</div>
            <div>{this.numberWithSpaces((+line.multiplier).toFixed(2))}</div>
            <div>{line.vat+'%'}</div>
            <div>{this.numberWithSpaces((+line.netprice*+line.multiplier + (+line.netprice*+line.multiplier)*(+line.vat/100)).toFixed(2))}</div>
          </div>
        ))}
        <div id='timelist-sumup-mini'>
            <div>{'TOTAL: '}</div>
            <div>{''+this.numberWithSpaces(totalPrice.toFixed(2))}</div>
            {mvaBase[0]>0 &&
            <>
            <div>{'MVA grunnlag 0%: '}</div><div>{this.numberWithSpaces(mvaBase[0].toFixed(2))}</div>
            <div>{'MVA 0%: '}</div><div>{this.numberWithSpaces(mvaCalculation[0].toFixed(2))}</div>
            </>
            }
            {mvaBase[1]>0 &&
            <>
            <div>{'MVA grunnlag 12%: '}</div><div>{this.numberWithSpaces(mvaBase[1].toFixed(2))}</div>
            <div>{'MVA 12%: '}</div><div>{this.numberWithSpaces(mvaCalculation[1].toFixed(2))}</div>
            </>
            }
            {mvaBase[2]>0 &&
            <>
            <div>{'MVA grunnlag 15%: '}</div><div>{this.numberWithSpaces(mvaBase[2].toFixed(2))}</div>
            <div>{'MVA 15%: '}</div><div>{this.numberWithSpaces(mvaCalculation[2].toFixed(2))}</div>
            </>
            }
            {mvaBase[3]>0 &&
            <>
            <div>{'MVA grunnlag 25%: '}</div><div>{this.numberWithSpaces(mvaBase[3].toFixed(2))}</div>
            <div>{'MVA 25%: '}</div><div>{this.numberWithSpaces(mvaCalculation[3].toFixed(2))}</div>
            </>
            }
          </div>
      </>;
        return fakturaPreview;
      break;
      case 'tilbud':
        sumUp = this.sumUpFaktura(documentFile.content.content);
        mvaBase= sumUp.mvaBase;
        mvaCalculation= sumUp.mvaCalculation;
        totalPrice = sumUp.totalPrice;
        const tilbudPreview = 
        <>
        <div id='tilbud-data-preview' >
            <div>{documentFile.content.companyname}</div>
            <div>{documentFile.content.address}</div>
            <div>{documentFile.content.postnr + ' ' + documentFile.content.post}</div>
            <div>{'org nr: NO'+documentFile.content.mva+'MVA'}</div> 
          </div>  
          <div id='tilbud-data-preview' >
            <div className="client-card-link" id={documentFile.content.client.cid ? documentFile.content.client.cid : '0'} onClick={this.openClient}>{documentFile.content.client.clientname}</div>
            <div>{documentFile.content.client.address}</div>
            <div>{documentFile.content.client.postnr + ' ' + documentFile.content.client.post}</div>
            <div>{documentFile.content.client.mva ? 'org nr: NO'+documentFile.content.client.mva+'MVA' : ''}</div> 
          </div>
          <div id='tilbud-data-preview' >
            <div>{documentFile.pid}</div>
          </div>         
          <div id='tilbud-content-preview' >
            <div>{'VARENR'}</div>
            <div>{'DESCRIPTION'}</div>
            <div>{'NET'}</div>
            <div>{'AMOUNT'}</div>
            <div>{'MVA'}</div> 
            <div>{'SUM'}</div>
          </div>
        {documentFile.content.content.map((line, index)=>(
          <div id='tilbud-content-preview' key={index}>
            <div>{index+1+'.'}</div>
            <div>{line.description}</div>
            <div>{this.numberWithSpaces((+line.netprice).toFixed(2))}</div>
            <div>{this.numberWithSpaces((+line.multiplier).toFixed(2))}</div>
            <div>{line.vat+'%'}</div>
            <div>{this.numberWithSpaces((+line.netprice*+line.multiplier + (+line.netprice*+line.multiplier)*(+line.vat/100)).toFixed(2))}</div>
          </div>
        ))}
        <div id='timelist-sumup-mini'>
            <div>{'TOTAL: '}</div>
            <div>{''+this.numberWithSpaces(totalPrice.toFixed(2))}</div>
            {mvaBase[0]>0 &&
            <>
            <div>{'MVA grunnlag 0%: '}</div><div>{this.numberWithSpaces(mvaBase[0].toFixed(2))}</div>
            <div>{'MVA 0%: '}</div><div>{this.numberWithSpaces(mvaCalculation[0].toFixed(2))}</div>
            </>
            }
            {mvaBase[1]>0 &&
            <>
            <div>{'MVA grunnlag 12%: '}</div><div>{this.numberWithSpaces(mvaBase[1].toFixed(2))}</div>
            <div>{'MVA 12%: '}</div><div>{this.numberWithSpaces(mvaCalculation[1].toFixed(2))}</div>
            </>
            }
            {mvaBase[2]>0 &&
            <>
            <div>{'MVA grunnlag 15%: '}</div><div>{this.numberWithSpaces(mvaBase[2].toFixed(2))}</div>
            <div>{'MVA 15%: '}</div><div>{this.numberWithSpaces(mvaCalculation[2].toFixed(2))}</div>
            </>
            }
            {mvaBase[3]>0 &&
            <>
            <div>{'MVA grunnlag 25%: '}</div><div>{this.numberWithSpaces(mvaBase[3].toFixed(2))}</div>
            <div>{'MVA 25%: '}</div><div>{this.numberWithSpaces(mvaCalculation[3].toFixed(2))}</div>
            </>
            }
          </div>
        </>;
        return tilbudPreview;
      break;
      default:
        return <></>;     
    } 
  }*/
  checkForKM(kmArray){
    if(Array.isArray(kmArray))
    {
      const areAnyKm = kmArray.map((position, index)=>{
        if (position > 0)
        {
          return true;
        }
        else
        {
          return false;
        } 
      })
      const result = areAnyKm.includes(true);
      return result;
    }
    else
    {
      return false;
    }
  }

  verifyDocumentType(documentFile){
    let type = documentFile.type;
    ////console.log(type);
    let sumUp, totalPrice, mvaBase, mvaCalculation;
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      workerName: chooseLanguage('workerName', langinterface),
      project: chooseLanguage('project', langinterface),
      year: chooseLanguage('year', langinterface),
      week: chooseLanguage('week', langinterface),
      day: chooseLanguage('day', langinterface),
      in: chooseLanguage('in', langinterface),
      out: chooseLanguage('out', langinterface),
      break: chooseLanguage('break', langinterface),
      description: chooseLanguage('description', langinterface),
      timer: chooseLanguage('timer', langinterface),
      km: chooseLanguage('km', langinterface),
      extra: chooseLanguage('extra', langinterface),
      tilbudIssueDate: chooseLanguage('tilbudIssueDate', langinterface),
      fakturaDueDate: chooseLanguage('fakturaDueDate', langinterface),
      fakturaIssueDate: chooseLanguage('fakturaIssueDate', langinterface),
      inkasovarseldato: chooseLanguage('inkasovarseldato', langinterface),
      varselDate: chooseLanguage('varselDate', langinterface),
      netprice: chooseLanguage('netprice', langinterface),
      amount: chooseLanguage('amount', langinterface),
      mva: chooseLanguage('mva', langinterface),    
      gross: chooseLanguage('gross', langinterface),  
      total: chooseLanguage('total', langinterface),
      vatBase: chooseLanguage('vatBase', langinterface),
      faktura: chooseLanguage('faktura', langinterface),
      timelist: chooseLanguage('timelist', langinterface),
      kreditnota: chooseLanguage('kreditnota', langinterface),
      tilbud: chooseLanguage('tilbud', langinterface),
    }
    switch (type) {
      case 'timelist':
        sumUp = this.sumUpTimelist(documentFile.content.days, documentFile.content.in, documentFile.content.out, documentFile.content.breakpaid, documentFile.content.breaktime, documentFile.content.km, documentFile.content.extra);
        const week = this.getWeekOfDate(documentFile.content.days[0]) === this.getWeekOfDate(documentFile.content.days[documentFile.content.days.length-1]) ? this.getWeekOfDate(documentFile.content.days[0]) : ''+this.getWeekOfDate(documentFile.content.days[0]) + ' - ' + this.getWeekOfDate(documentFile.content.days[documentFile.content.days.length-1]);
        const year = this.getYearOfDate(documentFile.content.days[0]) === this.getYearOfDate(documentFile.content.days[documentFile.content.days.length-1]) ? this.getYearOfDate(documentFile.content.days[0]) : ''+this.getYearOfDate(documentFile.content.days[0]) + ' - ' + this.getYearOfDate(documentFile.content.days[documentFile.content.days.length-1]);
        const previewWithKm = sumUp.regular.km > 0 || sumUp.extra.km > 0 ? true : false;
        const timelistPreview = 
        <> 
          <div id='timelist-data-preview' >
            <div>{translation.workerName}</div>
            <div>{translation.project}</div>
            <div>{translation.week}</div>
            <div>{translation.year}</div>
          </div>
          <div id='timelist-data-preview' >
            <div>{documentFile.content.username}</div>
            <FullDocumentPreviewProject closeFullPreview={this.props.closeFullPreview} projectController={this.props.projectController} pid={documentFile.pid} projectName={documentFile.content.project}/>
            <div>{week}</div>
            <div>{year}</div>
          </div>
          <div id={previewWithKm ? 'timelist-content-with-km-preview-header' :'timelist-content-mini-preview-header'} >
            <div>{translation.day}</div>
            <div>{translation.in + ' - ' + translation.out}</div>
            <div>{translation.break}</div>
            <div>{translation.timer}</div>
            {previewWithKm && <div>{translation.km}</div>}
            <div>{translation.description}</div>
          </div>
          {documentFile.content.days.map((day, index)=>(
            <div id={previewWithKm ? 'timelist-content-with-km-preview' : 'timelist-content-mini-preview'} style={{borderColor: documentFile.content.extra && documentFile.content.extra[index] ? "#F00" : "#000"}} key={index}>
              <div>{day.slice(0,5)}</div>
              <div>{documentFile.content.in[index]+' - '+documentFile.content.out[index]}</div>
              <div>{this.breaktimeToString(documentFile.content.breaktime[index])}</div>
              <div>{this.sumUpDay(documentFile.content.days[index], documentFile.content.in[index], documentFile.content.out[index], documentFile.content.breakpaid[index], documentFile.content.breaktime[index])}</div>
              {previewWithKm && <div>{documentFile.content.km && documentFile.content.km[index] > 0 ? documentFile.content.km[index] : '0'}</div>}
              <div>{documentFile.content.daydescription[index]}</div>
            </div>
          ))}
            <div id='timelist-sumup-mini'>
              <div>{translation.timer + ': '}</div>
              <div>{''+sumUp.regular.hours+ ':'+sumUp.regular.minutes}</div>
              {previewWithKm && <div>{translation.km + ': '}</div>}
              {previewWithKm && <div>{''+sumUp.regular.km}</div>}
              {(sumUp.extra.hours > 0 || sumUp.extra.minutes > 0) && <div>{translation.extra + ' ' + translation.timer +': '}</div>}
              {(sumUp.extra.hours > 0 || sumUp.extra.minutes > 0) && <div>{''+sumUp.extra.hours+ ':'+sumUp.extra.minutes}</div>}
              {sumUp.extra.km > 0 && <div>{translation.extra + ' ' + translation.km +': '}</div>}
              {sumUp.extra.km > 0 && <div>{''+sumUp.extra.km}</div>}
            </div>
        </>;
   
        return timelistPreview;
      break;
      case 'kreditnota':
        sumUp = this.sumUpFaktura(documentFile.content.content);
        mvaBase= sumUp.mvaBase;
        mvaCalculation= sumUp.mvaCalculation;
        totalPrice = sumUp.totalPrice;
        const kreditnotaPreview = 
        <> 
          <div id='faktura-basic-data'>
            <div id='faktura-data-preview' >
              <div>{documentFile.content.companyname}</div>
              <div>{documentFile.content.address}</div>
              <div>{documentFile.content.postnr + ' ' + documentFile.content.post}</div>
              <div>{'org nr: NO'+documentFile.content.mva+'MVA'}</div> 
            </div>  
            <div id='faktura-dates-preview' >
              <div>{'kreditnotadato: ' + dateToSlashString(documentFile.createdate)}</div>        
            </div>
            <div id='faktura-data-preview' >
              <div>{documentFile.content.client.clientname}</div>
              <div>{documentFile.content.client.address}</div>
              <div>{documentFile.content.client.postnr + ' ' + documentFile.content.client.post}</div>
              <div>{documentFile.content.client.mva ? 'NO'+documentFile.content.client.mva+'MVA' : ''}</div> 
              <div>
                <FullDocumentPreviewProject closeFullPreview={this.props.closeFullPreview} projectController={this.props.projectController} pid={documentFile.pid} projectName={documentFile.content.project}/>
              </div>
            </div>  
          </div>         
          <div id='faktura-content-preview' >
            <div></div>
            <div>{translation.description}</div>
            <div>{translation.netprice}</div>
            <div>{translation.amount}</div>
            <div>{translation.mva}</div> 
            <div>{translation.gross}</div>
          </div>
        {documentFile.content.content.map((line, index)=>(
          <div id='faktura-content-preview' key={index}>
            <div>{index+1+'.'}</div>
            <div>{line.description}</div>
            <div>{this.numberWithSpaces((+line.netprice).toFixed(2))}</div>
            <div>{this.numberWithSpaces((+line.multiplier).toFixed(2))}</div>
            <div>{line.vat+'%'}</div>
            <div>{this.numberWithSpaces((+line.netprice*+line.multiplier + (+line.netprice*+line.multiplier)*(+line.vat/100)).toFixed(2))}</div>
          </div>
        ))}
        <div id='timelist-sumup-mini'>
              <div>{translation.total+': '}</div>
              <div>{''+this.numberWithSpaces(totalPrice.toFixed(2))}</div>
              {mvaBase[0]>0 &&
              <>
              <div>{ translation.vatBase + ' 0%: '}</div><div>{this.numberWithSpaces(mvaBase[0].toFixed(2))}</div>
              <div>{translation.mva + ' 0%: '}</div><div>{this.numberWithSpaces(mvaCalculation[0].toFixed(2))}</div>
              </>
              }
              {mvaBase[1]>0 &&
              <>
              <div>{translation.vatBase + ' 12%: '}</div><div>{this.numberWithSpaces(mvaBase[1].toFixed(2))}</div>
              <div>{translation.mva + ' 12%: '}</div><div>{this.numberWithSpaces(mvaCalculation[1].toFixed(2))}</div>
              </>
              }
              {mvaBase[2]>0 &&
              <>
              <div>{translation.vatBase + ' 15%: '}</div><div>{this.numberWithSpaces(mvaBase[2].toFixed(2))}</div>
              <div>{translation.mva + ' 15%: '}</div><div>{this.numberWithSpaces(mvaCalculation[2].toFixed(2))}</div>
              </>
              }
              {mvaBase[3]>0 &&
              <>
              <div>{translation.vatBase + ' 25%: '}</div><div>{this.numberWithSpaces(mvaBase[3].toFixed(2))}</div>
              <div>{translation.mva + ' 25%: '}</div><div>{this.numberWithSpaces(mvaCalculation[3].toFixed(2))}</div>
              </>
              }
            </div>
      </>;
        return kreditnotaPreview;
      break;
      
      case 'faktura':
        sumUp = this.sumUpFaktura(documentFile.content.content);
        mvaBase= sumUp.mvaBase;
        mvaCalculation= sumUp.mvaCalculation;
        totalPrice = sumUp.totalPrice;
        const varsel = documentFile.content.varsel && documentFile.content.varsel;
        const varsel_lvl = varsel && varsel.length;
        const varselDato = varsel && varsel[varsel_lvl-1]; 
        const fakturaPreview = 
        <> 
        <div id='faktura-basic-data'>  
        <div id='faktura-data-preview' >
            <div>{documentFile.content.companyname}</div>
            <div>{documentFile.content.address}</div>
            <div>{documentFile.content.postnr + ' ' + documentFile.content.post}</div>
            <div>{'org nr: NO'+documentFile.content.mva+'MVA'}</div> 
          </div>  
          <div id='faktura-dates-preview' >
              <div>{translation.fakturaIssueDate+': ' + dateToSlashString(documentFile.content.fakturaissue)}</div>
              <div>{translation.fakturaDueDate+': ' + dateToSlashString(documentFile.content.fakturadue)}</div>         
              {varsel_lvl < 3 && varsel_lvl > 0 && <div>{translation.varselDate + ': ' + dateToSlashString(varselDato)}</div>}        
              {varsel_lvl === 3 && <div>{translation.inkasovarseldato + ': ' + dateToSlashString(varselDato)}</div>}               
            </div>   
          <div id='faktura-data-preview' >
           <div className="client-card-link" id={documentFile.content.client.cid ? documentFile.content.client.cid : '0'} onClick={this.openClient}>{documentFile.content.client.clientname}</div>
            <div>{documentFile.content.client.address}</div>
            <div>{documentFile.content.client.postnr + ' ' + documentFile.content.client.post}</div>
            <div>{documentFile.content.client.mva ? 'org nr: NO'+documentFile.content.client.mva+'MVA' : ''}</div> 
            <div>
              <FullDocumentPreviewProject closeFullPreview={this.props.closeFullPreview} projectController={this.props.projectController} pid={documentFile.pid} projectName={documentFile.content.project}/>
            </div>
          </div>         
        </div>
        
          <div id='faktura-content-preview' >
            <div></div>
            <div>{translation.description}</div>
            <div>{translation.netprice}</div>
            <div>{translation.amount}</div>
            <div>{translation.mva}</div> 
            <div>{translation.gross}</div>
          </div>
        {documentFile.content.content.map((line, index)=>(
          <div id='faktura-content-preview' key={index}>
            <div>{index+1+'.'}</div>
            <div>{line.description}</div>
            <div>{this.numberWithSpaces((+line.netprice).toFixed(2))}</div>
            <div>{this.numberWithSpaces((+line.multiplier).toFixed(2))}</div>
            <div>{line.vat+'%'}</div>
            <div>{this.numberWithSpaces((+line.netprice*+line.multiplier + (+line.netprice*+line.multiplier)*(+line.vat/100)).toFixed(2))}</div>
          </div>
        ))}
        <div id='timelist-sumup-mini'>
          <div>{translation.total+': '}</div>
            <div>{''+this.numberWithSpaces(totalPrice.toFixed(2))}</div>
            {mvaBase[0]>0 &&
            <>
            <div>{ translation.vatBase + ' 0%: '}</div><div>{this.numberWithSpaces(mvaBase[0].toFixed(2))}</div>
            <div>{ translation.mva + ' 0%: '}</div><div>{this.numberWithSpaces(mvaCalculation[0].toFixed(2))}</div>
            </>
            }
            {mvaBase[1]>0 &&
            <>
            <div>{ translation.vatBase + ' 12%: '}</div><div>{this.numberWithSpaces(mvaBase[1].toFixed(2))}</div>
            <div>{ translation.mva + ' 12%: '}</div><div>{this.numberWithSpaces(mvaCalculation[1].toFixed(2))}</div>
            </>
            }
            {mvaBase[2]>0 &&
            <>
            <div>{ translation.vatBase + ' 15%: '}</div><div>{this.numberWithSpaces(mvaBase[2].toFixed(2))}</div>
            <div>{ translation.mva + ' 15%: '}</div><div>{this.numberWithSpaces(mvaCalculation[2].toFixed(2))}</div>
            </>
            }
            {mvaBase[3]>0 &&
            <>
            <div>{ translation.vatBase + ' 25%: '}</div><div>{this.numberWithSpaces(mvaBase[3].toFixed(2))}</div>
            <div>{ translation.mva + ' 25%: '}</div><div>{this.numberWithSpaces(mvaCalculation[3].toFixed(2))}</div>
            </>
            }
          </div>
      </>;
        return fakturaPreview;
      break;
      case 'tilbud':
        sumUp = this.sumUpFaktura(documentFile.content.content);
        mvaBase= sumUp.mvaBase;
        mvaCalculation= sumUp.mvaCalculation;
        totalPrice = sumUp.totalPrice;
        const tilbudPreview = 
        <>
          <div id='faktura-basic-data'>
            <div id='faktura-data-preview' >
              <div>{documentFile.content.companyname}</div>
              <div>{documentFile.content.address}</div>
              <div>{documentFile.content.postnr + ' ' + documentFile.content.post}</div>
              <div>{'org nr: NO'+documentFile.content.mva+'MVA'}</div> 
            </div> 
            <div id='faktura-dates-preview' >
              <div>{translation.tilbudIssueDate+': ' + dateToSlashString(documentFile.content.tilbudissue)}</div>
              <div>{translation.fakturaDueDate+': ' + dateToSlashString(documentFile.content.tilbuddue)}</div>           
            </div>           
            <div id='tilbud-data-preview' >
              <div>{documentFile.content.client.clientname}</div>
              <div>{documentFile.content.client.address}</div>
              <div>{documentFile.content.client.postnr + ' ' + documentFile.content.client.post}</div>
              <div>{documentFile.content.client.mva ? 'NO'+documentFile.content.client.mva+'MVA' : ''}</div> 
              <div>
                <FullDocumentPreviewProject closeFullPreview={this.props.closeFullPreview} projectController={this.props.projectController} pid={documentFile.pid} projectName={documentFile.content.project}/>
              </div>
            </div>
          </div>                 
          <div id='tilbud-content-preview' >
            <div></div>
            <div>{translation.description}</div>
            <div>{translation.netprice}</div>
            <div>{translation.amount}</div>
            <div>{translation.mva}</div> 
            <div>{translation.gross}</div>
          </div>
        {documentFile.content.content.map((line, index)=>(
          <div id='faktura-content-preview' key={index}>
            <div>{index+1+'.'}</div>
            <div>{line.description}</div>
            <div>{this.numberWithSpaces((+line.netprice).toFixed(2))}</div>
            <div>{this.numberWithSpaces((+line.multiplier).toFixed(2))}</div>
            <div>{line.vat+'%'}</div>
            <div>{this.numberWithSpaces((+line.netprice*+line.multiplier + (+line.netprice*+line.multiplier)*(+line.vat/100)).toFixed(2))}</div>
          </div>
        ))}
        <div id='timelist-sumup-mini'>
              <div>{translation.total+': '}</div>
              <div>{''+this.numberWithSpaces(totalPrice.toFixed(2))}</div>
              {mvaBase[0]>0 &&
              <>
              <div>{ translation.vatBase + ' 0%: '}</div><div>{this.numberWithSpaces(mvaBase[0].toFixed(2))}</div>
              <div>{translation.mva + ' 0%: '}</div><div>{this.numberWithSpaces(mvaCalculation[0].toFixed(2))}</div>
              </>
              }
              {mvaBase[1]>0 &&
              <>
              <div>{translation.vatBase + ' 12%: '}</div><div>{this.numberWithSpaces(mvaBase[1].toFixed(2))}</div>
              <div>{translation.mva + ' 12%: '}</div><div>{this.numberWithSpaces(mvaCalculation[1].toFixed(2))}</div>
              </>
              }
              {mvaBase[2]>0 &&
              <>
              <div>{translation.vatBase + ' 15%: '}</div><div>{this.numberWithSpaces(mvaBase[2].toFixed(2))}</div>
              <div>{translation.mva + ' 15%: '}</div><div>{this.numberWithSpaces(mvaCalculation[2].toFixed(2))}</div>
              </>
              }
              {mvaBase[3]>0 &&
              <>
              <div>{translation.vatBase + ' 25%: '}</div><div>{this.numberWithSpaces(mvaBase[3].toFixed(2))}</div>
              <div>{translation.mva + ' 25%: '}</div><div>{this.numberWithSpaces(mvaCalculation[3].toFixed(2))}</div>
              </>
              }
            </div>
        </>;
        return tilbudPreview;
      break;
      default:
        return <></>;     
    } 
  }

  DatesDifference = (dateToCompare) => {
    const date1 = new Date();
    const date2 = new Date(dateToCompare);
    //console.log(date1);
    //console.log(date2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
  }

  render (){
    const {find} = this.props.documentOptions;
    const documentFile = this.props.doc ? this.props.doc : {};
    const stapled = documentFile && documentFile.stapled && documentFile.stapled.map((doc, index)=>(find(doc)));
    const varsel = documentFile.content.varsel && documentFile.content.varsel;
    const varsel_lvl = varsel && varsel.length;
    const varselDato = varsel && varsel[varsel_lvl-1];
    const varselCycleExpiration = this.DatesDifference(varselDato); 
    const {lang} = this.props;
    return (
      <>
        <div id="document-background">
        <div id='tilbud-document'>
            <div id='top-beam' className={documentFile.type + "-top-beam"}>
                <h1>{this.verifyHeaderType(documentFile)}</h1>
              <CloseButton closeAction={this.props.closeFullPreview}/>
            </div>
        </div>
        <Container id="tilbud" fluid>
          <div id='document-options-full-preview'>
            <DocumentOptions lang={lang} varsel={{varselLvl: varsel_lvl, expiry: varselCycleExpiration}} openFullPreview={this.props.openFullPreview} showPopup={this.props.showPopup} stapled={stapled} documentOptions={this.props.documentOptions} closeFullPreview={this.props.closeFullPreview} document={documentFile}/>
          </div>
{            <Row>
              <Col>
          <div id='white-arc' pid={documentFile.pid} did={documentFile.did} week={this.props.weekNo} year={this.getYear(documentFile.createdate)} onMouseEnter={this.props.previewFile}>
              <div id='document-file' className={documentFile && Object.keys(documentFile).length ? 'show-document' : 'hide-document'}>
              {
                documentFile && Object.keys(documentFile).length && this.verifyDocumentType(documentFile)
              }
              </div>  
            </div> 
              </Col>
        </Row>}
      </Container> 
      </div>
    </>
    );
  }
}

export default FullDocumentPreview;
