import React, { Component } from "react";
import NewTimelistContainer from "./NewTimelistContainer";
import NewOfferContainer from "./NewOfferContainer";
import NewInvoiceContainer from "./NewInvoiceContainer";

class NewDocument extends Component {
  render (){
    const props = {...this.props};
    const {username, days, deleteImportedDays} = props;
    return (
      <>
      {props && props.documentController.newDocument.timelist && <NewTimelistContainer
        clients={props.clients}
        creator={props.creator}
        closeDocument={props.documentOptions.close}
        days={days}
        deleteImportedDays={deleteImportedDays}
        documentOptions={props.documentOptions}
        edit={props.documentController.edit.timelist} 
        editedDocument={props.documentController.editedDocument.timelist} 
        editSetting={props.editSetting}
        generateDocument={props.documentOptions.generate}
        lang={props.lang}
        projects={props.projects}
        showPopup={props.showPopup}
        timelistdetails={props.timelistdetails}
        username={username}
      />}
      {props && props.documentController.newDocument.tilbud && <NewOfferContainer
        clients={props.clients}
        creator={props.creator}
        closeDocument={props.documentOptions.close}
        documentOptions={props.documentOptions}
        edit={props.documentController.edit.tilbud} 
        editedDocument={props.documentController.editedDocument.tilbud} 
        editSetting={props.editSetting}
        fakturadetails={props.fakturadetails}
        generateDocument={props.documentOptions.generate}
        lang={props.lang}
        projects={props.projects}
        showPopup={props.showPopup}
        tilbuddetails={props.tilbuddetails}
      />}  
      {props && props.documentController.newDocument.faktura && <NewInvoiceContainer
        clients={props.clients}
        creator={props.creator}
        closeDocument={props.documentOptions.close}
        documentOptions={props.documentOptions}
        edit={props.documentController.edit.faktura} 
        editedDocument={props.documentController.editedDocument.faktura} 
        editSetting={props.editSetting}                 
        fakturadetails={props.fakturadetails}
        generateDocument={props.documentOptions.generate} 
        lang={props.lang}
        projects={props.projects}
        showPopup={props.showPopup}
        />
      }
 
      </>
    );
  }
}

export default NewDocument;
