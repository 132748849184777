import React, { Component } from "react";

class ClientDataLocked extends Component {
  constructor () {
	  super();
	  this.state = {
          mva: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.saveBasis = this.saveBasis.bind(this);
  }

  componentDidMount() {
  }

  saveBasis(event){
    let record = event.target.id,
      value = event.target.value;
      this.props.writeClientToState(record, value);
  }

  handleChange(){
      this.setState({
          mva: true,
      })
  }

  render (){
    const client = this.props.client ? this.props.client : {};
    const {editClientData} = this.props;
    return (
      <div className="user-company-data" onClick={editClientData}>
       <h4>{client.clientname}</h4>
       {client.mva && <h4>{client.mva}{" MVA"}</h4>}
       <h4>{client.address}</h4>
       <h4>{client.postnr}{' '}{client.post}</h4>
      </div>
    );
  }
}

export default ClientDataLocked;
