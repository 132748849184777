import React, { Component } from "react";
import { chooseLanguage } from "../Lang/langpack";
class TimelineDocRow extends Component {
 
  fullPreview(event){
    let did = event.target.id;
    did = did ? did : event.target.parentNode.id;
    console.log(did);
    this.props.openFullPreview(did);
  }
 

  verifyDocumentTypeAndIco(doc){
    let type = doc.type;
    switch (type) {
      case 'timelist':
        return 'url(./timelist.svg)';
      break;
      case 'faktura':
        return 'url(./faktura.svg)';
      break;
      case 'tilbud':
        return 'url(./tilbud.svg)';
      break;
      case 'kreditnota':
        return 'url(./kreditnota.svg)';        
      break;
      case 'varsel':
        return 'url(./varsel.svg)';
      break;
      default:
        return 'url(./documents.svg)';
    } 
  }

  getYear(date){
    let dte = new Date(date);
    return dte.getFullYear();
  }

  docNr = (doc) => {
    const {langinterface} = this.props.lang;
    const docType = chooseLanguage(...doc.type, langinterface);
    return (
    <p id='timeline-doc-nr'>{docType}{" "}<span>{doc.type === 'timelist' ? doc.content.timelistnr : doc.type === 'faktura' || doc.type === 'varsel' ? doc.content.fakturanr : doc.type === 'tilbud' ? doc.content.tilbudnr : ''}</span></p>
  )
}

docNrRow = (doc) => {
  const {langinterface} = this.props.lang;
  const docType = doc.type;
  const docTypeHeader = chooseLanguage(docType, langinterface);
  return (
  <p id='timeline-doc-nr-row'>{docTypeHeader}{" "}<span>{doc.type === 'timelist' ? doc.content.timelistnr : doc.type === 'faktura' || doc.type === 'varsel' ? doc.content.fakturanr : doc.type === 'tilbud' ? doc.content.tilbudnr : ''}</span></p>
)
}

  docIco = (doc, idx, weekNo, day, previewFile, fullPreview) => {return <div id={doc.did} onClick={previewFile} className='tl-ico-mobile' style={{
    backgroundImage: this.verifyDocumentTypeAndIco(doc)}} 
    key={idx} 
    pid={doc.pid} 
    did={doc.did} 
    week={weekNo} 
    year={this.getYear(doc.createdate)}>
        {this.docNr(doc)}
    </div>
  }

  docRow = (doc, idx, weekNo, day, previewFile, fullPreview) => {return <div className='tl-row-mobile'>
    <div id={doc.did} onClick={previewFile} className='tl-ico-row' style={{
      backgroundImage: this.verifyDocumentTypeAndIco(doc)}} 
      key={idx} 
      pid={doc.pid} 
      did={doc.did} 
      week={weekNo} 
      year={this.getYear(doc.createdate)}>
          {this.docNrRow(doc)}
      </div>
    </div>
  }

  //{this.state.previewFile && this.state.previewFile.did === doc.did && (this.props.groupByCreateDate ? this.dateComparsion(doc.createdate, day) : this.compareTwoStringDate(day,doc.content.days[doc.content.days.length-1])) && <DocumentPreview previewFile={this.previewFile} document={doc} dateComparsion={this.dateComparsion} documentOptions={this.props.documentOptions}/>}

  render (){
    let {day, idx, weekNo, doc, previewFile, fullPreview} = {...this.props};
    return (
        this.docRow(doc, idx, weekNo, day, previewFile, fullPreview) 
    );
  }
}

export default TimelineDocRow;
