import React, { Component } from "react";

import './../lists.css';
import ClientList from "./ClientList";
import ProjectList from "./ProjectList";
import TaskList from "./TaskList";
import DocumentList from "./DocumentList";
import MessageList from "./MessageList";
import IcoSet from "./IcoSet";
import SearchBox from "../Search/SearchBox";
import SearchComponent from "./SearchComponent";
import { chooseLanguage } from "../Lang/langpack";



class ListWindow extends Component {
  constructor () {
	  super();
	  this.state = {
      searchterm: ''
    }
    this.inputSearchTerm = this.inputSearchTerm.bind(this);
  }

  inputSearchTerm(searchterm){
    this.setState({
      searchterm
    })
  }

 
  componentDidMount() {

  }

  render (){
    const type=this.props.type;
    const searchterm = this.state.searchterm;
    const {clearList, lang} = this.props;
    const {langinterface} = this.props.lang;
    //Translation
    const translation = {
      searchResultsHeader: chooseLanguage('searchResults', langinterface),
      recent: chooseLanguage('recent', langinterface),
      projects: chooseLanguage('projects', langinterface),
      documents: chooseLanguage('documents', langinterface),
      clients: chooseLanguage('clients', langinterface),
    }

    return (
      <div className="list-window-background" onClick={this.props.clearList} >
        <div id='list-window' className={type}>
          {type=== 'search' && <SearchBox 
          inputSearchTerm={this.inputSearchTerm}
          lang={lang}
          />}
          {type !== 'search' && <p id='list-header'>{translation.recent + ' ' + translation[type]}</p>}
          {type === 'search' && searchterm.length >= 3 && <p id='list-header'>{translation.searchResultsHeader}</p>}
          {type === 'clients' && <ClientList 
          lang={lang}
          clients={this.props.clients} 
          clearList={this.props.clearList} 
          clientController={this.props.clientController}/>}
          {type === 'projects' &&  <ProjectList 
          lang={lang}
          clearList={this.props.clearList} 
          projectController={this.props.projectController} 
          projects={this.props.projects} />}
          {type === 'tasks' && <TaskList/>}
          {type === 'documents' && <DocumentList/>}
          {type === 'messages' && <MessageList/>}
          {type === 'search' && searchterm.length >= 3 && <SearchComponent clientController={this.props.clientController} projectController={this.props.projectController} openFullPreview={this.props.openFullPreview} showPopup={this.props.showPopup} editDocument={this.props.editDocument} repeatDocument={this.props.repeatDocument} displaySearchResult={this.props.displaySearchResult} uid={this.props.uid} searchterm={searchterm} clients={this.props.clients} documents={this.props.documents} projects={this.props.projects} lang={lang}/>}
          {<IcoSet 
            createNewClient={this.props.createNewClient} 
            openMenu={this.props.openMenu} 
            createNewProject={this.props.createNewProject} 
            clearList={this.props.clearList} 
            showPopup={this.props.showPopup} 
            type={type}
            lang={lang}
          />}
        </div>
      </div>
    );
  }
}

export default ListWindow;
