import React, { Component } from "react";
import {Col, Row, Form, Button} from 'react-bootstrap';
import { chooseLanguage } from "../Lang/langpack";


class SearchBox extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.getValue = this.getValue.bind(this);
  }

  getValue(event){
    const value = event.target.value;
    this.props.inputSearchTerm(value);
  }

  componentDidMount() {
  }

  render (){
    const {langinterface} = this.props.lang;
    const searchBoxPlaceholder = chooseLanguage('searchBoxPlaceholder', langinterface);
    return (
      <>
        <Form.Control autoFocus onChange={this.getValue} type='text' name='searchBox' id='search' placeholder={searchBoxPlaceholder} style={{display: 'inline', width: '92%', marginTop: 'auto', marginBottom: 'auto', marginLeft: '1rem'}}/>
      </>
    );
  }
}

export default SearchBox;
