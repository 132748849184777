import React, { Component } from "react";
import MinimizeButton from "../FormComponents/MinimizeButton";
import CloseButton from "../FormComponents/CloseButton";
import { Form } from "react-bootstrap";
import { chooseLanguage } from "../Lang/langpack";

class LoggedInDocumentsViewOptions extends Component {
  constructor () {
	  super();
	  this.state = {
      filtersPanel: false,
    }

  }

  filtersPanelOn = () => {
    this.setState({
      filtersPanel: true,
    })
  }
  filtersPanelOff = () => {
    this.setState({
      filtersPanel: false,
    })
  }

  render (){
    const filters = this.props.filters;
    const {lang} = {...this.props};
    const {langinterface} = lang; 
    const translation = {
      view: chooseLanguage('view', langinterface),
      timeline: chooseLanguage('timeline', langinterface),
      calendar: chooseLanguage('calendar', langinterface),
      docTypeHeader: chooseLanguage('docTypeHeader', langinterface),
    }
    const {filtersPanel} = this.state;
    return (
      <>
        {!filtersPanel && <div id='timeline-filters' onClick={this.filtersPanelOn}></div>}
        {filtersPanel && <div id='documents-view-options-beam'>
          <div id='top-beam-filters'>
            <CloseButton closeAction={this.filtersPanelOff}/>
          </div>
            <div id='view-toggle-panel' >
                <div id='view-button-label'>
                  <p>{translation.view}</p>
                </div>            
                <div id='view-toggle-button' onClick={this.props.toggleView}>
                {chooseLanguage(this.props.view, langinterface)}
                </div> 
            </div> 
            <div id='document-type-panel' >
                <div id='view-button-label'>
                  <p>{translation.docTypeHeader}</p>
                </div>            
                <div id='document-toggle-button'>
                    <div className="document-type-button" name='offer' onClick={this.props.toggleDocumentVisibility} id='tilbud-button'>
                      <div className="document-type-status" name='offer' style={{filter: !filters.offer ? 'grayscale(1)' : 'none'}}  ></div>
                    </div>
                    <div className="document-type-button" name='timelist' onClick={this.props.toggleDocumentVisibility} id='timelist-button'>
                      <div className="document-type-status" name='timelist' style={{filter: !filters.timelist ? 'grayscale(1)' : 'none'}}  ></div>
                    </div>
                    <div className="document-type-button" name='invoice' onClick={this.props.toggleDocumentVisibility}  id='faktura-button'>
                    <div className="document-type-status" name='invoice' style={{filter: !filters.invoice ? 'grayscale(1)' : 'none'}}  ></div>
                    </div>
                </div> 
            </div>
            {/*<div id='document-cover-panel' style={{display: this.props.view === 'timeline' ? 'initial' : 'none'}} >
                <div id='view-button-label'>
                  {'Timelist:'}
                </div>            
                <div id='view-toggle-button' onClick={this.props.toggleGroup}>
                    {this.props.groupByCreateDate ? 'create date' : 'coverage'}
                </div> 
            </div> 

            <div id='timeline-zoom-panel' style={{display: this.props.view === 'timeline' ? 'initial' : 'none'}} >
                <div id='view-button-label'>
                  {'Zoom:'}
                </div>            
                <div id='timeline-zoom-button' onClick={this.props.zoom.zoomIn}>
                    {'+'}
                </div> 
                <div id='timeline-zoom-button' onClick={this.props.zoom.zoomOut}>
                    {'-'}
                </div> 
            </div> */}
        </div>}
      </>
    );
  }
}

export default LoggedInDocumentsViewOptions;
