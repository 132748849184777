import React, { Component } from "react";


class ProjectItem extends Component {
  constructor () {
	  super();
	  this.state = {
      hoverItem: false
    }
    this.hoverProject = this.hoverProject.bind(this);
    this.unhoverProject = this.unhoverProject.bind(this);
  }

  hoverProject(){
    this.setState({
      hoverItem: true
    })
  }
  unhoverProject(){
    this.setState({
      hoverItem: false
    })
  }
 
  openProject = (e) =>
  {
    let id = e.target.id;
    //console.log(id);
    
    const open = this.props.projectController.open; 
    open(id);
  }


  render (){
    const {project} = this.props;
    return (
      <div id='project-fulllist-item' onMouseEnter={this.hoverProject} onMouseLeave={this.unhoverProject}>
      <div className="clickable-panel"
        id={project.pid} 
        onClick={this.openProject}></div>
          <div>{project.name}</div>
      
        </div>  
    );
  }
}

export default ProjectItem;
