import React, { Component } from "react";
import './../menu.css';
import './../popup.css';
import { chooseLanguage } from "../Lang/langpack";

class MainMenu extends Component {
  constructor () {
	  super();
	  this.state = {
          menu: [
            {ico: "search.svg", link: "search", title: "Search"}, 
            {ico: "documents.svg", link: "document", title: "New Document"}, 
            {ico: "clients.svg", link: "clients", title: "Clients"}, 
              {ico: "projects.svg", link: "projects", title: "Projects"},
              {ico: "settings.svg", link: "settings", title: "Settings"},
              {ico: "logout.svg", link: "logout", title: "Logout"}, 
            ]
    }
    this.setHidden = this.setHidden.bind(this);
    this.setVisible = this.setVisible.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
  }

  setLang = () => {
    const {langinterface} = this.props.lang;
    const language = chooseLanguage('menu', langinterface);
    const menu = [
      {ico: "search.svg", link: "search", title: language[0]}, 
      {ico: "documents.svg", link: "document", title: language[1]},
      {ico: "clients.svg", link: "clients", title: language[2]},
        {ico: "projects.svg", link: "projects", title: language[3]},
        {ico: "settings.svg", link: "settings", title: language[4]},
        {ico: "logout.svg", link: "logout", title: language[5]},
      ]
    this.setState({menu});
   }

  componentDidMount() {
    this.setLang();
    this.setHidden();
  }

  componentWillUnmount() {
    this.setVisible();
  }
  
setHidden () {
  document.body.style.overflow = "hidden";
}
setVisible () {
  document.body.style.overflow = "auto";
}

handleViewChange(event) {
    let id = event.target.id;
    if (''+id === 'document' || ''+id === 'projects' || ''+ id === 'settings' || ''+ id === 'clients')
    {
      this.props.openMenu(id);
      this.props.closeMenu('main');      
    }
    else
    {
      this.props.handleViewChange(id);
    }
  }

  createNewDocument(){
    this.clearList();
  }

  render (){
/*
new user
new project
confirm
edit
error
info
settings
ad
*/
const type = this.props.type;
const content = this.props.content;
    return (
        <div className="popup-background" onClick={()=>this.props.closeMenu('main')}>
            <div className="menu-window">
                    <div class="menu-grid">
                        {this.state.menu.map((item, index)=>(<div key={index} class="menu-item"><img alt={item.title} src={'./'+item.ico}/><div id={item.link} className='clickable-panel' onClick={this.handleViewChange}></div><p>{item.title}</p></div>))}
                    </div>
            </div>
        </div>
    );
  }
}

export default MainMenu;
