import React, { Component } from "react";
import { chooseLanguage } from "../Lang/langpack";

class ProjectMenu extends Component {
  constructor () {
	  super();
    }

    openTab = e => {
        const selected = e.target.id;
        this.props.openTab(selected);
    }

  render (){
    const {tab,lang, menu} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      deleted: chooseLanguage('deleted',  langinterface),
      archived: chooseLanguage('archived',  langinterface), 
      active: chooseLanguage('active',  langinterface), 
    }
    const menuTranslation = [translation.active, translation.archived, translation.deleted];
    return (  
      <div className="project-menu-wrapper">
        {menu && menu.map((item, index)=>(
            <div key={index} className={tab[item] ? "project-menu-item-selected" : "project-menu-item"}>
                <div className="clickable-panel" id={item} onClick={this.openTab}>
                </div>
                <p>{menuTranslation[index]}</p>
            </div>
        ))}

        </div>
    );
  }
}

export default ProjectMenu;
