import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';

class ToggleComponent extends Component {
  
  handleChange = (e) => {
    const {id, checked, name } = e.target,
    returnValue = this.props.saveValue;
    const value = e.target.checked ? true : false;
    console.log('isChecked:' + value);
    returnValue(name, id, value );
  }

  render (){
    let {index, value, record, translation} = {...this.props};
    const label = translation ? translation : record;
    return (
    <Form>
        <Form.Check // prettier-ignore
        checked={value}
        type="switch"
        id={index ? index : record}
        name={record} 
        label={label}
        onChange={this.handleChange}
        disabled={false}
      />
      </Form>
    );
  }
}

export default ToggleComponent;
