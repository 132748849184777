import React, { Component } from "react";
import {Col, Row, Form, Button} from 'react-bootstrap';
import ListWindow from "../Lists/ListWindow";
import { chooseLanguage } from "../Lang/langpack";


class MenuBarFull extends Component {
  constructor () {
	  super();
	  this.state = {
      list: {
        clients: false,
        projects: false,
        tasks: false,
        search: false,
      }
    }
    this.showPopup = this.showPopup.bind(this);
    this.viewList = this.viewList.bind(this);
    this.clearList = this.clearList.bind(this);
    this.createNewDocument = this.createNewDocument.bind(this);
  }
  createNewDocument(){
    this.clearList();
    this.props.openMenu('document');
  }

  openSettings = () => {
    this.props.openMenu('settings');
  }
  
  showPopup(event) {
    let type = event.target.id;
    let content = event.target.getAttribute('alt');
    this.props.showPopup(type, content);
  }


  viewList(event){
    let type = event.target.id;
    let list = this.state.list;
    list = {[type]: !list[type]};
    //console.log(list);
    this.setState({list});
    }

  clearList(){
    this.setState({
      list: {
        clients: false,
        projects: false,
        tasks: false,
        search: false,
      }
    })
  }
  

  render (){
    const {langinterface} = this.props.lang;
    const menu = chooseLanguage('menu', langinterface);
    return (
        <div id='menu-new-documents'>
          <div id='menu-wrapper'>
          <div className='menu-item' >
            <div 
            className="clickable-panel" 
            id='search'  
            onClick={this.viewList}>
            </div>
            <img src={'./search.svg'}              
              alt='search'
            />
          <p>{menu[0]}</p>
          </div>
            {this.state.list.clients && <ListWindow
            lang={this.props.lang}
            clientController={this.props.clientController}
            createNewClient={this.props.createNewClient}
            openPopupWithNewClient={this.props.openPopupWithNewClient}
            clients={this.props.clients}
            showPopup={this.props.showPopup} 
            clearList={this.clearList} 
            openMenu={this.props.openMenu} 
            type={'clients'}/>}
          <div className='menu-item'>
          <div className="clickable-panel"
              id='newDay'  
              onClick={this.showPopup}         
          ></div>
            <img src='./time_plus.svg' 
              alt={'DEMO MODE, can\'t modify settings'}
              />
          <p>{menu[6]}</p>
          </div>
          <div className='menu-item' >
          <div className="clickable-panel"
              id='documents'  
              onClick={this.createNewDocument}
          ></div>
            <img src='./documents.svg'
              alt='documents'
             />
          <p>{menu[1]}</p>
          </div>
            {this.state.list.search && <ListWindow
            lang={this.props.lang}
            projectController={this.props.projectController}
            openFullPreview={this.props.openFullPreview}
            showPopup={this.props.showPopup}
            editDocument={this.props.editDocument} 
            repeatDocument={this.props.repeatDocument} 
            clearList={this.clearList} 
            displaySearchResult={this.props.displaySearchResult}
            uid={this.props.uid} 
            type={'search'} 
            projects={this.props.projects}
            clients={this.props.clients}
            documents={this.props.documents}
            clientController={this.props.clientController}
            />}
          <div className='menu-item' >
          <div className="clickable-panel"
              id='projects'  
              onClick={this.viewList}
          ></div>
            <img src='./projects.svg' 
              alt='new project'
              />
          <p>{menu[2]}</p>
          </div>
            {this.state.list.projects && <ListWindow
            lang={this.props.lang}
            projectController={this.props.projectController}
            projects={this.props.projects}
            createNewProject={this.props.createNewProject}
            clearList={this.clearList} 
            showPopup={this.props.showPopup} 
            openMenu={this.props.openMenu} 
            type={'projects'}/>}
          <div className='menu-item' >
          <div className="clickable-panel"
              id='clients'  
              onClick={this.viewList} 
          ></div>
            <img src='./clients.svg' 
              alt='new invoice'
              /> 
          <p>{menu[3]}</p>
          {/*<div className='menu-item' >
          <div className="clickable-panel"
              id='info'  
              onClick={this.showPopup}
          ></div>
          <img src='./popup_information.svg' 
            alt='info Popup'
            />
          <p>info</p>
          </div>
          <div className='menu-item'>
          <div className="clickable-panel"
              id='info'  
              onClick={this.showPopup}      
          ></div>
              <img src='./messages.svg' 
              alt='Work in progress'
             />
          <p>Messages</p>
          </div>
        <div className='menu-item' >
          <div className="clickable-panel"
              id='confirm'  
              onClick={this.showPopup}
          ></div>
          <img src='./popup_confirm.svg' 
            alt='confirm Popup'
            />
          <p>confirm</p>
          </div>*/}
        </div>
          <div className='menu-item'>
          <div className="clickable-panel"
              id='settings'  
              onClick={this.openSettings}         
          ></div>
            <img src='./settings.svg' 
              alt={'DEMO MODE, can\'t modify settings'}
              />
          <p>{menu[4]}</p>
          </div>
          
          <div className='menu-item'>
          <div className="clickable-panel"
              id='logout' 
              onClick={this.props.logout}
          ></div>
            <img src='./logout.svg' 
              alt='logout'
              />
          <p>{menu[5]}</p>
        </div>
        
                { /*
                 <img src='./popup_error.svg' 
                 id='error'  
                 onClick={this.showPopup}
                 alt='Error Popup'
                 style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
                 <img src='./popup_information.svg' 
                 id='info'  
                 onClick={this.showPopup}
                 alt='Info Popup'
                 style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
                 <img src='./popup_confirm.svg' 
                 id='confirm'  
                 onClick={this.showPopup}
                 alt='Confirmation Popup'
                 style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>  
                */
                }
      </div>
      </div>
           
    );
  }
}

export default MenuBarFull;
