import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { localizeDateStr } from "../Features/dateFeatures";
import { chooseLanguage } from "../Lang/langpack";

class DateComponent extends Component {

  handleChange = (e) => {
    const {id, value } = e.target,
    returnValue = this.props.saveDate;
    returnValue(value, id);
  }

  render (){
    const {day, index} = {...this.props};
    const inDateFormat = new Date(day);
    const weekDay = inDateFormat.getDay();
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      weekDays: chooseLanguage('weekDays', langinterface),
    };
    return (
      <>
        <Form.Control block='true' type='date' name='dateComponent' id={index} value={localizeDateStr(day)} onChange={this.handleChange}/>
        <p className={weekDay === 0 ? 'weekday-sunday-timelist' : 'weekday-timelist'}>{translation.weekDays[weekDay]}</p>
      </>
    );
  }
}

export default DateComponent;
