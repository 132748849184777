import React, { Component } from "react";
import { Container, Row, Col, Form} from 'react-bootstrap';
import ClientData from './../FormComponents/ClientData';
import UserCompanyData from "./UserCompanyData";
import TilbudDatesModule from '../FormComponents/TilbudDatesModule';
import {serverRouteParser, requestObjectGenerator} from '../ServerRoutes/serverRoute';
import DocumentExportOptions from "../FormComponents/DocumentExportOptions";
import InvoiceRow from "./InvoiceRow";
import InvoiceHeader from "./InvoiceHeader";
import { postkode } from "./postkode";
import ClientDataLocked from "../FormComponents/ClientDataLocked";
import InvoiceRowMobile from "./InvoiceRowMobile";
import ProjectName from "../FormComponents/ProjectName";
import { chooseLanguage } from "../Lang/langpack";

class NewOffer extends Component {
  render (){
    let {tilbudnr, step, stepOptions,  tilbudPreview, tilbuddetails, client, clients, projects, editClientData, loggedprojectname, isValid, fakturadetails, tilbuddue, tilbudissue, position, writeClientToState, writeBasisToState, moveDown, moveUp, addNewRow, content, saveDate, savePost, deletePosition, writeToState, sendFormToExecute, lang, arrayLength} = this.props;
    const {clientname, address, postnr, post} = {...client};
    const {langinterface} = lang;
    const translation = {
     projectName: chooseLanguage('projectName', langinterface)
    }
    return (
      <Container id="tilbud" fluid>
          <Row>            
            <Col lg={{span: 6, offset: 0}}>
              {(!clientname || !address || !postnr || !post || editClientData) && <ClientData 
              lang={lang}
              savePost={savePost} 
              client={client} 
              writeClientToState={writeClientToState}
              suggestions={clients}
              />}
              {(clientname && address && postnr && post && !editClientData) && <ClientDataLocked editClientData={editClientData} client={client}/>}
              {editClientData && 
              <div className="edit-accept">
                <img src='./ok.svg' 
                      id='accept'  
                      onClick={editClientData}
                      alt='accept'
                      style={{width: '5vw',cursor: 'pointer'}}/>
              </div>}
            </Col>
              <Col xs={12} lg={{span: 6, offset: 0}} id='faktura-details'>
              <Col lg={12} className="d-none d-lg-block"><UserCompanyData fakturadetails={fakturadetails}/></Col>
              <Form.Group id='document-fieldset' as={Row} className="justify-content-center">
              <Form.Label column xs={12} lg={3}><h4>{translation.projectName}</h4></Form.Label>
              <Col xs={12} lg={9}>
              <ProjectName 
              lang={lang}
              suggestions={projects} 
              writeBasisToState={writeBasisToState} 
              isValid={isValid} 
              loggedprojectname={loggedprojectname}/>
              </Col>
              </Form.Group>
             {position.length > 0 && <TilbudDatesModule 
             lang={lang}
              tilbuddue={tilbuddue} 
              tilbudissue={tilbudissue} 
              isValid={isValid}
              saveDate={saveDate}
              />}
            </Col>
          </Row>   
          <Row>
            
          </Row>
          {

          //window.innerHeight < window.innerWidth &&<InvoiceHeader/>
          }
          {
            //window.innerHeight < window.innerWidth && position.map((position, index)=>(<InvoiceRow moveDown={MoveDown} moveUp={MoveUp} addNewRow={addNewRow} content={content[index]} deletePosition={deletePosition} writeToState={writeToState} key={index} position={position} arrayLength={position.length} index={index.toString()}/>))
          }
          {position.map((position, index)=>(<InvoiceRowMobile lang={lang} moveDown={moveDown} moveUp={moveUp} addNewRow={addNewRow} content={content[index]} deletePosition={deletePosition} writeToState={writeToState} key={index} position={position} arrayLength={arrayLength} index={index.toString()}/>))}
          <div id='document-options-full-preview'><DocumentExportOptions lang={lang} sendFormToExecute={sendFormToExecute}/></div>
      </Container>
    );
  }
}

export default NewOffer;
