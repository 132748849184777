import React, { Component } from "react";
import { Container, Row, Col, Form} from 'react-bootstrap';
import InvoiceHeader from './InvoiceHeader';
import InvoiceRow from './InvoiceRow';
import ClientData from './../FormComponents/ClientData';
import UserCompanyData from "./UserCompanyData";
import InvoiceDateModule from '../FormComponents/InvoiceDatesModule';
import DocumentExportOptions from './../FormComponents/DocumentExportOptions';
import {serverRouteParser, requestObjectGenerator} from './../ServerRoutes/serverRoute';
import { postkode } from "./postkode";
import ClientDataLocked from "../FormComponents/ClientDataLocked";
import InvoiceRowMobile from "./InvoiceRowMobile";
import ProjectName from "../FormComponents/ProjectName";
import { chooseLanguage } from "../Lang/langpack";

class NewInvoice extends Component {

  render (){
    let {arrayLength, client, clients, projects, editClientData, loggedprojectname, isValid, fakturadetails, fakturadue, fakturaissue, position, writeClientToState, writeBasisToState, moveDown, moveUp, addNewRow, content, saveDate, savePost, deletePosition, writeToState, sendFormToExecute, lang} = this.props;
    const {clientname, address, postnr, post} = {...client};
    const {langinterface} = lang;
    const translation = {
     projectName: chooseLanguage('projectName', langinterface)
    }
    return (
      <Container id="invoice" fluid>
          <Row>            
          <Col xs={12} lg={{span: 6, offset: 0}}>
            {(!clientname || !address || !postnr || !post || editClientData) && <ClientData
              lang={lang} 
              savePost={savePost} 
              client={client} 
              writeClientToState={writeClientToState}
              suggestions={clients}
            />}
              {(clientname && address && postnr && post && !editClientData) && <ClientDataLocked editClientData={editClientData} client={client}/>}
              {editClientData && 
              <div className="edit-accept">
                <img src='./ok.svg' 
                      id='accept'  
                      onClick={editClientData}
                      alt='accept'
                      style={{width: '5vw',cursor: 'pointer'}}/>
              </div>}
            </Col>
            <Col xs={12} lg={{span: 6, offset: 0}} id='faktura-details'>
            <Col lg={12} className="d-none d-lg-block"><UserCompanyData fakturadetails={fakturadetails}/></Col>
              <Form.Group id='document-fieldset' as={Row} className="justify-content-center">
              <Form.Label column xs={12} lg={3}><h4>{translation.projectName}</h4></Form.Label>
              <Col xs={12} lg={9}>
              <ProjectName 
              lang={lang}
              suggestions={projects} 
              writeBasisToState={writeBasisToState} 
              isValid={isValid} 
              loggedprojectname={loggedprojectname}/>
              </Col>
              </Form.Group>
          {position.length > 0 && <InvoiceDateModule lang={lang} fakturadue={fakturadue} fakturaissue={fakturaissue} isValid={isValid} saveDate={saveDate}/>}
            </Col>
          </Row>   
          {position.map((position, index)=>(<InvoiceRowMobile arrayLength={arrayLength} lang={lang} moveDown={moveDown} moveUp={moveUp} addNewRow={addNewRow} content={content[index]} deletePosition={deletePosition} writeToState={writeToState} key={index} position={position} index={index.toString()}/>))}
          {
          <div id='document-options-full-preview'>
            <DocumentExportOptions lang={lang} sendFormToExecute={sendFormToExecute}/>
          </div>  
          }

      </Container>
    );
  }
}

export default NewInvoice;
