import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import NewRowButton from "./NewRowButton";

class DocumentRowOptions extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.handleDelete = this.handleDelete.bind(this);
    this.handleMoveUp = this.handleMoveUp.bind(this);
    this.handleMoveDown = this.handleMoveDown.bind(this);
  }

  componentDidMount() {
  }

  handleDelete(event) {
    let id = event.target.id;
    this.props.deletePosition(parseInt(id));

  }
  handleMoveUp(event) {
    let id = event.target.id;
    this.props.moveUp(parseInt(id));

  }
  handleMoveDown(event) {
    let id = event.target.id;
    this.props.moveDown(parseInt(id));
  }



  render (){
    let {index, arrayLength} = {...this.props};

    return (
      <div>
        <NewRowButton index={index} addNewRow={this.props.addNewRow}/>
        <br/><br/>
        <Button style={{display: +index===0 ? "none" : "inline"}} id={index} onClick={this.handleMoveUp} variant='dark'>{'↑'}</Button>
        <Button style={{display: +index===arrayLength-1 ? "none" : "inline"}} id={index} onClick={this.handleMoveDown} variant='dark'>{'↓'}</Button>
        <br/><br/>
        <Button style={{display: arrayLength-1===0 ? "none" : "inline"}}  id={index} onClick={this.handleDelete} variant='danger'>X</Button>
      </div>
    );
  }
}

export default DocumentRowOptions;
