import React, { Component } from "react";
import ClientItem from './ClientItem';
import { chooseLanguage } from "../Lang/langpack";

class ClientList extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

 
  componentDidMount() {

  }

  render (){
    let {clients} =  this.props;
    let clientsObject = {
      archived: clients.filter((client, index) => (client.archived && client.archived.status)),
      deleted: clients.filter((client, index) => (client.deleted && client.deleted.status)),
      clients: clients.filter((client, index) => ((!client.archived || !client.archived.status) && (!client.deleted || !client.deleted.status))),
    };
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      emptyList: chooseLanguage('emptyList', langinterface),
    }
    return (
      <div id='client-list'>
          {clientsObject.clients.length > 0 && clientsObject.clients.map((client, index) => <ClientItem key={index} index={index} clearList={this.props.clearList}  clientController={this.props.clientController} client={client}/>)}
          {clientsObject.clients.length === 0 && <div id='empty-list'>{translation.emptyList}</div>}
        </div>  
    );
  }
}

export default ClientList;
