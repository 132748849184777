import React, { Component } from "react";
import {Tabs, Tab} from 'react-bootstrap';
import SettingKnob from "./SettingKnob";


class PrivacySettings extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }


  render (){
    const {agrees, editSetting} = {...this.props};
    return (  
      <div className="settings-document-settings">
            <h3>Privacy Settings</h3>
            <div className="knob-panel">
              <SettingKnob type='toggle' editSetting={editSetting} setting={{value: agrees.newsletter, setting: 'newsletter', option: 'Newsletter'}}/>
              <SettingKnob type='toggle' editSetting={editSetting} setting={{value: agrees.dataanalysis,setting: 'dataanalysis',  option: 'Data Analysis'}}/>
              <SettingKnob type='toggle' editSetting={editSetting} setting={{value: agrees.cookies, setting: 'cookies',  option: 'Cookies'}}/>
          </div>
        </div>
    );
  }
}

export default PrivacySettings;
