import React, { Component } from "react";
import PopupAd from "./../Popups/PopupAd";
import PopupConfirm from "./../Popups/PopupConfirm";
import PopupEdit from "./../Popups/PopupEdit";
import PopupError from "./../Popups/PopupError";
import PopupWarning from "./../Popups/PopupWarning";
import PopupInfo from "./../Popups/PopupInfo";
import PopupNewProject from "./../Popups/PopupNewProject";
import PopupNewUser from "./../Popups/PopupNewUser";
import PopupNewTask from "./../Popups/PopupNewTask";
import PopupNewClient from "./../Popups/PopupNewClient";
//import { Row, Col, Form} from 'react-bootstrap';

import './../jobbie.css';

class JobbieContainer extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  render (){
/*
confirm
error
info
settings
ad
*/
const {type, content, callback, abort} = {...this.props.popup}

let backgroundImage =
 type === 'warning' ? 'popup_warning.svg' : 
 type === 'info' ? 'popup_information.svg' : '';
    return (
    <>
        {(type === 'warning' || type === 'info') && <div className="jobbie-window">
            {<div className='jobbie-topbeam' style={{backgroundImage: 'url(./'+backgroundImage}}><h1>{type}</h1></div>}
            {<div className='jobbie-close-button' onClick={abort}>X</div>}
            {<div className='jobbie-info'>{content}</div>}
        </div>}
    </>
    );
  }
}

export default JobbieContainer;
