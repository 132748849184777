import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';
import { postkode } from "../Documents/postkode";
import AutocompleteComponent from './AutocompleteComponent';

class PostNrComponent extends Component {
  constructor () {
	  super();
	  this.state = {
      postnr: '0123',
      post: 'Oslo'
    }
  }

  
  onChange = e => {
    const {option, pid} = this.props;
    const userInput = e.currentTarget.value;
    let stringPostNr = new String(userInput);
    //console.log(stringPostNr)
    let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === userInput);
    selectedCity = selectedCity ? selectedCity[0][1] : null;
    //console.log(selectedCity);
    //odczytaj z state i zapisz do bazy danych
    this.setState({postnr: stringPostNr, post: selectedCity});
    let {savePost} = this.props;
    savePost(option, pid, stringPostNr);
  };

  componentDidMount() {
    let {postnr} = this.props;
    let stringPostNr = ''+postnr;
    //console.log(stringPostNr);
    let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === postnr);
    selectedCity = selectedCity[0][1];
    //console.log(selectedCity);
    //odczytaj z state i zapisz do bazy danych
    this.setState({postnr: stringPostNr, post: selectedCity});
  }

  render (){
      let {postnr, post} = this.state;

    return (
      <Row>
        <Form.Label style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} className='text-center d-lg-block' column xs={0} lg={3}><h4>POST</h4></Form.Label>
        <Col lg={3} xs={6}>
        <Form.Control id='postnr' placeholder='Post nr' type='text' value={postnr} onChange={this.onChange} maxLength={4} required/>
        </Col>
        <Col lg={6} xs={6}>
        <Form.Control style={{cursor: 'not-allowed'}} value={post} id='post' type="text" placeholder='City'/>
        </Col>
      </Row>
    );
  }
}

export default PostNrComponent;
