import React, { Component } from "react";
import ListWindow from "../Lists/ListWindow";
import DocumentPreview from "../Documents/DocumentPreview";

class HudContainer extends Component {

  render (){ 
    const {activeView, hud, documentOptions, showPopup, lang} = this.props;
    return (
        <div id='timeline-sidebar'>
            <div id='timeline-sidebar-box'>
             {hud && hud.previewFile.file && <DocumentPreview lang={lang} showPopup={showPopup} documentOptions={documentOptions} documentCardView={true} document={hud.previewFile.file} openFullPreview={hud.previewFile.open}/>}

            </div>
        </div>
    );
  }
}

export default HudContainer;
