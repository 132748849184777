import React, { Component } from "react";
import LoggedInDocuments from "../Documents/LoggedInDocuments";
import SettingsMenu from '../Settings/SettingsMenu';
import ProjectOptions from './ProjectOptions';
import ProjectStructure from './ProjectStructure';
import ProjectStats from './ProjectStats';
import ProjectAbout from './ProjectAbout';
import './../projects.css';
import { chooseLanguage } from "../Lang/langpack";

class ProjectCard extends Component {
    constructor () {
        super();
        this.state = {
        tab: {
            about: true,
            structure: false,
            documents: false,
            stats: false,
            options: false
        }
      }
    }
    
    closeMenu = () => {
      const { projectController } = {...this.props};
      projectController.close();
    }
  
    openTab = (selected) => {
      let tab = {
        about: false,
        structure: false,
        documents: false,
        stats: false,
        options: false
      }
      tab[selected]=true;
      this.setState({tab});
    }
  

  render (){
    let {
      project, 
      documents, 
      documentOptions, 
      editSetting, 
      openFullPreview, 
      showPopup,
      lang} = this.props;
    const {tab} = this.state;
    const menu = ['about','stats','documents','options','structure'];
    const backgroundImage = 'projects_full.svg';
    const {langinterface} = lang;
    const translation = {
      documents: chooseLanguage('documents',  langinterface),
    }
    const isEmpty = documents.length > 0 ? false : true;
    return (  
    <div className="project-background">
      <div className="project-window">
        <div className='popup-topbeam' style={{zIndex:  10, backgroundImage: 'url(./'+backgroundImage}}><h1>{project.name}</h1>
        {<div className='popup-close-button' onClick={this.closeMenu}>X</div>}</div>
          <div className="project-card">          
            {<div>
            <ProjectAbout
              pid={project ? project.pid : undefined}
              project={project ? project : undefined}
              details={project ? project.details : undefined}
              editSetting={editSetting}
              lang={lang}
              />
            <ProjectStats 
              settings = {project ? project.settings : undefined}
              details={project ? project.details : undefined}
              documents={documents}
              lang={lang} />
            <ProjectOptions 
              isEmpty={isEmpty}
              pid={project ? project.pid : undefined} 
              editSetting={editSetting} 
              lang={lang}
              removed={project.settings.deleted}
              archived={project.settings.archived}
              showPopup={showPopup}
              />
              </div>}
            <div>
            <p className="project-section-header">{translation.documents}</p>
            {<LoggedInDocuments 
              openFullPreview={openFullPreview}
              pid={project ? project.pid : undefined}
              documents={documents} 
              documentOptions={documentOptions}
              lang={lang}
              view={'cards'}
              showPopup={showPopup}
              /> }     
            </div>
            {

            //tab.structure && <ProjectStructure/> 
            }  
        </div>
      </div>
    </div>
    );
  }
}

export default ProjectCard;
