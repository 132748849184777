import React, { Component } from "react";
import NewProjectIco from "./NewProjectIco";
import NewClientIco from "./NewClientIco";
import AllProjectsIco from "./AllProjectsIco";
import AllClientsIco from "./AllClientsIco";


class IcoSet extends Component {
  constructor () {
	  super();
	  this.state = {

    }
  }

   render (){
    const type = this.props.type ? this.props.type : false; 
    const {lang} = {...this.props};
    return (
      <div id='list-ico-set'>
            {type && type === 'projects' && <AllProjectsIco lang={lang} clearList={this.props.clearList} openMenu={this.props.openMenu}/>}
            {type && type === 'projects' && <NewProjectIco lang={lang} showPopup={this.props.showPopup} createNewProject={this.props.createNewProject}/>}
            {type && type === 'clients' && <AllClientsIco lang={lang} clearList={this.props.clearList} openMenu={this.props.openMenu}/>}
            {type && type === 'clients' && <NewClientIco lang={lang} createNewClient={this.props.createNewClient} clearList={this.props.clearList} showPopup={this.props.showPopup}/>}            
        </div>  
    );
  }
}

export default IcoSet;
