import React, { Component } from "react";
import { Container, Row, Form, Col} from 'react-bootstrap';
import TimelistHeader from './TimelistHeader';
import TimelistRow from './TimelistRow';
import TimelistBasis from './TimelistBasis';
import {serverRouteParser, requestObjectGenerator} from '../ServerRoutes/serverRoute';
import { localizeDateStr, dateToSlashString } from "../Features/dateFeatures";
import DocumentExportOptions from "../FormComponents/DocumentExportOptions";
import TimelistRowMobile from "./TimelistRowMobile";
import ClientData from "../FormComponents/ClientData";
import ClientDataLocked from "../FormComponents/ClientDataLocked";


class NewTimelistWizardStepDocPositions extends Component {

  render (){
    let {loggedDaysArray, saveDate, moveDown, moveUp, addNewRow, timelist, deleteDay, writeToState, arrayLength, lang, showKm} = this.props;
    return (
      <>
          <div id='timelist-regular'> {loggedDaysArray.map((day, index)=>(<TimelistRow 
            saveDate={saveDate} 
            moveDown={moveDown} 
            moveUp={moveUp} 
            addNewRow={addNewRow} 
            timelist={{
              extra: timelist.extra[index],
              km: timelist.km[index],
              files: timelist.files[index],
              day: timelist.day[index],
              in: timelist.in[index],
              out: timelist.out[index],
              paidbreak: timelist.paidbreak[index], 
              breakValue: timelist.breakValue[index],
              daydescription: timelist.daydescription[index],
            }}
            showKm={showKm}
            deleteDay={deleteDay} 
            writeToState={writeToState} 
            key={index} 
            day={day} 
            arrayLength={arrayLength} 
            index={index.toString()}
            lang={lang}
            />))
          }
          </div>
        </>
    );
  }
}

export default NewTimelistWizardStepDocPositions;
