import React, { Component } from "react";
import {Form} from 'react-bootstrap';


class LangComponent extends Component {

  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }

  render (){
    let {index, value} = this.props;
    console.log(index + " : " + value);
    return (        
      <Form.Control style={{display: 'inline'}} 
      id={index} 
      name={'lang'}  
      as="select" 
      onChange={this.handleChange}>
          <option selected={value === 'PL' && true} value='PL'>Polski</option>
          <option selected={value === 'EN' && true} value='EN'>English</option>
          <option selected={value === 'NO' && true} value='NO'>Norsk</option>
      </Form.Control>
    );
  }
}

export default LangComponent;
