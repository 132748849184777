import React, { Component } from "react";
import ProjectItem from "./ProjectItem";

import './../projects.css';
import { chooseLanguage } from "../Lang/langpack";
import ProjectMenu from "./ProjectMenu";

class ProjectList extends Component {
  constructor () {
    super();
    this.state = {
    tab: {
        active: true,
        archived: false,
        deleted: false,
    },
    menu: ['active', 'archived', 'deleted']
  }
}
  closeMenu = () => {
    this.props.closeMenu('projects');
  }

  openTab = (tab) => {
    let tabs = {
      active: false,
      archived: false,
      deleted: false,
    }
    tabs[tab] = true;
    this.setState({
      tab: tabs,
    })
  }

  render (){
    let {projects} =  this.props;
    const {tab, menu} = this.state;
    let projectsObject = {
      archived: projects.filter((project, index) => (project.settings.archived && project.settings.archived.status)),
      deleted: projects.filter((project, index) => (project.settings.deleted && project.settings.deleted.status)),
      projects: projects.filter((project, index) => ((!project.settings.archived || !project.settings.archived.status) && (!project.settings.deleted || !project.settings.deleted.status))),
    };
    const backgroundImage = 'projects.svg';
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      emptyList: chooseLanguage('emptyList', langinterface),
      projects: chooseLanguage('projects', langinterface)
    }
    return (
      <div className="project-background">
        <div className="project-window">
          <div className='popup-topbeam' style={{backgroundImage: 'url(./'+backgroundImage}}><h1>{translation.projects}</h1>
          {<div className='popup-close-button' onClick={this.closeMenu}>X</div>}</div>
          <ProjectMenu menu={menu} tab={tab} lang={lang} openTab={this.openTab}/>
          {tab.active && <div id='project-fulllist'>
            {projectsObject.projects.length > 0 && projectsObject.projects.map((project, index) => <ProjectItem key={index} projectController={this.props.projectController} project={project}/>)}
            {projectsObject.projects.length === 0 && <div id='empty-list'>{translation.emptyList}</div>}
          </div>} 
          {tab.archived && <div id='project-fulllist'>
            {projectsObject.archived.length > 0 && projectsObject.archived.map((project, index) => <ProjectItem key={index} projectController={this.props.projectController} project={project}/>)}
            {projectsObject.archived.length === 0 && <div id='empty-list'>{translation.emptyList}</div>}
          </div>} 
          {tab.deleted && <div id='project-fulllist'>
            {projectsObject.deleted.length > 0 && projectsObject.deleted.map((project, index) => <ProjectItem key={index} projectController={this.props.projectController} project={project}/>)}
            {projectsObject.deleted.length === 0 && <div id='empty-list'>{translation.emptyList}</div>}
          </div>} 
        </div>
      </div>

    );
  }
}

export default ProjectList;
