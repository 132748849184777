import React, { Component } from "react";
import {Container, Card, DropdownButton, Dropdown} from 'react-bootstrap';
import DocumentPreview from "./DocumentPreview";
import './../timeline.css';
class DocumentCard extends Component {
  constructor () {
	  super();
	  this.state = {
      previewFile: false,
    }
    this.editDocument = this.editDocument.bind(this);
    this.repeatDocument = this.repeatDocument.bind(this);    
    this.deleteDocument = this.deleteDocument.bind(this);
    this.regenerateDocument = this.regenerateDocument.bind(this);
    this.previewFile = this.previewFile.bind(this);
    this.blankPreview = this.blankPreview.bind(this);
    this.blankPreviewFile = this.blankPreviewFile.bind(this);
    this.fullPreview = this.fullPreview.bind(this);
  }

  componentDidMount() {
  }

  editDocument(event) {
    let did = event.target.id;
    this.props.editDocument(did, false)
  }

  repeatDocument(event) {
    let did = event.target.id;
    this.props.editDocument(did, true)
  }


  deleteDocument(event) {
    let target = event.target;
    let did = target.id;
    let uid = target.name;
    this.props.deleteDocument(did, uid);
  }
  
  regenerateDocument(event) {
    let target = event.target;
    let did = target.id;
    let uid = target.name;
    this.props.regenerateDocument(did, uid);
  }
  
  previewFile(){
    const document = this.props.document;
    this.setState({
      previewFile: document
    })
  }

  blankPreview(){
    setTimeout(this.blankPreviewFile, 3)
  }

  fullPreview(event){
    let did = event.target.id;
    console.log(event.target)
    this.props.openFullPreview(did);
  }

  blankPreviewFile(){
    this.setState({
      previewFile: false
    }) 
  }
    
  verifyDocumentTypeAndColor(doc){
    let type = doc.type;
    switch (type) {
      case 'timelist':
        return 'rgb(255, 127, 39)';
      break;
      case 'faktura':
        return '#0000cc';
      break;
      case 'tilbud':
        return '#003333';
      break;
      default:
        return '#FFF';     
    } 
  }

  verifyDocumentTypeAndIco(doc){
    let type = doc.type;
    switch (type) {
      case 'timelist':
        return 'url(./timelist.svg)';
      break;
      case 'faktura':
        return 'url(./faktura.svg)';
      break;
      case 'tilbud':
        return 'url(./tilbud.svg)';
      break;
      default:
        return 'url(./documents.svg)';
    } 
  }

  verifyHeaderType(documentFile){
    let type = documentFile.type;
    switch (type) {
      case 'timelist':
        return 'Timelist ' + documentFile.content.timelistnr;
      break;
      case 'faktura':
        return 'Faktura ' + documentFile.content.fakturanr;
       break;
      case 'tilbud':
        return 'Tilbud ' + documentFile.content.tilbudnr;
      break;
      default:
      return 'Jobbleder'
  }
}

  render (){
    let doc = this.props.document;
    let content = doc.content;
    const date = new Date(doc.createdate);
    let dateString = (date.getDate() > 9 ? date.getDate() : '0'+date.getDate()) + '/' + (date.getMonth() > 9 ? date.getMonth() : '0'+date.getMonth()) + '/' + date.getFullYear(); 
    const {lang} = this.props;
    return (  
        <>
            <DocumentPreview lang={lang} documentOptions={this.props.documentOptions} openFullPreview={this.props.openFullPreview} documentCardView={true} previewFile={doc} document={doc} />
        </>

    );
  }
}

export default DocumentCard;
