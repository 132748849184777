//Myśl pozytywnie
const langObject = {
    PL: {
        menu: ['Szukaj', 'Nowy dokument', 'Projekty', 'Klienci', 'Ustawienia', 'Wyloguj', 'Dniówka'],
        createNewDocument: 'Utwórz nowy dokument', 
        docType: ['Oferta', 'Faktura', 'Lista godzin'],
        searchBoxPlaceholder: 'Szukaj dokument, projekt, klienta',
        searchResults: 'Wyniki wyszukiwania',
        recent: 'ostatnie',
        projects: 'projekty',
        documents: 'dokumenty',
        clients: 'klienci',
        timelist: 'Lista godzin',
        tilbud: 'Oferta',
        faktura: 'Faktura',
        kreditnota: 'Nota kredytowa',
        week: 'tydzień',
        day: 'dzień',
        view: 'widok',
        calendar: 'kalendarz',
        timeline: 'lista',
        docTypeHeader: 'Typ dokumentu',
        client: 'klient',
        project: 'projekt',
        dates: 'daty',
        document: 'dokument',
        preview: 'podgląd',
        worker: 'pracownik',
        name: 'klient',
        mva: 'VAT',
        address: 'ulica',
        post: 'miasto',
        projectName: 'Nazwa projektu',
        workerName: 'Imię pracownika',
        in: 'wejście',
        out: 'wyjście',
        break: 'przerwa',
        paid: 'płatna',
        description: 'opis',
        options: 'opcje',
        timer: 'godziny',
        year: 'rok',
        extra: 'ekstra',
        save: 'zapisz',
        print: 'drukuj',
        share: 'wyślij',
        issueDate: 'data wystawienia',
        dueDate: 'data przedawnienia',
        netprice: 'cena netto',
        amount: 'ilość',
        gross: 'brutto',
        fakturaIssueDate: 'Data faktury',
        fakturaDueDate: 'Ważny do',
        tilbudIssueDate: 'Data Oferty',
        next: 'dalej',
        back: 'wstecz',
        skip: 'pomiń',
        expiryDate: 'ważność dokumentu',
        nr: 'nr',
        printGiro: 'drukuj GIRO',
        hourprice: 'stawka za godzinę',
        kmprice: 'stawka za km',
        breakIsPaid: 'przerwa płatna',
        interfaceLang: 'Język interfejsu',
        username: 'nazwa użytkownika',
        password: 'hasło',
        repassword: 'powtórz hasło',
        email: 'e-mail',
        emptyList: 'Pusto. Utwórz nowy aby zobaczyć opcje.',
        errorEdit: 'Podczas edytowania dokumentu wystąpił błąd. Spróbuj ponownie.',
        errorRepeat: 'Podczas powtarzania dokumentu wystąpił błąd. Spróbuj ponownie.',
        errorFakturaTilbud: 'Wystąpił błąd podczas tworzenia faktury z dokumentu oferty. Spróbuj ponownie.',
        documentDeletedPopup: 'Dokument usunięto.',
        documentSavedPopup: 'Dokument zapisano.',
        settingSaved: 'Zmiany zapisano.',
        clientSaved: 'Utworzono klienta.',
        errorClose: 'Wystąpił błąd podczas zamykania pliku. Spróbuj ponownie',
        errorDownload: 'Wystąpił błąd podczas pobierania danych. Odśwież stronę.',
        pdfReady: 'Dokument w pliku pdf gotowy.',
        varselReady: 'Utworzono varsel dla dokumentu faktury',
        projectCreated: 'Projekt został utworzony',
        errorSaveSetting: 'Ustawienia nie udało się zapisać.',
        errorCreate: 'Wystąpił błąd podczas tworzenia nowego dokumentu. Spróbuj ponownie.',
        importDays: 'Importuj godziny',
        import: 'Importuj',
        weekDays: ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'],
        crafts: ['usługi budowlane', 'malarz', 'murarz', 'stolarz', 'personel medyczny', 'usługi sprzątania', 'dekarz', 'usługi przewozowe', 'gipsiarz', 'inne'],
        craft: 'zawód',
        deleted: 'usunięte',
        archived: 'zarchiwizowane', 
        active: 'aktywne',            
        clientOptions: 'Opcje klienta',
        archiveClient: 'archiwizuj klienta',
        removeClient: 'usuń klienta',
        backToActiveClients: 'przywróć do aktywnych klientów',
        archiveClientConfirm: 'Czy na pewno chcesz przenieść klienta do archiwum?',
        removeClientConfirm: 'Czy na pewno chcesz usunąć dane klienta?',
        removeClientPermanentlyConfirm: 'Czy na pewno chcesz usunąć dane klienta bezpowrotnie?',
        removeClientPermanently: 'usuń klienta z bazy danych',
        projectOptions: 'Opcje projektu',
        archiveProject: 'archiwizuj projekt',
        archiveProjectConfirm: 'Czy na pewno chcesz przenieść projekt do archiwum?',
        removeProject: 'usuń projekt',
        removeProjectConfirm: 'Czy na pewno chcesz usunąć ten projekt?',
        removeProjectPermanentlyConfirm: 'Czy na pewno chcesz usunąć ten projekt bezpowrotnie?',
        varselConfirm: 'Czy chcesz wystawić dokument ponaglający?',
        kreditnotaConfirm: 'Czy chcesz wystawić notę kredytową?',
        emailChangeConfirmLogout: 'Zmieniono adres e-mail. Nastąpi wylogowanie.',
        backToActiveProjects: 'przywróć do aktywnych projektów',
        removeProjectPermanently: 'usuń projekt z bazy danych',
        businessName: 'Nazwa firmy',
        postnr: 'kod pocztowy',
        kontonr: 'nr konta',
        account: 'konto',
        jobblederSettings: 'Ustawienia Jobbleder',
        accountSettings: 'Ustawienia Konta',
        invoiceSettings: 'Dane do faktury',
        allProjects: 'Wszystkie projekty',
        newProject: 'Nowy projekt',
        allClients: 'Wszyscy klienci',
        newClient: 'Nowy klient',
        km: 'km',
        clientData: 'dane klienta',
        varselDate: 'data ponaglenia',
        varsel: 'varsel',
        inkasovarsel: 'Inkasovarsel',
        inkasovarseldato: 'data 3 ponaglenia',
        closePopup: 'Czy chcesz zamknąć bez zapisywania?',
        vatBase: 'Podstawa podatku VAT',
        total: 'Łącznie',
        repeat: 'powtórz',
        delete: 'usuń',
        workerStats: 'statystyki pracowników',
        documentStats: 'statystyki dokumentu',
        timeLimits: 'Limity czasu',
        projectLimit: 'limit projektu',
        newUser: 'Utwórz nowe konto użytkownika',
        newDay: 'Zapisz godziny',
        newPassword: 'nowe hasło',
        oldPassword: 'stare hasło',
        repeatNewPassword: 'powtórz nowe hasło',
        newEmail: 'nowy e-mail',
        oldEmail: 'stary e-mail', 
        repeatNewEmail: 'powtórz nowy e-mail',
        logIn: 'zaloguj',
        wrongLogin: 'Nieprawidłowy e-mail lub hasło.',
        register: 'Utwórz konto',
        rememberUser: 'pamiętaj mnie',
        daySaved: 'Godziny zapisane',
        edit: 'edytuj',
        editSetting: 'Edytuj ustawienie',
        missingData: 'Uzupełnij dane firmy.',
        settings: 'Ustawienia'
    },
    EN: {
        menu: ['Search', 'New Document', 'Projects', 'Clients', 'Settings', 'Logout', 'Add a day'],
        createNewDocument: 'Create new document',
        docType: ['Budget', 'Invoice', 'Time sheet'],
        searchBoxPlaceholder: 'Search for document, project or client',
        searchResults: 'Search results',
        recent: 'recent',
        projects: 'projects',
        documents: 'documents',
        clients: 'clients',
        timelist: 'Time sheet',
        tilbud: 'Budget',
        faktura: 'Invoice',
        kreditnota: 'Credit nota',
        week: 'week',
        day: 'date',
        view: 'view',
        calendar: 'calendar',
        timeline: 'list',
        docTypeHeader: 'Document type',
        client: 'client',
        project: 'project',
        dates: 'dates',
        document: 'document',
        preview: 'preview',
        worker: 'worker',
        name: 'name',
        mva: 'VAT',
        address: 'address',
        post: 'post',
        projectName: 'project name',
        workerName: 'worker name',
        in: 'in',
        out: 'out',
        break: 'break',
        paid: 'paid',
        description: 'description',
        options: 'options',
        timer: 'timer',
        year: 'year',
        extra: 'extra',
        save: 'save',
        print: 'print',
        share: 'share',
        issueDate: 'issue date',
        dueDate: 'due date',
        netprice: 'net price',
        amount: 'amount',
        gross: 'gross value',
        fakturaIssueDate: 'invoice issue',
        fakturaDueDate: 'due',
        tilbudIssueDate: 'budget issue',
        next: 'next',
        back: 'back',
        skip: 'skip',
        expiryDate: 'document validity',
        nr: 'no.',
        printGiro: 'print GIRO',
        hourprice: 'price per hour',
        kmprice: 'km rate',
        breakIsPaid: 'paid breaks',
        interfaceLang: 'Interface Language',
        username: 'username',
        password: 'password',
        repassword: 'repeat password',
        email: 'e-mail',
        emptyList: 'Nothing to display. Add new for more options.',
        errorEdit: 'An error occurred while editing the document. Try again.',
        errorRepeat: 'An error occurred while repeating the document. Try again.',
        errorFakturaTilbud: 'An error occurred while creating an invoice from an offer document. Try again.',
        documentDeletedPopup: 'Document deleted.',
        documentSavedPopup: 'Document saved.',
        settingSaved: 'Changes saved.',
        clientSaved: 'Client created.',
        errorClose: 'An error occurred while closing the file. Try again',
        errorDownload: 'An error occurred while downloading data. Refresh the page.',
        pdfReady: 'Document in PDF file ready.',
        varselReady: 'Utworzono varsel dla dokumentu faktury',
        projectCreated: 'The project has been created',
        errorSaveSetting: 'Settings failed to save.',
        errorCreate: 'An error occurred creating a new document. Try again.',
        importDays: 'Import days',
        import: 'Import',
        weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        crafts: ['construction services', 'painter', 'bricklayer', 'carpenter', 'medical staff', 'cleaning services', 'roofer', 'transportation services', 'plasterer', 'other'],
        craft: 'craf',
        deleted: 'deleted',
        archived: 'archived', 
        active: 'active',        
        clientOptions: 'Client options',
        archiveClient: 'archive client',
        removeClient: 'remove client',
        backToActiveClients: 'restore to active clients',
        archiveClientConfirm: 'Are you sure you want to move the client to the archive?',
        removeClientConfirm: 'Are you sure you want to remove client?',
        removeClientPermanentlyConfirm: 'Are you sure you want to delete client permanently?',
        removeClientPermanently: 'remove client from database',
        projectOptions: 'Project options',
        archiveProject: 'archive project',
        archiveProjectConfirm: 'Are you sure you want to move this project to the archive?',
        removeProject: 'remove project',
        removeProjectConfirm: 'Are you sure you want to remove the project?',
        removeProjectPermanentlyConfirm: 'Are you sure you want to delete the project permanently?',
        varselConfirm: 'Do you want to issue a reminder document?',
        kreditnotaConfirm: 'Do you want to issue a credit note?',
        emailChangeConfirmLogout: 'Email address changed. You will be logged out.',
        backToActiveProjects: 'restore to active projects',
        removeProjectPermanently: 'remove project from database',
        businessName: 'Business Name',
        postnr: 'postcode',
        kontonr: 'account no.',
        account: 'Account',
        jobblederSettings: 'Jobbleder settings',
        accountSettings: 'Account settings',
        invoiceSettings: 'Invoice settings',
        allProjects: 'All projects',
        newProject: 'New project',
        allClients: 'All clients',
        newClient: 'New client',
        km: 'km',
        clientData: 'client data',
        varselDate: 'varseldato',
        varsel: 'varsel',
        inkasovarsel: 'Inkasovarsel',
        inkasovarseldato: 'Inkasovarseldato',
        closePopup: 'Do you want to leave with unsaved document?',
        vatBase: 'VAT base - ',
        total: 'Total',
        repeat: 'repeat',
        delete: 'delete',
        workerStats: 'worker stats',
        documentStats: 'document stats',
        timeLimits: 'time limits',
        projectLimit: 'project limit',
        newUser: 'Create new User Account',
        newDay: 'Add a new workday',
        newPassword: 'new password',
        oldPassword: 'old password',
        repeatNewPassword: 'repeat new password',
        newEmail: 'new e-mail',
        oldEmail: 'old e-mail',
        repeatNewEmail: 'repeat new e-mail',
        logIn: 'log in',
        wrongLogin: 'Incorrect email or password.',
        register: 'new user account',
        rememberUser: 'keep logged in',
        daySaved: 'Day saved',
        edit: 'edit',
        editSetting: 'modify setting',
        missingData: 'Missing data, please fill in company details.',
        settings: 'Settings'
    },
    NO: {
        menu: ['Søk', 'Lage dokument', 'Prosjekter', 'Kunder', 'Innstillinger', 'Logg ut', 'Husk timer'],
        createNewDocument: 'Lage  et nytt dokument',
        docType: ['Tilbud', 'Faktura', 'Timelist'],
        searchBoxPlaceholder: 'Søk etter dokument, prosjekt eller klient',
        searchResults: 'Søkeresultater',
        recent: 'siste',
        projects: 'prosjekter',
        documents: 'dokumenter',
        clients: 'kunder',
        accountSettings: 'Kontoinnstillinger',
        timelist: 'Timelist',
        tilbud: 'Tilbud',
        faktura: 'Faktura',
        kreditnota: 'Kreditnota',
        week: 'uke',
        day: 'dato',
        view: 'synlighet',
        calendar: 'kalender',
        timeline: 'liste',
        docTypeHeader: 'Dokumenttype',
        client: 'kunde',
        project: 'prosjekt',
        dates: 'datoer',
        document: 'dokument',
        preview: 'virkning',
        worker: 'arbeider',
        name: 'navn',
        mva: 'MVA',
        address: 'adresse',
        post: 'post',
        projectName: 'prosjektnavn',
        workerName: 'arbeidernavn',
        in: 'i',
        out: 'ut',
        break: 'pause',
        paid: 'betalt',
        description: 'beskrivelse',
        options: 'alternativer',
        timer: 'timer',
        year: 'år',
        extra: 'ekstra',
        save: 'lagre',
        print: 'print',
        share: 'dele',
        issueDate: 'utstedelsesdato',
        dueDate: 'forfallsdato',
        netprice: 'nettopris',
        amount: 'antall',
        gross: 'beløp',
        fakturaIssueDate: 'fakturadato',
        fakturaDueDate: 'gyldig til',
        tilbudIssueDate: 'tilbuddato',
        next: 'neste',
        back: 'tilbake',
        skip: 'hopp over',
        expiryDate: 'dokumentets gyldighet',
        nr: 'nr.',
        printGiro: 'print GIRO',
        hourprice: 'timepris',
        kmprice: 'km rate',
        breakIsPaid: 'betalt pause',
        interfaceLang: 'Grensesnittspråk',
        username: 'brukernavn',
        password: 'passord',
        repassword: 'gjenta passord',
        email: 'e-post',
        emptyList: 'Ingenting å vise. Legg til ny for flere alternativer.',
        errorEdit: 'Det oppstod en feil ved redigering av dokumentet. Prøv igjen.',
        errorRepeat: 'Det oppsto en feil ved gjentagelse av dokumentet. Prøv igjen.',
        errorFakturaTilbud: 'Det oppsto en feil under oppretting av en faktura fra et tilbudsdokument. Prøv igjen.',
        documentDeletedPopup: 'Dokument slettet.',
        documentSavedPopup: 'Dokumentet er lagret.',
        settingSaved: 'Endringer lagret.',
        clientSaved: 'Klient opprettet.',
        errorClose: 'Det oppstod en feil under lukking av filen. Prøv igjen',
        errorDownload: 'Det oppsto en feil under nedlasting av data. Oppdater siden.',
        pdfReady: 'Dokumentet i PDF-filen er klart.',
        varselReady: 'Utworzono varsel dla dokumentu faktury',
        projectCreated: 'Prosjektet er opprettet',
        errorSaveSetting: 'Innstillinger kunne ikke lagres.',
        errorCreate: 'En feil med å opprette nytt dokument. Prøv igjen.',
        importDays: 'Importer arbeidstimer.',
        import: 'Import',
        weekDays: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
        crafts: ['byggetjenester', 'maler', 'murer', 'tømrer', 'medisinsk personale', 'renholdstjenester', 'taklegger', 'transporttjenester', 'gipser', 'annet'],
        craft: 'yrke',
        deleted: 'slettet',
        archived: 'arkivert', 
        active: 'active', 
        clientOptions: 'Client options',
        archiveClient: 'arkivklient',
        removeClient: 'fjern klient',
        backToActiveClients: 'gjenopprett til aktive klienter',
        archiveClientConfirm: 'Er du sikker på at du vil flytte kundedataene dine til arkivet?',
        removeClientConfirm: 'Er du sikker på at du vil slette kundedata?',
        removeClientPermanentlyConfirm: 'Er du sikker på at du vil slette kundedataene dine for alltid?',
        removeClientPermanently: 'fjern klient fra database',
        projectOptions: 'Prosjektalternativer',
        archiveProject: 'arkivprosjekt',
        archiveProjectConfirm: 'Er du sikker på at du vil flytte dette prosjektet til arkivet?',
        removeProject: 'fjern prosjekt',
        removeProjectConfirm: 'Er du sikker på at du vil slette dette prosjektet?',
        removeProjectPermanentlyConfirm: 'Er du sikker på at du vil slette dette prosjektet for alltid?',
        varselConfirm: 'Vil du utføre varsel?',
        kreditnotaConfirm: 'Vil du lage en kreditnota?',
        emailChangeConfirmLogout: 'E-postadresse endret. Du blir logget av.',
        backToActiveProjects: 'gjenopprett til aktive prosjekter',
        removeProjectPermanently: 'fjern prosjekt fra database',
        businessName: 'Firmanavn',
        postnr: 'post kode',
        kontonr: 'kontonr',
        account: 'Konto',
        jobblederSettings: 'Jobblederinnstillinger',
        kontoinnstillinger: 'Kontoinnstillinger',
        invoiceSettings: 'Fakturainnstillinger',
        allProjects: 'Alle prosjekter',
        newProject: 'Nytt prosjekt',
        allClients: 'Alle kunder',
        newClient: 'Ny klient',
        km: 'km',
        clientData: 'kundedata',
        varselDate: 'varseldato',
        varsel: 'varsel',
        inkasovarsel: 'Inkasovarsel',
        inkasovarseldato: 'Inkasovarseldato',
        closePopup: 'Vil du lukke med et ulagret dokument?',
        vatBase: 'MVA grunnlag',
        total: 'Total',
        repeat: 'gjenta',
        delete: 'slett',
        workerStats: 'arbeiderstatistikk',
        documentStats: 'dokumentstatistikk',
        timeLimits: 'tidsbegrensninger',
        projectLimit: 'prosjekttidsfrist',
        newUser: 'Opprett ny brukerkonto',
        newDay: 'Husk timer',
        newPassword: 'nytt passord',
        oldPassword: 'gammelt passord',
        repeatNewPassword: 'gjenta nytt passord',
        newEmail: 'nytt epost',
        oldEmail: 'gammelt epost',
        repeatNewEmail: 'gjenta nytt epost',
        logIn: 'logg Inn',
        wrongLogin: 'Feil email eller passord.',
        register: 'ny brukerkonto',
        rememberUser: 'holde pålogget',
        daySaved: 'Arbeidsdag lagret',
        edit: 'endre',
        editSetting: 'endre innstillingen',
        missingData: 'Manglende data, fyll ut bedriftsinformasjonen din.',
        settings: 'Innstillinger',
    }
}

const chooseLanguage = (record, lang) =>
{
	let outputObject = {};
	for (let [collectionName, languagesCollections] of Object.entries(langObject)) {
		outputObject[collectionName] = languagesCollections[record];
	}
    
	return outputObject[lang];
}

export {chooseLanguage};