import React, { Component } from "react";
import {Form} from 'react-bootstrap';


class ExtraPosition extends Component {

  handleChange = (e) => {
    const {id, name } = e.target,
    value = e.target.checked ? true : false,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }

  render (){
    let {index, value, placeholder} = {...this.props};
    placeholder = placeholder ? placeholder : 'extra';
    return (  
        <div style={{'display':'inline-block'}}>      
            <Form.Control 
                type='checkbox'
                id={index}
                name={'extra'}
                onChange={this.handleChange}
                checked={value}
            >
            </Form.Control>
            <Form.Label htmlFor='extra'>{placeholder}</Form.Label>
        </div>
    );
  }
}

export default ExtraPosition;

