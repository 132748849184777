const localizeDateStr = date_to_convert_str =>
{
    let date_to_convert = new Date(date_to_convert_str);
    let dateArray = [date_to_convert.getDate(), date_to_convert.getMonth()+1, date_to_convert.getFullYear(), date_to_convert.getHours(), date_to_convert.getMinutes(), date_to_convert.getSeconds()];
    let day = dateArray[0].toString();
    let month = dateArray[1].toString();
    dateArray[0] = day.length === 1 ? '0' + day : day;
    dateArray[1] = month.length === 1 ? '0' + month : month;
    let dateString = dateArray[2]+ '-' + dateArray[1] + '-' + dateArray[0];
    return dateString; 
}

const dateToSlashString = date_to_convert_str =>
{
    let date_to_convert = new Date(date_to_convert_str);
    let dateArray = [date_to_convert.getDate(), date_to_convert.getMonth()+1, date_to_convert.getFullYear(), date_to_convert.getHours(), date_to_convert.getMinutes(), date_to_convert.getSeconds()];
    let day = dateArray[0].toString();
    let month = dateArray[1].toString();
    dateArray[0] = day.length === 1 ? '0' + day : day;
    dateArray[1] = month.length === 1 ? '0' + month : month;
    let dateString = dateArray[0]+ '/' + dateArray[1] + '/' + dateArray[2];
    return dateString; 
}

export {localizeDateStr, dateToSlashString}