import React, { Component } from "react";
import { Row, Col, Form, Button} from 'react-bootstrap';
import { chooseLanguage } from "../Lang/langpack";


class EmailChangeComponent extends Component {
  constructor () {
	  super();
	  this.state = {
        newmail: '',
        oldmail: '',
        repeatmail: '',
    }
  }

  componentDidMount() {
  }

  saveBasis = (event) => {
    let record = event.target.id,
      value = event.target.value;
      this.setState({
        [record]: value,
      })
  }


  executeMailChange = () => {
    const { newmail, oldmail } = this.state;
    const { emailChange } = this.props;
    const emailObject = {
      newmail, oldmail
    }
    emailChange(emailObject);
  }
  

  render (){
    const {closeRegister} = this.props;
    const {newmail, oldmail, repeatmail} = this.state;
    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const validEmailAddress = validateEmail(newmail) && validateEmail(oldmail) ? true : false;
    const {langinterface}= this.props.lang;
    const translation = {
      newEmail: chooseLanguage('newEmail', langinterface),
      repeatNewEmail: chooseLanguage('repeatNewEmail', langinterface),
      oldEmail:  chooseLanguage('oldEmail', langinterface),
    }
    return (
    <Form>
      <Form.Row className='text-right'>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={4} xs={12}><h4>{translation.oldEmail}</h4></Form.Label>
        <Col lg={8} xs={12}>
          <Form.Control id='oldmail' type="email" placeholder={translation.oldEmail} onChange={this.saveBasis} required/>
        </Col>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={4} xs={12}><h4>{translation.newEmail}</h4></Form.Label>
        <Col lg={8} xs={12}>
          <Form.Control id='newmail' type="email" placeholder={translation.newEmail} onChange={this.saveBasis} required/>
        </Col>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={4} xs={12}><h4>{translation.repeatNewEmail}</h4></Form.Label>
        <Col lg={8} xs={12}>
          <Form.Control id='repeatmail' type="email" placeholder={translation.repeatNewEmail} onChange={this.saveBasis} required/>
        </Col>
      </Form.Row>

      {validEmailAddress && oldmail.length > 0 && newmail === repeatmail && newmail.length > 0 && newmail !== oldmail && <div className="popup-buttons">
            <img src='./ok.svg' 
            id='accept'  
            onClick={this.executeMailChange}
            alt='accept'
            style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
            <img src='./abort.svg' 
            id='abort'  
            onClick={this.props.closePopup}
            alt='accept'
            style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
          </div> }
    </Form>
    );
  }
}

export default EmailChangeComponent;
