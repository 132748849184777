import React, { Component } from "react";
import DocumentSettings from "./DocumentSettings";
import InvoiceSettings from "./CompanySettings";
import AppSettings from "./AppSettings";
import PrivacySettings from "./PrivacySettings";

import './../settings.css'
import SettingsMenu from "./SettingsMenu";
import AccountSettings from "./AccountSettings";
import { chooseLanguage } from "../Lang/langpack";

class Settings extends Component {
  constructor () {
	  super();
	  this.state = {
      tab: {
        app: false,
        document: true,
        company: false,
        privacy: false,
        account: false,
      }
    }
  }

  componentDidMount() {
  }

  closeMenu = () => {
    this.props.closeMenu('settings');
  }

  openTab = (selected) => {
    let tab = {
      app: false,
      document: false,
      company: false,
      privacy: false,
      account: false
    }
    tab[selected]=true;
    this.setState({tab});
  }

  

  render (){
    const {tab} = {...this.state};
    const {editSetting, lang, username, craft} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      settings: chooseLanguage('settings',  langinterface),
      document: chooseLanguage('document',  langinterface),
      account: chooseLanguage('account',  langinterface),
      
    }
    const menu = ['document', 'account'];
    let backgroundImage = 'settings.svg';
    return (  
      <div className="settings-background">
        <div className="settings-window">
            <div className='popup-topbeam' style={{zIndex: 10, backgroundImage: 'url(./'+backgroundImage}}><h1>{translation.settings}</h1>
            {<div className='popup-close-button' onClick={this.closeMenu}>X</div>}</div>
            <SettingsMenu lang={lang} tab={tab} menu={menu} openTab={this.openTab}/> 
            <div className="jobbleder-settings">
                {tab.account && <AppSettings lang={lang} editSetting={editSetting}/>}
                {tab.document && <DocumentSettings lang={lang} editSetting={editSetting} 
                agrees={this.props.agrees} 
                timelistdetails={this.props.timelistdetails}
                fakturadetails={this.props.fakturadetails}
                tilbuddetails={this.props.tilbuddetails}
                />}
                {tab.account && <AccountSettings lang={lang} craft={craft} editSetting={editSetting} username={username}/>}
                {tab.account && <InvoiceSettings lang={lang} editSetting={editSetting} 
                fakturadetails={this.props.fakturadetails}/>
                }
                {tab.privacy && <PrivacySettings lang={lang} editSetting={editSetting} agrees={this.props.agrees} />}
            </div>
          </div>
        </div>
    );
  }
}

export default Settings;
