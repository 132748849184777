import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';

class KontoComponent extends Component {
  constructor () {
	  super();
	  this.state = {
          mva: false,
    }

  }

  componentDidMount() {

  }

  handleChange = (e) => {
    let {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value);
  }

  render (){
    const {value, option, setting} = {...this.props};
    return (
        <>
        <Col lg={12} xs={12}>
        <Form.Control id={setting} type="text" placeholder={option} value={value} onChange={this.handleChange} maxLength={11} required/>
        </Col>
        </>
    );
  }
}

export default KontoComponent;
