import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';

class NumberComponent extends Component {
  
  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }

  render (){
    let {index, value, record} = {...this.props};
    return (
        <Form.Control
         id={index}
         name={record} 
         type="number" 
         value={value} 
         placeholder={record} 
         onChange={this.handleChange} 
         required/>
    );
  }
}

export default NumberComponent;
