import React, { Component } from "react";
import DocumentListItem from './DocumentListItem';
import TimelineSidebar from "../Hud/HudContainer";

class DocumentList extends Component {
  constructor () {
	  super();
	  this.state = {
    }

  }

  render (){
    let documents = this.props.documents ? this.props.documents : [];
    
    //console.log(documents);
    return (
      <div>
        <div id='document-list'>
          {documents.map((document, index) => (<DocumentListItem 
          hud={this.props.hud}
          key={index} 
          document={document} 
          documents={documents} 
          deleteDocument={this.props.documentOptions.delete} 
          editDocument={this.props.documentOptions.edit} 
          regenerateDocument={this.props.documentOptions.regenerate} 
          openFullPreview={this.props.openFullPreview}/>))}
        </div>  
      </div>
    );
  }
}

export default DocumentList;
