import React, { Component } from "react";
import ProjectItem from "./ProjectItem";
import { chooseLanguage } from "../Lang/langpack";

class ProjectList extends Component {

  render (){
    let {projects} =  this.props;
    let projectsObject = {
      archived: projects.filter((project, index) => (project.settings.archived && project.settings.archived.status)),
      deleted: projects.filter((project, index) => (project.settings.deleted && project.settings.deleted.status)),
      projects: projects.filter((project, index) => ((!project.settings.archived || !project.settings.archived.status) && (!project.settings.deleted || !project.settings.deleted.status))),
    };
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      emptyList: chooseLanguage('emptyList', langinterface),
    }
    return (
      <div id='project-list'>
          {projectsObject.projects.length > 0 && projectsObject.projects.map((project, index) => <ProjectItem key={index} index={index} clearList={this.props.clearList} projectController={this.props.projectController} project={project}/>)}
          {projectsObject.projects.length === 0 && <div id='empty-list'>{translation.emptyList}</div>}
        </div>  
    );
  }
}

export default ProjectList;
