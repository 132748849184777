import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';
import DateComponent from "./DateComponent";
import { chooseLanguage } from "../Lang/langpack";

class InvoiceDatesModule extends Component {

  render (){
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      issueDate: chooseLanguage('issueDate', langinterface),
      dueDate: chooseLanguage('dueDate', langinterface),
    }
    return (
      <Form.Group id="document-fieldset" as={Row}>
        <Form.Label className='text-right' column lg={'2'}><h4>{translation.issueDate}:</h4></Form.Label>
        <Col lg={3}>
        <DateComponent
          lang={lang}          
          saveDate={this.props.saveDate} 
          day={this.props.fakturaissue} 
          index={'fakturaissue'}
        />
        </Col>
        <Form.Label className='text-right' column lg={'2'}><h4>{translation.dueDate}:</h4></Form.Label>
        <Col lg={3}>
        <DateComponent
          lang={lang}          
          saveDate={this.props.saveDate} 
          day={this.props.fakturadue} 
          index={'fakturadue'}
                />
        </Col>
      </Form.Group>
    );
  }
}

export default InvoiceDatesModule;
