import React, { Component } from "react";
import { Container, Row, Form, Col} from 'react-bootstrap';
import ProjectName from "../FormComponents/ProjectName";
import { chooseLanguage } from "../Lang/langpack";


class WizardStepProject extends Component {  
    render (){
      let {projects, writeBasisToState, isValid, loggedprojectname, lang} = this.props;
      const {langinterface} = lang;
      const translation = {
        projectName: chooseLanguage('projectName', langinterface),
      }
      return (
        <Form.Group id="document-fieldset" as={Row} className="justify-content-center">
          <Form.Label column xs={12} lg={3}><h4>{translation.projectName}</h4></Form.Label>
          <Col xs={10} lg={3}>
          <ProjectName lang={lang} suggestions={projects} writeBasisToState={writeBasisToState} isValid={isValid} loggedprojectname={loggedprojectname}/>
   
           {!isValid.projectName && <div>
              Please input Project Name.
            </div>}
          </Col>
        </Form.Group>
      );
    }
  }

export default WizardStepProject;
