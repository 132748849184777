import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { chooseLanguage } from "../Lang/langpack";



class DocumentExportOptions extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  send = (e) => {
    const target = e.target;
    const method = target.getAttribute('name');
    console.log(method);
    this.props.sendFormToExecute(method);
  }

  render (){
    let {index, lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      save: chooseLanguage('save', langinterface),
      print: chooseLanguage('print', langinterface),
      share: chooseLanguage('share', langinterface),
    }
    return (
      <div id='new-document-export-options' className="knob-panel">
      <div className="option-knob">
        <div className="clickable-panel" id={index} name="save" onClick={this.send}>
        </div>
        <p>{translation.save}</p>
        <div  className="document-export-ico"
          style={{
          backgroundImage: 'url("/export_jobbleder.svg")',
          backgroundSize: 'contain',
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          cursor: 'pointer'}}/>
      </div>
      <div className="option-knob">
      <div className="clickable-panel" id={index} name="create" onClick={this.send}>
        </div>
        <p>{translation.print}</p>
        <div className="document-export-ico"
          style={{
          backgroundImage: 'url("/export_pdf.svg")',
          backgroundSize: 'contain',
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          cursor: 'pointer'}}/>
      </div>
      <div className="option-knob">
      <div className="clickable-panel" id={index} name="share" onClick={this.send}>
        </div>
        <p>{translation.share}</p>
        <div className="document-export-ico"
            style={{
            backgroundImage: 'url("/export_share.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
      </div>
      </div>
    );     
  }
}

export default DocumentExportOptions;
