import React, { Component } from "react";


class ClientItem extends Component {
  constructor () {
	  super();
	  this.state = {
      hoverItem: false
    }
    this.hoverClient = this.hoverClient.bind(this);
    this.unhoverClient = this.unhoverClient.bind(this);
  }

  hoverClient(){
    this.setState({
      hoverItem: true
    })
  }
  unhoverClient(){
    this.setState({
      hoverItem: false
    })
  }
 
  openClient = (e) =>
  {
    let id = e.target.id;
    //console.log(id);
    const open = this.props.clientController.open; 
    open(id);
  }


  render (){
    const client = this.props.client;
    const {index} = {...this.props};
    return (
      <div key={index} id='client-fulllist-item' onMouseEnter={this.hoverClient} onMouseLeave={this.unhoverClient}>
      <div className="clickable-panel"
        id={client.cid} 
        onClick={this.openClient}></div>
        <div>
          <div>
            {client.clientname}{//client.mva && '- NO'+client.mva+'MVA'
            }
          </div>
          <div>
            {client.address + ', ' + client.postnr + ' ' + client.post}
          </div>
        </div>
        </div>  
    );
  }
}

export default ClientItem;
