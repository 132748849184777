import React, { Component } from "react";
import { Row, Col, Form, Button} from 'react-bootstrap';
import { chooseLanguage } from "../Lang/langpack";


class PasswordChangeComponent extends Component {
  constructor () {
	  super();
	  this.state = {
        oldpass: '',
        newpass: '',
        repeatpass: '',


    }
  }

  componentDidMount() {
  }

  saveBasis = (event) => {
    let record = event.target.id,
      value = event.target.value;
      this.setState({
        [record]: value,
      })
  }

  executePassChange = () => {
    const { newpass, oldpass } = this.state;
    const { passwordChange } = this.props;
    const passwordObject = {
      newpass, oldpass
    }
    passwordChange(passwordObject);
  }
  

  render (){
    const {closeRegister} = this.props;
    const {newpass, oldpass, repeatpass} = this.state;
    const {langinterface}= this.props.lang;
    const translation = {
      newPassword: chooseLanguage('newPassword', langinterface),
      repeatNewPassword: chooseLanguage('repeatNewPassword', langinterface),
      oldPassword:  chooseLanguage('oldPassword', langinterface),
    }
    return (
    <Form>
      <Form.Row className='text-right'>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={3} xs={12}><h4>{translation.oldPassword}</h4></Form.Label>
        <Col lg={9} xs={12}>
          <Form.Control id='oldpass' type="password" placeholder={translation.oldPassword} onChange={this.saveBasis} required/>
        </Col>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={3} xs={12}><h4>{translation.newPassword}</h4></Form.Label>
        <Col lg={9} xs={12}>
          <Form.Control id='newpass' type="password" placeholder={translation.newPassword} onChange={this.saveBasis} required/>
        </Col>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={3} xs={12}><h4>{translation.repeatNewPassword}</h4></Form.Label>
        <Col lg={9} xs={12}>
          <Form.Control id='repeatpass' type="password" placeholder={translation.repeatNewPassword} onChange={this.saveBasis} required/>
        </Col>
      </Form.Row>

      {oldpass.length > 0 && newpass === repeatpass && newpass.length > 0 && newpass !== oldpass && <div className="popup-buttons">
            <img src='./ok.svg' 
            id='accept'  
            onClick={this.executePassChange}
            alt='accept'
            style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
            <img src='./abort.svg' 
            id='abort'  
            onClick={this.props.closePopup}
            alt='accept'
            style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
          </div> }
    </Form>
    );
  }
}

export default PasswordChangeComponent;
