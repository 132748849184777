import React, { Component } from "react";
import AddNewProject from "./AddNewProject";
import { chooseLanguage } from "../Lang/langpack";


class AllProjectsIco extends Component {
  constructor () {
	  super();
	  this.state = { 
    }
  }

  openMenu = () => {
    let callback = this.props.openMenu;
    let {clearList} = {...this.props};
    clearList();
    callback('projects');
  }

  render (){
    const type = this.props.type ? this.props.type : false; 
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      allProjects: chooseLanguage('allProjects',  langinterface),
    }
    return (
      <div id='project-ico-set'>
        <div>
            <div onClick={this.openMenu} style={{backgroundImage: 'url(/projects.svg', width: '3rem', height: '3rem', margin: '1rem 1rem 0 1rem', cursor: 'pointer'}}></div>
            <div className='ico-label'>{translation.allProjects}</div>
        </div>
      </div>  
    );
  }
}

export default AllProjectsIco;
