import React, { Component } from "react";
//import { Row, Col, Form} from 'react-bootstrap';


class PopupError extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){

    return (
      <div className="popup-error">
      <div>
        <p>{this.props.content}</p>
      </div>
    </div>
    );
  }
}

export default PopupError;
