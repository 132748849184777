import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';

class MVAComponent extends Component {
  
  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }

  render (){
    let {index, value, record} = {...this.props};
    return (
        <Form.Control id='mva' type="text" placeholder='MVA' value={value} onChange={this.handleChange} maxLength={9} required/>
    );
  }
}

export default MVAComponent;
