import React, { Component } from "react";
import {Form} from 'react-bootstrap';
import { chooseLanguage } from "../Lang/langpack";


class CraftComponent extends Component {

  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }


  render (){
    let {index, value} = this.props;
    const {langinterface}= this.props.lang;
    const translation = {
      crafts: chooseLanguage('crafts', langinterface),
    }
    const craftsValue = chooseLanguage('crafts', 'EN');
    return (        
      <Form.Control style={{display: 'inline'}} 
      id={index} 
      name={'craft'}  
      as="select" 
      onChange={this.handleChange}>
        {
            translation.crafts && translation.crafts.map((craft, index)=>
                <option selected={value === craft && true} value={craftsValue[index]}>{craft}</option>
            )
        }
      </Form.Control>
    );
  }
}

export default CraftComponent;
