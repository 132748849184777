import React, { Component } from "react";


class PopupConfirm extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){
    return (
      <div className="popup-confirm">
      <div>
        <p>{this.props.content}</p>
      </div>
    </div>
    );
  }
}

export default PopupConfirm;
