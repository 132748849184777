import React, { Component } from "react";
//import { Row, Col, Form} from 'react-bootstrap';


class PopupEdit extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){

    return (
        <div className="popup-edit">
          {
          //time
          //string value
          //number with different steps
          }
        </div>
    );
  }
}

export default PopupEdit;
