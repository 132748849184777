import React, { Component } from "react";

class FullDocumentPreviewProject extends Component {
  openProjectWindow = (e) => {
    const {closeFullPreview, projectController} = this.props;
    const pid = e.target.getAttribute('pid');
    projectController.open(pid);
    closeFullPreview();
  }
  
  projectLink = (pid, projectName) => {
  const withoutLink = <div>{projectName}</div>
  const withLink = <div style={{cursor: 'pointer'}} onClick={this.openProjectWindow} pid={pid}>{projectName}</div>
  if (pid)
  {
    return withLink;
  }
  if (!pid)
  {
    return withoutLink;
  }
} 
  
  render (){
    const {pid, projectName} = this.props;
    return this.projectLink(pid, projectName);
  }
}

export default FullDocumentPreviewProject;
