import React, { Component } from "react";
import {Form} from 'react-bootstrap';

class NetPrice extends Component {
  
  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }

  render (){
    let {index, netPrice, defaultNetPrice, placeholder} = {...this.props};
    placeholder = placeholder ? placeholder : 'net price';
    return (
        <Form.Control 
          name='netprice' 
          id={index}
          type="number" 
          placeholder={placeholder} 
          value={netPrice ? netPrice : defaultNetPrice ? defaultNetPrice : '' } 
          onChange={this.handleChange} 
          required
          />
    );
  }
}

export default NetPrice;
