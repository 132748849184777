import React, { Component } from "react";
import {Row, Col, Form} from 'react-bootstrap';
import DescriptionComponent from './../FormComponents/DescriptionComponent';
import DateComponent from "../FormComponents/DateComponent";
import TimeComponent from "../FormComponents/TimeComponent";
import BreakValueComponent from "../FormComponents/BreakValueComponent";
import BreakIsPaidComponent from "../FormComponents/BreakIsPaidComponent";
import DocumentRowOptions from "../FormComponents/DocumentRowOptions";
import ExtraPosition from "../FormComponents/ExtraPosition";
import Km from "../FormComponents/Km";
import Foto from "./Foto";
import { chooseLanguage } from "../Lang/langpack";

class NewDayRow extends Component {

  render (){
    const props = {...this.props};
    const timelist = {...props.timelist};
    const {lang, showKm} = props;
    const {langinterface} = lang;
    const translation = {
      day: chooseLanguage('day', langinterface),
      in: chooseLanguage('in', langinterface),
      out: chooseLanguage('out', langinterface),
      break: chooseLanguage('break', langinterface),
      description: chooseLanguage('description', langinterface),
      extra: chooseLanguage('extra', langinterface),
      paid: chooseLanguage('paid', langinterface),
      options: chooseLanguage('options', langinterface),
    }
    return (
        <Row id='document-fieldset'>
            <Col className='timelist-row text-center' xs='9' lg='9'>
                <h4>{translation.day}</h4>
                <DateComponent 
                    lang={lang}
                    saveDate={props.saveDate} 
                    day={timelist.day} 
                    index={props.index}
                />
            </Col>
            <Col className='timelist-row text-center' xs='3' lg='3'>
                <ExtraPosition
                    value={timelist.extra} 
                    record='extra'
                    saveValue={props.writeToState} 
                    index={props.index} 
                    placeholder={translation.extra}
                />
            </Col>
            <Col className='timelist-row' xs='6' lg='4'>
                <h4>{translation.in}</h4>
                <TimeComponent 
                    time={timelist.in} 
                    record='loggedin' 
                    saveTime={props.writeToState} 
                    index={props.index} />
            </Col>
            <Col className='timelist-row' xs='6' lg='4'>
                <h4>{translation.out}</h4>
                <TimeComponent 
                    time={timelist.out} 
                    record='loggedout' 
                    saveTime={props.writeToState} 
                    index={props.index} />
            </Col>

            <Col className='timelist-row' xs='12' lg='4'>
                <h4>{translation.break}</h4>
                <BreakValueComponent 
                    breakValue = {timelist.breakValue}
                    saveValue={props.writeToState} 
                    index={props.index} />                
                <BreakIsPaidComponent 
                    placeholder={translation.paid}
                    paidbreak = {timelist.paidbreak} 
                    saveValue={props.writeToState} 
                    index={props.index} />
            </Col>

            <Col className='timelist-row' xs='12' lg='9'>
                <h4>{translation.description}</h4>
                <DescriptionComponent 
                    placeholder={translation.description}
                    value={timelist.daydescription} 
                    record='loggeddaydescription'
                    saveValue={props.writeToState} 
                    index={props.index} />
            </Col>
            {showKm && <Col className='timelist-row' xs='12' lg='3'>
                <h4>KM</h4>
                <Km
                    value={timelist.km}
                    record='km'
                    saveValue={props.writeToState} 
                    index={props.index} 
                />
            </Col>}   
            {/*<Col className='text-center' xs='3' lg='3'>
                <h4>FOTO</h4>
            </Col>          
            <Col className='timelist-row' xs='9' lg='5'>
                <Foto
                    value={timelist.files} 
                    record='files'
                    saveValue={props.writeToState} 
                    index={props.index} />
            </Col>*/}

          </Row>
    );
  }
}

export default NewDayRow;
