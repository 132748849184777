const postkode = [
    [
        "0001",
        "OSLO"
    ],
    [
        "0010",
        "OSLO"
    ],
    [
        "0015",
        "OSLO"
    ],
    [
        "0018",
        "OSLO"
    ],
    [
        "0021",
        "OSLO"
    ],
    [
        "0024",
        "OSLO"
    ],
    [
        "0026",
        "OSLO"
    ],
    [
        "0028",
        "OSLO"
    ],
    [
        "0030",
        "OSLO"
    ],
    [
        "0031",
        "OSLO"
    ],
    [
        "0032",
        "OSLO"
    ],
    [
        "0033",
        "OSLO"
    ],
    [
        "0034",
        "OSLO"
    ],
    [
        "0037",
        "OSLO"
    ],
    [
        "0040",
        "OSLO"
    ],
    [
        "0045",
        "OSLO"
    ],
    [
        "0046",
        "OSLO"
    ],
    [
        "0047",
        "OSLO"
    ],
    [
        "0048",
        "OSLO"
    ],
    [
        "0050",
        "OSLO"
    ],
    [
        "0055",
        "OSLO"
    ],
    [
        "0060",
        "OSLO"
    ],
    [
        "0081",
        "OSLO"
    ],
    [
        "0101",
        "OSLO"
    ],
    [
        "0102",
        "OSLO"
    ],
    [
        "0103",
        "OSLO"
    ],
    [
        "0104",
        "OSLO"
    ],
    [
        "0105",
        "OSLO"
    ],
    [
        "0106",
        "OSLO"
    ],
    [
        "0107",
        "OSLO"
    ],
    [
        "0109",
        "OSLO"
    ],
    [
        "0110",
        "OSLO"
    ],
    [
        "0111",
        "OSLO"
    ],
    [
        "0112",
        "OSLO"
    ],
    [
        "0113",
        "OSLO"
    ],
    [
        "0114",
        "OSLO"
    ],
    [
        "0115",
        "OSLO"
    ],
    [
        "0116",
        "OSLO"
    ],
    [
        "0117",
        "OSLO"
    ],
    [
        "0118",
        "OSLO"
    ],
    [
        "0119",
        "OSLO"
    ],
    [
        "0120",
        "OSLO"
    ],
    [
        "0121",
        "OSLO"
    ],
    [
        "0122",
        "OSLO"
    ],
    [
        "0123",
        "OSLO"
    ],
    [
        "0124",
        "OSLO"
    ],
    [
        "0125",
        "OSLO"
    ],
    [
        "0128",
        "OSLO"
    ],
    [
        "0129",
        "OSLO"
    ],
    [
        "0130",
        "OSLO"
    ],
    [
        "0131",
        "OSLO"
    ],
    [
        "0132",
        "OSLO"
    ],
    [
        "0133",
        "OSLO"
    ],
    [
        "0134",
        "OSLO"
    ],
    [
        "0135",
        "OSLO"
    ],
    [
        "0136",
        "OSLO"
    ],
    [
        "0138",
        "OSLO"
    ],
    [
        "0139",
        "OSLO"
    ],
    [
        "0140",
        "OSLO"
    ],
    [
        "0150",
        "OSLO"
    ],
    [
        "0151",
        "OSLO"
    ],
    [
        "0152",
        "OSLO"
    ],
    [
        "0153",
        "OSLO"
    ],
    [
        "0154",
        "OSLO"
    ],
    [
        "0155",
        "OSLO"
    ],
    [
        "0157",
        "OSLO"
    ],
    [
        "0158",
        "OSLO"
    ],
    [
        "0159",
        "OSLO"
    ],
    [
        "0160",
        "OSLO"
    ],
    [
        "0161",
        "OSLO"
    ],
    [
        "0162",
        "OSLO"
    ],
    [
        "0164",
        "OSLO"
    ],
    [
        "0165",
        "OSLO"
    ],
    [
        "0166",
        "OSLO"
    ],
    [
        "0167",
        "OSLO"
    ],
    [
        "0168",
        "OSLO"
    ],
    [
        "0169",
        "OSLO"
    ],
    [
        "0170",
        "OSLO"
    ],
    [
        "0171",
        "OSLO"
    ],
    [
        "0172",
        "OSLO"
    ],
    [
        "0173",
        "OSLO"
    ],
    [
        "0174",
        "OSLO"
    ],
    [
        "0175",
        "OSLO"
    ],
    [
        "0176",
        "OSLO"
    ],
    [
        "0177",
        "OSLO"
    ],
    [
        "0178",
        "OSLO"
    ],
    [
        "0179",
        "OSLO"
    ],
    [
        "0180",
        "OSLO"
    ],
    [
        "0181",
        "OSLO"
    ],
    [
        "0182",
        "OSLO"
    ],
    [
        "0183",
        "OSLO"
    ],
    [
        "0184",
        "OSLO"
    ],
    [
        "0185",
        "OSLO"
    ],
    [
        "0186",
        "OSLO"
    ],
    [
        "0187",
        "OSLO"
    ],
    [
        "0188",
        "OSLO"
    ],
    [
        "0190",
        "OSLO"
    ],
    [
        "0191",
        "OSLO"
    ],
    [
        "0192",
        "OSLO"
    ],
    [
        "0193",
        "OSLO"
    ],
    [
        "0194",
        "OSLO"
    ],
    [
        "0195",
        "OSLO"
    ],
    [
        "0196",
        "OSLO"
    ],
    [
        "0198",
        "OSLO"
    ],
    [
        "0201",
        "OSLO"
    ],
    [
        "0202",
        "OSLO"
    ],
    [
        "0203",
        "OSLO"
    ],
    [
        "0204",
        "OSLO"
    ],
    [
        "0207",
        "OSLO"
    ],
    [
        "0208",
        "OSLO"
    ],
    [
        "0211",
        "OSLO"
    ],
    [
        "0212",
        "OSLO"
    ],
    [
        "0213",
        "OSLO"
    ],
    [
        "0214",
        "OSLO"
    ],
    [
        "0215",
        "OSLO"
    ],
    [
        "0216",
        "OSLO"
    ],
    [
        "0217",
        "OSLO"
    ],
    [
        "0218",
        "OSLO"
    ],
    [
        "0230",
        "OSLO"
    ],
    [
        "0240",
        "OSLO"
    ],
    [
        "0244",
        "OSLO"
    ],
    [
        "0247",
        "OSLO"
    ],
    [
        "0250",
        "OSLO"
    ],
    [
        "0251",
        "OSLO"
    ],
    [
        "0252",
        "OSLO"
    ],
    [
        "0253",
        "OSLO"
    ],
    [
        "0254",
        "OSLO"
    ],
    [
        "0255",
        "OSLO"
    ],
    [
        "0256",
        "OSLO"
    ],
    [
        "0257",
        "OSLO"
    ],
    [
        "0258",
        "OSLO"
    ],
    [
        "0259",
        "OSLO"
    ],
    [
        "0260",
        "OSLO"
    ],
    [
        "0262",
        "OSLO"
    ],
    [
        "0263",
        "OSLO"
    ],
    [
        "0264",
        "OSLO"
    ],
    [
        "0265",
        "OSLO"
    ],
    [
        "0266",
        "OSLO"
    ],
    [
        "0267",
        "OSLO"
    ],
    [
        "0268",
        "OSLO"
    ],
    [
        "0270",
        "OSLO"
    ],
    [
        "0271",
        "OSLO"
    ],
    [
        "0272",
        "OSLO"
    ],
    [
        "0273",
        "OSLO"
    ],
    [
        "0274",
        "OSLO"
    ],
    [
        "0275",
        "OSLO"
    ],
    [
        "0276",
        "OSLO"
    ],
    [
        "0277",
        "OSLO"
    ],
    [
        "0278",
        "OSLO"
    ],
    [
        "0279",
        "OSLO"
    ],
    [
        "0280",
        "OSLO"
    ],
    [
        "0281",
        "OSLO"
    ],
    [
        "0282",
        "OSLO"
    ],
    [
        "0283",
        "OSLO"
    ],
    [
        "0284",
        "OSLO"
    ],
    [
        "0286",
        "OSLO"
    ],
    [
        "0287",
        "OSLO"
    ],
    [
        "0301",
        "OSLO"
    ],
    [
        "0302",
        "OSLO"
    ],
    [
        "0303",
        "OSLO"
    ],
    [
        "0304",
        "OSLO"
    ],
    [
        "0305",
        "OSLO"
    ],
    [
        "0306",
        "OSLO"
    ],
    [
        "0307",
        "OSLO"
    ],
    [
        "0308",
        "OSLO"
    ],
    [
        "0309",
        "OSLO"
    ],
    [
        "0311",
        "OSLO"
    ],
    [
        "0313",
        "OSLO"
    ],
    [
        "0314",
        "OSLO"
    ],
    [
        "0315",
        "OSLO"
    ],
    [
        "0316",
        "OSLO"
    ],
    [
        "0317",
        "OSLO"
    ],
    [
        "0318",
        "OSLO"
    ],
    [
        "0319",
        "OSLO"
    ],
    [
        "0323",
        "OSLO"
    ],
    [
        "0330",
        "OSLO"
    ],
    [
        "0340",
        "OSLO"
    ],
    [
        "0349",
        "OSLO"
    ],
    [
        "0350",
        "OSLO"
    ],
    [
        "0351",
        "OSLO"
    ],
    [
        "0352",
        "OSLO"
    ],
    [
        "0353",
        "OSLO"
    ],
    [
        "0354",
        "OSLO"
    ],
    [
        "0355",
        "OSLO"
    ],
    [
        "0356",
        "OSLO"
    ],
    [
        "0357",
        "OSLO"
    ],
    [
        "0358",
        "OSLO"
    ],
    [
        "0359",
        "OSLO"
    ],
    [
        "0360",
        "OSLO"
    ],
    [
        "0361",
        "OSLO"
    ],
    [
        "0362",
        "OSLO"
    ],
    [
        "0363",
        "OSLO"
    ],
    [
        "0364",
        "OSLO"
    ],
    [
        "0365",
        "OSLO"
    ],
    [
        "0366",
        "OSLO"
    ],
    [
        "0367",
        "OSLO"
    ],
    [
        "0368",
        "OSLO"
    ],
    [
        "0369",
        "OSLO"
    ],
    [
        "0370",
        "OSLO"
    ],
    [
        "0371",
        "OSLO"
    ],
    [
        "0372",
        "OSLO"
    ],
    [
        "0373",
        "OSLO"
    ],
    [
        "0374",
        "OSLO"
    ],
    [
        "0375",
        "OSLO"
    ],
    [
        "0376",
        "OSLO"
    ],
    [
        "0377",
        "OSLO"
    ],
    [
        "0378",
        "OSLO"
    ],
    [
        "0379",
        "OSLO"
    ],
    [
        "0380",
        "OSLO"
    ],
    [
        "0381",
        "OSLO"
    ],
    [
        "0382",
        "OSLO"
    ],
    [
        "0383",
        "OSLO"
    ],
    [
        "0401",
        "OSLO"
    ],
    [
        "0402",
        "OSLO"
    ],
    [
        "0403",
        "OSLO"
    ],
    [
        "0404",
        "OSLO"
    ],
    [
        "0405",
        "OSLO"
    ],
    [
        "0406",
        "OSLO"
    ],
    [
        "0409",
        "OSLO"
    ],
    [
        "0410",
        "OSLO"
    ],
    [
        "0411",
        "OSLO"
    ],
    [
        "0412",
        "OSLO"
    ],
    [
        "0413",
        "OSLO"
    ],
    [
        "0415",
        "OSLO"
    ],
    [
        "0421",
        "OSLO"
    ],
    [
        "0422",
        "OSLO"
    ],
    [
        "0423",
        "OSLO"
    ],
    [
        "0424",
        "OSLO"
    ],
    [
        "0440",
        "OSLO"
    ],
    [
        "0441",
        "OSLO"
    ],
    [
        "0442",
        "OSLO"
    ],
    [
        "0445",
        "OSLO"
    ],
    [
        "0450",
        "OSLO"
    ],
    [
        "0451",
        "OSLO"
    ],
    [
        "0452",
        "OSLO"
    ],
    [
        "0454",
        "OSLO"
    ],
    [
        "0455",
        "OSLO"
    ],
    [
        "0456",
        "OSLO"
    ],
    [
        "0457",
        "OSLO"
    ],
    [
        "0458",
        "OSLO"
    ],
    [
        "0459",
        "OSLO"
    ],
    [
        "0460",
        "OSLO"
    ],
    [
        "0461",
        "OSLO"
    ],
    [
        "0462",
        "OSLO"
    ],
    [
        "0463",
        "OSLO"
    ],
    [
        "0464",
        "OSLO"
    ],
    [
        "0465",
        "OSLO"
    ],
    [
        "0467",
        "OSLO"
    ],
    [
        "0468",
        "OSLO"
    ],
    [
        "0469",
        "OSLO"
    ],
    [
        "0470",
        "OSLO"
    ],
    [
        "0472",
        "OSLO"
    ],
    [
        "0473",
        "OSLO"
    ],
    [
        "0474",
        "OSLO"
    ],
    [
        "0475",
        "OSLO"
    ],
    [
        "0476",
        "OSLO"
    ],
    [
        "0477",
        "OSLO"
    ],
    [
        "0478",
        "OSLO"
    ],
    [
        "0479",
        "OSLO"
    ],
    [
        "0480",
        "OSLO"
    ],
    [
        "0481",
        "OSLO"
    ],
    [
        "0482",
        "OSLO"
    ],
    [
        "0483",
        "OSLO"
    ],
    [
        "0484",
        "OSLO"
    ],
    [
        "0485",
        "OSLO"
    ],
    [
        "0486",
        "OSLO"
    ],
    [
        "0487",
        "OSLO"
    ],
    [
        "0488",
        "OSLO"
    ],
    [
        "0489",
        "OSLO"
    ],
    [
        "0490",
        "OSLO"
    ],
    [
        "0491",
        "OSLO"
    ],
    [
        "0492",
        "OSLO"
    ],
    [
        "0493",
        "OSLO"
    ],
    [
        "0494",
        "OSLO"
    ],
    [
        "0495",
        "OSLO"
    ],
    [
        "0496",
        "OSLO"
    ],
    [
        "0501",
        "OSLO"
    ],
    [
        "0502",
        "OSLO"
    ],
    [
        "0503",
        "OSLO"
    ],
    [
        "0504",
        "OSLO"
    ],
    [
        "0505",
        "OSLO"
    ],
    [
        "0506",
        "OSLO"
    ],
    [
        "0507",
        "OSLO"
    ],
    [
        "0508",
        "OSLO"
    ],
    [
        "0509",
        "OSLO"
    ],
    [
        "0510",
        "OSLO"
    ],
    [
        "0511",
        "OSLO"
    ],
    [
        "0512",
        "OSLO"
    ],
    [
        "0513",
        "OSLO"
    ],
    [
        "0515",
        "OSLO"
    ],
    [
        "0516",
        "OSLO"
    ],
    [
        "0517",
        "OSLO"
    ],
    [
        "0518",
        "OSLO"
    ],
    [
        "0520",
        "OSLO"
    ],
    [
        "0540",
        "OSLO"
    ],
    [
        "0550",
        "OSLO"
    ],
    [
        "0551",
        "OSLO"
    ],
    [
        "0552",
        "OSLO"
    ],
    [
        "0553",
        "OSLO"
    ],
    [
        "0554",
        "OSLO"
    ],
    [
        "0555",
        "OSLO"
    ],
    [
        "0556",
        "OSLO"
    ],
    [
        "0557",
        "OSLO"
    ],
    [
        "0558",
        "OSLO"
    ],
    [
        "0559",
        "OSLO"
    ],
    [
        "0560",
        "OSLO"
    ],
    [
        "0561",
        "OSLO"
    ],
    [
        "0562",
        "OSLO"
    ],
    [
        "0563",
        "OSLO"
    ],
    [
        "0564",
        "OSLO"
    ],
    [
        "0565",
        "OSLO"
    ],
    [
        "0566",
        "OSLO"
    ],
    [
        "0567",
        "OSLO"
    ],
    [
        "0568",
        "OSLO"
    ],
    [
        "0569",
        "OSLO"
    ],
    [
        "0570",
        "OSLO"
    ],
    [
        "0571",
        "OSLO"
    ],
    [
        "0572",
        "OSLO"
    ],
    [
        "0573",
        "OSLO"
    ],
    [
        "0574",
        "OSLO"
    ],
    [
        "0575",
        "OSLO"
    ],
    [
        "0576",
        "OSLO"
    ],
    [
        "0577",
        "OSLO"
    ],
    [
        "0578",
        "OSLO"
    ],
    [
        "0579",
        "OSLO"
    ],
    [
        "0580",
        "OSLO"
    ],
    [
        "0581",
        "OSLO"
    ],
    [
        "0582",
        "OSLO"
    ],
    [
        "0583",
        "OSLO"
    ],
    [
        "0584",
        "OSLO"
    ],
    [
        "0585",
        "OSLO"
    ],
    [
        "0586",
        "OSLO"
    ],
    [
        "0587",
        "OSLO"
    ],
    [
        "0588",
        "OSLO"
    ],
    [
        "0589",
        "OSLO"
    ],
    [
        "0590",
        "OSLO"
    ],
    [
        "0591",
        "OSLO"
    ],
    [
        "0592",
        "OSLO"
    ],
    [
        "0593",
        "OSLO"
    ],
    [
        "0594",
        "OSLO"
    ],
    [
        "0595",
        "OSLO"
    ],
    [
        "0596",
        "OSLO"
    ],
    [
        "0597",
        "OSLO"
    ],
    [
        "0598",
        "OSLO"
    ],
    [
        "0601",
        "OSLO"
    ],
    [
        "0602",
        "OSLO"
    ],
    [
        "0603",
        "OSLO"
    ],
    [
        "0604",
        "OSLO"
    ],
    [
        "0605",
        "OSLO"
    ],
    [
        "0606",
        "OSLO"
    ],
    [
        "0607",
        "OSLO"
    ],
    [
        "0608",
        "OSLO"
    ],
    [
        "0609",
        "OSLO"
    ],
    [
        "0611",
        "OSLO"
    ],
    [
        "0612",
        "OSLO"
    ],
    [
        "0613",
        "OSLO"
    ],
    [
        "0614",
        "OSLO"
    ],
    [
        "0615",
        "OSLO"
    ],
    [
        "0616",
        "OSLO"
    ],
    [
        "0617",
        "OSLO"
    ],
    [
        "0618",
        "OSLO"
    ],
    [
        "0619",
        "OSLO"
    ],
    [
        "0620",
        "OSLO"
    ],
    [
        "0621",
        "OSLO"
    ],
    [
        "0622",
        "OSLO"
    ],
    [
        "0623",
        "OSLO"
    ],
    [
        "0624",
        "OSLO"
    ],
    [
        "0626",
        "OSLO"
    ],
    [
        "0650",
        "OSLO"
    ],
    [
        "0651",
        "OSLO"
    ],
    [
        "0652",
        "OSLO"
    ],
    [
        "0653",
        "OSLO"
    ],
    [
        "0654",
        "OSLO"
    ],
    [
        "0655",
        "OSLO"
    ],
    [
        "0656",
        "OSLO"
    ],
    [
        "0657",
        "OSLO"
    ],
    [
        "0658",
        "OSLO"
    ],
    [
        "0659",
        "OSLO"
    ],
    [
        "0660",
        "OSLO"
    ],
    [
        "0661",
        "OSLO"
    ],
    [
        "0662",
        "OSLO"
    ],
    [
        "0663",
        "OSLO"
    ],
    [
        "0664",
        "OSLO"
    ],
    [
        "0665",
        "OSLO"
    ],
    [
        "0666",
        "OSLO"
    ],
    [
        "0667",
        "OSLO"
    ],
    [
        "0668",
        "OSLO"
    ],
    [
        "0669",
        "OSLO"
    ],
    [
        "0670",
        "OSLO"
    ],
    [
        "0671",
        "OSLO"
    ],
    [
        "0672",
        "OSLO"
    ],
    [
        "0673",
        "OSLO"
    ],
    [
        "0674",
        "OSLO"
    ],
    [
        "0675",
        "OSLO"
    ],
    [
        "0676",
        "OSLO"
    ],
    [
        "0677",
        "OSLO"
    ],
    [
        "0678",
        "OSLO"
    ],
    [
        "0679",
        "OSLO"
    ],
    [
        "0680",
        "OSLO"
    ],
    [
        "0681",
        "OSLO"
    ],
    [
        "0682",
        "OSLO"
    ],
    [
        "0683",
        "OSLO"
    ],
    [
        "0684",
        "OSLO"
    ],
    [
        "0685",
        "OSLO"
    ],
    [
        "0686",
        "OSLO"
    ],
    [
        "0687",
        "OSLO"
    ],
    [
        "0688",
        "OSLO"
    ],
    [
        "0689",
        "OSLO"
    ],
    [
        "0690",
        "OSLO"
    ],
    [
        "0691",
        "OSLO"
    ],
    [
        "0692",
        "OSLO"
    ],
    [
        "0693",
        "OSLO"
    ],
    [
        "0694",
        "OSLO"
    ],
    [
        "0701",
        "OSLO"
    ],
    [
        "0702",
        "OSLO"
    ],
    [
        "0705",
        "OSLO"
    ],
    [
        "0710",
        "OSLO"
    ],
    [
        "0712",
        "OSLO"
    ],
    [
        "0750",
        "OSLO"
    ],
    [
        "0751",
        "OSLO"
    ],
    [
        "0752",
        "OSLO"
    ],
    [
        "0753",
        "OSLO"
    ],
    [
        "0754",
        "OSLO"
    ],
    [
        "0755",
        "OSLO"
    ],
    [
        "0756",
        "OSLO"
    ],
    [
        "0757",
        "OSLO"
    ],
    [
        "0758",
        "OSLO"
    ],
    [
        "0760",
        "OSLO"
    ],
    [
        "0763",
        "OSLO"
    ],
    [
        "0764",
        "OSLO"
    ],
    [
        "0765",
        "OSLO"
    ],
    [
        "0766",
        "OSLO"
    ],
    [
        "0767",
        "OSLO"
    ],
    [
        "0768",
        "OSLO"
    ],
    [
        "0770",
        "OSLO"
    ],
    [
        "0771",
        "OSLO"
    ],
    [
        "0772",
        "OSLO"
    ],
    [
        "0773",
        "OSLO"
    ],
    [
        "0774",
        "OSLO"
    ],
    [
        "0775",
        "OSLO"
    ],
    [
        "0776",
        "OSLO"
    ],
    [
        "0777",
        "OSLO"
    ],
    [
        "0778",
        "OSLO"
    ],
    [
        "0779",
        "OSLO"
    ],
    [
        "0781",
        "OSLO"
    ],
    [
        "0782",
        "OSLO"
    ],
    [
        "0783",
        "OSLO"
    ],
    [
        "0784",
        "OSLO"
    ],
    [
        "0785",
        "OSLO"
    ],
    [
        "0786",
        "OSLO"
    ],
    [
        "0787",
        "OSLO"
    ],
    [
        "0788",
        "OSLO"
    ],
    [
        "0789",
        "OSLO"
    ],
    [
        "0790",
        "OSLO"
    ],
    [
        "0791",
        "OSLO"
    ],
    [
        "0801",
        "OSLO"
    ],
    [
        "0805",
        "OSLO"
    ],
    [
        "0806",
        "OSLO"
    ],
    [
        "0807",
        "OSLO"
    ],
    [
        "0840",
        "OSLO"
    ],
    [
        "0850",
        "OSLO"
    ],
    [
        "0851",
        "OSLO"
    ],
    [
        "0852",
        "OSLO"
    ],
    [
        "0853",
        "OSLO"
    ],
    [
        "0854",
        "OSLO"
    ],
    [
        "0855",
        "OSLO"
    ],
    [
        "0856",
        "OSLO"
    ],
    [
        "0857",
        "OSLO"
    ],
    [
        "0858",
        "OSLO"
    ],
    [
        "0860",
        "OSLO"
    ],
    [
        "0861",
        "OSLO"
    ],
    [
        "0862",
        "OSLO"
    ],
    [
        "0863",
        "OSLO"
    ],
    [
        "0864",
        "OSLO"
    ],
    [
        "0870",
        "OSLO"
    ],
    [
        "0871",
        "OSLO"
    ],
    [
        "0872",
        "OSLO"
    ],
    [
        "0873",
        "OSLO"
    ],
    [
        "0874",
        "OSLO"
    ],
    [
        "0875",
        "OSLO"
    ],
    [
        "0876",
        "OSLO"
    ],
    [
        "0877",
        "OSLO"
    ],
    [
        "0880",
        "OSLO"
    ],
    [
        "0881",
        "OSLO"
    ],
    [
        "0882",
        "OSLO"
    ],
    [
        "0883",
        "OSLO"
    ],
    [
        "0884",
        "OSLO"
    ],
    [
        "0890",
        "OSLO"
    ],
    [
        "0891",
        "OSLO"
    ],
    [
        "0901",
        "OSLO"
    ],
    [
        "0902",
        "OSLO"
    ],
    [
        "0903",
        "OSLO"
    ],
    [
        "0904",
        "OSLO"
    ],
    [
        "0905",
        "OSLO"
    ],
    [
        "0907",
        "OSLO"
    ],
    [
        "0908",
        "OSLO"
    ],
    [
        "0913",
        "OSLO"
    ],
    [
        "0914",
        "OSLO"
    ],
    [
        "0915",
        "OSLO"
    ],
    [
        "0950",
        "OSLO"
    ],
    [
        "0951",
        "OSLO"
    ],
    [
        "0952",
        "OSLO"
    ],
    [
        "0953",
        "OSLO"
    ],
    [
        "0954",
        "OSLO"
    ],
    [
        "0955",
        "OSLO"
    ],
    [
        "0956",
        "OSLO"
    ],
    [
        "0957",
        "OSLO"
    ],
    [
        "0958",
        "OSLO"
    ],
    [
        "0959",
        "OSLO"
    ],
    [
        "0960",
        "OSLO"
    ],
    [
        "0962",
        "OSLO"
    ],
    [
        "0963",
        "OSLO"
    ],
    [
        "0964",
        "OSLO"
    ],
    [
        "0968",
        "OSLO"
    ],
    [
        "0969",
        "OSLO"
    ],
    [
        "0970",
        "OSLO"
    ],
    [
        "0971",
        "OSLO"
    ],
    [
        "0972",
        "OSLO"
    ],
    [
        "0973",
        "OSLO"
    ],
    [
        "0975",
        "OSLO"
    ],
    [
        "0976",
        "OSLO"
    ],
    [
        "0977",
        "OSLO"
    ],
    [
        "0978",
        "OSLO"
    ],
    [
        "0979",
        "OSLO"
    ],
    [
        "0980",
        "OSLO"
    ],
    [
        "0981",
        "OSLO"
    ],
    [
        "0982",
        "OSLO"
    ],
    [
        "0983",
        "OSLO"
    ],
    [
        "0984",
        "OSLO"
    ],
    [
        "0985",
        "OSLO"
    ],
    [
        "0986",
        "OSLO"
    ],
    [
        "0987",
        "OSLO"
    ],
    [
        "0988",
        "OSLO"
    ],
    [
        "1001",
        "OSLO"
    ],
    [
        "1003",
        "OSLO"
    ],
    [
        "1005",
        "OSLO"
    ],
    [
        "1006",
        "OSLO"
    ],
    [
        "1007",
        "OSLO"
    ],
    [
        "1008",
        "OSLO"
    ],
    [
        "1009",
        "OSLO"
    ],
    [
        "1011",
        "OSLO"
    ],
    [
        "1051",
        "OSLO"
    ],
    [
        "1052",
        "OSLO"
    ],
    [
        "1053",
        "OSLO"
    ],
    [
        "1054",
        "OSLO"
    ],
    [
        "1055",
        "OSLO"
    ],
    [
        "1056",
        "OSLO"
    ],
    [
        "1061",
        "OSLO"
    ],
    [
        "1062",
        "OSLO"
    ],
    [
        "1063",
        "OSLO"
    ],
    [
        "1064",
        "OSLO"
    ],
    [
        "1065",
        "OSLO"
    ],
    [
        "1067",
        "OSLO"
    ],
    [
        "1068",
        "OSLO"
    ],
    [
        "1069",
        "OSLO"
    ],
    [
        "1071",
        "OSLO"
    ],
    [
        "1081",
        "OSLO"
    ],
    [
        "1083",
        "OSLO"
    ],
    [
        "1084",
        "OSLO"
    ],
    [
        "1086",
        "OSLO"
    ],
    [
        "1087",
        "OSLO"
    ],
    [
        "1088",
        "OSLO"
    ],
    [
        "1089",
        "OSLO"
    ],
    [
        "1101",
        "OSLO"
    ],
    [
        "1102",
        "OSLO"
    ],
    [
        "1108",
        "OSLO"
    ],
    [
        "1109",
        "OSLO"
    ],
    [
        "1112",
        "OSLO"
    ],
    [
        "1150",
        "OSLO"
    ],
    [
        "1151",
        "OSLO"
    ],
    [
        "1152",
        "OSLO"
    ],
    [
        "1153",
        "OSLO"
    ],
    [
        "1154",
        "OSLO"
    ],
    [
        "1155",
        "OSLO"
    ],
    [
        "1156",
        "OSLO"
    ],
    [
        "1157",
        "OSLO"
    ],
    [
        "1158",
        "OSLO"
    ],
    [
        "1160",
        "OSLO"
    ],
    [
        "1161",
        "OSLO"
    ],
    [
        "1162",
        "OSLO"
    ],
    [
        "1163",
        "OSLO"
    ],
    [
        "1164",
        "OSLO"
    ],
    [
        "1165",
        "OSLO"
    ],
    [
        "1166",
        "OSLO"
    ],
    [
        "1167",
        "OSLO"
    ],
    [
        "1168",
        "OSLO"
    ],
    [
        "1169",
        "OSLO"
    ],
    [
        "1170",
        "OSLO"
    ],
    [
        "1172",
        "OSLO"
    ],
    [
        "1176",
        "OSLO"
    ],
    [
        "1177",
        "OSLO"
    ],
    [
        "1178",
        "OSLO"
    ],
    [
        "1179",
        "OSLO"
    ],
    [
        "1181",
        "OSLO"
    ],
    [
        "1182",
        "OSLO"
    ],
    [
        "1184",
        "OSLO"
    ],
    [
        "1185",
        "OSLO"
    ],
    [
        "1187",
        "OSLO"
    ],
    [
        "1188",
        "OSLO"
    ],
    [
        "1189",
        "OSLO"
    ],
    [
        "1201",
        "OSLO"
    ],
    [
        "1203",
        "OSLO"
    ],
    [
        "1204",
        "OSLO"
    ],
    [
        "1205",
        "OSLO"
    ],
    [
        "1207",
        "OSLO"
    ],
    [
        "1214",
        "OSLO"
    ],
    [
        "1215",
        "OSLO"
    ],
    [
        "1250",
        "OSLO"
    ],
    [
        "1251",
        "OSLO"
    ],
    [
        "1252",
        "OSLO"
    ],
    [
        "1253",
        "OSLO"
    ],
    [
        "1254",
        "OSLO"
    ],
    [
        "1255",
        "OSLO"
    ],
    [
        "1256",
        "OSLO"
    ],
    [
        "1257",
        "OSLO"
    ],
    [
        "1258",
        "OSLO"
    ],
    [
        "1259",
        "OSLO"
    ],
    [
        "1262",
        "OSLO"
    ],
    [
        "1263",
        "OSLO"
    ],
    [
        "1266",
        "OSLO"
    ],
    [
        "1270",
        "OSLO"
    ],
    [
        "1271",
        "OSLO"
    ],
    [
        "1272",
        "OSLO"
    ],
    [
        "1273",
        "OSLO"
    ],
    [
        "1274",
        "OSLO"
    ],
    [
        "1275",
        "OSLO"
    ],
    [
        "1278",
        "OSLO"
    ],
    [
        "1279",
        "OSLO"
    ],
    [
        "1281",
        "OSLO"
    ],
    [
        "1283",
        "OSLO"
    ],
    [
        "1284",
        "OSLO"
    ],
    [
        "1285",
        "OSLO"
    ],
    [
        "1286",
        "OSLO"
    ],
    [
        "1290",
        "OSLO"
    ],
    [
        "1291",
        "OSLO"
    ],
    [
        "1294",
        "OSLO"
    ],
    [
        "1295",
        "OSLO"
    ],
    [
        "1300",
        "SANDVIKA"
    ],
    [
        "1301",
        "SANDVIKA"
    ],
    [
        "1302",
        "SANDVIKA"
    ],
    [
        "1303",
        "SANDVIKA"
    ],
    [
        "1304",
        "SANDVIKA"
    ],
    [
        "1305",
        "HASLUM"
    ],
    [
        "1306",
        "SANDVIKA"
    ],
    [
        "1307",
        "FORNEBU"
    ],
    [
        "1308",
        "JAR"
    ],
    [
        "1309",
        "RUD"
    ],
    [
        "1311",
        "HØVIKODDEN"
    ],
    [
        "1312",
        "SLEPENDEN"
    ],
    [
        "1313",
        "VØYENENGA"
    ],
    [
        "1314",
        "VØYENENGA"
    ],
    [
        "1316",
        "EIKSMARKA"
    ],
    [
        "1317",
        "BÆRUMS VERK"
    ],
    [
        "1318",
        "BEKKESTUA"
    ],
    [
        "1319",
        "BEKKESTUA"
    ],
    [
        "1321",
        "STABEKK"
    ],
    [
        "1322",
        "HØVIK"
    ],
    [
        "1323",
        "HØVIK"
    ],
    [
        "1324",
        "LYSAKER"
    ],
    [
        "1325",
        "LYSAKER"
    ],
    [
        "1326",
        "LYSAKER"
    ],
    [
        "1327",
        "LYSAKER"
    ],
    [
        "1328",
        "HØVIK"
    ],
    [
        "1329",
        "LOMMEDALEN"
    ],
    [
        "1330",
        "FORNEBU"
    ],
    [
        "1331",
        "FORNEBU"
    ],
    [
        "1332",
        "ØSTERÅS"
    ],
    [
        "1333",
        "KOLSÅS"
    ],
    [
        "1334",
        "RYKKINN"
    ],
    [
        "1335",
        "SNARØYA"
    ],
    [
        "1336",
        "SANDVIKA"
    ],
    [
        "1337",
        "SANDVIKA"
    ],
    [
        "1338",
        "SANDVIKA"
    ],
    [
        "1339",
        "VØYENENGA"
    ],
    [
        "1340",
        "SKUI"
    ],
    [
        "1341",
        "SLEPENDEN"
    ],
    [
        "1342",
        "GJETTUM"
    ],
    [
        "1344",
        "HASLUM"
    ],
    [
        "1346",
        "GJETTUM"
    ],
    [
        "1348",
        "RYKKINN"
    ],
    [
        "1349",
        "RYKKINN"
    ],
    [
        "1350",
        "LOMMEDALEN"
    ],
    [
        "1351",
        "RUD"
    ],
    [
        "1352",
        "KOLSÅS"
    ],
    [
        "1353",
        "BÆRUMS VERK"
    ],
    [
        "1354",
        "BÆRUMS VERK"
    ],
    [
        "1356",
        "BEKKESTUA"
    ],
    [
        "1357",
        "BEKKESTUA"
    ],
    [
        "1358",
        "JAR"
    ],
    [
        "1359",
        "EIKSMARKA"
    ],
    [
        "1360",
        "FORNEBU"
    ],
    [
        "1361",
        "ØSTERÅS"
    ],
    [
        "1362",
        "HOSLE"
    ],
    [
        "1363",
        "HØVIK"
    ],
    [
        "1364",
        "FORNEBU"
    ],
    [
        "1365",
        "BLOMMENHOLM"
    ],
    [
        "1366",
        "LYSAKER"
    ],
    [
        "1367",
        "SNARØYA"
    ],
    [
        "1368",
        "STABEKK"
    ],
    [
        "1369",
        "STABEKK"
    ],
    [
        "1371",
        "ASKER"
    ],
    [
        "1372",
        "ASKER"
    ],
    [
        "1373",
        "ASKER"
    ],
    [
        "1375",
        "BILLINGSTAD"
    ],
    [
        "1376",
        "BILLINGSTAD"
    ],
    [
        "1377",
        "BILLINGSTAD"
    ],
    [
        "1378",
        "NESBRU"
    ],
    [
        "1379",
        "NESBRU"
    ],
    [
        "1380",
        "HEGGEDAL"
    ],
    [
        "1381",
        "VETTRE"
    ],
    [
        "1383",
        "ASKER"
    ],
    [
        "1384",
        "ASKER"
    ],
    [
        "1385",
        "ASKER"
    ],
    [
        "1386",
        "ASKER"
    ],
    [
        "1387",
        "ASKER"
    ],
    [
        "1388",
        "BORGEN"
    ],
    [
        "1389",
        "HEGGEDAL"
    ],
    [
        "1390",
        "VOLLEN"
    ],
    [
        "1391",
        "VOLLEN"
    ],
    [
        "1392",
        "VETTRE"
    ],
    [
        "1393",
        "VOLLEN"
    ],
    [
        "1394",
        "NESBRU"
    ],
    [
        "1395",
        "HVALSTAD"
    ],
    [
        "1396",
        "BILLINGSTAD"
    ],
    [
        "1397",
        "NESØYA"
    ],
    [
        "1399",
        "ASKER"
    ],
    [
        "1400",
        "SKI"
    ],
    [
        "1401",
        "SKI"
    ],
    [
        "1402",
        "SKI"
    ],
    [
        "1403",
        "LANGHUS"
    ],
    [
        "1404",
        "SIGGERUD"
    ],
    [
        "1405",
        "LANGHUS"
    ],
    [
        "1406",
        "SKI"
    ],
    [
        "1407",
        "VINTERBRO"
    ],
    [
        "1408",
        "KRÅKSTAD"
    ],
    [
        "1409",
        "SKOTBU"
    ],
    [
        "1410",
        "KOLBOTN"
    ],
    [
        "1411",
        "KOLBOTN"
    ],
    [
        "1412",
        "SOFIEMYR"
    ],
    [
        "1413",
        "TÅRNÅSEN"
    ],
    [
        "1414",
        "TROLLÅSEN"
    ],
    [
        "1415",
        "OPPEGÅRD"
    ],
    [
        "1416",
        "OPPEGÅRD"
    ],
    [
        "1417",
        "SOFIEMYR"
    ],
    [
        "1418",
        "KOLBOTN"
    ],
    [
        "1419",
        "OPPEGÅRD"
    ],
    [
        "1420",
        "SVARTSKOG"
    ],
    [
        "1421",
        "TROLLÅSEN"
    ],
    [
        "1422",
        "SIGGERUD"
    ],
    [
        "1423",
        "SKI"
    ],
    [
        "1424",
        "SKI"
    ],
    [
        "1425",
        "SKI"
    ],
    [
        "1429",
        "VINTERBRO"
    ],
    [
        "1430",
        "ÅS"
    ],
    [
        "1431",
        "ÅS"
    ],
    [
        "1432",
        "ÅS"
    ],
    [
        "1433",
        "ÅS"
    ],
    [
        "1434",
        "ÅS"
    ],
    [
        "1435",
        "ÅS"
    ],
    [
        "1440",
        "DRØBAK"
    ],
    [
        "1441",
        "DRØBAK"
    ],
    [
        "1442",
        "DRØBAK"
    ],
    [
        "1443",
        "DRØBAK"
    ],
    [
        "1444",
        "DRØBAK"
    ],
    [
        "1445",
        "DRØBAK"
    ],
    [
        "1446",
        "DRØBAK"
    ],
    [
        "1447",
        "DRØBAK"
    ],
    [
        "1448",
        "DRØBAK"
    ],
    [
        "1449",
        "DRØBAK"
    ],
    [
        "1450",
        "NESODDTANGEN"
    ],
    [
        "1451",
        "NESODDTANGEN"
    ],
    [
        "1452",
        "NESODDTANGEN"
    ],
    [
        "1453",
        "BJØRNEMYR"
    ],
    [
        "1454",
        "FAGERSTRAND"
    ],
    [
        "1455",
        "NORDRE FROGN"
    ],
    [
        "1456",
        "NESODDTANGEN"
    ],
    [
        "1457",
        "FAGERSTRAND"
    ],
    [
        "1458",
        "FJELLSTRAND"
    ],
    [
        "1459",
        "NESODDEN"
    ],
    [
        "1461",
        "LØRENSKOG"
    ],
    [
        "1462",
        "FJELLHAMAR"
    ],
    [
        "1463",
        "FJELLHAMAR"
    ],
    [
        "1464",
        "FJELLHAMAR"
    ],
    [
        "1465",
        "STRØMMEN"
    ],
    [
        "1466",
        "STRØMMEN"
    ],
    [
        "1467",
        "STRØMMEN"
    ],
    [
        "1468",
        "FINSTADJORDET"
    ],
    [
        "1469",
        "RASTA"
    ],
    [
        "1470",
        "LØRENSKOG"
    ],
    [
        "1471",
        "LØRENSKOG"
    ],
    [
        "1472",
        "FJELLHAMAR"
    ],
    [
        "1473",
        "LØRENSKOG"
    ],
    [
        "1474",
        "LØRENSKOG"
    ],
    [
        "1475",
        "FINSTADJORDET"
    ],
    [
        "1476",
        "RASTA"
    ],
    [
        "1477",
        "FJELLHAMAR"
    ],
    [
        "1478",
        "LØRENSKOG"
    ],
    [
        "1479",
        "KURLAND"
    ],
    [
        "1480",
        "SLATTUM"
    ],
    [
        "1481",
        "HAGAN"
    ],
    [
        "1482",
        "NITTEDAL"
    ],
    [
        "1483",
        "HAGAN"
    ],
    [
        "1484",
        "HAKADAL"
    ],
    [
        "1485",
        "HAKADAL"
    ],
    [
        "1486",
        "NITTEDAL"
    ],
    [
        "1487",
        "HAKADAL"
    ],
    [
        "1488",
        "HAKADAL"
    ],
    [
        "1501",
        "MOSS"
    ],
    [
        "1502",
        "MOSS"
    ],
    [
        "1503",
        "MOSS"
    ],
    [
        "1504",
        "MOSS"
    ],
    [
        "1506",
        "MOSS"
    ],
    [
        "1508",
        "MOSS"
    ],
    [
        "1509",
        "MOSS"
    ],
    [
        "1510",
        "MOSS"
    ],
    [
        "1511",
        "MOSS"
    ],
    [
        "1512",
        "MOSS"
    ],
    [
        "1513",
        "MOSS"
    ],
    [
        "1514",
        "MOSS"
    ],
    [
        "1515",
        "MOSS"
    ],
    [
        "1516",
        "MOSS"
    ],
    [
        "1517",
        "MOSS"
    ],
    [
        "1518",
        "MOSS"
    ],
    [
        "1519",
        "MOSS"
    ],
    [
        "1520",
        "MOSS"
    ],
    [
        "1521",
        "MOSS"
    ],
    [
        "1522",
        "MOSS"
    ],
    [
        "1523",
        "MOSS"
    ],
    [
        "1524",
        "MOSS"
    ],
    [
        "1525",
        "MOSS"
    ],
    [
        "1526",
        "MOSS"
    ],
    [
        "1528",
        "MOSS"
    ],
    [
        "1529",
        "MOSS"
    ],
    [
        "1530",
        "MOSS"
    ],
    [
        "1531",
        "MOSS"
    ],
    [
        "1532",
        "MOSS"
    ],
    [
        "1533",
        "MOSS"
    ],
    [
        "1534",
        "MOSS"
    ],
    [
        "1535",
        "MOSS"
    ],
    [
        "1536",
        "MOSS"
    ],
    [
        "1537",
        "MOSS"
    ],
    [
        "1538",
        "MOSS"
    ],
    [
        "1539",
        "MOSS"
    ],
    [
        "1540",
        "VESTBY"
    ],
    [
        "1541",
        "VESTBY"
    ],
    [
        "1542",
        "VESTBY"
    ],
    [
        "1543",
        "VESTBY"
    ],
    [
        "1544",
        "VESTBY"
    ],
    [
        "1545",
        "HVITSTEN"
    ],
    [
        "1550",
        "HØLEN"
    ],
    [
        "1555",
        "SON"
    ],
    [
        "1556",
        "SON"
    ],
    [
        "1560",
        "LARKOLLEN"
    ],
    [
        "1561",
        "LARKOLLEN"
    ],
    [
        "1570",
        "DILLING"
    ],
    [
        "1580",
        "RYGGE"
    ],
    [
        "1581",
        "RYGGE"
    ],
    [
        "1590",
        "RYGGE"
    ],
    [
        "1591",
        "SPERREBOTN"
    ],
    [
        "1592",
        "VÅLER I ØSTFOLD"
    ],
    [
        "1593",
        "SVINNDAL"
    ],
    [
        "1594",
        "VÅLER I ØSTFOLD"
    ],
    [
        "1596",
        "MOSS"
    ],
    [
        "1597",
        "MOSS"
    ],
    [
        "1598",
        "MOSS"
    ],
    [
        "1599",
        "MOSS"
    ],
    [
        "1601",
        "FREDRIKSTAD"
    ],
    [
        "1602",
        "FREDRIKSTAD"
    ],
    [
        "1604",
        "FREDRIKSTAD"
    ],
    [
        "1605",
        "FREDRIKSTAD"
    ],
    [
        "1606",
        "FREDRIKSTAD"
    ],
    [
        "1607",
        "FREDRIKSTAD"
    ],
    [
        "1608",
        "FREDRIKSTAD"
    ],
    [
        "1609",
        "FREDRIKSTAD"
    ],
    [
        "1610",
        "FREDRIKSTAD"
    ],
    [
        "1612",
        "FREDRIKSTAD"
    ],
    [
        "1613",
        "FREDRIKSTAD"
    ],
    [
        "1614",
        "FREDRIKSTAD"
    ],
    [
        "1615",
        "FREDRIKSTAD"
    ],
    [
        "1616",
        "FREDRIKSTAD"
    ],
    [
        "1617",
        "FREDRIKSTAD"
    ],
    [
        "1618",
        "FREDRIKSTAD"
    ],
    [
        "1619",
        "FREDRIKSTAD"
    ],
    [
        "1620",
        "GRESSVIK"
    ],
    [
        "1621",
        "GRESSVIK"
    ],
    [
        "1622",
        "GRESSVIK"
    ],
    [
        "1623",
        "GRESSVIK"
    ],
    [
        "1624",
        "GRESSVIK"
    ],
    [
        "1625",
        "MANSTAD"
    ],
    [
        "1626",
        "MANSTAD"
    ],
    [
        "1628",
        "ENGELSVIKEN"
    ],
    [
        "1629",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1630",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1632",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1633",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1634",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1636",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1637",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1638",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1639",
        "GAMLE FREDRIKSTAD"
    ],
    [
        "1640",
        "RÅDE"
    ],
    [
        "1641",
        "RÅDE"
    ],
    [
        "1642",
        "SALTNES"
    ],
    [
        "1643",
        "RÅDE"
    ],
    [
        "1650",
        "SELLEBAKK"
    ],
    [
        "1651",
        "SELLEBAKK"
    ],
    [
        "1653",
        "SELLEBAKK"
    ],
    [
        "1654",
        "SELLEBAKK"
    ],
    [
        "1655",
        "SELLEBAKK"
    ],
    [
        "1657",
        "TORP"
    ],
    [
        "1658",
        "TORP"
    ],
    [
        "1659",
        "TORP"
    ],
    [
        "1661",
        "ROLVSØY"
    ],
    [
        "1662",
        "ROLVSØY"
    ],
    [
        "1663",
        "ROLVSØY"
    ],
    [
        "1664",
        "ROLVSØY"
    ],
    [
        "1665",
        "ROLVSØY"
    ],
    [
        "1666",
        "ROLVSØY"
    ],
    [
        "1667",
        "ROLVSØY"
    ],
    [
        "1670",
        "KRÅKERØY"
    ],
    [
        "1671",
        "KRÅKERØY"
    ],
    [
        "1672",
        "KRÅKERØY"
    ],
    [
        "1673",
        "KRÅKERØY"
    ],
    [
        "1675",
        "KRÅKERØY"
    ],
    [
        "1676",
        "KRÅKERØY"
    ],
    [
        "1678",
        "KRÅKERØY"
    ],
    [
        "1679",
        "KRÅKERØY"
    ],
    [
        "1680",
        "SKJÆRHALDEN"
    ],
    [
        "1682",
        "SKJÆRHALDEN"
    ],
    [
        "1683",
        "VESTERØY"
    ],
    [
        "1684",
        "VESTERØY"
    ],
    [
        "1690",
        "HERFØL"
    ],
    [
        "1692",
        "NEDGÅRDEN"
    ],
    [
        "1701",
        "SARPSBORG"
    ],
    [
        "1702",
        "SARPSBORG"
    ],
    [
        "1703",
        "SARPSBORG"
    ],
    [
        "1704",
        "SARPSBORG"
    ],
    [
        "1705",
        "SARPSBORG"
    ],
    [
        "1706",
        "SARPSBORG"
    ],
    [
        "1707",
        "SARPSBORG"
    ],
    [
        "1708",
        "SARPSBORG"
    ],
    [
        "1709",
        "SARPSBORG"
    ],
    [
        "1710",
        "SARPSBORG"
    ],
    [
        "1711",
        "SARPSBORG"
    ],
    [
        "1712",
        "GRÅLUM"
    ],
    [
        "1713",
        "GRÅLUM"
    ],
    [
        "1714",
        "GRÅLUM"
    ],
    [
        "1715",
        "YVEN"
    ],
    [
        "1718",
        "GREÅKER"
    ],
    [
        "1719",
        "GREÅKER"
    ],
    [
        "1720",
        "GREÅKER"
    ],
    [
        "1721",
        "SARPSBORG"
    ],
    [
        "1722",
        "SARPSBORG"
    ],
    [
        "1723",
        "SARPSBORG"
    ],
    [
        "1724",
        "SARPSBORG"
    ],
    [
        "1725",
        "SARPSBORG"
    ],
    [
        "1726",
        "SARPSBORG"
    ],
    [
        "1727",
        "SARPSBORG"
    ],
    [
        "1730",
        "ISE"
    ],
    [
        "1733",
        "HAFSLUNDSØY"
    ],
    [
        "1734",
        "HAFSLUNDSØY"
    ],
    [
        "1735",
        "VARTEIG"
    ],
    [
        "1738",
        "BORGENHAUGEN"
    ],
    [
        "1739",
        "BORGENHAUGEN"
    ],
    [
        "1740",
        "BORGENHAUGEN"
    ],
    [
        "1742",
        "KLAVESTADHAUGEN"
    ],
    [
        "1743",
        "KLAVESTADHAUGEN"
    ],
    [
        "1745",
        "SKJEBERG"
    ],
    [
        "1746",
        "SKJEBERG"
    ],
    [
        "1747",
        "SKJEBERG"
    ],
    [
        "1751",
        "HALDEN"
    ],
    [
        "1752",
        "HALDEN"
    ],
    [
        "1753",
        "HALDEN"
    ],
    [
        "1754",
        "HALDEN"
    ],
    [
        "1757",
        "HALDEN"
    ],
    [
        "1759",
        "HALDEN"
    ],
    [
        "1760",
        "HALDEN"
    ],
    [
        "1761",
        "HALDEN"
    ],
    [
        "1762",
        "HALDEN"
    ],
    [
        "1763",
        "HALDEN"
    ],
    [
        "1764",
        "HALDEN"
    ],
    [
        "1765",
        "HALDEN"
    ],
    [
        "1766",
        "HALDEN"
    ],
    [
        "1767",
        "HALDEN"
    ],
    [
        "1768",
        "HALDEN"
    ],
    [
        "1769",
        "HALDEN"
    ],
    [
        "1771",
        "HALDEN"
    ],
    [
        "1772",
        "HALDEN"
    ],
    [
        "1776",
        "HALDEN"
    ],
    [
        "1777",
        "HALDEN"
    ],
    [
        "1778",
        "HALDEN"
    ],
    [
        "1779",
        "HALDEN"
    ],
    [
        "1781",
        "HALDEN"
    ],
    [
        "1782",
        "HALDEN"
    ],
    [
        "1783",
        "HALDEN"
    ],
    [
        "1784",
        "HALDEN"
    ],
    [
        "1785",
        "HALDEN"
    ],
    [
        "1786",
        "HALDEN"
    ],
    [
        "1787",
        "HALDEN"
    ],
    [
        "1788",
        "HALDEN"
    ],
    [
        "1789",
        "BERG I ØSTFOLD"
    ],
    [
        "1790",
        "TISTEDAL"
    ],
    [
        "1791",
        "TISTEDAL"
    ],
    [
        "1792",
        "TISTEDAL"
    ],
    [
        "1793",
        "TISTEDAL"
    ],
    [
        "1794",
        "SPONVIKA"
    ],
    [
        "1796",
        "KORNSJØ"
    ],
    [
        "1798",
        "AREMARK"
    ],
    [
        "1799",
        "AREMARK"
    ],
    [
        "1801",
        "ASKIM"
    ],
    [
        "1802",
        "ASKIM"
    ],
    [
        "1803",
        "ASKIM"
    ],
    [
        "1804",
        "SPYDEBERG"
    ],
    [
        "1805",
        "TOMTER"
    ],
    [
        "1806",
        "SKIPTVET"
    ],
    [
        "1807",
        "ASKIM"
    ],
    [
        "1808",
        "ASKIM"
    ],
    [
        "1809",
        "ASKIM"
    ],
    [
        "1811",
        "ASKIM"
    ],
    [
        "1812",
        "ASKIM"
    ],
    [
        "1813",
        "ASKIM"
    ],
    [
        "1814",
        "ASKIM"
    ],
    [
        "1815",
        "ASKIM"
    ],
    [
        "1816",
        "SKIPTVET"
    ],
    [
        "1820",
        "SPYDEBERG"
    ],
    [
        "1821",
        "SPYDEBERG"
    ],
    [
        "1823",
        "KNAPSTAD"
    ],
    [
        "1825",
        "TOMTER"
    ],
    [
        "1827",
        "HOBØL"
    ],
    [
        "1828",
        "HOBØL"
    ],
    [
        "1830",
        "ASKIM"
    ],
    [
        "1831",
        "ASKIM"
    ],
    [
        "1832",
        "ASKIM"
    ],
    [
        "1833",
        "ASKIM"
    ],
    [
        "1850",
        "MYSEN"
    ],
    [
        "1851",
        "MYSEN"
    ],
    [
        "1852",
        "MYSEN"
    ],
    [
        "1859",
        "SLITU"
    ],
    [
        "1860",
        "TRØGSTAD"
    ],
    [
        "1861",
        "TRØGSTAD"
    ],
    [
        "1866",
        "BÅSTAD"
    ],
    [
        "1867",
        "BÅSTAD"
    ],
    [
        "1870",
        "ØRJE"
    ],
    [
        "1871",
        "ØRJE"
    ],
    [
        "1875",
        "OTTEID"
    ],
    [
        "1878",
        "HÆRLAND"
    ],
    [
        "1880",
        "EIDSBERG"
    ],
    [
        "1890",
        "RAKKESTAD"
    ],
    [
        "1891",
        "RAKKESTAD"
    ],
    [
        "1892",
        "DEGERNES"
    ],
    [
        "1893",
        "DEGERNES"
    ],
    [
        "1894",
        "RAKKESTAD"
    ],
    [
        "1900",
        "FETSUND"
    ],
    [
        "1901",
        "FETSUND"
    ],
    [
        "1903",
        "GAN"
    ],
    [
        "1910",
        "ENEBAKKNESET"
    ],
    [
        "1911",
        "FLATEBY"
    ],
    [
        "1912",
        "ENEBAKK"
    ],
    [
        "1914",
        "YTRE ENEBAKK"
    ],
    [
        "1916",
        "FLATEBY"
    ],
    [
        "1917",
        "YTRE ENEBAKK"
    ],
    [
        "1920",
        "SØRUMSAND"
    ],
    [
        "1921",
        "SØRUMSAND"
    ],
    [
        "1923",
        "SØRUM"
    ],
    [
        "1924",
        "SØRUM"
    ],
    [
        "1925",
        "BLAKER"
    ],
    [
        "1926",
        "BLAKER"
    ],
    [
        "1927",
        "RÅNÅSFOSS"
    ],
    [
        "1928",
        "AULI"
    ],
    [
        "1929",
        "AULI"
    ],
    [
        "1930",
        "AURSKOG"
    ],
    [
        "1931",
        "AURSKOG"
    ],
    [
        "1940",
        "BJØRKELANGEN"
    ],
    [
        "1941",
        "BJØRKELANGEN"
    ],
    [
        "1950",
        "RØMSKOG"
    ],
    [
        "1954",
        "SETSKOG"
    ],
    [
        "1960",
        "LØKEN"
    ],
    [
        "1961",
        "LØKEN"
    ],
    [
        "1963",
        "FOSSER"
    ],
    [
        "1970",
        "HEMNES"
    ],
    [
        "1971",
        "HEMNES"
    ],
    [
        "2000",
        "LILLESTRØM"
    ],
    [
        "2001",
        "LILLESTRØM"
    ],
    [
        "2003",
        "LILLESTRØM"
    ],
    [
        "2004",
        "LILLESTRØM"
    ],
    [
        "2005",
        "RÆLINGEN"
    ],
    [
        "2006",
        "LØVENSTAD"
    ],
    [
        "2007",
        "KJELLER"
    ],
    [
        "2008",
        "FJERDINGBY"
    ],
    [
        "2009",
        "NORDBY"
    ],
    [
        "2010",
        "STRØMMEN"
    ],
    [
        "2011",
        "STRØMMEN"
    ],
    [
        "2012",
        "LILLESTRØM"
    ],
    [
        "2013",
        "SKJETTEN"
    ],
    [
        "2014",
        "BLYSTADLIA"
    ],
    [
        "2015",
        "LEIRSUND"
    ],
    [
        "2016",
        "FROGNER"
    ],
    [
        "2017",
        "FROGNER"
    ],
    [
        "2018",
        "LØVENSTAD"
    ],
    [
        "2019",
        "SKEDSMOKORSET"
    ],
    [
        "2020",
        "SKEDSMOKORSET"
    ],
    [
        "2021",
        "SKEDSMOKORSET"
    ],
    [
        "2022",
        "GJERDRUM"
    ],
    [
        "2023",
        "SKEDSMOKORSET"
    ],
    [
        "2024",
        "GJERDRUM"
    ],
    [
        "2025",
        "FJERDINGBY"
    ],
    [
        "2026",
        "SKJETTEN"
    ],
    [
        "2027",
        "KJELLER"
    ],
    [
        "2028",
        "LILLESTRØM"
    ],
    [
        "2029",
        "RÆLINGEN"
    ],
    [
        "2030",
        "NANNESTAD"
    ],
    [
        "2031",
        "NANNESTAD"
    ],
    [
        "2032",
        "MAURA"
    ],
    [
        "2033",
        "ÅSGREINA"
    ],
    [
        "2034",
        "HOLTER"
    ],
    [
        "2035",
        "HOLTER"
    ],
    [
        "2036",
        "MAURA"
    ],
    [
        "2040",
        "KLØFTA"
    ],
    [
        "2041",
        "KLØFTA"
    ],
    [
        "2050",
        "JESSHEIM"
    ],
    [
        "2051",
        "JESSHEIM"
    ],
    [
        "2052",
        "JESSHEIM"
    ],
    [
        "2053",
        "JESSHEIM"
    ],
    [
        "2054",
        "MOGREINA"
    ],
    [
        "2055",
        "NORDKISA"
    ],
    [
        "2056",
        "ALGARHEIM"
    ],
    [
        "2057",
        "JESSHEIM"
    ],
    [
        "2058",
        "SESSVOLLMOEN"
    ],
    [
        "2060",
        "GARDERMOEN"
    ],
    [
        "2061",
        "GARDERMOEN"
    ],
    [
        "2062",
        "JESSHEIM"
    ],
    [
        "2063",
        "JESSHEIM"
    ],
    [
        "2066",
        "JESSHEIM"
    ],
    [
        "2067",
        "JESSHEIM"
    ],
    [
        "2068",
        "JESSHEIM"
    ],
    [
        "2069",
        "JESSHEIM"
    ],
    [
        "2070",
        "RÅHOLT"
    ],
    [
        "2071",
        "RÅHOLT"
    ],
    [
        "2072",
        "DAL"
    ],
    [
        "2073",
        "BØN"
    ],
    [
        "2074",
        "EIDSVOLL VERK"
    ],
    [
        "2076",
        "DAL"
    ],
    [
        "2080",
        "EIDSVOLL"
    ],
    [
        "2081",
        "EIDSVOLL"
    ],
    [
        "2090",
        "HURDAL"
    ],
    [
        "2091",
        "HURDAL"
    ],
    [
        "2092",
        "MINNESUND"
    ],
    [
        "2093",
        "FEIRING"
    ],
    [
        "2094",
        "MINNESUND"
    ],
    [
        "2100",
        "SKARNES"
    ],
    [
        "2101",
        "SKARNES"
    ],
    [
        "2110",
        "SLÅSTAD"
    ],
    [
        "2114",
        "DISENÅ"
    ],
    [
        "2116",
        "SANDER"
    ],
    [
        "2120",
        "SAGSTUA"
    ],
    [
        "2121",
        "SAGSTUA"
    ],
    [
        "2123",
        "BRUVOLL"
    ],
    [
        "2130",
        "KNAPPER"
    ],
    [
        "2132",
        "GARDVIK"
    ],
    [
        "2133",
        "GARDVIK"
    ],
    [
        "2134",
        "AUSTVATN"
    ],
    [
        "2150",
        "ÅRNES"
    ],
    [
        "2151",
        "ÅRNES"
    ],
    [
        "2160",
        "VORMSUND"
    ],
    [
        "2161",
        "VORMSUND"
    ],
    [
        "2162",
        "BRÅRUD"
    ],
    [
        "2163",
        "SKOGBYGDA"
    ],
    [
        "2164",
        "SKOGBYGDA"
    ],
    [
        "2165",
        "HVAM"
    ],
    [
        "2166",
        "OPPAKER"
    ],
    [
        "2167",
        "HVAM"
    ],
    [
        "2170",
        "FENSTAD"
    ],
    [
        "2201",
        "KONGSVINGER"
    ],
    [
        "2202",
        "KONGSVINGER"
    ],
    [
        "2203",
        "KONGSVINGER"
    ],
    [
        "2204",
        "KONGSVINGER"
    ],
    [
        "2205",
        "KONGSVINGER"
    ],
    [
        "2206",
        "KONGSVINGER"
    ],
    [
        "2207",
        "KONGSVINGER"
    ],
    [
        "2208",
        "KONGSVINGER"
    ],
    [
        "2209",
        "KONGSVINGER"
    ],
    [
        "2210",
        "GRANLI"
    ],
    [
        "2211",
        "KONGSVINGER"
    ],
    [
        "2212",
        "KONGSVINGER"
    ],
    [
        "2213",
        "KONGSVINGER"
    ],
    [
        "2214",
        "KONGSVINGER"
    ],
    [
        "2215",
        "ROVERUD"
    ],
    [
        "2216",
        "ROVERUD"
    ],
    [
        "2217",
        "HOKKÅSEN"
    ],
    [
        "2218",
        "LUNDERSÆTER"
    ],
    [
        "2219",
        "BRANDVAL"
    ],
    [
        "2220",
        "ÅBOGEN"
    ],
    [
        "2223",
        "GALTERUD"
    ],
    [
        "2224",
        "AUSTMARKA"
    ],
    [
        "2225",
        "KONGSVINGER"
    ],
    [
        "2226",
        "KONGSVINGER"
    ],
    [
        "2227",
        "AUSTMARKA"
    ],
    [
        "2230",
        "SKOTTERUD"
    ],
    [
        "2231",
        "SKOTTERUD"
    ],
    [
        "2232",
        "TOBØL"
    ],
    [
        "2233",
        "VESTMARKA"
    ],
    [
        "2235",
        "MATRAND"
    ],
    [
        "2240",
        "MAGNOR"
    ],
    [
        "2241",
        "MAGNOR"
    ],
    [
        "2251",
        "GRUE FINNSKOG"
    ],
    [
        "2256",
        "GRUE FINNSKOG"
    ],
    [
        "2260",
        "KIRKENÆR"
    ],
    [
        "2261",
        "KIRKENÆR"
    ],
    [
        "2264",
        "GRINDER"
    ],
    [
        "2265",
        "NAMNÅ"
    ],
    [
        "2266",
        "ARNEBERG"
    ],
    [
        "2270",
        "FLISA"
    ],
    [
        "2271",
        "FLISA"
    ],
    [
        "2280",
        "GJESÅSEN"
    ],
    [
        "2283",
        "ÅSNES FINNSKOG"
    ],
    [
        "2301",
        "HAMAR"
    ],
    [
        "2302",
        "HAMAR"
    ],
    [
        "2303",
        "HAMAR"
    ],
    [
        "2304",
        "HAMAR"
    ],
    [
        "2305",
        "HAMAR"
    ],
    [
        "2306",
        "HAMAR"
    ],
    [
        "2307",
        "HAMAR"
    ],
    [
        "2308",
        "HAMAR"
    ],
    [
        "2309",
        "HAMAR"
    ],
    [
        "2311",
        "HAMAR"
    ],
    [
        "2312",
        "OTTESTAD"
    ],
    [
        "2313",
        "OTTESTAD"
    ],
    [
        "2314",
        "OTTESTAD"
    ],
    [
        "2315",
        "HAMAR"
    ],
    [
        "2316",
        "HAMAR"
    ],
    [
        "2317",
        "HAMAR"
    ],
    [
        "2318",
        "HAMAR"
    ],
    [
        "2319",
        "HAMAR"
    ],
    [
        "2320",
        "FURNES"
    ],
    [
        "2321",
        "HAMAR"
    ],
    [
        "2322",
        "RIDABU"
    ],
    [
        "2323",
        "INGEBERG"
    ],
    [
        "2324",
        "VANG PÅ HEDMARKEN"
    ],
    [
        "2325",
        "HAMAR"
    ],
    [
        "2326",
        "HAMAR"
    ],
    [
        "2327",
        "FURNES"
    ],
    [
        "2328",
        "RIDABU"
    ],
    [
        "2329",
        "VANG PÅ HEDMARKEN"
    ],
    [
        "2330",
        "VALLSET"
    ],
    [
        "2331",
        "VALLSET"
    ],
    [
        "2332",
        "ÅSVANG"
    ],
    [
        "2333",
        "ROMEDAL"
    ],
    [
        "2334",
        "ROMEDAL"
    ],
    [
        "2335",
        "STANGE"
    ],
    [
        "2336",
        "STANGE"
    ],
    [
        "2337",
        "TANGEN"
    ],
    [
        "2338",
        "ESPA"
    ],
    [
        "2339",
        "TANGEN"
    ],
    [
        "2340",
        "LØTEN"
    ],
    [
        "2341",
        "LØTEN"
    ],
    [
        "2344",
        "ILSENG"
    ],
    [
        "2345",
        "ÅDALSBRUK"
    ],
    [
        "2346",
        "ILSENG"
    ],
    [
        "2350",
        "NES PÅ HEDMARKEN"
    ],
    [
        "2351",
        "NES PÅ HEDMARKEN"
    ],
    [
        "2353",
        "STAVSJØ"
    ],
    [
        "2355",
        "GAUPEN"
    ],
    [
        "2360",
        "RUDSHØGDA"
    ],
    [
        "2361",
        "RUDSHØGDA"
    ],
    [
        "2364",
        "NÆROSET"
    ],
    [
        "2365",
        "ÅSMARKA"
    ],
    [
        "2372",
        "BRØTTUM"
    ],
    [
        "2373",
        "BRØTTUM"
    ],
    [
        "2380",
        "BRUMUNDDAL"
    ],
    [
        "2381",
        "BRUMUNDDAL"
    ],
    [
        "2382",
        "BRUMUNDDAL"
    ],
    [
        "2383",
        "BRUMUNDDAL"
    ],
    [
        "2384",
        "BRUMUNDDAL"
    ],
    [
        "2385",
        "BRUMUNDDAL"
    ],
    [
        "2386",
        "BRUMUNDDAL"
    ],
    [
        "2387",
        "BRUMUNDDAL"
    ],
    [
        "2388",
        "BRUMUNDDAL"
    ],
    [
        "2389",
        "BRUMUNDDAL"
    ],
    [
        "2390",
        "MOELV"
    ],
    [
        "2391",
        "MOELV"
    ],
    [
        "2401",
        "ELVERUM"
    ],
    [
        "2402",
        "ELVERUM"
    ],
    [
        "2403",
        "ELVERUM"
    ],
    [
        "2404",
        "ELVERUM"
    ],
    [
        "2405",
        "ELVERUM"
    ],
    [
        "2406",
        "ELVERUM"
    ],
    [
        "2407",
        "ELVERUM"
    ],
    [
        "2408",
        "ELVERUM"
    ],
    [
        "2409",
        "ELVERUM"
    ],
    [
        "2410",
        "HERNES"
    ],
    [
        "2411",
        "ELVERUM"
    ],
    [
        "2412",
        "SØRSKOGBYGDA"
    ],
    [
        "2413",
        "ELVERUM"
    ],
    [
        "2414",
        "ELVERUM"
    ],
    [
        "2415",
        "HERADSBYGD"
    ],
    [
        "2416",
        "JØMNA"
    ],
    [
        "2417",
        "ELVERUM"
    ],
    [
        "2418",
        "ELVERUM"
    ],
    [
        "2419",
        "ELVERUM"
    ],
    [
        "2420",
        "TRYSIL"
    ],
    [
        "2421",
        "TRYSIL"
    ],
    [
        "2422",
        "NYBERGSUND"
    ],
    [
        "2423",
        "ØSTBY"
    ],
    [
        "2424",
        "ØSTBY"
    ],
    [
        "2425",
        "LJØRDALEN"
    ],
    [
        "2426",
        "LJØRDALEN"
    ],
    [
        "2427",
        "PLASSEN"
    ],
    [
        "2428",
        "SØRE OSEN"
    ],
    [
        "2429",
        "TØRBERGET"
    ],
    [
        "2430",
        "JORDET"
    ],
    [
        "2432",
        "SLETTÅS"
    ],
    [
        "2434",
        "BRASKEREIDFOSS"
    ],
    [
        "2435",
        "BRASKEREIDFOSS"
    ],
    [
        "2436",
        "VÅLER I SOLØR"
    ],
    [
        "2437",
        "HASLEMOEN"
    ],
    [
        "2438",
        "GRAVBERGET"
    ],
    [
        "2440",
        "ENGERDAL"
    ],
    [
        "2441",
        "ENGERDAL"
    ],
    [
        "2442",
        "HERADSBYGD"
    ],
    [
        "2443",
        "DREVSJØ"
    ],
    [
        "2444",
        "DREVSJØ"
    ],
    [
        "2446",
        "ELGÅ"
    ],
    [
        "2447",
        "SØRE OSEN"
    ],
    [
        "2448",
        "SØMÅDALEN"
    ],
    [
        "2450",
        "RENA"
    ],
    [
        "2451",
        "RENA"
    ],
    [
        "2460",
        "OSEN"
    ],
    [
        "2461",
        "OSEN"
    ],
    [
        "2476",
        "ATNA"
    ],
    [
        "2477",
        "SOLLIA"
    ],
    [
        "2478",
        "HANESTAD"
    ],
    [
        "2480",
        "KOPPANG"
    ],
    [
        "2481",
        "KOPPANG"
    ],
    [
        "2484",
        "RENDALEN"
    ],
    [
        "2485",
        "RENDALEN"
    ],
    [
        "2486",
        "RENDALEN"
    ],
    [
        "2487",
        "RENDALEN"
    ],
    [
        "2488",
        "RENDALEN"
    ],
    [
        "2500",
        "TYNSET"
    ],
    [
        "2501",
        "TYNSET"
    ],
    [
        "2510",
        "TYLLDALEN"
    ],
    [
        "2512",
        "KVIKNE"
    ],
    [
        "2513",
        "KVIKNE"
    ],
    [
        "2540",
        "TOLGA"
    ],
    [
        "2541",
        "TOLGA"
    ],
    [
        "2542",
        "VINGELEN"
    ],
    [
        "2544",
        "ØVERSJØDALEN"
    ],
    [
        "2550",
        "OS I ØSTERDALEN"
    ],
    [
        "2551",
        "OS I ØSTERDALEN"
    ],
    [
        "2552",
        "DALSBYGDA"
    ],
    [
        "2555",
        "TUFSINGDALEN"
    ],
    [
        "2560",
        "ALVDAL"
    ],
    [
        "2561",
        "ALVDAL"
    ],
    [
        "2580",
        "FOLLDAL"
    ],
    [
        "2581",
        "FOLLDAL"
    ],
    [
        "2582",
        "GRIMSBU"
    ],
    [
        "2584",
        "DALHOLEN"
    ],
    [
        "2601",
        "LILLEHAMMER"
    ],
    [
        "2602",
        "LILLEHAMMER"
    ],
    [
        "2603",
        "LILLEHAMMER"
    ],
    [
        "2604",
        "LILLEHAMMER"
    ],
    [
        "2605",
        "LILLEHAMMER"
    ],
    [
        "2606",
        "LILLEHAMMER"
    ],
    [
        "2607",
        "VINGROM"
    ],
    [
        "2608",
        "LILLEHAMMER"
    ],
    [
        "2609",
        "LILLEHAMMER"
    ],
    [
        "2610",
        "MESNALI"
    ],
    [
        "2611",
        "LILLEHAMMER"
    ],
    [
        "2612",
        "SJUSJØEN"
    ],
    [
        "2613",
        "LILLEHAMMER"
    ],
    [
        "2614",
        "LILLEHAMMER"
    ],
    [
        "2615",
        "LILLEHAMMER"
    ],
    [
        "2616",
        "LISMARKA"
    ],
    [
        "2617",
        "LILLEHAMMER"
    ],
    [
        "2618",
        "LILLEHAMMER"
    ],
    [
        "2619",
        "LILLEHAMMER"
    ],
    [
        "2620",
        "MESNALI"
    ],
    [
        "2621",
        "VINGROM"
    ],
    [
        "2622",
        "LILLEHAMMER"
    ],
    [
        "2623",
        "LILLEHAMMER"
    ],
    [
        "2624",
        "LILLEHAMMER"
    ],
    [
        "2625",
        "FÅBERG"
    ],
    [
        "2626",
        "LILLEHAMMER"
    ],
    [
        "2627",
        "FÅBERG"
    ],
    [
        "2628",
        "SJUSJØEN"
    ],
    [
        "2629",
        "LILLEHAMMER"
    ],
    [
        "2630",
        "RINGEBU"
    ],
    [
        "2631",
        "RINGEBU"
    ],
    [
        "2632",
        "VENABYGD"
    ],
    [
        "2633",
        "FÅVANG"
    ],
    [
        "2634",
        "FÅVANG"
    ],
    [
        "2635",
        "TRETTEN"
    ],
    [
        "2636",
        "ØYER"
    ],
    [
        "2637",
        "ØYER"
    ],
    [
        "2638",
        "TRETTEN"
    ],
    [
        "2639",
        "VINSTRA"
    ],
    [
        "2640",
        "VINSTRA"
    ],
    [
        "2641",
        "KVAM"
    ],
    [
        "2642",
        "KVAM"
    ],
    [
        "2643",
        "SKÅBU"
    ],
    [
        "2644",
        "SKÅBU"
    ],
    [
        "2645",
        "SØR-FRON"
    ],
    [
        "2646",
        "GÅLÅ"
    ],
    [
        "2647",
        "SØR-FRON"
    ],
    [
        "2648",
        "SØR-FRON"
    ],
    [
        "2649",
        "ØSTRE GAUSDAL"
    ],
    [
        "2651",
        "ØSTRE GAUSDAL"
    ],
    [
        "2652",
        "SVINGVOLL"
    ],
    [
        "2653",
        "VESTRE GAUSDAL"
    ],
    [
        "2654",
        "VESTRE GAUSDAL"
    ],
    [
        "2656",
        "FOLLEBU"
    ],
    [
        "2657",
        "SVATSUM"
    ],
    [
        "2658",
        "ESPEDALEN"
    ],
    [
        "2659",
        "DOMBÅS"
    ],
    [
        "2660",
        "DOMBÅS"
    ],
    [
        "2661",
        "HJERKINN"
    ],
    [
        "2662",
        "DOVRE"
    ],
    [
        "2663",
        "DOVRESKOGEN"
    ],
    [
        "2664",
        "DOVRE"
    ],
    [
        "2665",
        "LESJA"
    ],
    [
        "2666",
        "LORA"
    ],
    [
        "2667",
        "LESJAVERK"
    ],
    [
        "2668",
        "LESJASKOG"
    ],
    [
        "2669",
        "BJORLI"
    ],
    [
        "2670",
        "OTTA"
    ],
    [
        "2671",
        "LESJA"
    ],
    [
        "2672",
        "SEL"
    ],
    [
        "2673",
        "HØVRINGEN"
    ],
    [
        "2674",
        "MYSUSÆTER"
    ],
    [
        "2675",
        "OTTA"
    ],
    [
        "2676",
        "HEIDAL"
    ],
    [
        "2677",
        "NEDRE HEIDAL"
    ],
    [
        "2678",
        "SEL"
    ],
    [
        "2679",
        "HEIDAL"
    ],
    [
        "2680",
        "VÅGÅ"
    ],
    [
        "2681",
        "LALM"
    ],
    [
        "2682",
        "LALM"
    ],
    [
        "2683",
        "TESSANDEN"
    ],
    [
        "2684",
        "VÅGÅ"
    ],
    [
        "2685",
        "GARMO"
    ],
    [
        "2686",
        "LOM"
    ],
    [
        "2687",
        "BØVERDALEN"
    ],
    [
        "2688",
        "LOM"
    ],
    [
        "2690",
        "SKJÅK"
    ],
    [
        "2693",
        "NORDBERG"
    ],
    [
        "2694",
        "SKJÅK"
    ],
    [
        "2695",
        "GROTLI"
    ],
    [
        "2711",
        "GRAN"
    ],
    [
        "2712",
        "BRANDBU"
    ],
    [
        "2713",
        "ROA"
    ],
    [
        "2714",
        "JAREN"
    ],
    [
        "2715",
        "LUNNER"
    ],
    [
        "2716",
        "HARESTUA"
    ],
    [
        "2717",
        "GRUA"
    ],
    [
        "2718",
        "BRANDBU"
    ],
    [
        "2720",
        "GRINDVOLL"
    ],
    [
        "2730",
        "LUNNER"
    ],
    [
        "2740",
        "ROA"
    ],
    [
        "2742",
        "GRUA"
    ],
    [
        "2743",
        "HARESTUA"
    ],
    [
        "2750",
        "GRAN"
    ],
    [
        "2760",
        "BRANDBU"
    ],
    [
        "2770",
        "JAREN"
    ],
    [
        "2801",
        "GJØVIK"
    ],
    [
        "2802",
        "GJØVIK"
    ],
    [
        "2803",
        "GJØVIK"
    ],
    [
        "2804",
        "GJØVIK"
    ],
    [
        "2805",
        "GJØVIK"
    ],
    [
        "2806",
        "GJØVIK"
    ],
    [
        "2807",
        "HUNNDALEN"
    ],
    [
        "2808",
        "GJØVIK"
    ],
    [
        "2809",
        "GJØVIK"
    ],
    [
        "2810",
        "GJØVIK"
    ],
    [
        "2811",
        "HUNNDALEN"
    ],
    [
        "2812",
        "GJØVIK"
    ],
    [
        "2815",
        "GJØVIK"
    ],
    [
        "2816",
        "GJØVIK"
    ],
    [
        "2817",
        "GJØVIK"
    ],
    [
        "2818",
        "GJØVIK"
    ],
    [
        "2819",
        "GJØVIK"
    ],
    [
        "2820",
        "NORDRE TOTEN"
    ],
    [
        "2821",
        "GJØVIK"
    ],
    [
        "2822",
        "BYBRUA"
    ],
    [
        "2825",
        "GJØVIK"
    ],
    [
        "2827",
        "HUNNDALEN"
    ],
    [
        "2830",
        "RAUFOSS"
    ],
    [
        "2831",
        "RAUFOSS"
    ],
    [
        "2832",
        "BIRI"
    ],
    [
        "2833",
        "RAUFOSS"
    ],
    [
        "2834",
        "RAUFOSS"
    ],
    [
        "2835",
        "RAUFOSS"
    ],
    [
        "2836",
        "BIRI"
    ],
    [
        "2837",
        "BIRISTRAND"
    ],
    [
        "2838",
        "SNERTINGDAL"
    ],
    [
        "2839",
        "ØVRE SNERTINGDAL"
    ],
    [
        "2840",
        "REINSVOLL"
    ],
    [
        "2841",
        "SNERTINGDAL"
    ],
    [
        "2843",
        "EINA"
    ],
    [
        "2844",
        "KOLBU"
    ],
    [
        "2845",
        "BØVERBRU"
    ],
    [
        "2846",
        "BØVERBRU"
    ],
    [
        "2847",
        "KOLBU"
    ],
    [
        "2848",
        "SKREIA"
    ],
    [
        "2849",
        "KAPP"
    ],
    [
        "2850",
        "LENA"
    ],
    [
        "2851",
        "LENA"
    ],
    [
        "2853",
        "REINSVOLL"
    ],
    [
        "2854",
        "EINA"
    ],
    [
        "2857",
        "SKREIA"
    ],
    [
        "2858",
        "KAPP"
    ],
    [
        "2860",
        "HOV"
    ],
    [
        "2861",
        "LANDÅSBYGDA"
    ],
    [
        "2862",
        "FLUBERG"
    ],
    [
        "2863",
        "VESTSIDA"
    ],
    [
        "2864",
        "FALL"
    ],
    [
        "2866",
        "ENGER"
    ],
    [
        "2867",
        "HOV"
    ],
    [
        "2870",
        "DOKKA"
    ],
    [
        "2879",
        "ODNES"
    ],
    [
        "2880",
        "NORD-TORPA"
    ],
    [
        "2881",
        "AUST-TORPA"
    ],
    [
        "2882",
        "DOKKA"
    ],
    [
        "2890",
        "ETNEDAL"
    ],
    [
        "2893",
        "ETNEDAL"
    ],
    [
        "2900",
        "FAGERNES"
    ],
    [
        "2901",
        "FAGERNES"
    ],
    [
        "2907",
        "LEIRA I VALDRES"
    ],
    [
        "2909",
        "AURDAL"
    ],
    [
        "2910",
        "AURDAL"
    ],
    [
        "2917",
        "SKRAUTVÅL"
    ],
    [
        "2918",
        "ULNES"
    ],
    [
        "2920",
        "LEIRA I VALDRES"
    ],
    [
        "2923",
        "TISLEIDALEN"
    ],
    [
        "2929",
        "BAGN"
    ],
    [
        "2930",
        "BAGN"
    ],
    [
        "2933",
        "REINLI"
    ],
    [
        "2936",
        "BEGNADALEN"
    ],
    [
        "2937",
        "BEGNA"
    ],
    [
        "2939",
        "HEGGENES"
    ],
    [
        "2940",
        "HEGGENES"
    ],
    [
        "2943",
        "ROGNE"
    ],
    [
        "2950",
        "SKAMMESTEIN"
    ],
    [
        "2952",
        "BEITO"
    ],
    [
        "2953",
        "BEITOSTØLEN"
    ],
    [
        "2954",
        "BEITOSTØLEN"
    ],
    [
        "2959",
        "RØN"
    ],
    [
        "2960",
        "RØN"
    ],
    [
        "2965",
        "SLIDRE"
    ],
    [
        "2966",
        "SLIDRE"
    ],
    [
        "2967",
        "LOMEN"
    ],
    [
        "2972",
        "RYFOSS"
    ],
    [
        "2973",
        "RYFOSS"
    ],
    [
        "2974",
        "VANG I VALDRES"
    ],
    [
        "2975",
        "VANG I VALDRES"
    ],
    [
        "2977",
        "ØYE"
    ],
    [
        "2985",
        "TYINKRYSSET"
    ],
    [
        "3001",
        "DRAMMEN"
    ],
    [
        "3002",
        "DRAMMEN"
    ],
    [
        "3003",
        "DRAMMEN"
    ],
    [
        "3004",
        "DRAMMEN"
    ],
    [
        "3005",
        "DRAMMEN"
    ],
    [
        "3006",
        "DRAMMEN"
    ],
    [
        "3007",
        "DRAMMEN"
    ],
    [
        "3008",
        "DRAMMEN"
    ],
    [
        "3009",
        "DRAMMEN"
    ],
    [
        "3010",
        "DRAMMEN"
    ],
    [
        "3011",
        "DRAMMEN"
    ],
    [
        "3012",
        "DRAMMEN"
    ],
    [
        "3013",
        "DRAMMEN"
    ],
    [
        "3014",
        "DRAMMEN"
    ],
    [
        "3015",
        "DRAMMEN"
    ],
    [
        "3016",
        "DRAMMEN"
    ],
    [
        "3017",
        "DRAMMEN"
    ],
    [
        "3018",
        "DRAMMEN"
    ],
    [
        "3019",
        "DRAMMEN"
    ],
    [
        "3021",
        "DRAMMEN"
    ],
    [
        "3022",
        "DRAMMEN"
    ],
    [
        "3023",
        "DRAMMEN"
    ],
    [
        "3024",
        "DRAMMEN"
    ],
    [
        "3025",
        "DRAMMEN"
    ],
    [
        "3026",
        "DRAMMEN"
    ],
    [
        "3027",
        "DRAMMEN"
    ],
    [
        "3028",
        "DRAMMEN"
    ],
    [
        "3029",
        "DRAMMEN"
    ],
    [
        "3030",
        "DRAMMEN"
    ],
    [
        "3031",
        "DRAMMEN"
    ],
    [
        "3032",
        "DRAMMEN"
    ],
    [
        "3033",
        "DRAMMEN"
    ],
    [
        "3034",
        "DRAMMEN"
    ],
    [
        "3035",
        "DRAMMEN"
    ],
    [
        "3036",
        "DRAMMEN"
    ],
    [
        "3037",
        "DRAMMEN"
    ],
    [
        "3038",
        "DRAMMEN"
    ],
    [
        "3039",
        "DRAMMEN"
    ],
    [
        "3040",
        "DRAMMEN"
    ],
    [
        "3041",
        "DRAMMEN"
    ],
    [
        "3042",
        "DRAMMEN"
    ],
    [
        "3043",
        "DRAMMEN"
    ],
    [
        "3044",
        "DRAMMEN"
    ],
    [
        "3045",
        "DRAMMEN"
    ],
    [
        "3046",
        "DRAMMEN"
    ],
    [
        "3047",
        "DRAMMEN"
    ],
    [
        "3048",
        "DRAMMEN"
    ],
    [
        "3050",
        "MJØNDALEN"
    ],
    [
        "3051",
        "MJØNDALEN"
    ],
    [
        "3053",
        "STEINBERG"
    ],
    [
        "3054",
        "KROKSTADELVA"
    ],
    [
        "3055",
        "KROKSTADELVA"
    ],
    [
        "3056",
        "SOLBERGELVA"
    ],
    [
        "3057",
        "SOLBERGELVA"
    ],
    [
        "3058",
        "SOLBERGMOEN"
    ],
    [
        "3060",
        "SVELVIK"
    ],
    [
        "3061",
        "SVELVIK"
    ],
    [
        "3063",
        "DRAMMEN"
    ],
    [
        "3064",
        "DRAMMEN"
    ],
    [
        "3065",
        "DRAMMEN"
    ],
    [
        "3066",
        "DRAMMEN"
    ],
    [
        "3070",
        "SANDE I VESTFOLD"
    ],
    [
        "3071",
        "SANDE I VESTFOLD"
    ],
    [
        "3072",
        "SANDE I VESTFOLD"
    ],
    [
        "3073",
        "SANDE I VESTFOLD"
    ],
    [
        "3074",
        "SANDE I VESTFOLD"
    ],
    [
        "3075",
        "BERGER"
    ],
    [
        "3076",
        "SANDE I VESTFOLD"
    ],
    [
        "3077",
        "SANDE I VESTFOLD"
    ],
    [
        "3080",
        "HOLMESTRAND"
    ],
    [
        "3081",
        "HOLMESTRAND"
    ],
    [
        "3082",
        "HOLMESTRAND"
    ],
    [
        "3083",
        "HOLMESTRAND"
    ],
    [
        "3084",
        "HOLMESTRAND"
    ],
    [
        "3085",
        "HOLMESTRAND"
    ],
    [
        "3086",
        "HOLMESTRAND"
    ],
    [
        "3087",
        "HOLMESTRAND"
    ],
    [
        "3088",
        "HOLMESTRAND"
    ],
    [
        "3089",
        "HOLMESTRAND"
    ],
    [
        "3090",
        "HOF"
    ],
    [
        "3091",
        "HOF"
    ],
    [
        "3092",
        "SUNDBYFOSS"
    ],
    [
        "3095",
        "EIDSFOSS"
    ],
    [
        "3101",
        "TØNSBERG"
    ],
    [
        "3103",
        "TØNSBERG"
    ],
    [
        "3104",
        "TØNSBERG"
    ],
    [
        "3105",
        "TØNSBERG"
    ],
    [
        "3106",
        "NØTTERØY"
    ],
    [
        "3107",
        "SEM"
    ],
    [
        "3108",
        "VEAR"
    ],
    [
        "3109",
        "TØNSBERG"
    ],
    [
        "3110",
        "TØNSBERG"
    ],
    [
        "3111",
        "TØNSBERG"
    ],
    [
        "3112",
        "TØNSBERG"
    ],
    [
        "3113",
        "TØNSBERG"
    ],
    [
        "3114",
        "TØNSBERG"
    ],
    [
        "3115",
        "TØNSBERG"
    ],
    [
        "3116",
        "TØNSBERG"
    ],
    [
        "3117",
        "TØNSBERG"
    ],
    [
        "3118",
        "TØNSBERG"
    ],
    [
        "3119",
        "TØNSBERG"
    ],
    [
        "3120",
        "NØTTERØY"
    ],
    [
        "3121",
        "NØTTERØY"
    ],
    [
        "3122",
        "TØNSBERG"
    ],
    [
        "3123",
        "TØNSBERG"
    ],
    [
        "3124",
        "TØNSBERG"
    ],
    [
        "3125",
        "TØNSBERG"
    ],
    [
        "3126",
        "TØNSBERG"
    ],
    [
        "3127",
        "TØNSBERG"
    ],
    [
        "3128",
        "NØTTERØY"
    ],
    [
        "3129",
        "TØNSBERG"
    ],
    [
        "3131",
        "HUSØYSUND"
    ],
    [
        "3132",
        "HUSØYSUND"
    ],
    [
        "3133",
        "DUKEN"
    ],
    [
        "3134",
        "TØNSBERG"
    ],
    [
        "3135",
        "TORØD"
    ],
    [
        "3137",
        "TORØD"
    ],
    [
        "3138",
        "SKALLESTAD"
    ],
    [
        "3139",
        "SKALLESTAD"
    ],
    [
        "3140",
        "NØTTERØY"
    ],
    [
        "3141",
        "KJØPMANNSKJÆR"
    ],
    [
        "3142",
        "VESTSKOGEN"
    ],
    [
        "3143",
        "KJØPMANNSKJÆR"
    ],
    [
        "3144",
        "VEIERLAND"
    ],
    [
        "3145",
        "TJØME"
    ],
    [
        "3148",
        "HVASSER"
    ],
    [
        "3150",
        "TOLVSRØD"
    ],
    [
        "3151",
        "TOLVSRØD"
    ],
    [
        "3152",
        "TOLVSRØD"
    ],
    [
        "3153",
        "TOLVSRØD"
    ],
    [
        "3154",
        "TOLVSRØD"
    ],
    [
        "3156",
        "MELSOMVIK"
    ],
    [
        "3157",
        "BARKÅKER"
    ],
    [
        "3158",
        "ANDEBU"
    ],
    [
        "3159",
        "MELSOMVIK"
    ],
    [
        "3160",
        "STOKKE"
    ],
    [
        "3161",
        "STOKKE"
    ],
    [
        "3162",
        "ANDEBU"
    ],
    [
        "3163",
        "NØTTERØY"
    ],
    [
        "3164",
        "REVETAL"
    ],
    [
        "3165",
        "TJØME"
    ],
    [
        "3166",
        "TOLVSRØD"
    ],
    [
        "3167",
        "ÅSGÅRDSTRAND"
    ],
    [
        "3168",
        "MELSOMVIK"
    ],
    [
        "3169",
        "STOKKE"
    ],
    [
        "3170",
        "SEM"
    ],
    [
        "3171",
        "SEM"
    ],
    [
        "3172",
        "VEAR"
    ],
    [
        "3173",
        "VEAR"
    ],
    [
        "3174",
        "REVETAL"
    ],
    [
        "3175",
        "RAMNES"
    ],
    [
        "3176",
        "UNDRUMSDAL"
    ],
    [
        "3177",
        "VÅLE"
    ],
    [
        "3178",
        "VÅLE"
    ],
    [
        "3179",
        "ÅSGÅRDSTRAND"
    ],
    [
        "3180",
        "NYKIRKE"
    ],
    [
        "3181",
        "HORTEN"
    ],
    [
        "3182",
        "HORTEN"
    ],
    [
        "3183",
        "HORTEN"
    ],
    [
        "3184",
        "BORRE"
    ],
    [
        "3185",
        "SKOPPUM"
    ],
    [
        "3186",
        "HORTEN"
    ],
    [
        "3187",
        "HORTEN"
    ],
    [
        "3188",
        "HORTEN"
    ],
    [
        "3189",
        "HORTEN"
    ],
    [
        "3191",
        "HORTEN"
    ],
    [
        "3192",
        "HORTEN"
    ],
    [
        "3193",
        "HORTEN"
    ],
    [
        "3194",
        "HORTEN"
    ],
    [
        "3195",
        "SKOPPUM"
    ],
    [
        "3196",
        "HORTEN"
    ],
    [
        "3197",
        "NYKIRKE"
    ],
    [
        "3199",
        "BORRE"
    ],
    [
        "3201",
        "SANDEFJORD"
    ],
    [
        "3202",
        "SANDEFJORD"
    ],
    [
        "3203",
        "SANDEFJORD"
    ],
    [
        "3204",
        "SANDEFJORD"
    ],
    [
        "3205",
        "SANDEFJORD"
    ],
    [
        "3206",
        "SANDEFJORD"
    ],
    [
        "3207",
        "SANDEFJORD"
    ],
    [
        "3208",
        "SANDEFJORD"
    ],
    [
        "3209",
        "SANDEFJORD"
    ],
    [
        "3210",
        "SANDEFJORD"
    ],
    [
        "3211",
        "SANDEFJORD"
    ],
    [
        "3212",
        "SANDEFJORD"
    ],
    [
        "3213",
        "SANDEFJORD"
    ],
    [
        "3214",
        "SANDEFJORD"
    ],
    [
        "3215",
        "SANDEFJORD"
    ],
    [
        "3216",
        "SANDEFJORD"
    ],
    [
        "3217",
        "SANDEFJORD"
    ],
    [
        "3218",
        "SANDEFJORD"
    ],
    [
        "3219",
        "SANDEFJORD"
    ],
    [
        "3220",
        "SANDEFJORD"
    ],
    [
        "3221",
        "SANDEFJORD"
    ],
    [
        "3222",
        "SANDEFJORD"
    ],
    [
        "3223",
        "SANDEFJORD"
    ],
    [
        "3224",
        "SANDEFJORD"
    ],
    [
        "3225",
        "SANDEFJORD"
    ],
    [
        "3226",
        "SANDEFJORD"
    ],
    [
        "3227",
        "SANDEFJORD"
    ],
    [
        "3228",
        "SANDEFJORD"
    ],
    [
        "3229",
        "SANDEFJORD"
    ],
    [
        "3230",
        "SANDEFJORD"
    ],
    [
        "3231",
        "SANDEFJORD"
    ],
    [
        "3232",
        "SANDEFJORD"
    ],
    [
        "3233",
        "SANDEFJORD"
    ],
    [
        "3234",
        "SANDEFJORD"
    ],
    [
        "3235",
        "SANDEFJORD"
    ],
    [
        "3236",
        "SANDEFJORD"
    ],
    [
        "3237",
        "SANDEFJORD"
    ],
    [
        "3238",
        "SANDEFJORD"
    ],
    [
        "3239",
        "SANDEFJORD"
    ],
    [
        "3240",
        "SANDEFJORD"
    ],
    [
        "3241",
        "SANDEFJORD"
    ],
    [
        "3242",
        "SANDEFJORD"
    ],
    [
        "3243",
        "KODAL"
    ],
    [
        "3244",
        "SANDEFJORD"
    ],
    [
        "3245",
        "KODAL"
    ],
    [
        "3246",
        "SANDEFJORD"
    ],
    [
        "3247",
        "SANDEFJORD"
    ],
    [
        "3248",
        "SANDEFJORD"
    ],
    [
        "3249",
        "SANDEFJORD"
    ],
    [
        "3251",
        "LARVIK"
    ],
    [
        "3252",
        "LARVIK"
    ],
    [
        "3253",
        "LARVIK"
    ],
    [
        "3254",
        "LARVIK"
    ],
    [
        "3255",
        "LARVIK"
    ],
    [
        "3256",
        "LARVIK"
    ],
    [
        "3257",
        "LARVIK"
    ],
    [
        "3258",
        "LARVIK"
    ],
    [
        "3259",
        "LARVIK"
    ],
    [
        "3260",
        "LARVIK"
    ],
    [
        "3261",
        "LARVIK"
    ],
    [
        "3262",
        "LARVIK"
    ],
    [
        "3263",
        "LARVIK"
    ],
    [
        "3264",
        "LARVIK"
    ],
    [
        "3265",
        "LARVIK"
    ],
    [
        "3267",
        "LARVIK"
    ],
    [
        "3268",
        "LARVIK"
    ],
    [
        "3269",
        "LARVIK"
    ],
    [
        "3270",
        "LARVIK"
    ],
    [
        "3271",
        "LARVIK"
    ],
    [
        "3274",
        "LARVIK"
    ],
    [
        "3275",
        "SVARSTAD"
    ],
    [
        "3276",
        "SVARSTAD"
    ],
    [
        "3277",
        "STEINSHOLT"
    ],
    [
        "3280",
        "TJODALYNG"
    ],
    [
        "3281",
        "TJODALYNG"
    ],
    [
        "3282",
        "KVELDE"
    ],
    [
        "3284",
        "KVELDE"
    ],
    [
        "3285",
        "LARVIK"
    ],
    [
        "3290",
        "STAVERN"
    ],
    [
        "3291",
        "STAVERN"
    ],
    [
        "3292",
        "STAVERN"
    ],
    [
        "3294",
        "STAVERN"
    ],
    [
        "3295",
        "HELGEROA"
    ],
    [
        "3296",
        "NEVLUNGHAVN"
    ],
    [
        "3297",
        "HELGEROA"
    ],
    [
        "3300",
        "HOKKSUND"
    ],
    [
        "3301",
        "HOKKSUND"
    ],
    [
        "3302",
        "HOKKSUND"
    ],
    [
        "3303",
        "HOKKSUND"
    ],
    [
        "3320",
        "VESTFOSSEN"
    ],
    [
        "3321",
        "VESTFOSSEN"
    ],
    [
        "3322",
        "FISKUM"
    ],
    [
        "3330",
        "SKOTSELV"
    ],
    [
        "3331",
        "SKOTSELV"
    ],
    [
        "3340",
        "ÅMOT"
    ],
    [
        "3341",
        "ÅMOT"
    ],
    [
        "3342",
        "ÅMOT"
    ],
    [
        "3350",
        "PRESTFOSS"
    ],
    [
        "3351",
        "PRESTFOSS"
    ],
    [
        "3355",
        "SOLUMSMOEN"
    ],
    [
        "3357",
        "EGGEDAL"
    ],
    [
        "3358",
        "NEDRE EGGEDAL"
    ],
    [
        "3359",
        "EGGEDAL"
    ],
    [
        "3360",
        "GEITHUS"
    ],
    [
        "3361",
        "GEITHUS"
    ],
    [
        "3370",
        "VIKERSUND"
    ],
    [
        "3371",
        "VIKERSUND"
    ],
    [
        "3401",
        "LIER"
    ],
    [
        "3402",
        "LIER"
    ],
    [
        "3403",
        "LIER"
    ],
    [
        "3404",
        "LIER"
    ],
    [
        "3405",
        "LIER"
    ],
    [
        "3406",
        "TRANBY"
    ],
    [
        "3407",
        "TRANBY"
    ],
    [
        "3408",
        "TRANBY"
    ],
    [
        "3409",
        "TRANBY"
    ],
    [
        "3410",
        "SYLLING"
    ],
    [
        "3411",
        "SYLLING"
    ],
    [
        "3412",
        "LIERSTRANDA"
    ],
    [
        "3413",
        "LIER"
    ],
    [
        "3414",
        "LIERSTRANDA"
    ],
    [
        "3420",
        "LIERSKOGEN"
    ],
    [
        "3421",
        "LIERSKOGEN"
    ],
    [
        "3425",
        "REISTAD"
    ],
    [
        "3426",
        "GULLAUG"
    ],
    [
        "3427",
        "GULLAUG"
    ],
    [
        "3428",
        "GULLAUG"
    ],
    [
        "3430",
        "SPIKKESTAD"
    ],
    [
        "3431",
        "SPIKKESTAD"
    ],
    [
        "3440",
        "RØYKEN"
    ],
    [
        "3441",
        "RØYKEN"
    ],
    [
        "3442",
        "HYGGEN"
    ],
    [
        "3470",
        "SLEMMESTAD"
    ],
    [
        "3471",
        "SLEMMESTAD"
    ],
    [
        "3472",
        "BØDALEN"
    ],
    [
        "3474",
        "ÅROS"
    ],
    [
        "3475",
        "SÆTRE"
    ],
    [
        "3476",
        "SÆTRE"
    ],
    [
        "3477",
        "BÅTSTØ"
    ],
    [
        "3478",
        "NÆRSNES"
    ],
    [
        "3479",
        "NÆRSNES"
    ],
    [
        "3480",
        "FILTVET"
    ],
    [
        "3481",
        "TOFTE"
    ],
    [
        "3482",
        "TOFTE"
    ],
    [
        "3483",
        "KANA"
    ],
    [
        "3484",
        "HOLMSBU"
    ],
    [
        "3485",
        "FILTVET"
    ],
    [
        "3490",
        "KLOKKARSTUA"
    ],
    [
        "3501",
        "HØNEFOSS"
    ],
    [
        "3502",
        "HØNEFOSS"
    ],
    [
        "3503",
        "HØNEFOSS"
    ],
    [
        "3504",
        "HØNEFOSS"
    ],
    [
        "3507",
        "HØNEFOSS"
    ],
    [
        "3510",
        "HØNEFOSS"
    ],
    [
        "3511",
        "HØNEFOSS"
    ],
    [
        "3512",
        "HØNEFOSS"
    ],
    [
        "3513",
        "HØNEFOSS"
    ],
    [
        "3514",
        "HØNEFOSS"
    ],
    [
        "3515",
        "HØNEFOSS"
    ],
    [
        "3516",
        "HØNEFOSS"
    ],
    [
        "3517",
        "HØNEFOSS"
    ],
    [
        "3518",
        "HØNEFOSS"
    ],
    [
        "3519",
        "HØNEFOSS"
    ],
    [
        "3520",
        "JEVNAKER"
    ],
    [
        "3521",
        "JEVNAKER"
    ],
    [
        "3522",
        "BJONEROA"
    ],
    [
        "3523",
        "NES I ÅDAL"
    ],
    [
        "3524",
        "NES I ÅDAL"
    ],
    [
        "3525",
        "HALLINGBY"
    ],
    [
        "3526",
        "HALLINGBY"
    ],
    [
        "3527",
        "BJONEROA"
    ],
    [
        "3528",
        "HEDALEN"
    ],
    [
        "3529",
        "RØYSE"
    ],
    [
        "3530",
        "RØYSE"
    ],
    [
        "3531",
        "KROKKLEIVA"
    ],
    [
        "3532",
        "TYRISTRAND"
    ],
    [
        "3533",
        "TYRISTRAND"
    ],
    [
        "3534",
        "SOKNA"
    ],
    [
        "3535",
        "KRØDEREN"
    ],
    [
        "3536",
        "NORESUND"
    ],
    [
        "3537",
        "KRØDEREN"
    ],
    [
        "3538",
        "SOLLIHØGDA"
    ],
    [
        "3539",
        "FLÅ"
    ],
    [
        "3540",
        "NESBYEN"
    ],
    [
        "3541",
        "NESBYEN"
    ],
    [
        "3542",
        "SOKNA"
    ],
    [
        "3543",
        "NORESUND"
    ],
    [
        "3544",
        "TUNHOVD"
    ],
    [
        "3545",
        "FLÅ"
    ],
    [
        "3546",
        "KROKKLEIVA"
    ],
    [
        "3550",
        "GOL"
    ],
    [
        "3551",
        "GOL"
    ],
    [
        "3560",
        "HEMSEDAL"
    ],
    [
        "3561",
        "HEMSEDAL"
    ],
    [
        "3570",
        "ÅL"
    ],
    [
        "3571",
        "ÅL"
    ],
    [
        "3575",
        "HOL"
    ],
    [
        "3576",
        "HOL"
    ],
    [
        "3577",
        "HOVET"
    ],
    [
        "3579",
        "TORPO"
    ],
    [
        "3580",
        "GEILO"
    ],
    [
        "3581",
        "GEILO"
    ],
    [
        "3588",
        "DAGALI"
    ],
    [
        "3593",
        "USTAOSET"
    ],
    [
        "3595",
        "HAUGASTØL"
    ],
    [
        "3601",
        "KONGSBERG"
    ],
    [
        "3602",
        "KONGSBERG"
    ],
    [
        "3603",
        "KONGSBERG"
    ],
    [
        "3604",
        "KONGSBERG"
    ],
    [
        "3605",
        "KONGSBERG"
    ],
    [
        "3606",
        "KONGSBERG"
    ],
    [
        "3607",
        "KONGSBERG"
    ],
    [
        "3608",
        "HEISTADMOEN"
    ],
    [
        "3609",
        "KONGSBERG"
    ],
    [
        "3610",
        "KONGSBERG"
    ],
    [
        "3611",
        "KONGSBERG"
    ],
    [
        "3612",
        "KONGSBERG"
    ],
    [
        "3613",
        "KONGSBERG"
    ],
    [
        "3614",
        "KONGSBERG"
    ],
    [
        "3615",
        "KONGSBERG"
    ],
    [
        "3616",
        "KONGSBERG"
    ],
    [
        "3617",
        "KONGSBERG"
    ],
    [
        "3618",
        "SKOLLENBORG"
    ],
    [
        "3619",
        "SKOLLENBORG"
    ],
    [
        "3620",
        "FLESBERG"
    ],
    [
        "3621",
        "LAMPELAND"
    ],
    [
        "3622",
        "SVENE"
    ],
    [
        "3623",
        "LAMPELAND"
    ],
    [
        "3624",
        "LYNGDAL I NUMEDAL"
    ],
    [
        "3625",
        "SKOLLENBORG"
    ],
    [
        "3626",
        "ROLLAG"
    ],
    [
        "3627",
        "VEGGLI"
    ],
    [
        "3628",
        "VEGGLI"
    ],
    [
        "3629",
        "NORE"
    ],
    [
        "3630",
        "RØDBERG"
    ],
    [
        "3631",
        "RØDBERG"
    ],
    [
        "3632",
        "UVDAL"
    ],
    [
        "3634",
        "NORE"
    ],
    [
        "3646",
        "HVITTINGFOSS"
    ],
    [
        "3647",
        "HVITTINGFOSS"
    ],
    [
        "3648",
        "PASSEBEKK"
    ],
    [
        "3650",
        "TINN AUSTBYGD"
    ],
    [
        "3652",
        "HOVIN I TELEMARK"
    ],
    [
        "3656",
        "ATRÅ"
    ],
    [
        "3658",
        "MILAND"
    ],
    [
        "3660",
        "RJUKAN"
    ],
    [
        "3661",
        "RJUKAN"
    ],
    [
        "3665",
        "SAULAND"
    ],
    [
        "3666",
        "ATRÅ"
    ],
    [
        "3671",
        "NOTODDEN"
    ],
    [
        "3672",
        "NOTODDEN"
    ],
    [
        "3673",
        "NOTODDEN"
    ],
    [
        "3674",
        "NOTODDEN"
    ],
    [
        "3675",
        "NOTODDEN"
    ],
    [
        "3676",
        "NOTODDEN"
    ],
    [
        "3677",
        "NOTODDEN"
    ],
    [
        "3678",
        "NOTODDEN"
    ],
    [
        "3679",
        "NOTODDEN"
    ],
    [
        "3680",
        "NOTODDEN"
    ],
    [
        "3681",
        "NOTODDEN"
    ],
    [
        "3683",
        "NOTODDEN"
    ],
    [
        "3684",
        "NOTODDEN"
    ],
    [
        "3690",
        "HJARTDAL"
    ],
    [
        "3691",
        "GRANSHERAD"
    ],
    [
        "3692",
        "SAULAND"
    ],
    [
        "3697",
        "TUDDAL"
    ],
    [
        "3701",
        "SKIEN"
    ],
    [
        "3702",
        "SKIEN"
    ],
    [
        "3703",
        "SKIEN"
    ],
    [
        "3704",
        "SKIEN"
    ],
    [
        "3705",
        "SKIEN"
    ],
    [
        "3707",
        "SKIEN"
    ],
    [
        "3710",
        "SKIEN"
    ],
    [
        "3711",
        "SKIEN"
    ],
    [
        "3712",
        "SKIEN"
    ],
    [
        "3713",
        "SKIEN"
    ],
    [
        "3714",
        "SKIEN"
    ],
    [
        "3715",
        "SKIEN"
    ],
    [
        "3716",
        "SKIEN"
    ],
    [
        "3717",
        "SKIEN"
    ],
    [
        "3718",
        "SKIEN"
    ],
    [
        "3719",
        "SKIEN"
    ],
    [
        "3720",
        "SKIEN"
    ],
    [
        "3721",
        "SKIEN"
    ],
    [
        "3722",
        "SKIEN"
    ],
    [
        "3723",
        "SKIEN"
    ],
    [
        "3724",
        "SKIEN"
    ],
    [
        "3725",
        "SKIEN"
    ],
    [
        "3726",
        "SKIEN"
    ],
    [
        "3727",
        "SKIEN"
    ],
    [
        "3728",
        "SKIEN"
    ],
    [
        "3729",
        "SKIEN"
    ],
    [
        "3730",
        "SKIEN"
    ],
    [
        "3731",
        "SKIEN"
    ],
    [
        "3732",
        "SKIEN"
    ],
    [
        "3733",
        "SKIEN"
    ],
    [
        "3734",
        "SKIEN"
    ],
    [
        "3735",
        "SKIEN"
    ],
    [
        "3736",
        "SKIEN"
    ],
    [
        "3737",
        "SKIEN"
    ],
    [
        "3738",
        "SKIEN"
    ],
    [
        "3739",
        "SKIEN"
    ],
    [
        "3740",
        "SKIEN"
    ],
    [
        "3741",
        "SKIEN"
    ],
    [
        "3742",
        "SKIEN"
    ],
    [
        "3743",
        "SKIEN"
    ],
    [
        "3744",
        "SKIEN"
    ],
    [
        "3746",
        "SKIEN"
    ],
    [
        "3747",
        "SKIEN"
    ],
    [
        "3748",
        "SILJAN"
    ],
    [
        "3749",
        "SILJAN"
    ],
    [
        "3750",
        "DRANGEDAL"
    ],
    [
        "3753",
        "TØRDAL"
    ],
    [
        "3760",
        "NESLANDSVATN"
    ],
    [
        "3766",
        "SANNIDAL"
    ],
    [
        "3770",
        "KRAGERØ"
    ],
    [
        "3772",
        "KRAGERØ"
    ],
    [
        "3780",
        "SKÅTØY"
    ],
    [
        "3781",
        "JOMFRULAND"
    ],
    [
        "3783",
        "KRAGERØ SKJÆRGÅRD"
    ],
    [
        "3785",
        "SKIEN"
    ],
    [
        "3787",
        "SKIEN"
    ],
    [
        "3788",
        "STABBESTAD"
    ],
    [
        "3789",
        "KRAGERØ"
    ],
    [
        "3790",
        "HELLE"
    ],
    [
        "3791",
        "KRAGERØ"
    ],
    [
        "3792",
        "SKIEN"
    ],
    [
        "3793",
        "SANNIDAL"
    ],
    [
        "3794",
        "HELLE"
    ],
    [
        "3795",
        "DRANGEDAL"
    ],
    [
        "3796",
        "SKIEN"
    ],
    [
        "3798",
        "SKIEN"
    ],
    [
        "3799",
        "SKIEN"
    ],
    [
        "3800",
        "BØ I TELEMARK"
    ],
    [
        "3801",
        "BØ I TELEMARK"
    ],
    [
        "3802",
        "BØ I TELEMARK"
    ],
    [
        "3803",
        "BØ I TELEMARK"
    ],
    [
        "3804",
        "BØ I TELEMARK"
    ],
    [
        "3805",
        "BØ I TELEMARK"
    ],
    [
        "3810",
        "GVARV"
    ],
    [
        "3811",
        "HØRTE"
    ],
    [
        "3812",
        "AKKERHAUGEN"
    ],
    [
        "3820",
        "NORDAGUTU"
    ],
    [
        "3825",
        "LUNDE"
    ],
    [
        "3830",
        "ULEFOSS"
    ],
    [
        "3831",
        "ULEFOSS"
    ],
    [
        "3832",
        "LUNDE"
    ],
    [
        "3833",
        "BØ I TELEMARK"
    ],
    [
        "3834",
        "GVARV"
    ],
    [
        "3835",
        "SELJORD"
    ],
    [
        "3836",
        "KVITESEID"
    ],
    [
        "3840",
        "SELJORD"
    ],
    [
        "3841",
        "FLATDAL"
    ],
    [
        "3844",
        "ÅMOTSDAL"
    ],
    [
        "3848",
        "MORGEDAL"
    ],
    [
        "3849",
        "VRÅLIOSEN"
    ],
    [
        "3850",
        "KVITESEID"
    ],
    [
        "3852",
        "VRÅDAL"
    ],
    [
        "3853",
        "VRÅDAL"
    ],
    [
        "3854",
        "NISSEDAL"
    ],
    [
        "3855",
        "TREUNGEN"
    ],
    [
        "3864",
        "RAULAND"
    ],
    [
        "3870",
        "FYRESDAL"
    ],
    [
        "3880",
        "DALEN"
    ],
    [
        "3882",
        "ÅMDALS VERK"
    ],
    [
        "3883",
        "TREUNGEN"
    ],
    [
        "3884",
        "RAULAND"
    ],
    [
        "3885",
        "FYRESDAL"
    ],
    [
        "3886",
        "DALEN"
    ],
    [
        "3887",
        "VINJE"
    ],
    [
        "3888",
        "EDLAND"
    ],
    [
        "3890",
        "VINJE"
    ],
    [
        "3891",
        "HØYDALSMO"
    ],
    [
        "3893",
        "VINJESVINGEN"
    ],
    [
        "3895",
        "EDLAND"
    ],
    [
        "3901",
        "PORSGRUNN"
    ],
    [
        "3902",
        "PORSGRUNN"
    ],
    [
        "3903",
        "PORSGRUNN"
    ],
    [
        "3904",
        "PORSGRUNN"
    ],
    [
        "3905",
        "PORSGRUNN"
    ],
    [
        "3906",
        "PORSGRUNN"
    ],
    [
        "3910",
        "PORSGRUNN"
    ],
    [
        "3911",
        "PORSGRUNN"
    ],
    [
        "3912",
        "PORSGRUNN"
    ],
    [
        "3913",
        "PORSGRUNN"
    ],
    [
        "3914",
        "PORSGRUNN"
    ],
    [
        "3915",
        "PORSGRUNN"
    ],
    [
        "3916",
        "PORSGRUNN"
    ],
    [
        "3917",
        "PORSGRUNN"
    ],
    [
        "3918",
        "PORSGRUNN"
    ],
    [
        "3919",
        "PORSGRUNN"
    ],
    [
        "3920",
        "PORSGRUNN"
    ],
    [
        "3921",
        "PORSGRUNN"
    ],
    [
        "3922",
        "PORSGRUNN"
    ],
    [
        "3924",
        "PORSGRUNN"
    ],
    [
        "3925",
        "PORSGRUNN"
    ],
    [
        "3928",
        "PORSGRUNN"
    ],
    [
        "3929",
        "PORSGRUNN"
    ],
    [
        "3930",
        "PORSGRUNN"
    ],
    [
        "3931",
        "PORSGRUNN"
    ],
    [
        "3933",
        "PORSGRUNN"
    ],
    [
        "3936",
        "PORSGRUNN"
    ],
    [
        "3937",
        "PORSGRUNN"
    ],
    [
        "3939",
        "PORSGRUNN"
    ],
    [
        "3940",
        "PORSGRUNN"
    ],
    [
        "3941",
        "PORSGRUNN"
    ],
    [
        "3942",
        "PORSGRUNN"
    ],
    [
        "3943",
        "PORSGRUNN"
    ],
    [
        "3944",
        "PORSGRUNN"
    ],
    [
        "3946",
        "PORSGRUNN"
    ],
    [
        "3947",
        "LANGANGEN"
    ],
    [
        "3948",
        "PORSGRUNN"
    ],
    [
        "3949",
        "PORSGRUNN"
    ],
    [
        "3950",
        "BREVIK"
    ],
    [
        "3960",
        "STATHELLE"
    ],
    [
        "3961",
        "STATHELLE"
    ],
    [
        "3962",
        "STATHELLE"
    ],
    [
        "3965",
        "HERRE"
    ],
    [
        "3966",
        "STATHELLE"
    ],
    [
        "3967",
        "STATHELLE"
    ],
    [
        "3970",
        "LANGESUND"
    ],
    [
        "3991",
        "BREVIK"
    ],
    [
        "3993",
        "LANGESUND"
    ],
    [
        "3994",
        "LANGESUND"
    ],
    [
        "3995",
        "STATHELLE"
    ],
    [
        "3996",
        "PORSGRUNN"
    ],
    [
        "3997",
        "PORSGRUNN"
    ],
    [
        "3998",
        "PORSGRUNN"
    ],
    [
        "3999",
        "HERRE"
    ],
    [
        "4001",
        "STAVANGER"
    ],
    [
        "4002",
        "STAVANGER"
    ],
    [
        "4003",
        "STAVANGER"
    ],
    [
        "4004",
        "STAVANGER"
    ],
    [
        "4005",
        "STAVANGER"
    ],
    [
        "4006",
        "STAVANGER"
    ],
    [
        "4007",
        "STAVANGER"
    ],
    [
        "4008",
        "STAVANGER"
    ],
    [
        "4009",
        "STAVANGER"
    ],
    [
        "4010",
        "STAVANGER"
    ],
    [
        "4011",
        "STAVANGER"
    ],
    [
        "4012",
        "STAVANGER"
    ],
    [
        "4013",
        "STAVANGER"
    ],
    [
        "4014",
        "STAVANGER"
    ],
    [
        "4015",
        "STAVANGER"
    ],
    [
        "4016",
        "STAVANGER"
    ],
    [
        "4017",
        "STAVANGER"
    ],
    [
        "4018",
        "STAVANGER"
    ],
    [
        "4019",
        "STAVANGER"
    ],
    [
        "4020",
        "STAVANGER"
    ],
    [
        "4021",
        "STAVANGER"
    ],
    [
        "4022",
        "STAVANGER"
    ],
    [
        "4023",
        "STAVANGER"
    ],
    [
        "4024",
        "STAVANGER"
    ],
    [
        "4025",
        "STAVANGER"
    ],
    [
        "4026",
        "STAVANGER"
    ],
    [
        "4027",
        "STAVANGER"
    ],
    [
        "4028",
        "STAVANGER"
    ],
    [
        "4029",
        "STAVANGER"
    ],
    [
        "4031",
        "STAVANGER"
    ],
    [
        "4032",
        "STAVANGER"
    ],
    [
        "4033",
        "STAVANGER"
    ],
    [
        "4034",
        "STAVANGER"
    ],
    [
        "4035",
        "STAVANGER"
    ],
    [
        "4036",
        "STAVANGER"
    ],
    [
        "4041",
        "HAFRSFJORD"
    ],
    [
        "4042",
        "HAFRSFJORD"
    ],
    [
        "4043",
        "HAFRSFJORD"
    ],
    [
        "4044",
        "HAFRSFJORD"
    ],
    [
        "4045",
        "HAFRSFJORD"
    ],
    [
        "4046",
        "HAFRSFJORD"
    ],
    [
        "4047",
        "HAFRSFJORD"
    ],
    [
        "4048",
        "HAFRSFJORD"
    ],
    [
        "4049",
        "HAFRSFJORD"
    ],
    [
        "4050",
        "SOLA"
    ],
    [
        "4051",
        "SOLA"
    ],
    [
        "4052",
        "RØYNEBERG"
    ],
    [
        "4053",
        "RÆGE"
    ],
    [
        "4054",
        "TJELTA"
    ],
    [
        "4055",
        "SOLA"
    ],
    [
        "4056",
        "TANANGER"
    ],
    [
        "4057",
        "TANANGER"
    ],
    [
        "4058",
        "TANANGER"
    ],
    [
        "4059",
        "RØYNEBERG"
    ],
    [
        "4063",
        "TJELTA"
    ],
    [
        "4068",
        "STAVANGER"
    ],
    [
        "4070",
        "RANDABERG"
    ],
    [
        "4071",
        "RANDABERG"
    ],
    [
        "4072",
        "RANDABERG"
    ],
    [
        "4073",
        "RANDABERG"
    ],
    [
        "4076",
        "VASSØY"
    ],
    [
        "4077",
        "HUNDVÅG"
    ],
    [
        "4078",
        "STAVANGER"
    ],
    [
        "4079",
        "STAVANGER"
    ],
    [
        "4081",
        "STAVANGER"
    ],
    [
        "4082",
        "STAVANGER"
    ],
    [
        "4083",
        "HUNDVÅG"
    ],
    [
        "4084",
        "STAVANGER"
    ],
    [
        "4085",
        "HUNDVÅG"
    ],
    [
        "4086",
        "HUNDVÅG"
    ],
    [
        "4087",
        "STAVANGER"
    ],
    [
        "4088",
        "STAVANGER"
    ],
    [
        "4089",
        "HAFRSFJORD"
    ],
    [
        "4090",
        "HAFRSFJORD"
    ],
    [
        "4091",
        "HAFRSFJORD"
    ],
    [
        "4092",
        "STAVANGER"
    ],
    [
        "4093",
        "STAVANGER"
    ],
    [
        "4094",
        "STAVANGER"
    ],
    [
        "4095",
        "STAVANGER"
    ],
    [
        "4096",
        "RANDABERG"
    ],
    [
        "4097",
        "SOLA"
    ],
    [
        "4098",
        "TANANGER"
    ],
    [
        "4099",
        "STAVANGER"
    ],
    [
        "4100",
        "JØRPELAND"
    ],
    [
        "4102",
        "IDSE"
    ],
    [
        "4103",
        "JØRPELAND"
    ],
    [
        "4104",
        "JØRPELAND"
    ],
    [
        "4105",
        "JØRPELAND"
    ],
    [
        "4110",
        "FORSAND"
    ],
    [
        "4119",
        "FORSAND"
    ],
    [
        "4120",
        "TAU"
    ],
    [
        "4121",
        "TAU"
    ],
    [
        "4123",
        "SØR-HIDLE"
    ],
    [
        "4124",
        "TAU"
    ],
    [
        "4126",
        "JØRPELAND"
    ],
    [
        "4127",
        "LYSEBOTN"
    ],
    [
        "4128",
        "FLØYRLI"
    ],
    [
        "4129",
        "SONGESAND"
    ],
    [
        "4130",
        "HJELMELAND"
    ],
    [
        "4134",
        "JØSENFJORDEN"
    ],
    [
        "4137",
        "ÅRDAL I RYFYLKE"
    ],
    [
        "4139",
        "FISTER"
    ],
    [
        "4146",
        "SKIFTUN"
    ],
    [
        "4148",
        "HJELMELAND"
    ],
    [
        "4150",
        "RENNESØY"
    ],
    [
        "4152",
        "VESTRE ÅMØY"
    ],
    [
        "4153",
        "BRIMSE"
    ],
    [
        "4154",
        "AUSTRE ÅMØY"
    ],
    [
        "4156",
        "MOSTERØY"
    ],
    [
        "4158",
        "BRU"
    ],
    [
        "4159",
        "RENNESØY"
    ],
    [
        "4160",
        "FINNØY"
    ],
    [
        "4161",
        "FINNØY"
    ],
    [
        "4163",
        "TALGJE"
    ],
    [
        "4164",
        "FOGN"
    ],
    [
        "4167",
        "HELGØY I RYFYLKE"
    ],
    [
        "4168",
        "BYRE"
    ],
    [
        "4169",
        "SØRBOKN"
    ],
    [
        "4170",
        "SJERNARØY"
    ],
    [
        "4173",
        "NORD-HIDLE"
    ],
    [
        "4174",
        "SJERNARØY"
    ],
    [
        "4180",
        "KVITSØY"
    ],
    [
        "4181",
        "KVITSØY"
    ],
    [
        "4182",
        "SKARTVEIT"
    ],
    [
        "4187",
        "OMBO"
    ],
    [
        "4198",
        "FOLDØY"
    ],
    [
        "4200",
        "SAUDA"
    ],
    [
        "4201",
        "SAUDA"
    ],
    [
        "4208",
        "SAUDASJØEN"
    ],
    [
        "4209",
        "VANVIK"
    ],
    [
        "4230",
        "SAND"
    ],
    [
        "4233",
        "ERFJORD"
    ],
    [
        "4234",
        "JELSA"
    ],
    [
        "4235",
        "HEBNES"
    ],
    [
        "4237",
        "SULDALSOSEN"
    ],
    [
        "4239",
        "SAND"
    ],
    [
        "4240",
        "SULDALSOSEN"
    ],
    [
        "4244",
        "NESFLATEN"
    ],
    [
        "4250",
        "KOPERVIK"
    ],
    [
        "4260",
        "TORVASTAD"
    ],
    [
        "4262",
        "AVALDSNES"
    ],
    [
        "4264",
        "KVALAVÅG"
    ],
    [
        "4265",
        "HÅVIK"
    ],
    [
        "4270",
        "ÅKREHAMN"
    ],
    [
        "4272",
        "SANDVE"
    ],
    [
        "4274",
        "STOL"
    ],
    [
        "4275",
        "SÆVELANDSVIK"
    ],
    [
        "4276",
        "VEAVÅGEN"
    ],
    [
        "4280",
        "SKUDENESHAVN"
    ],
    [
        "4291",
        "KOPERVIK"
    ],
    [
        "4294",
        "KOPERVIK"
    ],
    [
        "4295",
        "VEAVÅGEN"
    ],
    [
        "4296",
        "ÅKREHAMN"
    ],
    [
        "4297",
        "SKUDENESHAVN"
    ],
    [
        "4298",
        "TORVASTAD"
    ],
    [
        "4299",
        "AVALDSNES"
    ],
    [
        "4301",
        "SANDNES"
    ],
    [
        "4302",
        "SANDNES"
    ],
    [
        "4306",
        "SANDNES"
    ],
    [
        "4307",
        "SANDNES"
    ],
    [
        "4308",
        "SANDNES"
    ],
    [
        "4309",
        "SANDNES"
    ],
    [
        "4310",
        "HOMMERSÅK"
    ],
    [
        "4311",
        "HOMMERSÅK"
    ],
    [
        "4312",
        "SANDNES"
    ],
    [
        "4313",
        "SANDNES"
    ],
    [
        "4314",
        "SANDNES"
    ],
    [
        "4315",
        "SANDNES"
    ],
    [
        "4316",
        "SANDNES"
    ],
    [
        "4317",
        "SANDNES"
    ],
    [
        "4318",
        "SANDNES"
    ],
    [
        "4319",
        "SANDNES"
    ],
    [
        "4320",
        "SANDNES"
    ],
    [
        "4321",
        "SANDNES"
    ],
    [
        "4322",
        "SANDNES"
    ],
    [
        "4323",
        "SANDNES"
    ],
    [
        "4324",
        "SANDNES"
    ],
    [
        "4325",
        "SANDNES"
    ],
    [
        "4326",
        "SANDNES"
    ],
    [
        "4327",
        "SANDNES"
    ],
    [
        "4328",
        "SANDNES"
    ],
    [
        "4329",
        "SANDNES"
    ],
    [
        "4330",
        "ÅLGÅRD"
    ],
    [
        "4331",
        "ÅLGÅRD"
    ],
    [
        "4332",
        "FIGGJO"
    ],
    [
        "4333",
        "OLTEDAL"
    ],
    [
        "4334",
        "ÅLGÅRD"
    ],
    [
        "4335",
        "DIRDAL"
    ],
    [
        "4336",
        "SANDNES"
    ],
    [
        "4337",
        "SANDNES"
    ],
    [
        "4338",
        "SANDNES"
    ],
    [
        "4339",
        "ÅLGÅRD"
    ],
    [
        "4340",
        "BRYNE"
    ],
    [
        "4341",
        "BRYNE"
    ],
    [
        "4342",
        "UNDHEIM"
    ],
    [
        "4343",
        "ORRE"
    ],
    [
        "4344",
        "BRYNE"
    ],
    [
        "4345",
        "BRYNE"
    ],
    [
        "4346",
        "BRYNE"
    ],
    [
        "4347",
        "LYE"
    ],
    [
        "4348",
        "LYE"
    ],
    [
        "4349",
        "BRYNE"
    ],
    [
        "4350",
        "KLEPPE"
    ],
    [
        "4351",
        "KLEPPE"
    ],
    [
        "4352",
        "KLEPPE"
    ],
    [
        "4353",
        "KLEPP STASJON"
    ],
    [
        "4354",
        "VOLL"
    ],
    [
        "4355",
        "KVERNALAND"
    ],
    [
        "4356",
        "KVERNALAND"
    ],
    [
        "4357",
        "KLEPP STASJON"
    ],
    [
        "4358",
        "KLEPPE"
    ],
    [
        "4360",
        "VARHAUG"
    ],
    [
        "4361",
        "SIREVÅG"
    ],
    [
        "4362",
        "VIGRESTAD"
    ],
    [
        "4363",
        "BRUSAND"
    ],
    [
        "4364",
        "SIREVÅG"
    ],
    [
        "4365",
        "NÆRBØ"
    ],
    [
        "4367",
        "NÆRBØ"
    ],
    [
        "4368",
        "VARHAUG"
    ],
    [
        "4369",
        "VIGRESTAD"
    ],
    [
        "4370",
        "EGERSUND"
    ],
    [
        "4371",
        "EGERSUND"
    ],
    [
        "4372",
        "EGERSUND"
    ],
    [
        "4373",
        "EGERSUND"
    ],
    [
        "4374",
        "EGERSUND"
    ],
    [
        "4375",
        "HELLVIK"
    ],
    [
        "4376",
        "HELLELAND"
    ],
    [
        "4378",
        "EGERSUND"
    ],
    [
        "4379",
        "EGERSUND"
    ],
    [
        "4380",
        "HAUGE I DALANE"
    ],
    [
        "4381",
        "HAUGE I DALANE"
    ],
    [
        "4384",
        "VIKESÅ"
    ],
    [
        "4385",
        "HELLELAND"
    ],
    [
        "4387",
        "BJERKREIM"
    ],
    [
        "4389",
        "VIKESÅ"
    ],
    [
        "4390",
        "OLTEDAL"
    ],
    [
        "4391",
        "SANDNES"
    ],
    [
        "4392",
        "SANDNES"
    ],
    [
        "4393",
        "SANDNES"
    ],
    [
        "4394",
        "SANDNES"
    ],
    [
        "4395",
        "HOMMERSÅK"
    ],
    [
        "4396",
        "SANDNES"
    ],
    [
        "4397",
        "SANDNES"
    ],
    [
        "4398",
        "SANDNES"
    ],
    [
        "4399",
        "SANDNES"
    ],
    [
        "4400",
        "FLEKKEFJORD"
    ],
    [
        "4401",
        "FLEKKEFJORD"
    ],
    [
        "4402",
        "FLEKKEFJORD"
    ],
    [
        "4403",
        "FLEKKEFJORD"
    ],
    [
        "4404",
        "FLEKKEFJORD"
    ],
    [
        "4405",
        "FLEKKEFJORD"
    ],
    [
        "4406",
        "FLEKKEFJORD"
    ],
    [
        "4407",
        "FLEKKEFJORD"
    ],
    [
        "4420",
        "ÅNA-SIRA"
    ],
    [
        "4432",
        "HIDRASUND"
    ],
    [
        "4434",
        "ANDABELØY"
    ],
    [
        "4436",
        "GYLAND"
    ],
    [
        "4438",
        "SIRA"
    ],
    [
        "4439",
        "SIRA"
    ],
    [
        "4440",
        "TONSTAD"
    ],
    [
        "4441",
        "TONSTAD"
    ],
    [
        "4443",
        "TJØRHOM"
    ],
    [
        "4460",
        "MOI"
    ],
    [
        "4462",
        "HOVSHERAD"
    ],
    [
        "4463",
        "UALAND"
    ],
    [
        "4465",
        "MOI"
    ],
    [
        "4473",
        "KVINLOG"
    ],
    [
        "4480",
        "KVINESDAL"
    ],
    [
        "4484",
        "ØYESTRANDA"
    ],
    [
        "4485",
        "FEDA"
    ],
    [
        "4486",
        "FEDA"
    ],
    [
        "4490",
        "KVINESDAL"
    ],
    [
        "4491",
        "KVINESDAL"
    ],
    [
        "4492",
        "KVINESDAL"
    ],
    [
        "4501",
        "MANDAL"
    ],
    [
        "4502",
        "MANDAL"
    ],
    [
        "4503",
        "MANDAL"
    ],
    [
        "4504",
        "MANDAL"
    ],
    [
        "4507",
        "MANDAL"
    ],
    [
        "4508",
        "MANDAL"
    ],
    [
        "4509",
        "MANDAL"
    ],
    [
        "4513",
        "MANDAL"
    ],
    [
        "4514",
        "MANDAL"
    ],
    [
        "4515",
        "MANDAL"
    ],
    [
        "4516",
        "MANDAL"
    ],
    [
        "4517",
        "MANDAL"
    ],
    [
        "4519",
        "HOLUM"
    ],
    [
        "4520",
        "LINDESNES"
    ],
    [
        "4521",
        "LINDESNES"
    ],
    [
        "4522",
        "LINDESNES"
    ],
    [
        "4523",
        "LINDESNES"
    ],
    [
        "4524",
        "LINDESNES"
    ],
    [
        "4525",
        "KONSMO"
    ],
    [
        "4526",
        "KONSMO"
    ],
    [
        "4528",
        "KOLLUNGTVEIT"
    ],
    [
        "4529",
        "BYREMO"
    ],
    [
        "4532",
        "ØYSLEBØ"
    ],
    [
        "4534",
        "MARNARDAL"
    ],
    [
        "4535",
        "MARNARDAL"
    ],
    [
        "4536",
        "BJELLAND"
    ],
    [
        "4540",
        "ÅSERAL"
    ],
    [
        "4541",
        "ÅSERAL"
    ],
    [
        "4544",
        "FOSSDAL"
    ],
    [
        "4550",
        "FARSUND"
    ],
    [
        "4551",
        "FARSUND"
    ],
    [
        "4552",
        "FARSUND"
    ],
    [
        "4553",
        "FARSUND"
    ],
    [
        "4554",
        "FARSUND"
    ],
    [
        "4557",
        "VANSE"
    ],
    [
        "4558",
        "VANSE"
    ],
    [
        "4560",
        "VANSE"
    ],
    [
        "4563",
        "BORHAUG"
    ],
    [
        "4575",
        "LYNGDAL"
    ],
    [
        "4576",
        "LYNGDAL"
    ],
    [
        "4577",
        "LYNGDAL"
    ],
    [
        "4579",
        "LYNGDAL"
    ],
    [
        "4580",
        "LYNGDAL"
    ],
    [
        "4586",
        "KORSHAMN"
    ],
    [
        "4588",
        "KVÅS"
    ],
    [
        "4590",
        "SNARTEMO"
    ],
    [
        "4595",
        "TINGVATN"
    ],
    [
        "4596",
        "EIKEN"
    ],
    [
        "4597",
        "EIKEN"
    ],
    [
        "4604",
        "KRISTIANSAND S"
    ],
    [
        "4605",
        "KRISTIANSAND S"
    ],
    [
        "4606",
        "KRISTIANSAND S"
    ],
    [
        "4608",
        "KRISTIANSAND S"
    ],
    [
        "4609",
        "KARDEMOMME BY"
    ],
    [
        "4610",
        "KRISTIANSAND S"
    ],
    [
        "4611",
        "KRISTIANSAND S"
    ],
    [
        "4612",
        "KRISTIANSAND S"
    ],
    [
        "4613",
        "KRISTIANSAND S"
    ],
    [
        "4614",
        "KRISTIANSAND S"
    ],
    [
        "4615",
        "KRISTIANSAND S"
    ],
    [
        "4616",
        "KRISTIANSAND S"
    ],
    [
        "4617",
        "KRISTIANSAND S"
    ],
    [
        "4618",
        "KRISTIANSAND S"
    ],
    [
        "4619",
        "MOSBY"
    ],
    [
        "4620",
        "KRISTIANSAND S"
    ],
    [
        "4621",
        "KRISTIANSAND S"
    ],
    [
        "4622",
        "KRISTIANSAND S"
    ],
    [
        "4623",
        "KRISTIANSAND S"
    ],
    [
        "4624",
        "KRISTIANSAND S"
    ],
    [
        "4625",
        "FLEKKERØY"
    ],
    [
        "4626",
        "KRISTIANSAND S"
    ],
    [
        "4628",
        "KRISTIANSAND S"
    ],
    [
        "4629",
        "KRISTIANSAND S"
    ],
    [
        "4630",
        "KRISTIANSAND S"
    ],
    [
        "4631",
        "KRISTIANSAND S"
    ],
    [
        "4632",
        "KRISTIANSAND S"
    ],
    [
        "4633",
        "KRISTIANSAND S"
    ],
    [
        "4634",
        "KRISTIANSAND S"
    ],
    [
        "4635",
        "KRISTIANSAND S"
    ],
    [
        "4636",
        "KRISTIANSAND S"
    ],
    [
        "4637",
        "KRISTIANSAND S"
    ],
    [
        "4638",
        "KRISTIANSAND S"
    ],
    [
        "4639",
        "KRISTIANSAND S"
    ],
    [
        "4640",
        "SØGNE"
    ],
    [
        "4641",
        "SØGNE"
    ],
    [
        "4642",
        "SØGNE"
    ],
    [
        "4643",
        "SØGNE"
    ],
    [
        "4644",
        "SØGNE"
    ],
    [
        "4645",
        "NODELAND"
    ],
    [
        "4646",
        "FINSLAND"
    ],
    [
        "4647",
        "BRENNÅSEN"
    ],
    [
        "4649",
        "FINSLAND"
    ],
    [
        "4656",
        "HAMRESANDEN"
    ],
    [
        "4657",
        "KJEVIK"
    ],
    [
        "4658",
        "TVEIT"
    ],
    [
        "4661",
        "KRISTIANSAND S"
    ],
    [
        "4662",
        "KRISTIANSAND S"
    ],
    [
        "4663",
        "KRISTIANSAND S"
    ],
    [
        "4664",
        "KRISTIANSAND S"
    ],
    [
        "4665",
        "KRISTIANSAND S"
    ],
    [
        "4666",
        "KRISTIANSAND S"
    ],
    [
        "4670",
        "KRISTIANSAND S"
    ],
    [
        "4671",
        "KRISTIANSAND S"
    ],
    [
        "4672",
        "KRISTIANSAND S"
    ],
    [
        "4673",
        "KRISTIANSAND S"
    ],
    [
        "4674",
        "KRISTIANSAND S"
    ],
    [
        "4675",
        "KRISTIANSAND S"
    ],
    [
        "4676",
        "KRISTIANSAND S"
    ],
    [
        "4677",
        "KRISTIANSAND S"
    ],
    [
        "4678",
        "KRISTIANSAND S"
    ],
    [
        "4679",
        "FLEKKERØY"
    ],
    [
        "4681",
        "SØGNE"
    ],
    [
        "4682",
        "SØGNE"
    ],
    [
        "4683",
        "SØGNE"
    ],
    [
        "4684",
        "BRENNÅSEN"
    ],
    [
        "4685",
        "NODELAND"
    ],
    [
        "4686",
        "KRISTIANSAND S"
    ],
    [
        "4687",
        "KRISTIANSAND S"
    ],
    [
        "4688",
        "KRISTIANSAND S"
    ],
    [
        "4689",
        "KRISTIANSAND S"
    ],
    [
        "4691",
        "KRISTIANSAND S"
    ],
    [
        "4693",
        "KRISTIANSAND S"
    ],
    [
        "4694",
        "KRISTIANSAND S"
    ],
    [
        "4695",
        "KRISTIANSAND S"
    ],
    [
        "4696",
        "KRISTIANSAND S"
    ],
    [
        "4697",
        "KRISTIANSAND S"
    ],
    [
        "4698",
        "KRISTIANSAND S"
    ],
    [
        "4699",
        "TVEIT"
    ],
    [
        "4700",
        "VENNESLA"
    ],
    [
        "4701",
        "VENNESLA"
    ],
    [
        "4702",
        "VENNESLA"
    ],
    [
        "4703",
        "VENNESLA"
    ],
    [
        "4705",
        "ØVREBØ"
    ],
    [
        "4706",
        "VENNESLA"
    ],
    [
        "4707",
        "VENNESLA"
    ],
    [
        "4708",
        "VENNESLA"
    ],
    [
        "4715",
        "ØVREBØ"
    ],
    [
        "4720",
        "HÆGELAND"
    ],
    [
        "4721",
        "HÆGELAND"
    ],
    [
        "4724",
        "IVELAND"
    ],
    [
        "4725",
        "IVELAND"
    ],
    [
        "4730",
        "VATNESTRØM"
    ],
    [
        "4733",
        "EVJE"
    ],
    [
        "4734",
        "EVJE"
    ],
    [
        "4735",
        "EVJE"
    ],
    [
        "4737",
        "HORNNES"
    ],
    [
        "4741",
        "BYGLANDSFJORD"
    ],
    [
        "4742",
        "GRENDI"
    ],
    [
        "4744",
        "BYGLAND"
    ],
    [
        "4745",
        "BYGLAND"
    ],
    [
        "4746",
        "VALLE"
    ],
    [
        "4747",
        "VALLE"
    ],
    [
        "4748",
        "RYSSTAD"
    ],
    [
        "4749",
        "RYSSTAD"
    ],
    [
        "4754",
        "BYKLE"
    ],
    [
        "4755",
        "HOVDEN I SETESDAL"
    ],
    [
        "4756",
        "HOVDEN I SETESDAL"
    ],
    [
        "4760",
        "BIRKELAND"
    ],
    [
        "4766",
        "HEREFOSS"
    ],
    [
        "4768",
        "ENGESLAND"
    ],
    [
        "4770",
        "HØVÅG"
    ],
    [
        "4780",
        "BREKKESTØ"
    ],
    [
        "4790",
        "LILLESAND"
    ],
    [
        "4791",
        "LILLESAND"
    ],
    [
        "4792",
        "LILLESAND"
    ],
    [
        "4793",
        "HØVÅG"
    ],
    [
        "4794",
        "LILLESAND"
    ],
    [
        "4795",
        "BIRKELAND"
    ],
    [
        "4801",
        "ARENDAL"
    ],
    [
        "4802",
        "ARENDAL"
    ],
    [
        "4803",
        "ARENDAL"
    ],
    [
        "4804",
        "ARENDAL"
    ],
    [
        "4808",
        "ARENDAL"
    ],
    [
        "4809",
        "ARENDAL"
    ],
    [
        "4810",
        "EYDEHAVN"
    ],
    [
        "4812",
        "KONGSHAVN"
    ],
    [
        "4815",
        "SALTRØD"
    ],
    [
        "4816",
        "KOLBJØRNSVIK"
    ],
    [
        "4817",
        "HIS"
    ],
    [
        "4818",
        "FÆRVIK"
    ],
    [
        "4820",
        "FROLAND"
    ],
    [
        "4821",
        "RYKENE"
    ],
    [
        "4822",
        "RYKENE"
    ],
    [
        "4823",
        "NEDENES"
    ],
    [
        "4824",
        "BJORBEKK"
    ],
    [
        "4825",
        "ARENDAL"
    ],
    [
        "4827",
        "FROLANDS VERK"
    ],
    [
        "4828",
        "MJÅVATN"
    ],
    [
        "4830",
        "HYNNEKLEIV"
    ],
    [
        "4832",
        "MYKLAND"
    ],
    [
        "4834",
        "RISDAL"
    ],
    [
        "4836",
        "ARENDAL"
    ],
    [
        "4838",
        "ARENDAL"
    ],
    [
        "4839",
        "ARENDAL"
    ],
    [
        "4841",
        "ARENDAL"
    ],
    [
        "4842",
        "ARENDAL"
    ],
    [
        "4843",
        "ARENDAL"
    ],
    [
        "4844",
        "ARENDAL"
    ],
    [
        "4846",
        "ARENDAL"
    ],
    [
        "4847",
        "ARENDAL"
    ],
    [
        "4848",
        "ARENDAL"
    ],
    [
        "4849",
        "ARENDAL"
    ],
    [
        "4851",
        "SALTRØD"
    ],
    [
        "4852",
        "FÆRVIK"
    ],
    [
        "4853",
        "HIS"
    ],
    [
        "4854",
        "NEDENES"
    ],
    [
        "4855",
        "FROLAND"
    ],
    [
        "4856",
        "ARENDAL"
    ],
    [
        "4857",
        "ARENDAL"
    ],
    [
        "4858",
        "ARENDAL"
    ],
    [
        "4859",
        "ARENDAL"
    ],
    [
        "4862",
        "EYDEHAVN"
    ],
    [
        "4863",
        "NELAUG"
    ],
    [
        "4864",
        "ÅMLI"
    ],
    [
        "4865",
        "ÅMLI"
    ],
    [
        "4868",
        "SELÅSVATN"
    ],
    [
        "4869",
        "DØLEMO"
    ],
    [
        "4870",
        "FEVIK"
    ],
    [
        "4876",
        "GRIMSTAD"
    ],
    [
        "4877",
        "GRIMSTAD"
    ],
    [
        "4878",
        "GRIMSTAD"
    ],
    [
        "4879",
        "GRIMSTAD"
    ],
    [
        "4884",
        "GRIMSTAD"
    ],
    [
        "4885",
        "GRIMSTAD"
    ],
    [
        "4886",
        "GRIMSTAD"
    ],
    [
        "4887",
        "GRIMSTAD"
    ],
    [
        "4888",
        "HOMBORSUND"
    ],
    [
        "4889",
        "FEVIK"
    ],
    [
        "4891",
        "GRIMSTAD"
    ],
    [
        "4892",
        "GRIMSTAD"
    ],
    [
        "4893",
        "GRIMSTAD"
    ],
    [
        "4894",
        "GRIMSTAD"
    ],
    [
        "4896",
        "GRIMSTAD"
    ],
    [
        "4898",
        "GRIMSTAD"
    ],
    [
        "4900",
        "TVEDESTRAND"
    ],
    [
        "4901",
        "TVEDESTRAND"
    ],
    [
        "4902",
        "TVEDESTRAND"
    ],
    [
        "4903",
        "TVEDESTRAND"
    ],
    [
        "4904",
        "TVEDESTRAND"
    ],
    [
        "4905",
        "TVEDESTRAND"
    ],
    [
        "4909",
        "SONGE"
    ],
    [
        "4910",
        "LYNGØR"
    ],
    [
        "4912",
        "GJEVING"
    ],
    [
        "4915",
        "VESTRE SANDØYA"
    ],
    [
        "4916",
        "BORØY"
    ],
    [
        "4920",
        "STAUBØ"
    ],
    [
        "4921",
        "STAUBØ"
    ],
    [
        "4934",
        "NES VERK"
    ],
    [
        "4950",
        "RISØR"
    ],
    [
        "4951",
        "RISØR"
    ],
    [
        "4952",
        "RISØR"
    ],
    [
        "4953",
        "RISØR"
    ],
    [
        "4955",
        "RISØR"
    ],
    [
        "4956",
        "RISØR"
    ],
    [
        "4957",
        "RISØR"
    ],
    [
        "4971",
        "SUNDEBRU"
    ],
    [
        "4972",
        "GJERSTAD"
    ],
    [
        "4973",
        "VEGÅRSHEI"
    ],
    [
        "4974",
        "SØNDELED"
    ],
    [
        "4980",
        "GJERSTAD"
    ],
    [
        "4985",
        "VEGÅRSHEI"
    ],
    [
        "4990",
        "SØNDELED"
    ],
    [
        "4993",
        "SUNDEBRU"
    ],
    [
        "4994",
        "AKLAND"
    ],
    [
        "5003",
        "BERGEN"
    ],
    [
        "5004",
        "BERGEN"
    ],
    [
        "5005",
        "BERGEN"
    ],
    [
        "5006",
        "BERGEN"
    ],
    [
        "5007",
        "BERGEN"
    ],
    [
        "5008",
        "BERGEN"
    ],
    [
        "5009",
        "BERGEN"
    ],
    [
        "5010",
        "BERGEN"
    ],
    [
        "5011",
        "BERGEN"
    ],
    [
        "5012",
        "BERGEN"
    ],
    [
        "5013",
        "BERGEN"
    ],
    [
        "5014",
        "BERGEN"
    ],
    [
        "5015",
        "BERGEN"
    ],
    [
        "5016",
        "BERGEN"
    ],
    [
        "5017",
        "BERGEN"
    ],
    [
        "5018",
        "BERGEN"
    ],
    [
        "5019",
        "BERGEN"
    ],
    [
        "5020",
        "BERGEN"
    ],
    [
        "5021",
        "BERGEN"
    ],
    [
        "5022",
        "BERGEN"
    ],
    [
        "5031",
        "BERGEN"
    ],
    [
        "5032",
        "BERGEN"
    ],
    [
        "5033",
        "BERGEN"
    ],
    [
        "5034",
        "BERGEN"
    ],
    [
        "5035",
        "BERGEN"
    ],
    [
        "5036",
        "BERGEN"
    ],
    [
        "5037",
        "BERGEN"
    ],
    [
        "5038",
        "BERGEN"
    ],
    [
        "5039",
        "BERGEN"
    ],
    [
        "5041",
        "BERGEN"
    ],
    [
        "5042",
        "BERGEN"
    ],
    [
        "5043",
        "BERGEN"
    ],
    [
        "5045",
        "BERGEN"
    ],
    [
        "5052",
        "BERGEN"
    ],
    [
        "5053",
        "BERGEN"
    ],
    [
        "5054",
        "BERGEN"
    ],
    [
        "5055",
        "BERGEN"
    ],
    [
        "5056",
        "BERGEN"
    ],
    [
        "5057",
        "BERGEN"
    ],
    [
        "5058",
        "BERGEN"
    ],
    [
        "5059",
        "BERGEN"
    ],
    [
        "5063",
        "BERGEN"
    ],
    [
        "5067",
        "BERGEN"
    ],
    [
        "5068",
        "BERGEN"
    ],
    [
        "5072",
        "BERGEN"
    ],
    [
        "5073",
        "BERGEN"
    ],
    [
        "5075",
        "BERGEN"
    ],
    [
        "5081",
        "BERGEN"
    ],
    [
        "5082",
        "BERGEN"
    ],
    [
        "5089",
        "BERGEN"
    ],
    [
        "5093",
        "BERGEN"
    ],
    [
        "5094",
        "BERGEN"
    ],
    [
        "5096",
        "BERGEN"
    ],
    [
        "5097",
        "BERGEN"
    ],
    [
        "5098",
        "BERGEN"
    ],
    [
        "5099",
        "BERGEN"
    ],
    [
        "5101",
        "EIDSVÅGNESET"
    ],
    [
        "5104",
        "EIDSVÅG I ÅSANE"
    ],
    [
        "5105",
        "EIDSVÅG I ÅSANE"
    ],
    [
        "5106",
        "ØVRE ERVIK"
    ],
    [
        "5107",
        "SALHUS"
    ],
    [
        "5108",
        "HORDVIK"
    ],
    [
        "5109",
        "HYLKJE"
    ],
    [
        "5111",
        "BREISTEIN"
    ],
    [
        "5113",
        "TERTNES"
    ],
    [
        "5114",
        "TERTNES"
    ],
    [
        "5115",
        "ULSET"
    ],
    [
        "5116",
        "ULSET"
    ],
    [
        "5117",
        "ULSET"
    ],
    [
        "5118",
        "ULSET"
    ],
    [
        "5119",
        "ULSET"
    ],
    [
        "5121",
        "ULSET"
    ],
    [
        "5122",
        "MORVIK"
    ],
    [
        "5124",
        "MORVIK"
    ],
    [
        "5130",
        "NYBORG"
    ],
    [
        "5131",
        "NYBORG"
    ],
    [
        "5132",
        "NYBORG"
    ],
    [
        "5134",
        "FLAKTVEIT"
    ],
    [
        "5135",
        "FLAKTVEIT"
    ],
    [
        "5136",
        "MJØLKERÅEN"
    ],
    [
        "5137",
        "MJØLKERÅEN"
    ],
    [
        "5141",
        "FYLLINGSDALEN"
    ],
    [
        "5142",
        "FYLLINGSDALEN"
    ],
    [
        "5143",
        "FYLLINGSDALEN"
    ],
    [
        "5144",
        "FYLLINGSDALEN"
    ],
    [
        "5145",
        "FYLLINGSDALEN"
    ],
    [
        "5146",
        "FYLLINGSDALEN"
    ],
    [
        "5147",
        "FYLLINGSDALEN"
    ],
    [
        "5148",
        "FYLLINGSDALEN"
    ],
    [
        "5151",
        "STRAUMSGREND"
    ],
    [
        "5152",
        "BØNES"
    ],
    [
        "5153",
        "BØNES"
    ],
    [
        "5154",
        "BØNES"
    ],
    [
        "5155",
        "BØNES"
    ],
    [
        "5160",
        "LAKSEVÅG"
    ],
    [
        "5161",
        "LAKSEVÅG"
    ],
    [
        "5162",
        "LAKSEVÅG"
    ],
    [
        "5163",
        "LAKSEVÅG"
    ],
    [
        "5164",
        "LAKSEVÅG"
    ],
    [
        "5165",
        "LAKSEVÅG"
    ],
    [
        "5170",
        "BJØRNDALSTRÆ"
    ],
    [
        "5171",
        "LODDEFJORD"
    ],
    [
        "5172",
        "LODDEFJORD"
    ],
    [
        "5173",
        "LODDEFJORD"
    ],
    [
        "5174",
        "MATHOPEN"
    ],
    [
        "5176",
        "LODDEFJORD"
    ],
    [
        "5177",
        "BJØRØYHAMN"
    ],
    [
        "5178",
        "LODDEFJORD"
    ],
    [
        "5179",
        "GODVIK"
    ],
    [
        "5183",
        "OLSVIK"
    ],
    [
        "5184",
        "OLSVIK"
    ],
    [
        "5200",
        "OS"
    ],
    [
        "5201",
        "OS"
    ],
    [
        "5202",
        "OS"
    ],
    [
        "5203",
        "OS"
    ],
    [
        "5206",
        "OS"
    ],
    [
        "5207",
        "SØFTELAND"
    ],
    [
        "5208",
        "OS"
    ],
    [
        "5209",
        "OS"
    ],
    [
        "5210",
        "OS"
    ],
    [
        "5211",
        "OS"
    ],
    [
        "5212",
        "SØFTELAND"
    ],
    [
        "5213",
        "LEPSØY"
    ],
    [
        "5214",
        "LYSEKLOSTER"
    ],
    [
        "5215",
        "LYSEKLOSTER"
    ],
    [
        "5216",
        "LEPSØY"
    ],
    [
        "5217",
        "HAGAVIK"
    ],
    [
        "5218",
        "NORDSTRØNO"
    ],
    [
        "5221",
        "NESTTUN"
    ],
    [
        "5222",
        "NESTTUN"
    ],
    [
        "5223",
        "NESTTUN"
    ],
    [
        "5224",
        "NESTTUN"
    ],
    [
        "5225",
        "NESTTUN"
    ],
    [
        "5226",
        "NESTTUN"
    ],
    [
        "5227",
        "NESTTUN"
    ],
    [
        "5228",
        "NESTTUN"
    ],
    [
        "5229",
        "KALANDSEIDET"
    ],
    [
        "5230",
        "PARADIS"
    ],
    [
        "5231",
        "PARADIS"
    ],
    [
        "5232",
        "PARADIS"
    ],
    [
        "5235",
        "RÅDAL"
    ],
    [
        "5236",
        "RÅDAL"
    ],
    [
        "5237",
        "RÅDAL"
    ],
    [
        "5238",
        "RÅDAL"
    ],
    [
        "5239",
        "RÅDAL"
    ],
    [
        "5243",
        "FANA"
    ],
    [
        "5244",
        "FANA"
    ],
    [
        "5251",
        "SØREIDGREND"
    ],
    [
        "5252",
        "SØREIDGREND"
    ],
    [
        "5253",
        "SANDSLI"
    ],
    [
        "5254",
        "SANDSLI"
    ],
    [
        "5257",
        "KOKSTAD"
    ],
    [
        "5258",
        "BLOMSTERDALEN"
    ],
    [
        "5259",
        "HJELLESTAD"
    ],
    [
        "5260",
        "INDRE ARNA"
    ],
    [
        "5261",
        "INDRE ARNA"
    ],
    [
        "5262",
        "ARNATVEIT"
    ],
    [
        "5263",
        "TRENGEREID"
    ],
    [
        "5264",
        "GARNES"
    ],
    [
        "5265",
        "YTRE ARNA"
    ],
    [
        "5267",
        "ESPELAND"
    ],
    [
        "5268",
        "HAUKELAND"
    ],
    [
        "5281",
        "VALESTRANDSFOSSEN"
    ],
    [
        "5282",
        "LONEVÅG"
    ],
    [
        "5283",
        "FOTLANDSVÅG"
    ],
    [
        "5284",
        "TYSSEBOTNEN"
    ],
    [
        "5285",
        "BRUVIK"
    ],
    [
        "5286",
        "HAUS"
    ],
    [
        "5291",
        "VALESTRANDSFOSSEN"
    ],
    [
        "5293",
        "LONEVÅG"
    ],
    [
        "5299",
        "HAUS"
    ],
    [
        "5300",
        "KLEPPESTØ"
    ],
    [
        "5301",
        "KLEPPESTØ"
    ],
    [
        "5302",
        "STRUSSHAMN"
    ],
    [
        "5303",
        "FOLLESE"
    ],
    [
        "5304",
        "HETLEVIK"
    ],
    [
        "5305",
        "FLORVÅG"
    ],
    [
        "5306",
        "ERDAL"
    ],
    [
        "5307",
        "ASK"
    ],
    [
        "5308",
        "KLEPPESTØ"
    ],
    [
        "5309",
        "KLEPPESTØ"
    ],
    [
        "5310",
        "HAUGLANDSHELLA"
    ],
    [
        "5311",
        "KJERRGARDEN"
    ],
    [
        "5314",
        "KJERRGARDEN"
    ],
    [
        "5315",
        "HERDLA"
    ],
    [
        "5318",
        "STRUSSHAMN"
    ],
    [
        "5319",
        "KLEPPESTØ"
    ],
    [
        "5321",
        "KLEPPESTØ"
    ],
    [
        "5322",
        "KLEPPESTØ"
    ],
    [
        "5323",
        "KLEPPESTØ"
    ],
    [
        "5325",
        "FOLLESE"
    ],
    [
        "5326",
        "ASK"
    ],
    [
        "5327",
        "HAUGLANDSHELLA"
    ],
    [
        "5329",
        "FLORVÅG"
    ],
    [
        "5331",
        "RONG"
    ],
    [
        "5333",
        "TJELDSTØ"
    ],
    [
        "5334",
        "HELLESØY"
    ],
    [
        "5335",
        "HERNAR"
    ],
    [
        "5336",
        "TJELDSTØ"
    ],
    [
        "5337",
        "RONG"
    ],
    [
        "5341",
        "STRAUME"
    ],
    [
        "5342",
        "STRAUME"
    ],
    [
        "5343",
        "STRAUME"
    ],
    [
        "5345",
        "KNARREVIK"
    ],
    [
        "5346",
        "ÅGOTNES"
    ],
    [
        "5347",
        "ÅGOTNES"
    ],
    [
        "5350",
        "BRATTHOLMEN"
    ],
    [
        "5353",
        "STRAUME"
    ],
    [
        "5354",
        "STRAUME"
    ],
    [
        "5355",
        "KNARREVIK"
    ],
    [
        "5357",
        "FJELL"
    ],
    [
        "5358",
        "FJELL"
    ],
    [
        "5360",
        "KOLLTVEIT"
    ],
    [
        "5363",
        "ÅGOTNES"
    ],
    [
        "5365",
        "TURØY"
    ],
    [
        "5366",
        "MISJE"
    ],
    [
        "5371",
        "SKOGSVÅG"
    ],
    [
        "5374",
        "STEINSLAND"
    ],
    [
        "5378",
        "KLOKKARVIK"
    ],
    [
        "5379",
        "STEINSLAND"
    ],
    [
        "5380",
        "TELAVÅG"
    ],
    [
        "5381",
        "GLESVÆR"
    ],
    [
        "5382",
        "SKOGSVÅG"
    ],
    [
        "5384",
        "TORANGSVÅG"
    ],
    [
        "5385",
        "BAKKASUND"
    ],
    [
        "5387",
        "MØKSTER"
    ],
    [
        "5388",
        "LITLAKALSØY"
    ],
    [
        "5392",
        "STOREBØ"
    ],
    [
        "5393",
        "STOREBØ"
    ],
    [
        "5394",
        "KOLBEINSVIK"
    ],
    [
        "5396",
        "VESTRE VINNESVÅG"
    ],
    [
        "5397",
        "BEKKJARVIK"
    ],
    [
        "5398",
        "STOLMEN"
    ],
    [
        "5399",
        "BEKKJARVIK"
    ],
    [
        "5401",
        "STORD"
    ],
    [
        "5402",
        "STORD"
    ],
    [
        "5403",
        "STORD"
    ],
    [
        "5404",
        "STORD"
    ],
    [
        "5406",
        "STORD"
    ],
    [
        "5407",
        "STORD"
    ],
    [
        "5408",
        "SAGVÅG"
    ],
    [
        "5409",
        "STORD"
    ],
    [
        "5410",
        "SAGVÅG"
    ],
    [
        "5411",
        "STORD"
    ],
    [
        "5412",
        "STORD"
    ],
    [
        "5413",
        "HUGLO"
    ],
    [
        "5414",
        "STORD"
    ],
    [
        "5415",
        "STORD"
    ],
    [
        "5416",
        "STORD"
    ],
    [
        "5417",
        "STORD"
    ],
    [
        "5418",
        "FITJAR"
    ],
    [
        "5419",
        "FITJAR"
    ],
    [
        "5420",
        "RUBBESTADNESET"
    ],
    [
        "5423",
        "BRANDASUND"
    ],
    [
        "5427",
        "URANGSVÅG"
    ],
    [
        "5428",
        "FOLDRØYHAMN"
    ],
    [
        "5430",
        "BREMNES"
    ],
    [
        "5437",
        "FINNÅS"
    ],
    [
        "5440",
        "MOSTERHAMN"
    ],
    [
        "5443",
        "BØMLO"
    ],
    [
        "5444",
        "ESPEVÆR"
    ],
    [
        "5445",
        "BREMNES"
    ],
    [
        "5447",
        "MOSTERHAMN"
    ],
    [
        "5449",
        "BØMLO"
    ],
    [
        "5450",
        "SUNDE I SUNNHORDLAND"
    ],
    [
        "5451",
        "VALEN"
    ],
    [
        "5452",
        "SANDVOLL"
    ],
    [
        "5453",
        "UTÅKER"
    ],
    [
        "5454",
        "SÆBØVIK"
    ],
    [
        "5455",
        "HALSNØY KLOSTER"
    ],
    [
        "5457",
        "HØYLANDSBYGD"
    ],
    [
        "5458",
        "ARNAVIK"
    ],
    [
        "5459",
        "FJELBERG"
    ],
    [
        "5460",
        "HUSNES"
    ],
    [
        "5462",
        "HERØYSUNDET"
    ],
    [
        "5463",
        "USKEDALEN"
    ],
    [
        "5464",
        "DIMMELSVIK"
    ],
    [
        "5465",
        "USKEDALEN"
    ],
    [
        "5470",
        "ROSENDAL"
    ],
    [
        "5472",
        "SEIMSFOSS"
    ],
    [
        "5473",
        "SNILSTVEITØY"
    ],
    [
        "5474",
        "LØFALLSTRAND"
    ],
    [
        "5475",
        "ÆNES"
    ],
    [
        "5476",
        "MAURANGER"
    ],
    [
        "5480",
        "HUSNES"
    ],
    [
        "5484",
        "SÆBØVIK"
    ],
    [
        "5486",
        "ROSENDAL"
    ],
    [
        "5498",
        "MATRE"
    ],
    [
        "5499",
        "ÅKRA"
    ],
    [
        "5501",
        "HAUGESUND"
    ],
    [
        "5502",
        "HAUGESUND"
    ],
    [
        "5503",
        "HAUGESUND"
    ],
    [
        "5504",
        "HAUGESUND"
    ],
    [
        "5505",
        "HAUGESUND"
    ],
    [
        "5506",
        "HAUGESUND"
    ],
    [
        "5507",
        "HAUGESUND"
    ],
    [
        "5508",
        "KARMSUND"
    ],
    [
        "5509",
        "HAUGESUND"
    ],
    [
        "5511",
        "HAUGESUND"
    ],
    [
        "5512",
        "HAUGESUND"
    ],
    [
        "5514",
        "HAUGESUND"
    ],
    [
        "5515",
        "HAUGESUND"
    ],
    [
        "5516",
        "HAUGESUND"
    ],
    [
        "5517",
        "HAUGESUND"
    ],
    [
        "5518",
        "HAUGESUND"
    ],
    [
        "5519",
        "HAUGESUND"
    ],
    [
        "5521",
        "HAUGESUND"
    ],
    [
        "5522",
        "HAUGESUND"
    ],
    [
        "5523",
        "HAUGESUND"
    ],
    [
        "5525",
        "HAUGESUND"
    ],
    [
        "5527",
        "HAUGESUND"
    ],
    [
        "5528",
        "HAUGESUND"
    ],
    [
        "5529",
        "HAUGESUND"
    ],
    [
        "5531",
        "HAUGESUND"
    ],
    [
        "5532",
        "HAUGESUND"
    ],
    [
        "5533",
        "HAUGESUND"
    ],
    [
        "5534",
        "HAUGESUND"
    ],
    [
        "5535",
        "HAUGESUND"
    ],
    [
        "5536",
        "HAUGESUND"
    ],
    [
        "5537",
        "HAUGESUND"
    ],
    [
        "5538",
        "HAUGESUND"
    ],
    [
        "5541",
        "KOLNES"
    ],
    [
        "5542",
        "KARMSUND"
    ],
    [
        "5544",
        "VORMEDAL"
    ],
    [
        "5545",
        "VORMEDAL"
    ],
    [
        "5546",
        "RØYKSUND"
    ],
    [
        "5547",
        "UTSIRA"
    ],
    [
        "5548",
        "FEØY"
    ],
    [
        "5549",
        "RØVÆR"
    ],
    [
        "5550",
        "SVEIO"
    ],
    [
        "5551",
        "AUKLANDSHAMN"
    ],
    [
        "5554",
        "VALEVÅG"
    ],
    [
        "5555",
        "FØRDE I HORDALAND"
    ],
    [
        "5556",
        "FØRDE I HORDALAND"
    ],
    [
        "5559",
        "SVEIO"
    ],
    [
        "5560",
        "NEDSTRAND"
    ],
    [
        "5561",
        "BOKN"
    ],
    [
        "5562",
        "NEDSTRAND"
    ],
    [
        "5563",
        "FØRRESFJORDEN"
    ],
    [
        "5565",
        "TYSVÆRVÅG"
    ],
    [
        "5566",
        "HERVIK"
    ],
    [
        "5567",
        "SKJOLDASTRAUMEN"
    ],
    [
        "5568",
        "VIKEBYGD"
    ],
    [
        "5569",
        "BOKN"
    ],
    [
        "5570",
        "AKSDAL"
    ],
    [
        "5574",
        "SKJOLD"
    ],
    [
        "5575",
        "AKSDAL"
    ],
    [
        "5576",
        "ØVRE VATS"
    ],
    [
        "5578",
        "NEDRE VATS"
    ],
    [
        "5580",
        "ØLEN"
    ],
    [
        "5582",
        "ØLENSVÅG"
    ],
    [
        "5583",
        "VIKEDAL"
    ],
    [
        "5584",
        "BJOA"
    ],
    [
        "5585",
        "SANDEID"
    ],
    [
        "5586",
        "VIKEDAL"
    ],
    [
        "5588",
        "ØLEN"
    ],
    [
        "5589",
        "SANDEID"
    ],
    [
        "5590",
        "ETNE"
    ],
    [
        "5591",
        "ETNE"
    ],
    [
        "5593",
        "SKÅNEVIK"
    ],
    [
        "5594",
        "SKÅNEVIK"
    ],
    [
        "5595",
        "FØRRESFJORDEN"
    ],
    [
        "5596",
        "MARKHUS"
    ],
    [
        "5598",
        "FJÆRA"
    ],
    [
        "5600",
        "NORHEIMSUND"
    ],
    [
        "5601",
        "NORHEIMSUND"
    ],
    [
        "5602",
        "NORHEIMSUND"
    ],
    [
        "5604",
        "ØYSTESE"
    ],
    [
        "5605",
        "ÅLVIK"
    ],
    [
        "5610",
        "ØYSTESE"
    ],
    [
        "5612",
        "STEINSTØ"
    ],
    [
        "5614",
        "ÅLVIK"
    ],
    [
        "5620",
        "TØRVIKBYGD"
    ],
    [
        "5626",
        "KYSNESSTRAND"
    ],
    [
        "5627",
        "JONDAL"
    ],
    [
        "5628",
        "HERAND"
    ],
    [
        "5629",
        "JONDAL"
    ],
    [
        "5630",
        "STRANDEBARM"
    ],
    [
        "5631",
        "STRANDEBARM"
    ],
    [
        "5632",
        "OMASTRAND"
    ],
    [
        "5633",
        "OMASTRAND"
    ],
    [
        "5635",
        "HATLESTRAND"
    ],
    [
        "5636",
        "VARALDSØY"
    ],
    [
        "5637",
        "ØLVE"
    ],
    [
        "5640",
        "EIKELANDSOSEN"
    ],
    [
        "5641",
        "FUSA"
    ],
    [
        "5642",
        "HOLMEFJORD"
    ],
    [
        "5643",
        "STRANDVIK"
    ],
    [
        "5644",
        "SÆVAREID"
    ],
    [
        "5645",
        "SÆVAREID"
    ],
    [
        "5646",
        "NORDTVEITGREND"
    ],
    [
        "5647",
        "BALDERSHEIM"
    ],
    [
        "5648",
        "FUSA"
    ],
    [
        "5649",
        "EIKELANDSOSEN"
    ],
    [
        "5650",
        "TYSSE"
    ],
    [
        "5651",
        "TYSSE"
    ],
    [
        "5652",
        "ÅRLAND"
    ],
    [
        "5653",
        "ÅRLAND"
    ],
    [
        "5680",
        "TYSNES"
    ],
    [
        "5683",
        "REKSTEREN"
    ],
    [
        "5685",
        "UGGDAL"
    ],
    [
        "5687",
        "FLATRÅKER"
    ],
    [
        "5690",
        "LUNDEGREND"
    ],
    [
        "5693",
        "ÅRBAKKA"
    ],
    [
        "5694",
        "ONARHEIM"
    ],
    [
        "5695",
        "UGGDAL"
    ],
    [
        "5696",
        "TYSNES"
    ],
    [
        "5700",
        "VOSS"
    ],
    [
        "5701",
        "VOSS"
    ],
    [
        "5702",
        "VOSS"
    ],
    [
        "5703",
        "VOSS"
    ],
    [
        "5704",
        "VOSS"
    ],
    [
        "5705",
        "VOSS"
    ],
    [
        "5706",
        "VOSS"
    ],
    [
        "5707",
        "EVANGER"
    ],
    [
        "5708",
        "VOSS"
    ],
    [
        "5709",
        "VOSS"
    ],
    [
        "5710",
        "SKULESTADMO"
    ],
    [
        "5711",
        "SKULESTADMO"
    ],
    [
        "5712",
        "VOSSESTRAND"
    ],
    [
        "5713",
        "VOSSESTRAND"
    ],
    [
        "5714",
        "VOSS"
    ],
    [
        "5715",
        "STALHEIM"
    ],
    [
        "5718",
        "MYRDAL"
    ],
    [
        "5719",
        "FINSE"
    ],
    [
        "5720",
        "STANGHELLE"
    ],
    [
        "5721",
        "DALEKVAM"
    ],
    [
        "5722",
        "DALEKVAM"
    ],
    [
        "5723",
        "BOLSTADØYRI"
    ],
    [
        "5724",
        "STANGHELLE"
    ],
    [
        "5725",
        "VAKSDAL"
    ],
    [
        "5726",
        "VAKSDAL"
    ],
    [
        "5727",
        "STAMNES"
    ],
    [
        "5728",
        "EIDSLANDET"
    ],
    [
        "5729",
        "MODALEN"
    ],
    [
        "5730",
        "ULVIK"
    ],
    [
        "5731",
        "ULVIK"
    ],
    [
        "5732",
        "MODALEN"
    ],
    [
        "5733",
        "GRANVIN"
    ],
    [
        "5734",
        "VALLAVIK"
    ],
    [
        "5736",
        "GRANVIN"
    ],
    [
        "5741",
        "AURLAND"
    ],
    [
        "5742",
        "FLÅM"
    ],
    [
        "5743",
        "FLÅM"
    ],
    [
        "5745",
        "AURLAND"
    ],
    [
        "5746",
        "UNDREDAL"
    ],
    [
        "5747",
        "GUDVANGEN"
    ],
    [
        "5748",
        "STYVI"
    ],
    [
        "5749",
        "GUDVANGEN"
    ],
    [
        "5750",
        "ODDA"
    ],
    [
        "5751",
        "ODDA"
    ],
    [
        "5752",
        "ODDA"
    ],
    [
        "5760",
        "RØLDAL"
    ],
    [
        "5763",
        "SKARE"
    ],
    [
        "5770",
        "TYSSEDAL"
    ],
    [
        "5773",
        "HOVLAND"
    ],
    [
        "5775",
        "NÅ"
    ],
    [
        "5776",
        "NÅ"
    ],
    [
        "5777",
        "GRIMO"
    ],
    [
        "5778",
        "UTNE"
    ],
    [
        "5779",
        "UTNE"
    ],
    [
        "5780",
        "KINSARVIK"
    ],
    [
        "5781",
        "LOFTHUS"
    ],
    [
        "5782",
        "KINSARVIK"
    ],
    [
        "5783",
        "EIDFJORD"
    ],
    [
        "5784",
        "ØVRE EIDFJORD"
    ],
    [
        "5785",
        "VØRINGSFOSS"
    ],
    [
        "5786",
        "EIDFJORD"
    ],
    [
        "5787",
        "LOFTHUS"
    ],
    [
        "5788",
        "KINSARVIK"
    ],
    [
        "5802",
        "BERGEN"
    ],
    [
        "5803",
        "BERGEN"
    ],
    [
        "5804",
        "BERGEN"
    ],
    [
        "5805",
        "BERGEN"
    ],
    [
        "5806",
        "BERGEN"
    ],
    [
        "5807",
        "BERGEN"
    ],
    [
        "5808",
        "BERGEN"
    ],
    [
        "5809",
        "BERGEN"
    ],
    [
        "5810",
        "BERGEN"
    ],
    [
        "5811",
        "BERGEN"
    ],
    [
        "5812",
        "BERGEN"
    ],
    [
        "5813",
        "BERGEN"
    ],
    [
        "5814",
        "BERGEN"
    ],
    [
        "5815",
        "BERGEN"
    ],
    [
        "5816",
        "BERGEN"
    ],
    [
        "5817",
        "BERGEN"
    ],
    [
        "5818",
        "BERGEN"
    ],
    [
        "5819",
        "BERGEN"
    ],
    [
        "5820",
        "BERGEN"
    ],
    [
        "5821",
        "BERGEN"
    ],
    [
        "5822",
        "BERGEN"
    ],
    [
        "5823",
        "BERGEN"
    ],
    [
        "5824",
        "BERGEN"
    ],
    [
        "5825",
        "BERGEN"
    ],
    [
        "5826",
        "BERGEN"
    ],
    [
        "5827",
        "BERGEN"
    ],
    [
        "5828",
        "BERGEN"
    ],
    [
        "5829",
        "BERGEN"
    ],
    [
        "5830",
        "BERGEN"
    ],
    [
        "5831",
        "BERGEN"
    ],
    [
        "5832",
        "BERGEN"
    ],
    [
        "5833",
        "BERGEN"
    ],
    [
        "5834",
        "BERGEN"
    ],
    [
        "5835",
        "BERGEN"
    ],
    [
        "5836",
        "BERGEN"
    ],
    [
        "5837",
        "BERGEN"
    ],
    [
        "5838",
        "BERGEN"
    ],
    [
        "5841",
        "BERGEN"
    ],
    [
        "5843",
        "BERGEN"
    ],
    [
        "5844",
        "BERGEN"
    ],
    [
        "5845",
        "BERGEN"
    ],
    [
        "5847",
        "BERGEN"
    ],
    [
        "5848",
        "BERGEN"
    ],
    [
        "5849",
        "BERGEN"
    ],
    [
        "5851",
        "BERGEN"
    ],
    [
        "5852",
        "BERGEN"
    ],
    [
        "5853",
        "BERGEN"
    ],
    [
        "5854",
        "BERGEN"
    ],
    [
        "5855",
        "BERGEN"
    ],
    [
        "5857",
        "BERGEN"
    ],
    [
        "5858",
        "BERGEN"
    ],
    [
        "5859",
        "BERGEN"
    ],
    [
        "5861",
        "BERGEN"
    ],
    [
        "5862",
        "BERGEN"
    ],
    [
        "5863",
        "BERGEN"
    ],
    [
        "5864",
        "BERGEN"
    ],
    [
        "5865",
        "BERGEN"
    ],
    [
        "5866",
        "BERGEN"
    ],
    [
        "5867",
        "BERGEN"
    ],
    [
        "5868",
        "BERGEN"
    ],
    [
        "5869",
        "BERGEN"
    ],
    [
        "5872",
        "BERGEN"
    ],
    [
        "5873",
        "BERGEN"
    ],
    [
        "5876",
        "BERGEN"
    ],
    [
        "5877",
        "BERGEN"
    ],
    [
        "5878",
        "BERGEN"
    ],
    [
        "5879",
        "BERGEN"
    ],
    [
        "5881",
        "BERGEN"
    ],
    [
        "5884",
        "BERGEN"
    ],
    [
        "5886",
        "BERGEN"
    ],
    [
        "5887",
        "BERGEN"
    ],
    [
        "5888",
        "BERGEN"
    ],
    [
        "5889",
        "BERGEN"
    ],
    [
        "5892",
        "BERGEN"
    ],
    [
        "5893",
        "BERGEN"
    ],
    [
        "5895",
        "BERGEN"
    ],
    [
        "5896",
        "BERGEN"
    ],
    [
        "5899",
        "BERGEN"
    ],
    [
        "5902",
        "ISDALSTØ"
    ],
    [
        "5903",
        "ISDALSTØ"
    ],
    [
        "5904",
        "ISDALSTØ"
    ],
    [
        "5906",
        "FREKHAUG"
    ],
    [
        "5907",
        "ALVERSUND"
    ],
    [
        "5908",
        "ISDALSTØ"
    ],
    [
        "5911",
        "ALVERSUND"
    ],
    [
        "5912",
        "SEIM"
    ],
    [
        "5913",
        "EIKANGERVÅG"
    ],
    [
        "5914",
        "ISDALSTØ"
    ],
    [
        "5915",
        "HJELMÅS"
    ],
    [
        "5916",
        "ISDALSTØ"
    ],
    [
        "5917",
        "ROSSLAND"
    ],
    [
        "5918",
        "FREKHAUG"
    ],
    [
        "5919",
        "FREKHAUG"
    ],
    [
        "5931",
        "MANGER"
    ],
    [
        "5935",
        "BØVÅGEN"
    ],
    [
        "5936",
        "MANGER"
    ],
    [
        "5937",
        "BØVÅGEN"
    ],
    [
        "5938",
        "SÆBØVÅGEN"
    ],
    [
        "5939",
        "SLETTA"
    ],
    [
        "5941",
        "AUSTRHEIM"
    ],
    [
        "5943",
        "AUSTRHEIM"
    ],
    [
        "5947",
        "FEDJE"
    ],
    [
        "5948",
        "FEDJE"
    ],
    [
        "5951",
        "LINDÅS"
    ],
    [
        "5952",
        "FONNES"
    ],
    [
        "5953",
        "FONNES"
    ],
    [
        "5954",
        "MONGSTAD"
    ],
    [
        "5955",
        "LINDÅS"
    ],
    [
        "5956",
        "HUNDVIN"
    ],
    [
        "5957",
        "MYKING"
    ],
    [
        "5960",
        "DALSØYRA"
    ],
    [
        "5961",
        "BREKKE"
    ],
    [
        "5962",
        "BJORDAL"
    ],
    [
        "5963",
        "DALSØYRA"
    ],
    [
        "5964",
        "BREKKE"
    ],
    [
        "5965",
        "BJORDAL"
    ],
    [
        "5966",
        "EIVINDVIK"
    ],
    [
        "5967",
        "EIVINDVIK"
    ],
    [
        "5970",
        "BYRKNESØY"
    ],
    [
        "5977",
        "ÅNNELAND"
    ],
    [
        "5978",
        "MJØMNA"
    ],
    [
        "5979",
        "BYRKNESØY"
    ],
    [
        "5981",
        "MASFJORDNES"
    ],
    [
        "5982",
        "MASFJORDNES"
    ],
    [
        "5983",
        "HAUGSVÆR"
    ],
    [
        "5984",
        "MATREDAL"
    ],
    [
        "5985",
        "HAUGSVÆR"
    ],
    [
        "5986",
        "HOSTELAND"
    ],
    [
        "5987",
        "HOSTELAND"
    ],
    [
        "5991",
        "OSTEREIDET"
    ],
    [
        "5993",
        "OSTEREIDET"
    ],
    [
        "5994",
        "VIKANES"
    ],
    [
        "6001",
        "ÅLESUND"
    ],
    [
        "6002",
        "ÅLESUND"
    ],
    [
        "6003",
        "ÅLESUND"
    ],
    [
        "6004",
        "ÅLESUND"
    ],
    [
        "6005",
        "ÅLESUND"
    ],
    [
        "6006",
        "ÅLESUND"
    ],
    [
        "6007",
        "ÅLESUND"
    ],
    [
        "6008",
        "ÅLESUND"
    ],
    [
        "6009",
        "ÅLESUND"
    ],
    [
        "6010",
        "ÅLESUND"
    ],
    [
        "6011",
        "ÅLESUND"
    ],
    [
        "6012",
        "ÅLESUND"
    ],
    [
        "6013",
        "ÅLESUND"
    ],
    [
        "6014",
        "ÅLESUND"
    ],
    [
        "6015",
        "ÅLESUND"
    ],
    [
        "6016",
        "ÅLESUND"
    ],
    [
        "6017",
        "ÅLESUND"
    ],
    [
        "6018",
        "ÅLESUND"
    ],
    [
        "6019",
        "ÅLESUND"
    ],
    [
        "6020",
        "ÅLESUND"
    ],
    [
        "6021",
        "ÅLESUND"
    ],
    [
        "6022",
        "ÅLESUND"
    ],
    [
        "6023",
        "ÅLESUND"
    ],
    [
        "6024",
        "ÅLESUND"
    ],
    [
        "6025",
        "ÅLESUND"
    ],
    [
        "6026",
        "ÅLESUND"
    ],
    [
        "6028",
        "ÅLESUND"
    ],
    [
        "6030",
        "LANGEVÅG"
    ],
    [
        "6034",
        "EIDSNES"
    ],
    [
        "6035",
        "FISKARSTRAND"
    ],
    [
        "6036",
        "MAUSEIDVÅG"
    ],
    [
        "6037",
        "EIDSNES"
    ],
    [
        "6039",
        "LANGEVÅG"
    ],
    [
        "6040",
        "VIGRA"
    ],
    [
        "6044",
        "ÅLESUND"
    ],
    [
        "6045",
        "ÅLESUND"
    ],
    [
        "6046",
        "ÅLESUND"
    ],
    [
        "6047",
        "ÅLESUND"
    ],
    [
        "6048",
        "ÅLESUND"
    ],
    [
        "6050",
        "VALDERØYA"
    ],
    [
        "6052",
        "GISKE"
    ],
    [
        "6054",
        "GODØYA"
    ],
    [
        "6055",
        "GODØYA"
    ],
    [
        "6057",
        "ELLINGSØY"
    ],
    [
        "6058",
        "VALDERØYA"
    ],
    [
        "6059",
        "VIGRA"
    ],
    [
        "6060",
        "HAREID"
    ],
    [
        "6062",
        "BRANDAL"
    ],
    [
        "6063",
        "HJØRUNGAVÅG"
    ],
    [
        "6064",
        "HADDAL"
    ],
    [
        "6065",
        "ULSTEINVIK"
    ],
    [
        "6067",
        "ULSTEINVIK"
    ],
    [
        "6068",
        "EIKSUND"
    ],
    [
        "6069",
        "HAREID"
    ],
    [
        "6070",
        "TJØRVÅG"
    ],
    [
        "6075",
        "MOLTUSTRANDA"
    ],
    [
        "6076",
        "MOLTUSTRANDA"
    ],
    [
        "6078",
        "GJERDSVIKA"
    ],
    [
        "6079",
        "GURSKØY"
    ],
    [
        "6080",
        "GURSKØY"
    ],
    [
        "6082",
        "GURSKEN"
    ],
    [
        "6083",
        "GJERDSVIKA"
    ],
    [
        "6084",
        "LARSNES"
    ],
    [
        "6085",
        "LARSNES"
    ],
    [
        "6086",
        "KVAMSØY"
    ],
    [
        "6087",
        "KVAMSØY"
    ],
    [
        "6088",
        "SANDSHAMN"
    ],
    [
        "6089",
        "SANDSHAMN"
    ],
    [
        "6090",
        "FOSNAVÅG"
    ],
    [
        "6091",
        "FOSNAVÅG"
    ],
    [
        "6092",
        "FOSNAVÅG"
    ],
    [
        "6094",
        "LEINØY"
    ],
    [
        "6095",
        "BØLANDET"
    ],
    [
        "6096",
        "RUNDE"
    ],
    [
        "6098",
        "NERLANDSØY"
    ],
    [
        "6099",
        "FOSNAVÅG"
    ],
    [
        "6100",
        "VOLDA"
    ],
    [
        "6101",
        "VOLDA"
    ],
    [
        "6102",
        "VOLDA"
    ],
    [
        "6103",
        "VOLDA"
    ],
    [
        "6104",
        "VOLDA"
    ],
    [
        "6105",
        "VOLDA"
    ],
    [
        "6106",
        "VOLDA"
    ],
    [
        "6110",
        "AUSTEFJORDEN"
    ],
    [
        "6120",
        "FOLKESTAD"
    ],
    [
        "6121",
        "FOLKESTAD"
    ],
    [
        "6133",
        "LAUVSTAD"
    ],
    [
        "6134",
        "LAUVSTAD"
    ],
    [
        "6138",
        "SYVDE"
    ],
    [
        "6139",
        "FISKÅ"
    ],
    [
        "6140",
        "SYVDE"
    ],
    [
        "6141",
        "ROVDE"
    ],
    [
        "6142",
        "EIDSÅ"
    ],
    [
        "6143",
        "FISKÅ"
    ],
    [
        "6144",
        "SYLTE"
    ],
    [
        "6146",
        "ÅHEIM"
    ],
    [
        "6147",
        "ÅHEIM"
    ],
    [
        "6149",
        "ÅRAM"
    ],
    [
        "6150",
        "ØRSTA"
    ],
    [
        "6151",
        "ØRSTA"
    ],
    [
        "6152",
        "ØRSTA"
    ],
    [
        "6153",
        "ØRSTA"
    ],
    [
        "6154",
        "ØRSTA"
    ],
    [
        "6155",
        "ØRSTA"
    ],
    [
        "6156",
        "ØRSTA"
    ],
    [
        "6160",
        "HOVDEBYGDA"
    ],
    [
        "6161",
        "HOVDEBYGDA"
    ],
    [
        "6165",
        "SÆBØ"
    ],
    [
        "6166",
        "SÆBØ"
    ],
    [
        "6170",
        "VARTDAL"
    ],
    [
        "6171",
        "VARTDAL"
    ],
    [
        "6174",
        "BARSTADVIK"
    ],
    [
        "6183",
        "TRANDAL"
    ],
    [
        "6184",
        "STORESTANDAL"
    ],
    [
        "6190",
        "BJØRKE"
    ],
    [
        "6196",
        "NORANGSFJORDEN"
    ],
    [
        "6200",
        "STRANDA"
    ],
    [
        "6201",
        "STRANDA"
    ],
    [
        "6210",
        "VALLDAL"
    ],
    [
        "6211",
        "VALLDAL"
    ],
    [
        "6212",
        "LIABYGDA"
    ],
    [
        "6213",
        "TAFJORD"
    ],
    [
        "6214",
        "NORDDAL"
    ],
    [
        "6215",
        "EIDSDAL"
    ],
    [
        "6216",
        "GEIRANGER"
    ],
    [
        "6217",
        "GEIRANGER"
    ],
    [
        "6218",
        "HELLESYLT"
    ],
    [
        "6219",
        "HELLESYLT"
    ],
    [
        "6220",
        "STRAUMGJERDE"
    ],
    [
        "6222",
        "IKORNNES"
    ],
    [
        "6223",
        "IKORNNES"
    ],
    [
        "6224",
        "HUNDEIDVIK"
    ],
    [
        "6230",
        "SYKKYLVEN"
    ],
    [
        "6238",
        "STRAUMGJERDE"
    ],
    [
        "6239",
        "SYKKYLVEN"
    ],
    [
        "6240",
        "ØRSKOG"
    ],
    [
        "6249",
        "ØRSKOG"
    ],
    [
        "6250",
        "STORDAL"
    ],
    [
        "6255",
        "EIDSDAL"
    ],
    [
        "6259",
        "STORDAL"
    ],
    [
        "6260",
        "SKODJE"
    ],
    [
        "6263",
        "SKODJE"
    ],
    [
        "6264",
        "TENNFJORD"
    ],
    [
        "6265",
        "VATNE"
    ],
    [
        "6270",
        "BRATTVÅG"
    ],
    [
        "6272",
        "HILDRE"
    ],
    [
        "6280",
        "SØVIK"
    ],
    [
        "6281",
        "SØVIK"
    ],
    [
        "6282",
        "BRATTVÅG"
    ],
    [
        "6283",
        "VATNE"
    ],
    [
        "6285",
        "STOREKALVØY"
    ],
    [
        "6290",
        "HARAMSØY"
    ],
    [
        "6291",
        "HARAMSØY"
    ],
    [
        "6292",
        "KJERSTAD"
    ],
    [
        "6293",
        "LONGVA"
    ],
    [
        "6294",
        "FJØRTOFT"
    ],
    [
        "6300",
        "ÅNDALSNES"
    ],
    [
        "6301",
        "ÅNDALSNES"
    ],
    [
        "6310",
        "VEBLUNGSNES"
    ],
    [
        "6315",
        "INNFJORDEN"
    ],
    [
        "6320",
        "ISFJORDEN"
    ],
    [
        "6330",
        "VERMA"
    ],
    [
        "6331",
        "VERMA"
    ],
    [
        "6339",
        "ISFJORDEN"
    ],
    [
        "6350",
        "EIDSBYGDA"
    ],
    [
        "6360",
        "ÅFARNES"
    ],
    [
        "6361",
        "ÅFARNES"
    ],
    [
        "6363",
        "MITTET"
    ],
    [
        "6364",
        "VISTDAL"
    ],
    [
        "6365",
        "VISTDAL"
    ],
    [
        "6385",
        "MÅNDALEN"
    ],
    [
        "6386",
        "MÅNDALEN"
    ],
    [
        "6387",
        "VÅGSTRANDA"
    ],
    [
        "6388",
        "VÅGSTRANDA"
    ],
    [
        "6389",
        "FIKSDAL"
    ],
    [
        "6390",
        "VESTNES"
    ],
    [
        "6391",
        "TRESFJORD"
    ],
    [
        "6392",
        "VIKEBUKT"
    ],
    [
        "6393",
        "TOMREFJORD"
    ],
    [
        "6394",
        "FIKSDAL"
    ],
    [
        "6395",
        "REKDAL"
    ],
    [
        "6396",
        "VIKEBUKT"
    ],
    [
        "6397",
        "TRESFJORD"
    ],
    [
        "6398",
        "TOMREFJORD"
    ],
    [
        "6399",
        "VESTNES"
    ],
    [
        "6401",
        "MOLDE"
    ],
    [
        "6402",
        "MOLDE"
    ],
    [
        "6403",
        "MOLDE"
    ],
    [
        "6404",
        "MOLDE"
    ],
    [
        "6405",
        "MOLDE"
    ],
    [
        "6407",
        "MOLDE"
    ],
    [
        "6408",
        "AUREOSEN"
    ],
    [
        "6409",
        "MOLDE"
    ],
    [
        "6410",
        "MOLDE"
    ],
    [
        "6411",
        "MOLDE"
    ],
    [
        "6412",
        "MOLDE"
    ],
    [
        "6413",
        "MOLDE"
    ],
    [
        "6414",
        "MOLDE"
    ],
    [
        "6415",
        "MOLDE"
    ],
    [
        "6416",
        "MOLDE"
    ],
    [
        "6418",
        "SEKKEN"
    ],
    [
        "6419",
        "MOLDE"
    ],
    [
        "6421",
        "MOLDE"
    ],
    [
        "6422",
        "MOLDE"
    ],
    [
        "6423",
        "MOLDE"
    ],
    [
        "6425",
        "MOLDE"
    ],
    [
        "6429",
        "MOLDE"
    ],
    [
        "6430",
        "BUD"
    ],
    [
        "6431",
        "BUD"
    ],
    [
        "6433",
        "HUSTAD"
    ],
    [
        "6434",
        "MOLDE"
    ],
    [
        "6435",
        "MOLDE"
    ],
    [
        "6436",
        "MOLDE"
    ],
    [
        "6440",
        "ELNESVÅGEN"
    ],
    [
        "6443",
        "TORNES I ROMSDAL"
    ],
    [
        "6444",
        "FARSTAD"
    ],
    [
        "6445",
        "MALMEFJORDEN"
    ],
    [
        "6446",
        "FARSTAD"
    ],
    [
        "6447",
        "ELNESVÅGEN"
    ],
    [
        "6450",
        "HJELSET"
    ],
    [
        "6452",
        "KLEIVE"
    ],
    [
        "6453",
        "KLEIVE"
    ],
    [
        "6454",
        "HJELSET"
    ],
    [
        "6455",
        "KORTGARDEN"
    ],
    [
        "6456",
        "SKÅLA"
    ],
    [
        "6457",
        "BOLSØYA"
    ],
    [
        "6458",
        "SKÅLA"
    ],
    [
        "6460",
        "EIDSVÅG I ROMSDAL"
    ],
    [
        "6461",
        "EIDSVÅG I ROMSDAL"
    ],
    [
        "6462",
        "RAUDSAND"
    ],
    [
        "6470",
        "ERESFJORD"
    ],
    [
        "6471",
        "ERESFJORD"
    ],
    [
        "6472",
        "EIKESDAL"
    ],
    [
        "6475",
        "MIDSUND"
    ],
    [
        "6476",
        "MIDSUND"
    ],
    [
        "6480",
        "AUKRA"
    ],
    [
        "6481",
        "AUKRA"
    ],
    [
        "6483",
        "ONA"
    ],
    [
        "6484",
        "SANDØY"
    ],
    [
        "6485",
        "HARØY"
    ],
    [
        "6486",
        "ORTEN"
    ],
    [
        "6487",
        "HARØY"
    ],
    [
        "6488",
        "MYKLEBOST"
    ],
    [
        "6490",
        "EIDE"
    ],
    [
        "6493",
        "LYNGSTAD"
    ],
    [
        "6494",
        "VEVANG"
    ],
    [
        "6499",
        "EIDE"
    ],
    [
        "6501",
        "KRISTIANSUND N"
    ],
    [
        "6502",
        "KRISTIANSUND N"
    ],
    [
        "6503",
        "KRISTIANSUND N"
    ],
    [
        "6504",
        "KRISTIANSUND N"
    ],
    [
        "6506",
        "KRISTIANSUND N"
    ],
    [
        "6507",
        "KRISTIANSUND N"
    ],
    [
        "6508",
        "KRISTIANSUND N"
    ],
    [
        "6509",
        "KRISTIANSUND N"
    ],
    [
        "6510",
        "KRISTIANSUND N"
    ],
    [
        "6511",
        "KRISTIANSUND N"
    ],
    [
        "6512",
        "KRISTIANSUND N"
    ],
    [
        "6514",
        "KRISTIANSUND N"
    ],
    [
        "6515",
        "KRISTIANSUND N"
    ],
    [
        "6516",
        "KRISTIANSUND N"
    ],
    [
        "6517",
        "KRISTIANSUND N"
    ],
    [
        "6518",
        "KRISTIANSUND N"
    ],
    [
        "6520",
        "FREI"
    ],
    [
        "6521",
        "FREI"
    ],
    [
        "6522",
        "FREI"
    ],
    [
        "6523",
        "FREI"
    ],
    [
        "6524",
        "FREI"
    ],
    [
        "6525",
        "FREI"
    ],
    [
        "6527",
        "FREI"
    ],
    [
        "6528",
        "FREI"
    ],
    [
        "6529",
        "FREI"
    ],
    [
        "6530",
        "AVERØY"
    ],
    [
        "6531",
        "AVERØY"
    ],
    [
        "6532",
        "AVERØY"
    ],
    [
        "6533",
        "AVERØY"
    ],
    [
        "6538",
        "AVERØY"
    ],
    [
        "6539",
        "AVERØY"
    ],
    [
        "6546",
        "KRISTIANSUND N"
    ],
    [
        "6547",
        "KRISTIANSUND N"
    ],
    [
        "6548",
        "KRISTIANSUND N"
    ],
    [
        "6549",
        "KRISTIANSUND N"
    ],
    [
        "6570",
        "SMØLA"
    ],
    [
        "6571",
        "SMØLA"
    ],
    [
        "6590",
        "TUSTNA"
    ],
    [
        "6591",
        "TUSTNA"
    ],
    [
        "6600",
        "SUNNDALSØRA"
    ],
    [
        "6601",
        "SUNNDALSØRA"
    ],
    [
        "6610",
        "ØKSENDAL"
    ],
    [
        "6611",
        "FURUGRENDA"
    ],
    [
        "6612",
        "GRØA"
    ],
    [
        "6613",
        "GJØRA"
    ],
    [
        "6614",
        "GJØRA"
    ],
    [
        "6620",
        "ÅLVUNDEID"
    ],
    [
        "6622",
        "ÅLVUNDFJORD"
    ],
    [
        "6623",
        "ÅLVUNDFJORD"
    ],
    [
        "6627",
        "TINGVOLL"
    ],
    [
        "6628",
        "MEISINGSET"
    ],
    [
        "6629",
        "TORJULVÅGEN"
    ],
    [
        "6630",
        "TINGVOLL"
    ],
    [
        "6631",
        "BATNFJORDSØRA"
    ],
    [
        "6632",
        "BATNFJORDSØRA"
    ],
    [
        "6633",
        "GJEMNES"
    ],
    [
        "6636",
        "ANGVIK"
    ],
    [
        "6637",
        "FLEMMA"
    ],
    [
        "6638",
        "OSMARKA"
    ],
    [
        "6639",
        "TORVIKBUKT"
    ],
    [
        "6640",
        "KVANNE"
    ],
    [
        "6641",
        "TORVIKBUKT"
    ],
    [
        "6642",
        "STANGVIK"
    ],
    [
        "6643",
        "BØFJORDEN"
    ],
    [
        "6644",
        "BÆVERFJORD"
    ],
    [
        "6645",
        "TODALEN"
    ],
    [
        "6650",
        "SURNADAL"
    ],
    [
        "6652",
        "SURNADAL"
    ],
    [
        "6653",
        "ØVRE SURNADAL"
    ],
    [
        "6655",
        "VINDØLA"
    ],
    [
        "6656",
        "SURNADAL"
    ],
    [
        "6657",
        "RINDAL"
    ],
    [
        "6658",
        "RINDALSSKOGEN"
    ],
    [
        "6659",
        "RINDAL"
    ],
    [
        "6670",
        "ØYDEGARD"
    ],
    [
        "6671",
        "ØYDEGARD"
    ],
    [
        "6674",
        "KVISVIK"
    ],
    [
        "6680",
        "HALSANAUSTAN"
    ],
    [
        "6683",
        "VÅGLAND"
    ],
    [
        "6686",
        "VALSØYBOTN"
    ],
    [
        "6687",
        "VALSØYFJORD"
    ],
    [
        "6688",
        "VÅGLAND"
    ],
    [
        "6689",
        "AURE"
    ],
    [
        "6690",
        "AURE"
    ],
    [
        "6693",
        "MJOSUNDET"
    ],
    [
        "6694",
        "FOLDFJORDEN"
    ],
    [
        "6697",
        "VIHALS"
    ],
    [
        "6698",
        "LESUND"
    ],
    [
        "6699",
        "KJØRSVIKBUGEN"
    ],
    [
        "6700",
        "MÅLØY"
    ],
    [
        "6701",
        "MÅLØY"
    ],
    [
        "6702",
        "MÅLØY"
    ],
    [
        "6703",
        "MÅLØY"
    ],
    [
        "6704",
        "DEKNEPOLLEN"
    ],
    [
        "6707",
        "RAUDEBERG"
    ],
    [
        "6708",
        "BRYGGJA"
    ],
    [
        "6710",
        "RAUDEBERG"
    ],
    [
        "6711",
        "BRYGGJA"
    ],
    [
        "6713",
        "ALMENNINGEN"
    ],
    [
        "6714",
        "SILDA"
    ],
    [
        "6715",
        "BARMEN"
    ],
    [
        "6716",
        "HUSEVÅG"
    ],
    [
        "6717",
        "FLATRAKET"
    ],
    [
        "6718",
        "DEKNEPOLLEN"
    ],
    [
        "6719",
        "SKATESTRAUMEN"
    ],
    [
        "6721",
        "SVELGEN"
    ],
    [
        "6723",
        "SVELGEN"
    ],
    [
        "6726",
        "BREMANGER"
    ],
    [
        "6727",
        "BREMANGER"
    ],
    [
        "6728",
        "KALVÅG"
    ],
    [
        "6729",
        "KALVÅG"
    ],
    [
        "6730",
        "DAVIK"
    ],
    [
        "6734",
        "RUGSUND"
    ],
    [
        "6737",
        "ÅLFOTEN"
    ],
    [
        "6740",
        "SELJE"
    ],
    [
        "6741",
        "SELJE"
    ],
    [
        "6750",
        "STADLANDET"
    ],
    [
        "6751",
        "STADLANDET"
    ],
    [
        "6761",
        "HORNINDAL"
    ],
    [
        "6763",
        "HORNINDAL"
    ],
    [
        "6770",
        "NORDFJORDEID"
    ],
    [
        "6771",
        "NORDFJORDEID"
    ],
    [
        "6772",
        "NORDFJORDEID"
    ],
    [
        "6773",
        "NORDFJORDEID"
    ],
    [
        "6774",
        "NORDFJORDEID"
    ],
    [
        "6776",
        "KJØLSDALEN"
    ],
    [
        "6777",
        "STÅRHEIM"
    ],
    [
        "6778",
        "LOTE"
    ],
    [
        "6779",
        "HOLMØYANE"
    ],
    [
        "6781",
        "STRYN"
    ],
    [
        "6782",
        "STRYN"
    ],
    [
        "6783",
        "STRYN"
    ],
    [
        "6784",
        "OLDEN"
    ],
    [
        "6788",
        "OLDEN"
    ],
    [
        "6789",
        "LOEN"
    ],
    [
        "6790",
        "LOEN"
    ],
    [
        "6791",
        "OLDEDALEN"
    ],
    [
        "6792",
        "BRIKSDALSBRE"
    ],
    [
        "6793",
        "INNVIK"
    ],
    [
        "6794",
        "INNVIK"
    ],
    [
        "6795",
        "BLAKSÆTER"
    ],
    [
        "6796",
        "HOPLAND"
    ],
    [
        "6797",
        "UTVIK"
    ],
    [
        "6798",
        "HJELLEDALEN"
    ],
    [
        "6799",
        "OPPSTRYN"
    ],
    [
        "6800",
        "FØRDE"
    ],
    [
        "6801",
        "FØRDE"
    ],
    [
        "6802",
        "FØRDE"
    ],
    [
        "6803",
        "FØRDE"
    ],
    [
        "6804",
        "FØRDE"
    ],
    [
        "6805",
        "FØRDE"
    ],
    [
        "6806",
        "NAUSTDAL"
    ],
    [
        "6807",
        "FØRDE"
    ],
    [
        "6808",
        "FØRDE"
    ],
    [
        "6809",
        "FØRDE"
    ],
    [
        "6810",
        "FØRDE"
    ],
    [
        "6811",
        "FØRDE"
    ],
    [
        "6812",
        "FØRDE"
    ],
    [
        "6813",
        "FØRDE"
    ],
    [
        "6814",
        "FØRDE"
    ],
    [
        "6815",
        "FØRDE"
    ],
    [
        "6817",
        "NAUSTDAL"
    ],
    [
        "6818",
        "HAUKEDALEN"
    ],
    [
        "6819",
        "FØRDE"
    ],
    [
        "6820",
        "FØRDE"
    ],
    [
        "6821",
        "SANDANE"
    ],
    [
        "6822",
        "SANDANE"
    ],
    [
        "6823",
        "SANDANE"
    ],
    [
        "6826",
        "BYRKJELO"
    ],
    [
        "6827",
        "BREIM"
    ],
    [
        "6828",
        "HESTENESØYRA"
    ],
    [
        "6829",
        "HYEN"
    ],
    [
        "6830",
        "BYRKJELO"
    ],
    [
        "6831",
        "HYEN"
    ],
    [
        "6841",
        "SKEI I JØLSTER"
    ],
    [
        "6843",
        "SKEI I JØLSTER"
    ],
    [
        "6844",
        "VASSENDEN"
    ],
    [
        "6845",
        "FJÆRLAND"
    ],
    [
        "6847",
        "VASSENDEN"
    ],
    [
        "6848",
        "FJÆRLAND"
    ],
    [
        "6849",
        "KAUPANGER"
    ],
    [
        "6851",
        "SOGNDAL"
    ],
    [
        "6852",
        "SOGNDAL"
    ],
    [
        "6853",
        "SOGNDAL"
    ],
    [
        "6854",
        "KAUPANGER"
    ],
    [
        "6855",
        "FRØNNINGEN"
    ],
    [
        "6856",
        "SOGNDAL"
    ],
    [
        "6857",
        "SOGNDAL"
    ],
    [
        "6858",
        "FARDAL"
    ],
    [
        "6859",
        "SLINDE"
    ],
    [
        "6861",
        "LEIKANGER"
    ],
    [
        "6863",
        "LEIKANGER"
    ],
    [
        "6866",
        "GAUPNE"
    ],
    [
        "6867",
        "HAFSLO"
    ],
    [
        "6868",
        "GAUPNE"
    ],
    [
        "6869",
        "HAFSLO"
    ],
    [
        "6870",
        "ORNES"
    ],
    [
        "6871",
        "JOSTEDAL"
    ],
    [
        "6872",
        "LUSTER"
    ],
    [
        "6873",
        "MARIFJØRA"
    ],
    [
        "6874",
        "LUSTER"
    ],
    [
        "6875",
        "HØYHEIMSVIK"
    ],
    [
        "6876",
        "SKJOLDEN"
    ],
    [
        "6877",
        "FORTUN"
    ],
    [
        "6878",
        "VEITASTROND"
    ],
    [
        "6879",
        "SOLVORN"
    ],
    [
        "6881",
        "ÅRDALSTANGEN"
    ],
    [
        "6882",
        "ØVRE ÅRDAL"
    ],
    [
        "6884",
        "ØVRE ÅRDAL"
    ],
    [
        "6885",
        "ÅRDALSTANGEN"
    ],
    [
        "6886",
        "LÆRDAL"
    ],
    [
        "6887",
        "LÆRDAL"
    ],
    [
        "6888",
        "BORGUND"
    ],
    [
        "6891",
        "VIK I SOGN"
    ],
    [
        "6893",
        "VIK I SOGN"
    ],
    [
        "6894",
        "VANGSNES"
    ],
    [
        "6895",
        "FEIOS"
    ],
    [
        "6896",
        "FRESVIK"
    ],
    [
        "6898",
        "BALESTRAND"
    ],
    [
        "6899",
        "BALESTRAND"
    ],
    [
        "6900",
        "FLORØ"
    ],
    [
        "6901",
        "FLORØ"
    ],
    [
        "6902",
        "FLORØ"
    ],
    [
        "6903",
        "FLORØ"
    ],
    [
        "6905",
        "FLORØ"
    ],
    [
        "6906",
        "FLORØ"
    ],
    [
        "6907",
        "FLORØ"
    ],
    [
        "6908",
        "FLORØ"
    ],
    [
        "6909",
        "FLORØ"
    ],
    [
        "6910",
        "FLORØ"
    ],
    [
        "6912",
        "KINN"
    ],
    [
        "6913",
        "FLORØ"
    ],
    [
        "6914",
        "SVANØYBUKT"
    ],
    [
        "6915",
        "ROGNALDSVÅG"
    ],
    [
        "6916",
        "BAREKSTAD"
    ],
    [
        "6917",
        "BATALDEN"
    ],
    [
        "6918",
        "SØR-SKORPA"
    ],
    [
        "6919",
        "TANSØY"
    ],
    [
        "6921",
        "HARDBAKKE"
    ],
    [
        "6924",
        "HARDBAKKE"
    ],
    [
        "6926",
        "KRAKHELLA"
    ],
    [
        "6927",
        "YTRØYGREND"
    ],
    [
        "6928",
        "KOLGROV"
    ],
    [
        "6929",
        "HERSVIKBYGDA"
    ],
    [
        "6940",
        "EIKEFJORD"
    ],
    [
        "6941",
        "EIKEFJORD"
    ],
    [
        "6942",
        "SVORTEVIK"
    ],
    [
        "6944",
        "STAVANG"
    ],
    [
        "6946",
        "LAVIK"
    ],
    [
        "6947",
        "LAVIK"
    ],
    [
        "6951",
        "LEIRVIK I SOGN"
    ],
    [
        "6953",
        "LEIRVIK I SOGN"
    ],
    [
        "6957",
        "HYLLESTAD"
    ],
    [
        "6958",
        "SØRBØVÅG"
    ],
    [
        "6959",
        "SØRBØVÅG"
    ],
    [
        "6961",
        "DALE I SUNNFJORD"
    ],
    [
        "6963",
        "DALE I SUNNFJORD"
    ],
    [
        "6964",
        "KORSSUND"
    ],
    [
        "6966",
        "GUDDAL"
    ],
    [
        "6967",
        "HELLEVIK I FJALER"
    ],
    [
        "6968",
        "FLEKKE"
    ],
    [
        "6969",
        "STRAUMSNES"
    ],
    [
        "6971",
        "SANDE I SUNNFJORD"
    ],
    [
        "6973",
        "SANDE I SUNNFJORD"
    ],
    [
        "6975",
        "SKILBREI"
    ],
    [
        "6976",
        "BYGSTAD"
    ],
    [
        "6977",
        "BYGSTAD"
    ],
    [
        "6978",
        "VIKSDALEN"
    ],
    [
        "6980",
        "ASKVOLL"
    ],
    [
        "6982",
        "HOLMEDAL"
    ],
    [
        "6983",
        "KVAMMEN"
    ],
    [
        "6984",
        "STONGFJORDEN"
    ],
    [
        "6985",
        "ATLØY"
    ],
    [
        "6986",
        "VÆRLANDET"
    ],
    [
        "6987",
        "BULANDET"
    ],
    [
        "6988",
        "ASKVOLL"
    ],
    [
        "6991",
        "HØYANGER"
    ],
    [
        "6993",
        "HØYANGER"
    ],
    [
        "6994",
        "NESSANE"
    ],
    [
        "6995",
        "KYRKJEBØ"
    ],
    [
        "6996",
        "VADHEIM"
    ],
    [
        "6997",
        "VADHEIM"
    ],
    [
        "7003",
        "TRONDHEIM"
    ],
    [
        "7004",
        "TRONDHEIM"
    ],
    [
        "7005",
        "TRONDHEIM"
    ],
    [
        "7006",
        "TRONDHEIM"
    ],
    [
        "7010",
        "TRONDHEIM"
    ],
    [
        "7011",
        "TRONDHEIM"
    ],
    [
        "7012",
        "TRONDHEIM"
    ],
    [
        "7013",
        "TRONDHEIM"
    ],
    [
        "7014",
        "TRONDHEIM"
    ],
    [
        "7015",
        "TRONDHEIM"
    ],
    [
        "7016",
        "TRONDHEIM"
    ],
    [
        "7017",
        "TRONDHEIM"
    ],
    [
        "7018",
        "TRONDHEIM"
    ],
    [
        "7019",
        "TRONDHEIM"
    ],
    [
        "7020",
        "TRONDHEIM"
    ],
    [
        "7021",
        "TRONDHEIM"
    ],
    [
        "7022",
        "TRONDHEIM"
    ],
    [
        "7023",
        "TRONDHEIM"
    ],
    [
        "7024",
        "TRONDHEIM"
    ],
    [
        "7025",
        "TRONDHEIM"
    ],
    [
        "7026",
        "TRONDHEIM"
    ],
    [
        "7027",
        "TRONDHEIM"
    ],
    [
        "7028",
        "TRONDHEIM"
    ],
    [
        "7029",
        "TRONDHEIM"
    ],
    [
        "7030",
        "TRONDHEIM"
    ],
    [
        "7031",
        "TRONDHEIM"
    ],
    [
        "7032",
        "TRONDHEIM"
    ],
    [
        "7033",
        "TRONDHEIM"
    ],
    [
        "7034",
        "TRONDHEIM"
    ],
    [
        "7035",
        "TRONDHEIM"
    ],
    [
        "7036",
        "TRONDHEIM"
    ],
    [
        "7037",
        "TRONDHEIM"
    ],
    [
        "7038",
        "TRONDHEIM"
    ],
    [
        "7039",
        "TRONDHEIM"
    ],
    [
        "7040",
        "TRONDHEIM"
    ],
    [
        "7041",
        "TRONDHEIM"
    ],
    [
        "7042",
        "TRONDHEIM"
    ],
    [
        "7043",
        "TRONDHEIM"
    ],
    [
        "7044",
        "TRONDHEIM"
    ],
    [
        "7045",
        "TRONDHEIM"
    ],
    [
        "7046",
        "TRONDHEIM"
    ],
    [
        "7047",
        "TRONDHEIM"
    ],
    [
        "7048",
        "TRONDHEIM"
    ],
    [
        "7049",
        "TRONDHEIM"
    ],
    [
        "7050",
        "TRONDHEIM"
    ],
    [
        "7051",
        "TRONDHEIM"
    ],
    [
        "7052",
        "TRONDHEIM"
    ],
    [
        "7053",
        "RANHEIM"
    ],
    [
        "7054",
        "RANHEIM"
    ],
    [
        "7055",
        "RANHEIM"
    ],
    [
        "7056",
        "RANHEIM"
    ],
    [
        "7057",
        "JONSVATNET"
    ],
    [
        "7058",
        "JAKOBSLI"
    ],
    [
        "7059",
        "JAKOBSLI"
    ],
    [
        "7066",
        "TRONDHEIM"
    ],
    [
        "7067",
        "TRONDHEIM"
    ],
    [
        "7068",
        "TRONDHEIM"
    ],
    [
        "7069",
        "TRONDHEIM"
    ],
    [
        "7070",
        "BOSBERG"
    ],
    [
        "7071",
        "TRONDHEIM"
    ],
    [
        "7072",
        "HEIMDAL"
    ],
    [
        "7074",
        "SPONGDAL"
    ],
    [
        "7075",
        "TILLER"
    ],
    [
        "7078",
        "SAUPSTAD"
    ],
    [
        "7079",
        "FLATÅSEN"
    ],
    [
        "7080",
        "HEIMDAL"
    ],
    [
        "7081",
        "SJETNEMARKA"
    ],
    [
        "7082",
        "KATTEM"
    ],
    [
        "7083",
        "LEINSTRAND"
    ],
    [
        "7088",
        "HEIMDAL"
    ],
    [
        "7089",
        "HEIMDAL"
    ],
    [
        "7091",
        "TILLER"
    ],
    [
        "7092",
        "TILLER"
    ],
    [
        "7093",
        "TILLER"
    ],
    [
        "7097",
        "SAUPSTAD"
    ],
    [
        "7098",
        "SAUPSTAD"
    ],
    [
        "7099",
        "FLATÅSEN"
    ],
    [
        "7100",
        "RISSA"
    ],
    [
        "7101",
        "RISSA"
    ],
    [
        "7105",
        "STADSBYGD"
    ],
    [
        "7110",
        "FEVÅG"
    ],
    [
        "7111",
        "HASSELVIKA"
    ],
    [
        "7112",
        "HASSELVIKA"
    ],
    [
        "7113",
        "HUSBYSJØEN"
    ],
    [
        "7114",
        "RÅKVÅG"
    ],
    [
        "7115",
        "HUSBYSJØEN"
    ],
    [
        "7116",
        "RÅKVÅG"
    ],
    [
        "7119",
        "STADSBYGD"
    ],
    [
        "7120",
        "LEKSVIK"
    ],
    [
        "7121",
        "LEKSVIK"
    ],
    [
        "7125",
        "VANVIKAN"
    ],
    [
        "7126",
        "VANVIKAN"
    ],
    [
        "7127",
        "OPPHAUG"
    ],
    [
        "7129",
        "BREKSTAD"
    ],
    [
        "7130",
        "BREKSTAD"
    ],
    [
        "7140",
        "OPPHAUG"
    ],
    [
        "7142",
        "UTHAUG"
    ],
    [
        "7150",
        "STORFOSNA"
    ],
    [
        "7151",
        "STORFOSNA"
    ],
    [
        "7152",
        "KRÅKVÅG"
    ],
    [
        "7153",
        "GARTEN"
    ],
    [
        "7156",
        "LEKSA"
    ],
    [
        "7159",
        "BJUGN"
    ],
    [
        "7160",
        "BJUGN"
    ],
    [
        "7164",
        "LYSØYSUNDET"
    ],
    [
        "7165",
        "OKSVOLL"
    ],
    [
        "7166",
        "TARVA"
    ],
    [
        "7167",
        "VALLERSUND"
    ],
    [
        "7168",
        "LYSØYSUNDET"
    ],
    [
        "7169",
        "ÅFJORD"
    ],
    [
        "7170",
        "ÅFJORD"
    ],
    [
        "7174",
        "REVSNES"
    ],
    [
        "7175",
        "STOKKØY"
    ],
    [
        "7176",
        "LINESØYA"
    ],
    [
        "7177",
        "REVSNES"
    ],
    [
        "7178",
        "STOKKØY"
    ],
    [
        "7180",
        "ROAN"
    ],
    [
        "7181",
        "ROAN"
    ],
    [
        "7190",
        "BESSAKER"
    ],
    [
        "7194",
        "BRANDSFJORD"
    ],
    [
        "7200",
        "KYRKSÆTERØRA"
    ],
    [
        "7201",
        "KYRKSÆTERØRA"
    ],
    [
        "7203",
        "VINJEØRA"
    ],
    [
        "7206",
        "HELLANDSJØEN"
    ],
    [
        "7207",
        "YTRE SNILLFJORD"
    ],
    [
        "7211",
        "KORSVEGEN"
    ],
    [
        "7212",
        "KORSVEGEN"
    ],
    [
        "7213",
        "GÅSBAKKEN"
    ],
    [
        "7221",
        "MELHUS"
    ],
    [
        "7223",
        "MELHUS"
    ],
    [
        "7224",
        "MELHUS"
    ],
    [
        "7227",
        "GIMSE"
    ],
    [
        "7228",
        "KVÅL"
    ],
    [
        "7231",
        "LUNDAMO"
    ],
    [
        "7232",
        "LUNDAMO"
    ],
    [
        "7234",
        "LER"
    ],
    [
        "7235",
        "LER"
    ],
    [
        "7236",
        "HOVIN I GAULDAL"
    ],
    [
        "7238",
        "HOVIN I GAULDAL"
    ],
    [
        "7239",
        "HITRA"
    ],
    [
        "7240",
        "HITRA"
    ],
    [
        "7241",
        "ANSNES"
    ],
    [
        "7242",
        "KNARRLAGSUND"
    ],
    [
        "7243",
        "KVENVÆR"
    ],
    [
        "7244",
        "KNARRLAGSUND"
    ],
    [
        "7245",
        "KVENVÆR"
    ],
    [
        "7246",
        "SANDSTAD"
    ],
    [
        "7247",
        "HESTVIKA"
    ],
    [
        "7250",
        "MELANDSJØ"
    ],
    [
        "7252",
        "DOLMØY"
    ],
    [
        "7255",
        "SUNDLANDET"
    ],
    [
        "7256",
        "HEMNSKJELA"
    ],
    [
        "7257",
        "SNILLFJORD"
    ],
    [
        "7259",
        "SNILLFJORD"
    ],
    [
        "7260",
        "SISTRANDA"
    ],
    [
        "7261",
        "SISTRANDA"
    ],
    [
        "7263",
        "HAMARVIK"
    ],
    [
        "7264",
        "HAMARVIK"
    ],
    [
        "7266",
        "KVERVA"
    ],
    [
        "7267",
        "KVERVA"
    ],
    [
        "7268",
        "TITRAN"
    ],
    [
        "7270",
        "DYRVIK"
    ],
    [
        "7273",
        "NORDDYRØY"
    ],
    [
        "7274",
        "NORDDYRØY"
    ],
    [
        "7280",
        "SULA"
    ],
    [
        "7282",
        "BOGØYVÆR"
    ],
    [
        "7284",
        "MAUSUND"
    ],
    [
        "7285",
        "GJÆSINGEN"
    ],
    [
        "7286",
        "SØRBURØY"
    ],
    [
        "7287",
        "SAUØY"
    ],
    [
        "7288",
        "SOKNEDAL"
    ],
    [
        "7289",
        "SOKNEDAL"
    ],
    [
        "7290",
        "STØREN"
    ],
    [
        "7291",
        "STØREN"
    ],
    [
        "7295",
        "ROGNES"
    ],
    [
        "7298",
        "BUDALEN"
    ],
    [
        "7300",
        "ORKANGER"
    ],
    [
        "7301",
        "ORKANGER"
    ],
    [
        "7302",
        "ORKANGER"
    ],
    [
        "7303",
        "ORKANGER"
    ],
    [
        "7310",
        "GJØLME"
    ],
    [
        "7315",
        "LENSVIK"
    ],
    [
        "7316",
        "LENSVIK"
    ],
    [
        "7318",
        "AGDENES"
    ],
    [
        "7319",
        "AGDENES"
    ],
    [
        "7320",
        "FANNREM"
    ],
    [
        "7321",
        "FANNREM"
    ],
    [
        "7327",
        "SVORKMO"
    ],
    [
        "7329",
        "SVORKMO"
    ],
    [
        "7331",
        "LØKKEN VERK"
    ],
    [
        "7332",
        "LØKKEN VERK"
    ],
    [
        "7333",
        "STORÅS"
    ],
    [
        "7334",
        "STORÅS"
    ],
    [
        "7335",
        "JERPSTAD"
    ],
    [
        "7336",
        "MELDAL"
    ],
    [
        "7338",
        "MELDAL"
    ],
    [
        "7340",
        "OPPDAL"
    ],
    [
        "7341",
        "OPPDAL"
    ],
    [
        "7342",
        "LØNSET"
    ],
    [
        "7343",
        "VOGNILL"
    ],
    [
        "7345",
        "DRIVA"
    ],
    [
        "7350",
        "BUVIKA"
    ],
    [
        "7351",
        "BUVIKA"
    ],
    [
        "7353",
        "BØRSA"
    ],
    [
        "7354",
        "VIGGJA"
    ],
    [
        "7355",
        "EGGKLEIVA"
    ],
    [
        "7356",
        "SKAUN"
    ],
    [
        "7357",
        "SKAUN"
    ],
    [
        "7358",
        "BØRSA"
    ],
    [
        "7361",
        "RØROS"
    ],
    [
        "7370",
        "BREKKEBYGD"
    ],
    [
        "7372",
        "GLÅMOS"
    ],
    [
        "7374",
        "RØROS"
    ],
    [
        "7375",
        "RØROS"
    ],
    [
        "7380",
        "ÅLEN"
    ],
    [
        "7383",
        "HALTDALEN"
    ],
    [
        "7384",
        "ÅLEN"
    ],
    [
        "7386",
        "SINGSÅS"
    ],
    [
        "7387",
        "SINGSÅS"
    ],
    [
        "7388",
        "SINGSÅS"
    ],
    [
        "7391",
        "RENNEBU"
    ],
    [
        "7392",
        "RENNEBU"
    ],
    [
        "7393",
        "RENNEBU"
    ],
    [
        "7397",
        "RENNEBU"
    ],
    [
        "7398",
        "RENNEBU"
    ],
    [
        "7399",
        "RENNEBU"
    ],
    [
        "7400",
        "TRONDHEIM"
    ],
    [
        "7401",
        "TRONDHEIM"
    ],
    [
        "7402",
        "TRONDHEIM"
    ],
    [
        "7403",
        "TRONDHEIM"
    ],
    [
        "7404",
        "TRONDHEIM"
    ],
    [
        "7405",
        "TRONDHEIM"
    ],
    [
        "7406",
        "TRONDHEIM"
    ],
    [
        "7407",
        "TRONDHEIM"
    ],
    [
        "7408",
        "TRONDHEIM"
    ],
    [
        "7409",
        "TRONDHEIM"
    ],
    [
        "7410",
        "TRONDHEIM"
    ],
    [
        "7411",
        "TRONDHEIM"
    ],
    [
        "7412",
        "TRONDHEIM"
    ],
    [
        "7413",
        "TRONDHEIM"
    ],
    [
        "7414",
        "TRONDHEIM"
    ],
    [
        "7415",
        "TRONDHEIM"
    ],
    [
        "7416",
        "TRONDHEIM"
    ],
    [
        "7417",
        "TRONDHEIM"
    ],
    [
        "7418",
        "TRONDHEIM"
    ],
    [
        "7419",
        "TRONDHEIM"
    ],
    [
        "7420",
        "TRONDHEIM"
    ],
    [
        "7421",
        "TRONDHEIM"
    ],
    [
        "7422",
        "TRONDHEIM"
    ],
    [
        "7424",
        "TRONDHEIM"
    ],
    [
        "7425",
        "TRONDHEIM"
    ],
    [
        "7426",
        "TRONDHEIM"
    ],
    [
        "7427",
        "TRONDHEIM"
    ],
    [
        "7428",
        "TRONDHEIM"
    ],
    [
        "7429",
        "TRONDHEIM"
    ],
    [
        "7430",
        "TRONDHEIM"
    ],
    [
        "7431",
        "TRONDHEIM"
    ],
    [
        "7432",
        "TRONDHEIM"
    ],
    [
        "7433",
        "TRONDHEIM"
    ],
    [
        "7434",
        "TRONDHEIM"
    ],
    [
        "7435",
        "TRONDHEIM"
    ],
    [
        "7436",
        "TRONDHEIM"
    ],
    [
        "7437",
        "TRONDHEIM"
    ],
    [
        "7438",
        "TRONDHEIM"
    ],
    [
        "7439",
        "TRONDHEIM"
    ],
    [
        "7440",
        "TRONDHEIM"
    ],
    [
        "7441",
        "TRONDHEIM"
    ],
    [
        "7442",
        "TRONDHEIM"
    ],
    [
        "7443",
        "TRONDHEIM"
    ],
    [
        "7444",
        "TRONDHEIM"
    ],
    [
        "7445",
        "TRONDHEIM"
    ],
    [
        "7446",
        "TRONDHEIM"
    ],
    [
        "7447",
        "TRONDHEIM"
    ],
    [
        "7448",
        "TRONDHEIM"
    ],
    [
        "7449",
        "TRONDHEIM"
    ],
    [
        "7450",
        "TRONDHEIM"
    ],
    [
        "7451",
        "TRONDHEIM"
    ],
    [
        "7452",
        "TRONDHEIM"
    ],
    [
        "7453",
        "TRONDHEIM"
    ],
    [
        "7454",
        "TRONDHEIM"
    ],
    [
        "7455",
        "TRONDHEIM"
    ],
    [
        "7456",
        "TRONDHEIM"
    ],
    [
        "7457",
        "TRONDHEIM"
    ],
    [
        "7458",
        "TRONDHEIM"
    ],
    [
        "7459",
        "TRONDHEIM"
    ],
    [
        "7462",
        "TRONDHEIM"
    ],
    [
        "7463",
        "TRONDHEIM"
    ],
    [
        "7464",
        "TRONDHEIM"
    ],
    [
        "7465",
        "TRONDHEIM"
    ],
    [
        "7466",
        "TRONDHEIM"
    ],
    [
        "7467",
        "TRONDHEIM"
    ],
    [
        "7468",
        "TRONDHEIM"
    ],
    [
        "7469",
        "TRONDHEIM"
    ],
    [
        "7470",
        "TRONDHEIM"
    ],
    [
        "7471",
        "TRONDHEIM"
    ],
    [
        "7472",
        "TRONDHEIM"
    ],
    [
        "7473",
        "TRONDHEIM"
    ],
    [
        "7474",
        "TRONDHEIM"
    ],
    [
        "7475",
        "TRONDHEIM"
    ],
    [
        "7476",
        "TRONDHEIM"
    ],
    [
        "7477",
        "TRONDHEIM"
    ],
    [
        "7478",
        "TRONDHEIM"
    ],
    [
        "7479",
        "TRONDHEIM"
    ],
    [
        "7480",
        "TRONDHEIM"
    ],
    [
        "7481",
        "TRONDHEIM"
    ],
    [
        "7482",
        "TRONDHEIM"
    ],
    [
        "7483",
        "TRONDHEIM"
    ],
    [
        "7484",
        "TRONDHEIM"
    ],
    [
        "7485",
        "TRONDHEIM"
    ],
    [
        "7486",
        "TRONDHEIM"
    ],
    [
        "7487",
        "TRONDHEIM"
    ],
    [
        "7488",
        "TRONDHEIM"
    ],
    [
        "7489",
        "TRONDHEIM"
    ],
    [
        "7490",
        "TRONDHEIM"
    ],
    [
        "7491",
        "TRONDHEIM"
    ],
    [
        "7492",
        "TRONDHEIM"
    ],
    [
        "7493",
        "TRONDHEIM"
    ],
    [
        "7494",
        "TRONDHEIM"
    ],
    [
        "7495",
        "TRONDHEIM"
    ],
    [
        "7496",
        "TRONDHEIM"
    ],
    [
        "7497",
        "TRONDHEIM"
    ],
    [
        "7498",
        "TRONDHEIM"
    ],
    [
        "7500",
        "STJØRDAL"
    ],
    [
        "7501",
        "STJØRDAL"
    ],
    [
        "7502",
        "STJØRDAL"
    ],
    [
        "7503",
        "STJØRDAL"
    ],
    [
        "7504",
        "STJØRDAL"
    ],
    [
        "7505",
        "STJØRDAL"
    ],
    [
        "7506",
        "STJØRDAL"
    ],
    [
        "7507",
        "STJØRDAL"
    ],
    [
        "7508",
        "STJØRDAL"
    ],
    [
        "7509",
        "STJØRDAL"
    ],
    [
        "7510",
        "SKATVAL"
    ],
    [
        "7511",
        "SKATVAL"
    ],
    [
        "7512",
        "STJØRDAL"
    ],
    [
        "7513",
        "STJØRDAL"
    ],
    [
        "7514",
        "STJØRDAL"
    ],
    [
        "7517",
        "HELL"
    ],
    [
        "7519",
        "ELVARLI"
    ],
    [
        "7520",
        "HEGRA"
    ],
    [
        "7525",
        "FLORNES"
    ],
    [
        "7529",
        "HEGRA"
    ],
    [
        "7530",
        "MERÅKER"
    ],
    [
        "7531",
        "MERÅKER"
    ],
    [
        "7533",
        "KOPPERÅ"
    ],
    [
        "7540",
        "KLÆBU"
    ],
    [
        "7541",
        "KLÆBU"
    ],
    [
        "7549",
        "TANEM"
    ],
    [
        "7550",
        "HOMMELVIK"
    ],
    [
        "7551",
        "HOMMELVIK"
    ],
    [
        "7560",
        "VIKHAMMER"
    ],
    [
        "7562",
        "SAKSVIK"
    ],
    [
        "7563",
        "MALVIK"
    ],
    [
        "7566",
        "VIKHAMMER"
    ],
    [
        "7570",
        "HELL"
    ],
    [
        "7580",
        "SELBU"
    ],
    [
        "7581",
        "SELBU"
    ],
    [
        "7583",
        "SELBU"
    ],
    [
        "7584",
        "SELBUSTRAND"
    ],
    [
        "7590",
        "TYDAL"
    ],
    [
        "7591",
        "TYDAL"
    ],
    [
        "7596",
        "FLAKNAN"
    ],
    [
        "7600",
        "LEVANGER"
    ],
    [
        "7601",
        "LEVANGER"
    ],
    [
        "7602",
        "LEVANGER"
    ],
    [
        "7603",
        "LEVANGER"
    ],
    [
        "7604",
        "LEVANGER"
    ],
    [
        "7605",
        "LEVANGER"
    ],
    [
        "7606",
        "LEVANGER"
    ],
    [
        "7607",
        "LEVANGER"
    ],
    [
        "7608",
        "LEVANGER"
    ],
    [
        "7609",
        "LEVANGER"
    ],
    [
        "7610",
        "LEVANGER"
    ],
    [
        "7619",
        "SKOGN"
    ],
    [
        "7620",
        "SKOGN"
    ],
    [
        "7622",
        "MARKABYGDA"
    ],
    [
        "7623",
        "RONGLAN"
    ],
    [
        "7624",
        "EKNE"
    ],
    [
        "7629",
        "YTTERØY"
    ],
    [
        "7630",
        "ÅSEN"
    ],
    [
        "7631",
        "ÅSEN"
    ],
    [
        "7632",
        "ÅSENFJORD"
    ],
    [
        "7633",
        "FROSTA"
    ],
    [
        "7634",
        "FROSTA"
    ],
    [
        "7650",
        "VERDAL"
    ],
    [
        "7651",
        "VERDAL"
    ],
    [
        "7652",
        "VERDAL"
    ],
    [
        "7653",
        "VERDAL"
    ],
    [
        "7654",
        "VERDAL"
    ],
    [
        "7655",
        "VERDAL"
    ],
    [
        "7656",
        "VERDAL"
    ],
    [
        "7657",
        "VERDAL"
    ],
    [
        "7658",
        "VERDAL"
    ],
    [
        "7660",
        "VUKU"
    ],
    [
        "7661",
        "VUKU"
    ],
    [
        "7670",
        "INDERØY"
    ],
    [
        "7671",
        "INDERØY"
    ],
    [
        "7672",
        "INDERØY"
    ],
    [
        "7690",
        "MOSVIK"
    ],
    [
        "7691",
        "MOSVIK"
    ],
    [
        "7701",
        "STEINKJER"
    ],
    [
        "7702",
        "STEINKJER"
    ],
    [
        "7703",
        "STEINKJER"
    ],
    [
        "7704",
        "STEINKJER"
    ],
    [
        "7705",
        "STEINKJER"
    ],
    [
        "7707",
        "STEINKJER"
    ],
    [
        "7708",
        "STEINKJER"
    ],
    [
        "7709",
        "STEINKJER"
    ],
    [
        "7710",
        "SPARBU"
    ],
    [
        "7711",
        "STEINKJER"
    ],
    [
        "7712",
        "STEINKJER"
    ],
    [
        "7713",
        "STEINKJER"
    ],
    [
        "7714",
        "STEINKJER"
    ],
    [
        "7715",
        "STEINKJER"
    ],
    [
        "7716",
        "STEINKJER"
    ],
    [
        "7717",
        "STEINKJER"
    ],
    [
        "7718",
        "STEINKJER"
    ],
    [
        "7724",
        "STEINKJER"
    ],
    [
        "7725",
        "STEINKJER"
    ],
    [
        "7726",
        "STEINKJER"
    ],
    [
        "7729",
        "STEINKJER"
    ],
    [
        "7730",
        "BEITSTAD"
    ],
    [
        "7732",
        "STEINKJER"
    ],
    [
        "7733",
        "SPARBU"
    ],
    [
        "7734",
        "STEINKJER"
    ],
    [
        "7735",
        "STEINKJER"
    ],
    [
        "7736",
        "STEINKJER"
    ],
    [
        "7737",
        "STEINKJER"
    ],
    [
        "7738",
        "STEINKJER"
    ],
    [
        "7739",
        "BEITSTAD"
    ],
    [
        "7740",
        "STEINSDALEN"
    ],
    [
        "7741",
        "STEINSDALEN"
    ],
    [
        "7742",
        "YTTERVÅG"
    ],
    [
        "7744",
        "HEPSØY"
    ],
    [
        "7745",
        "OPPLAND"
    ],
    [
        "7746",
        "HASVÅG"
    ],
    [
        "7748",
        "SETER"
    ],
    [
        "7750",
        "NAMDALSEID"
    ],
    [
        "7751",
        "NAMDALSEID"
    ],
    [
        "7760",
        "SNÅSA"
    ],
    [
        "7761",
        "SNÅSA"
    ],
    [
        "7770",
        "FLATANGER"
    ],
    [
        "7771",
        "FLATANGER"
    ],
    [
        "7777",
        "NORD-STATLAND"
    ],
    [
        "7790",
        "MALM"
    ],
    [
        "7791",
        "MALM"
    ],
    [
        "7795",
        "FOLLAFOSS"
    ],
    [
        "7796",
        "FOLLAFOSS"
    ],
    [
        "7797",
        "VERRABOTN"
    ],
    [
        "7800",
        "NAMSOS"
    ],
    [
        "7801",
        "NAMSOS"
    ],
    [
        "7802",
        "NAMSOS"
    ],
    [
        "7803",
        "NAMSOS"
    ],
    [
        "7804",
        "NAMSOS"
    ],
    [
        "7805",
        "NAMSOS"
    ],
    [
        "7808",
        "NAMSOS"
    ],
    [
        "7810",
        "NAMSOS"
    ],
    [
        "7817",
        "SALSNES"
    ],
    [
        "7818",
        "LUND"
    ],
    [
        "7819",
        "FOSSLANDSOSEN"
    ],
    [
        "7820",
        "SPILLUM"
    ],
    [
        "7821",
        "SPILLUM"
    ],
    [
        "7822",
        "BANGSUND"
    ],
    [
        "7823",
        "BANGSUND"
    ],
    [
        "7856",
        "JØA"
    ],
    [
        "7860",
        "SKAGE I NAMDALEN"
    ],
    [
        "7863",
        "OVERHALLA"
    ],
    [
        "7864",
        "OVERHALLA"
    ],
    [
        "7869",
        "SKAGE I NAMDALEN"
    ],
    [
        "7870",
        "GRONG"
    ],
    [
        "7871",
        "GRONG"
    ],
    [
        "7873",
        "HARRAN"
    ],
    [
        "7874",
        "HARRAN"
    ],
    [
        "7876",
        "KONGSMOEN"
    ],
    [
        "7877",
        "HØYLANDET"
    ],
    [
        "7878",
        "HØYLANDET"
    ],
    [
        "7881",
        "NORDLI"
    ],
    [
        "7882",
        "NORDLI"
    ],
    [
        "7884",
        "SØRLI"
    ],
    [
        "7885",
        "SØRLI"
    ],
    [
        "7890",
        "NAMSSKOGAN"
    ],
    [
        "7891",
        "NAMSSKOGAN"
    ],
    [
        "7892",
        "TRONES"
    ],
    [
        "7893",
        "SKOROVATN"
    ],
    [
        "7896",
        "BREKKVASSELV"
    ],
    [
        "7897",
        "LIMINGEN"
    ],
    [
        "7898",
        "LIMINGEN"
    ],
    [
        "7900",
        "RØRVIK"
    ],
    [
        "7901",
        "RØRVIK"
    ],
    [
        "7902",
        "RØRVIK"
    ],
    [
        "7940",
        "OTTERSØY"
    ],
    [
        "7941",
        "OTTERSØY"
    ],
    [
        "7944",
        "INDRE NÆRØY"
    ],
    [
        "7950",
        "ABELVÆR"
    ],
    [
        "7960",
        "SALSBRUKET"
    ],
    [
        "7970",
        "KOLVEREID"
    ],
    [
        "7971",
        "KOLVEREID"
    ],
    [
        "7973",
        "GJERDINGA"
    ],
    [
        "7979",
        "TERRÅK"
    ],
    [
        "7980",
        "TERRÅK"
    ],
    [
        "7981",
        "HARANGSFJORD"
    ],
    [
        "7982",
        "BINDALSEIDET"
    ],
    [
        "7983",
        "BINDALSEIDET"
    ],
    [
        "7985",
        "FOLDEREID"
    ],
    [
        "7986",
        "FOLDEREID"
    ],
    [
        "7990",
        "NAUSTBUKTA"
    ],
    [
        "7993",
        "GUTVIK"
    ],
    [
        "7994",
        "LEKA"
    ],
    [
        "7995",
        "LEKA"
    ],
    [
        "8001",
        "BODØ"
    ],
    [
        "8002",
        "BODØ"
    ],
    [
        "8003",
        "BODØ"
    ],
    [
        "8004",
        "BODØ"
    ],
    [
        "8005",
        "BODØ"
    ],
    [
        "8006",
        "BODØ"
    ],
    [
        "8007",
        "BODØ"
    ],
    [
        "8008",
        "BODØ"
    ],
    [
        "8009",
        "BODØ"
    ],
    [
        "8010",
        "BODØ"
    ],
    [
        "8011",
        "BODØ"
    ],
    [
        "8012",
        "BODØ"
    ],
    [
        "8013",
        "BODØ"
    ],
    [
        "8014",
        "BODØ"
    ],
    [
        "8015",
        "BODØ"
    ],
    [
        "8016",
        "BODØ"
    ],
    [
        "8019",
        "BODØ"
    ],
    [
        "8020",
        "BODØ"
    ],
    [
        "8021",
        "BODØ"
    ],
    [
        "8022",
        "BODØ"
    ],
    [
        "8023",
        "BODØ"
    ],
    [
        "8026",
        "BODØ"
    ],
    [
        "8027",
        "BODØ"
    ],
    [
        "8028",
        "BODØ"
    ],
    [
        "8029",
        "BODØ"
    ],
    [
        "8030",
        "BODØ"
    ],
    [
        "8031",
        "BODØ"
    ],
    [
        "8037",
        "BODØ"
    ],
    [
        "8038",
        "BODØ"
    ],
    [
        "8041",
        "BODØ"
    ],
    [
        "8047",
        "BODØ"
    ],
    [
        "8048",
        "BODØ"
    ],
    [
        "8049",
        "BODØ"
    ],
    [
        "8050",
        "TVERLANDET"
    ],
    [
        "8056",
        "SALTSTRAUMEN"
    ],
    [
        "8057",
        "SALTSTRAUMEN"
    ],
    [
        "8058",
        "TVERLANDET"
    ],
    [
        "8062",
        "VÆRØY"
    ],
    [
        "8063",
        "VÆRØY"
    ],
    [
        "8064",
        "RØST"
    ],
    [
        "8065",
        "RØST"
    ],
    [
        "8070",
        "BODØ"
    ],
    [
        "8071",
        "BODØ"
    ],
    [
        "8072",
        "BODØ"
    ],
    [
        "8073",
        "BODØ"
    ],
    [
        "8074",
        "BODØ"
    ],
    [
        "8075",
        "BODØ"
    ],
    [
        "8076",
        "BODØ"
    ],
    [
        "8079",
        "BODØ"
    ],
    [
        "8084",
        "BODØ"
    ],
    [
        "8086",
        "BODØ"
    ],
    [
        "8087",
        "BODØ"
    ],
    [
        "8088",
        "BODØ"
    ],
    [
        "8089",
        "BODØ"
    ],
    [
        "8091",
        "BODØ"
    ],
    [
        "8092",
        "BODØ"
    ],
    [
        "8093",
        "KJERRINGØY"
    ],
    [
        "8094",
        "FLEINVÆR"
    ],
    [
        "8095",
        "HELLIGVÆR"
    ],
    [
        "8096",
        "BLIKSVÆR"
    ],
    [
        "8097",
        "GIVÆR"
    ],
    [
        "8098",
        "LANDEGODE"
    ],
    [
        "8099",
        "JAN MAYEN"
    ],
    [
        "8100",
        "MISVÆR"
    ],
    [
        "8102",
        "SKJERSTAD"
    ],
    [
        "8103",
        "BREIVIK I SALTEN"
    ],
    [
        "8108",
        "MISVÆR"
    ],
    [
        "8110",
        "MOLDJORD"
    ],
    [
        "8114",
        "TOLLÅ"
    ],
    [
        "8118",
        "MOLDJORD"
    ],
    [
        "8120",
        "NYGÅRDSJØEN"
    ],
    [
        "8128",
        "YTRE BEIARN"
    ],
    [
        "8130",
        "SANDHORNØY"
    ],
    [
        "8134",
        "SØRARNØY"
    ],
    [
        "8135",
        "SØRARNØY"
    ],
    [
        "8136",
        "NORDARNØY"
    ],
    [
        "8138",
        "INNDYR"
    ],
    [
        "8140",
        "INNDYR"
    ],
    [
        "8145",
        "STORVIK"
    ],
    [
        "8146",
        "REIPÅ"
    ],
    [
        "8149",
        "NEVERDAL"
    ],
    [
        "8150",
        "ØRNES"
    ],
    [
        "8151",
        "ØRNES"
    ],
    [
        "8157",
        "MELØY"
    ],
    [
        "8158",
        "BOLGA"
    ],
    [
        "8159",
        "STØTT"
    ],
    [
        "8160",
        "GLOMFJORD"
    ],
    [
        "8161",
        "GLOMFJORD"
    ],
    [
        "8168",
        "ENGAVÅGEN"
    ],
    [
        "8170",
        "ENGAVÅGEN"
    ],
    [
        "8178",
        "HALSA"
    ],
    [
        "8179",
        "HALSA"
    ],
    [
        "8181",
        "MYKEN"
    ],
    [
        "8182",
        "MELFJORDBOTN"
    ],
    [
        "8183",
        "VÅGAHOLMEN"
    ],
    [
        "8184",
        "ÅGSKARDET"
    ],
    [
        "8185",
        "VÅGAHOLMEN"
    ],
    [
        "8186",
        "TJONGSFJORDEN"
    ],
    [
        "8187",
        "JEKTVIK"
    ],
    [
        "8188",
        "NORDVERNES"
    ],
    [
        "8189",
        "GJERSVIKGRENDA"
    ],
    [
        "8190",
        "SØRFJORDEN"
    ],
    [
        "8192",
        "JEKTVIK"
    ],
    [
        "8193",
        "RØDØY"
    ],
    [
        "8195",
        "GJERØY"
    ],
    [
        "8196",
        "SELSØYVIK"
    ],
    [
        "8197",
        "STORSELSØY"
    ],
    [
        "8198",
        "NORDNESØY"
    ],
    [
        "8200",
        "FAUSKE"
    ],
    [
        "8201",
        "FAUSKE"
    ],
    [
        "8202",
        "FAUSKE"
    ],
    [
        "8203",
        "FAUSKE"
    ],
    [
        "8205",
        "FAUSKE"
    ],
    [
        "8206",
        "FAUSKE"
    ],
    [
        "8207",
        "FAUSKE"
    ],
    [
        "8208",
        "FAUSKE"
    ],
    [
        "8209",
        "FAUSKE"
    ],
    [
        "8210",
        "FAUSKE"
    ],
    [
        "8211",
        "FAUSKE"
    ],
    [
        "8214",
        "FAUSKE"
    ],
    [
        "8215",
        "VALNESFJORD"
    ],
    [
        "8218",
        "FAUSKE"
    ],
    [
        "8219",
        "FAUSKE"
    ],
    [
        "8220",
        "RØSVIK"
    ],
    [
        "8226",
        "STRAUMEN"
    ],
    [
        "8230",
        "SULITJELMA"
    ],
    [
        "8231",
        "SULITJELMA"
    ],
    [
        "8232",
        "STRAUMEN"
    ],
    [
        "8233",
        "VALNESFJORD"
    ],
    [
        "8250",
        "ROGNAN"
    ],
    [
        "8251",
        "ROGNAN"
    ],
    [
        "8252",
        "ROGNAN"
    ],
    [
        "8253",
        "ROGNAN"
    ],
    [
        "8255",
        "RØKLAND"
    ],
    [
        "8256",
        "RØKLAND"
    ],
    [
        "8260",
        "INNHAVET"
    ],
    [
        "8261",
        "INNHAVET"
    ],
    [
        "8264",
        "ENGAN"
    ],
    [
        "8266",
        "MØRSVIKBOTN"
    ],
    [
        "8270",
        "DRAG"
    ],
    [
        "8271",
        "DRAG"
    ],
    [
        "8273",
        "NEVERVIK"
    ],
    [
        "8274",
        "MUSKEN"
    ],
    [
        "8275",
        "STORJORD I TYSFJORD"
    ],
    [
        "8276",
        "ULVSVÅG"
    ],
    [
        "8278",
        "STORÅ"
    ],
    [
        "8281",
        "LEINESFJORD"
    ],
    [
        "8283",
        "LEINESFJORD"
    ],
    [
        "8285",
        "LEINES"
    ],
    [
        "8286",
        "NORDFOLD"
    ],
    [
        "8287",
        "ENGELØYA"
    ],
    [
        "8288",
        "BOGØY"
    ],
    [
        "8289",
        "ENGELØYA"
    ],
    [
        "8290",
        "SKUTVIK"
    ],
    [
        "8294",
        "HAMARØY"
    ],
    [
        "8297",
        "TRANØY"
    ],
    [
        "8298",
        "HAMARØY"
    ],
    [
        "8300",
        "SVOLVÆR"
    ],
    [
        "8301",
        "SVOLVÆR"
    ],
    [
        "8305",
        "SVOLVÆR"
    ],
    [
        "8309",
        "KABELVÅG"
    ],
    [
        "8310",
        "KABELVÅG"
    ],
    [
        "8311",
        "HENNINGSVÆR"
    ],
    [
        "8312",
        "HENNINGSVÆR"
    ],
    [
        "8313",
        "KLEPPSTAD"
    ],
    [
        "8314",
        "GIMSØYSAND"
    ],
    [
        "8315",
        "LAUKVIK"
    ],
    [
        "8316",
        "LAUPSTAD"
    ],
    [
        "8317",
        "STRØNSTAD"
    ],
    [
        "8320",
        "SKROVA"
    ],
    [
        "8322",
        "BRETTESNES"
    ],
    [
        "8323",
        "STORFJELL"
    ],
    [
        "8324",
        "DIGERMULEN"
    ],
    [
        "8325",
        "TENGELFJORD"
    ],
    [
        "8326",
        "MYRLAND"
    ],
    [
        "8328",
        "STORMOLLA"
    ],
    [
        "8340",
        "STAMSUND"
    ],
    [
        "8352",
        "SENNESVIK"
    ],
    [
        "8357",
        "VALBERG"
    ],
    [
        "8360",
        "BØSTAD"
    ],
    [
        "8361",
        "BØSTAD"
    ],
    [
        "8370",
        "LEKNES"
    ],
    [
        "8372",
        "GRAVDAL"
    ],
    [
        "8373",
        "BALLSTAD"
    ],
    [
        "8374",
        "BALLSTAD"
    ],
    [
        "8376",
        "LEKNES"
    ],
    [
        "8377",
        "GRAVDAL"
    ],
    [
        "8378",
        "STAMSUND"
    ],
    [
        "8380",
        "RAMBERG"
    ],
    [
        "8382",
        "NAPP"
    ],
    [
        "8384",
        "SUND I LOFOTEN"
    ],
    [
        "8387",
        "FREDVANG"
    ],
    [
        "8388",
        "RAMBERG"
    ],
    [
        "8390",
        "REINE"
    ],
    [
        "8392",
        "SØRVÅGEN"
    ],
    [
        "8393",
        "SØRVÅGEN"
    ],
    [
        "8398",
        "REINE"
    ],
    [
        "8400",
        "SORTLAND"
    ],
    [
        "8401",
        "SORTLAND"
    ],
    [
        "8402",
        "SORTLAND"
    ],
    [
        "8403",
        "SORTLAND"
    ],
    [
        "8404",
        "SORTLAND"
    ],
    [
        "8405",
        "SORTLAND"
    ],
    [
        "8406",
        "SORTLAND"
    ],
    [
        "8407",
        "SORTLAND"
    ],
    [
        "8408",
        "SORTLAND"
    ],
    [
        "8409",
        "GULLESFJORD"
    ],
    [
        "8410",
        "LØDINGEN"
    ],
    [
        "8411",
        "LØDINGEN"
    ],
    [
        "8412",
        "VESTBYGD"
    ],
    [
        "8413",
        "KVITNES"
    ],
    [
        "8414",
        "HENNES"
    ],
    [
        "8415",
        "SORTLAND"
    ],
    [
        "8416",
        "SORTLAND"
    ],
    [
        "8419",
        "SORTLAND"
    ],
    [
        "8426",
        "BARKESTAD"
    ],
    [
        "8428",
        "TUNSTAD"
    ],
    [
        "8430",
        "MYRE"
    ],
    [
        "8432",
        "ALSVÅG"
    ],
    [
        "8438",
        "STØ"
    ],
    [
        "8439",
        "MYRE"
    ],
    [
        "8445",
        "MELBU"
    ],
    [
        "8447",
        "LONKAN"
    ],
    [
        "8450",
        "STOKMARKNES"
    ],
    [
        "8455",
        "STOKMARKNES"
    ],
    [
        "8459",
        "MELBU"
    ],
    [
        "8465",
        "STRAUMSJØEN"
    ],
    [
        "8469",
        "BØ I VESTERÅLEN"
    ],
    [
        "8470",
        "BØ I VESTERÅLEN"
    ],
    [
        "8475",
        "STRAUMSJØEN"
    ],
    [
        "8480",
        "ANDENES"
    ],
    [
        "8481",
        "BLEIK"
    ],
    [
        "8483",
        "ANDENES"
    ],
    [
        "8484",
        "RISØYHAMN"
    ],
    [
        "8485",
        "DVERBERG"
    ],
    [
        "8488",
        "NØSS"
    ],
    [
        "8489",
        "NORDMELA"
    ],
    [
        "8493",
        "RISØYHAMN"
    ],
    [
        "8501",
        "NARVIK"
    ],
    [
        "8502",
        "NARVIK"
    ],
    [
        "8503",
        "NARVIK"
    ],
    [
        "8504",
        "NARVIK"
    ],
    [
        "8505",
        "NARVIK"
    ],
    [
        "8506",
        "NARVIK"
    ],
    [
        "8507",
        "NARVIK"
    ],
    [
        "8508",
        "NARVIK"
    ],
    [
        "8509",
        "NARVIK"
    ],
    [
        "8510",
        "NARVIK"
    ],
    [
        "8512",
        "NARVIK"
    ],
    [
        "8513",
        "ANKENES"
    ],
    [
        "8514",
        "NARVIK"
    ],
    [
        "8515",
        "NARVIK"
    ],
    [
        "8516",
        "NARVIK"
    ],
    [
        "8517",
        "NARVIK"
    ],
    [
        "8518",
        "NARVIK"
    ],
    [
        "8519",
        "NARVIK"
    ],
    [
        "8520",
        "ANKENES"
    ],
    [
        "8521",
        "ANKENES"
    ],
    [
        "8522",
        "BEISFJORD"
    ],
    [
        "8523",
        "SKJOMEN"
    ],
    [
        "8530",
        "BJERKVIK"
    ],
    [
        "8531",
        "BJERKVIK"
    ],
    [
        "8533",
        "BOGEN I OFOTEN"
    ],
    [
        "8534",
        "LILAND"
    ],
    [
        "8535",
        "TÅRSTAD"
    ],
    [
        "8536",
        "EVENES"
    ],
    [
        "8539",
        "BOGEN I OFOTEN"
    ],
    [
        "8540",
        "BALLANGEN"
    ],
    [
        "8543",
        "KJELDEBOTN"
    ],
    [
        "8546",
        "BALLANGEN"
    ],
    [
        "8590",
        "KJØPSVIK"
    ],
    [
        "8591",
        "KJØPSVIK"
    ],
    [
        "8601",
        "MO I RANA"
    ],
    [
        "8602",
        "MO I RANA"
    ],
    [
        "8603",
        "MO I RANA"
    ],
    [
        "8604",
        "MO I RANA"
    ],
    [
        "8607",
        "MO I RANA"
    ],
    [
        "8608",
        "MO I RANA"
    ],
    [
        "8609",
        "MO I RANA"
    ],
    [
        "8610",
        "MO I RANA"
    ],
    [
        "8613",
        "MO I RANA"
    ],
    [
        "8614",
        "MO I RANA"
    ],
    [
        "8615",
        "SKONSENG"
    ],
    [
        "8616",
        "MO I RANA"
    ],
    [
        "8617",
        "DALSGRENDA"
    ],
    [
        "8618",
        "MO I RANA"
    ],
    [
        "8619",
        "MO I RANA"
    ],
    [
        "8622",
        "MO I RANA"
    ],
    [
        "8624",
        "MO I RANA"
    ],
    [
        "8626",
        "MO I RANA"
    ],
    [
        "8630",
        "STORFORSHEI"
    ],
    [
        "8634",
        "MO I RANA"
    ],
    [
        "8638",
        "STORFORSHEI"
    ],
    [
        "8640",
        "HEMNESBERGET"
    ],
    [
        "8641",
        "HEMNESBERGET"
    ],
    [
        "8642",
        "FINNEIDFJORD"
    ],
    [
        "8643",
        "BJERKA"
    ],
    [
        "8644",
        "BJERKA"
    ],
    [
        "8646",
        "KORGEN"
    ],
    [
        "8647",
        "BLEIKVASSLIA"
    ],
    [
        "8648",
        "KORGEN"
    ],
    [
        "8651",
        "MOSJØEN"
    ],
    [
        "8652",
        "MOSJØEN"
    ],
    [
        "8654",
        "MOSJØEN"
    ],
    [
        "8655",
        "MOSJØEN"
    ],
    [
        "8656",
        "MOSJØEN"
    ],
    [
        "8657",
        "MOSJØEN"
    ],
    [
        "8658",
        "MOSJØEN"
    ],
    [
        "8659",
        "MOSJØEN"
    ],
    [
        "8660",
        "MOSJØEN"
    ],
    [
        "8661",
        "MOSJØEN"
    ],
    [
        "8663",
        "MOSJØEN"
    ],
    [
        "8664",
        "MOSJØEN"
    ],
    [
        "8665",
        "MOSJØEN"
    ],
    [
        "8666",
        "MOSJØEN"
    ],
    [
        "8672",
        "ELSFJORD"
    ],
    [
        "8680",
        "TROFORS"
    ],
    [
        "8681",
        "TROFORS"
    ],
    [
        "8682",
        "TROFORS"
    ],
    [
        "8683",
        "TROFORS"
    ],
    [
        "8684",
        "TROFORS"
    ],
    [
        "8685",
        "TROFORS"
    ],
    [
        "8690",
        "HATTFJELLDAL"
    ],
    [
        "8691",
        "HATTFJELLDAL"
    ],
    [
        "8692",
        "HATTFJELLDAL"
    ],
    [
        "8693",
        "HATTFJELLDAL"
    ],
    [
        "8694",
        "HATTFJELLDAL"
    ],
    [
        "8695",
        "HATTFJELLDAL"
    ],
    [
        "8696",
        "HATTFJELLDAL"
    ],
    [
        "8700",
        "NESNA"
    ],
    [
        "8701",
        "NESNA"
    ],
    [
        "8720",
        "VIKHOLMEN"
    ],
    [
        "8723",
        "HUSBY"
    ],
    [
        "8724",
        "SAURA"
    ],
    [
        "8725",
        "UTSKARPEN"
    ],
    [
        "8730",
        "BRATLAND"
    ],
    [
        "8732",
        "ALDRA"
    ],
    [
        "8733",
        "STUVLAND"
    ],
    [
        "8735",
        "STOKKVÅGEN"
    ],
    [
        "8740",
        "NORD-SOLVÆR"
    ],
    [
        "8742",
        "SELVÆR"
    ],
    [
        "8743",
        "INDRE KVARØY"
    ],
    [
        "8750",
        "TONNES"
    ],
    [
        "8752",
        "KONSVIKOSEN"
    ],
    [
        "8753",
        "KONSVIKOSEN"
    ],
    [
        "8754",
        "ØRESVIK"
    ],
    [
        "8762",
        "SLENESET"
    ],
    [
        "8764",
        "LOVUND"
    ],
    [
        "8766",
        "LURØY"
    ],
    [
        "8767",
        "LURØY"
    ],
    [
        "8770",
        "TRÆNA"
    ],
    [
        "8800",
        "SANDNESSJØEN"
    ],
    [
        "8801",
        "SANDNESSJØEN"
    ],
    [
        "8802",
        "SANDNESSJØEN"
    ],
    [
        "8803",
        "SANDNESSJØEN"
    ],
    [
        "8804",
        "SANDNESSJØEN"
    ],
    [
        "8805",
        "SANDNESSJØEN"
    ],
    [
        "8809",
        "SANDNESSJØEN"
    ],
    [
        "8813",
        "LØKTA"
    ],
    [
        "8820",
        "DØNNA"
    ],
    [
        "8827",
        "DØNNA"
    ],
    [
        "8830",
        "VANDVE"
    ],
    [
        "8842",
        "BRASØY"
    ],
    [
        "8844",
        "SANDVÆR"
    ],
    [
        "8850",
        "HERØY"
    ],
    [
        "8851",
        "HERØY"
    ],
    [
        "8852",
        "HERØY"
    ],
    [
        "8854",
        "AUSTBØ"
    ],
    [
        "8860",
        "TJØTTA"
    ],
    [
        "8861",
        "TJØTTA"
    ],
    [
        "8865",
        "TRO"
    ],
    [
        "8870",
        "VISTHUS"
    ],
    [
        "8880",
        "BÆRØYVÅGEN"
    ],
    [
        "8890",
        "LEIRFJORD"
    ],
    [
        "8891",
        "LEIRFJORD"
    ],
    [
        "8892",
        "SUNDØY"
    ],
    [
        "8897",
        "BARDAL"
    ],
    [
        "8900",
        "BRØNNØYSUND"
    ],
    [
        "8901",
        "BRØNNØYSUND"
    ],
    [
        "8902",
        "BRØNNØYSUND"
    ],
    [
        "8904",
        "BRØNNØYSUND"
    ],
    [
        "8905",
        "BRØNNØYSUND"
    ],
    [
        "8906",
        "BRØNNØYSUND"
    ],
    [
        "8907",
        "BRØNNØYSUND"
    ],
    [
        "8908",
        "BRØNNØYSUND"
    ],
    [
        "8909",
        "BRØNNØYSUND"
    ],
    [
        "8910",
        "BRØNNØYSUND"
    ],
    [
        "8920",
        "SØMNA"
    ],
    [
        "8921",
        "SØMNA"
    ],
    [
        "8922",
        "SØMNA"
    ],
    [
        "8960",
        "VELFJORD"
    ],
    [
        "8961",
        "VELFJORD"
    ],
    [
        "8976",
        "VEVELSTAD"
    ],
    [
        "8977",
        "VEVELSTAD"
    ],
    [
        "8980",
        "VEGA"
    ],
    [
        "8981",
        "VEGA"
    ],
    [
        "8982",
        "VEGA"
    ],
    [
        "8983",
        "VEGA"
    ],
    [
        "8984",
        "VEGA"
    ],
    [
        "8985",
        "YLVINGEN"
    ],
    [
        "8986",
        "VEGA"
    ],
    [
        "9006",
        "TROMSØ"
    ],
    [
        "9007",
        "TROMSØ"
    ],
    [
        "9008",
        "TROMSØ"
    ],
    [
        "9009",
        "TROMSØ"
    ],
    [
        "9010",
        "TROMSØ"
    ],
    [
        "9011",
        "TROMSØ"
    ],
    [
        "9012",
        "TROMSØ"
    ],
    [
        "9013",
        "TROMSØ"
    ],
    [
        "9014",
        "TROMSØ"
    ],
    [
        "9015",
        "TROMSØ"
    ],
    [
        "9016",
        "TROMSØ"
    ],
    [
        "9017",
        "TROMSØ"
    ],
    [
        "9018",
        "TROMSØ"
    ],
    [
        "9019",
        "TROMSØ"
    ],
    [
        "9020",
        "TROMSDALEN"
    ],
    [
        "9021",
        "TROMSDALEN"
    ],
    [
        "9022",
        "KROKELVDALEN"
    ],
    [
        "9023",
        "KROKELVDALEN"
    ],
    [
        "9024",
        "TOMASJORD"
    ],
    [
        "9027",
        "RAMFJORDBOTN"
    ],
    [
        "9029",
        "TROMSDALEN"
    ],
    [
        "9030",
        "SJURSNES"
    ],
    [
        "9034",
        "OLDERVIK"
    ],
    [
        "9037",
        "TROMSØ"
    ],
    [
        "9038",
        "TROMSØ"
    ],
    [
        "9040",
        "NORDKJOSBOTN"
    ],
    [
        "9042",
        "LAKSVATN"
    ],
    [
        "9043",
        "JØVIK"
    ],
    [
        "9046",
        "OTEREN"
    ],
    [
        "9049",
        "NORDKJOSBOTN"
    ],
    [
        "9050",
        "STORSTEINNES"
    ],
    [
        "9055",
        "MEISTERVIK"
    ],
    [
        "9056",
        "MORTENHALS"
    ],
    [
        "9057",
        "VIKRAN"
    ],
    [
        "9059",
        "STORSTEINNES"
    ],
    [
        "9060",
        "LYNGSEIDET"
    ],
    [
        "9062",
        "FURUFLATEN"
    ],
    [
        "9064",
        "SVENSBY"
    ],
    [
        "9068",
        "NORD-LENANGEN"
    ],
    [
        "9069",
        "LYNGSEIDET"
    ],
    [
        "9100",
        "KVALØYSLETTA"
    ],
    [
        "9101",
        "KVALØYSLETTA"
    ],
    [
        "9102",
        "KVALØYSLETTA"
    ],
    [
        "9103",
        "KVALØYA"
    ],
    [
        "9104",
        "KVALØYA"
    ],
    [
        "9105",
        "KVALØYA"
    ],
    [
        "9106",
        "STRAUMSBUKTA"
    ],
    [
        "9107",
        "KVALØYA"
    ],
    [
        "9108",
        "KVALØYA"
    ],
    [
        "9109",
        "KVALØYA"
    ],
    [
        "9110",
        "SOMMARØY"
    ],
    [
        "9118",
        "BRENSHOLMEN"
    ],
    [
        "9119",
        "SOMMARØY"
    ],
    [
        "9120",
        "VENGSØY"
    ],
    [
        "9128",
        "TUSSØY"
    ],
    [
        "9130",
        "HANSNES"
    ],
    [
        "9131",
        "KÅRVIK"
    ],
    [
        "9132",
        "STAKKVIK"
    ],
    [
        "9134",
        "HANSNES"
    ],
    [
        "9135",
        "VANNVÅG"
    ],
    [
        "9136",
        "VANNAREID"
    ],
    [
        "9137",
        "VANNVÅG"
    ],
    [
        "9138",
        "KARLSØY"
    ],
    [
        "9140",
        "REBBENES"
    ],
    [
        "9141",
        "MJØLVIK"
    ],
    [
        "9142",
        "SKIBOTN"
    ],
    [
        "9143",
        "SKIBOTN"
    ],
    [
        "9144",
        "SAMUELSBERG"
    ],
    [
        "9145",
        "SAMUELSBERG"
    ],
    [
        "9146",
        "OLDERDALEN"
    ],
    [
        "9147",
        "BIRTAVARRE"
    ],
    [
        "9148",
        "OLDERDALEN"
    ],
    [
        "9149",
        "BIRTAVARRE"
    ],
    [
        "9151",
        "STORSLETT"
    ],
    [
        "9152",
        "SØRKJOSEN"
    ],
    [
        "9153",
        "ROTSUND"
    ],
    [
        "9154",
        "STORSLETT"
    ],
    [
        "9155",
        "SØRKJOSEN"
    ],
    [
        "9156",
        "STORSLETT"
    ],
    [
        "9157",
        "STORSLETT"
    ],
    [
        "9158",
        "STORSLETT"
    ],
    [
        "9159",
        "HAVNNES"
    ],
    [
        "9161",
        "BURFJORD"
    ],
    [
        "9162",
        "SØRSTRAUMEN"
    ],
    [
        "9163",
        "JØKELFJORD"
    ],
    [
        "9169",
        "BURFJORD"
    ],
    [
        "9170",
        "LONGYEARBYEN"
    ],
    [
        "9171",
        "LONGYEARBYEN"
    ],
    [
        "9173",
        "NY-ÅLESUND"
    ],
    [
        "9174",
        "HOPEN"
    ],
    [
        "9175",
        "SVEAGRUVA"
    ],
    [
        "9176",
        "BJØRNØYA"
    ],
    [
        "9178",
        "BARENTSBURG"
    ],
    [
        "9180",
        "SKJERVØY"
    ],
    [
        "9181",
        "HAMNEIDET"
    ],
    [
        "9182",
        "SEGLVIK"
    ],
    [
        "9184",
        "REINFJORD"
    ],
    [
        "9185",
        "SPILDRA"
    ],
    [
        "9186",
        "ANDSNES"
    ],
    [
        "9187",
        "VALANHAMN"
    ],
    [
        "9189",
        "SKJERVØY"
    ],
    [
        "9190",
        "AKKARVIK"
    ],
    [
        "9192",
        "ARNØYHAMN"
    ],
    [
        "9193",
        "NIKKEBY"
    ],
    [
        "9194",
        "LAUKSLETTA"
    ],
    [
        "9195",
        "ÅRVIKSAND"
    ],
    [
        "9197",
        "ULØYBUKT"
    ],
    [
        "9240",
        "TROMSØ"
    ],
    [
        "9251",
        "TROMSØ"
    ],
    [
        "9252",
        "TROMSØ"
    ],
    [
        "9253",
        "TROMSØ"
    ],
    [
        "9254",
        "TROMSØ"
    ],
    [
        "9255",
        "TROMSØ"
    ],
    [
        "9256",
        "TROMSØ"
    ],
    [
        "9257",
        "TROMSØ"
    ],
    [
        "9258",
        "TROMSØ"
    ],
    [
        "9259",
        "TROMSØ"
    ],
    [
        "9260",
        "TROMSØ"
    ],
    [
        "9261",
        "TROMSØ"
    ],
    [
        "9262",
        "TROMSØ"
    ],
    [
        "9263",
        "TROMSØ"
    ],
    [
        "9265",
        "TROMSØ"
    ],
    [
        "9266",
        "TROMSØ"
    ],
    [
        "9267",
        "TROMSØ"
    ],
    [
        "9268",
        "TROMSØ"
    ],
    [
        "9269",
        "TROMSØ"
    ],
    [
        "9270",
        "TROMSØ"
    ],
    [
        "9271",
        "TROMSØ"
    ],
    [
        "9272",
        "TROMSØ"
    ],
    [
        "9273",
        "TROMSØ"
    ],
    [
        "9274",
        "TROMSØ"
    ],
    [
        "9275",
        "TROMSØ"
    ],
    [
        "9276",
        "TROMSØ"
    ],
    [
        "9277",
        "TROMSØ"
    ],
    [
        "9278",
        "TROMSØ"
    ],
    [
        "9279",
        "TROMSØ"
    ],
    [
        "9280",
        "TROMSØ"
    ],
    [
        "9281",
        "TROMSØ"
    ],
    [
        "9282",
        "TROMSØ"
    ],
    [
        "9283",
        "TROMSØ"
    ],
    [
        "9284",
        "TROMSØ"
    ],
    [
        "9285",
        "TROMSØ"
    ],
    [
        "9286",
        "TROMSØ"
    ],
    [
        "9287",
        "TROMSØ"
    ],
    [
        "9288",
        "TROMSØ"
    ],
    [
        "9290",
        "TROMSØ"
    ],
    [
        "9291",
        "TROMSØ"
    ],
    [
        "9292",
        "TROMSØ"
    ],
    [
        "9293",
        "TROMSØ"
    ],
    [
        "9294",
        "TROMSØ"
    ],
    [
        "9296",
        "TROMSØ"
    ],
    [
        "9298",
        "TROMSØ"
    ],
    [
        "9299",
        "TROMSØ"
    ],
    [
        "9300",
        "FINNSNES"
    ],
    [
        "9302",
        "ROSSFJORDSTRAUMEN"
    ],
    [
        "9303",
        "SILSAND"
    ],
    [
        "9304",
        "VANGSVIK"
    ],
    [
        "9305",
        "FINNSNES"
    ],
    [
        "9306",
        "FINNSNES"
    ],
    [
        "9307",
        "FINNSNES"
    ],
    [
        "9308",
        "FINNSNES"
    ],
    [
        "9309",
        "FINNSNES"
    ],
    [
        "9310",
        "SØRREISA"
    ],
    [
        "9311",
        "BRØSTADBOTN"
    ],
    [
        "9315",
        "SØRREISA"
    ],
    [
        "9316",
        "BRØSTADBOTN"
    ],
    [
        "9321",
        "MOEN"
    ],
    [
        "9322",
        "KARLSTAD"
    ],
    [
        "9325",
        "BARDUFOSS"
    ],
    [
        "9326",
        "BARDUFOSS"
    ],
    [
        "9329",
        "MOEN"
    ],
    [
        "9334",
        "ØVERBYGD"
    ],
    [
        "9335",
        "ØVERBYGD"
    ],
    [
        "9336",
        "RUNDHAUG"
    ],
    [
        "9350",
        "SJØVEGAN"
    ],
    [
        "9355",
        "SJØVEGAN"
    ],
    [
        "9357",
        "TENNEVOLL"
    ],
    [
        "9358",
        "TENNEVOLL"
    ],
    [
        "9360",
        "BARDU"
    ],
    [
        "9365",
        "BARDU"
    ],
    [
        "9370",
        "SILSAND"
    ],
    [
        "9372",
        "GIBOSTAD"
    ],
    [
        "9373",
        "BOTNHAMN"
    ],
    [
        "9376",
        "SKATVIK"
    ],
    [
        "9379",
        "GRYLLEFJORD"
    ],
    [
        "9380",
        "GRYLLEFJORD"
    ],
    [
        "9381",
        "TORSKEN"
    ],
    [
        "9382",
        "GIBOSTAD"
    ],
    [
        "9384",
        "SKALAND"
    ],
    [
        "9385",
        "SKALAND"
    ],
    [
        "9386",
        "SENJAHOPEN"
    ],
    [
        "9387",
        "SENJAHOPEN"
    ],
    [
        "9388",
        "FJORDGARD"
    ],
    [
        "9389",
        "HUSØY I SENJA"
    ],
    [
        "9391",
        "STONGLANDSEIDET"
    ],
    [
        "9392",
        "STONGLANDSEIDET"
    ],
    [
        "9393",
        "FLAKSTADVÅG"
    ],
    [
        "9395",
        "KALDFARNES"
    ],
    [
        "9402",
        "HARSTAD"
    ],
    [
        "9403",
        "HARSTAD"
    ],
    [
        "9404",
        "HARSTAD"
    ],
    [
        "9405",
        "HARSTAD"
    ],
    [
        "9406",
        "HARSTAD"
    ],
    [
        "9407",
        "HARSTAD"
    ],
    [
        "9408",
        "HARSTAD"
    ],
    [
        "9409",
        "HARSTAD"
    ],
    [
        "9411",
        "HARSTAD"
    ],
    [
        "9414",
        "HARSTAD"
    ],
    [
        "9415",
        "HARSTAD"
    ],
    [
        "9416",
        "HARSTAD"
    ],
    [
        "9419",
        "SØRVIK"
    ],
    [
        "9420",
        "LUNDENES"
    ],
    [
        "9423",
        "GRØTAVÆR"
    ],
    [
        "9424",
        "KJØTTA"
    ],
    [
        "9425",
        "SANDSØY"
    ],
    [
        "9426",
        "BJARKØY"
    ],
    [
        "9427",
        "MELØYVÆR"
    ],
    [
        "9430",
        "SANDTORG"
    ],
    [
        "9436",
        "KONGSVIK"
    ],
    [
        "9439",
        "EVENSKJER"
    ],
    [
        "9440",
        "EVENSKJER"
    ],
    [
        "9441",
        "FJELLDAL"
    ],
    [
        "9442",
        "RAMSUND"
    ],
    [
        "9443",
        "MYKLEBOSTAD"
    ],
    [
        "9444",
        "HOL I TJELDSUND"
    ],
    [
        "9445",
        "TOVIK"
    ],
    [
        "9446",
        "GROVFJORD"
    ],
    [
        "9447",
        "GROVFJORD"
    ],
    [
        "9448",
        "RAMSUND"
    ],
    [
        "9450",
        "HAMNVIK"
    ],
    [
        "9451",
        "HAMNVIK"
    ],
    [
        "9453",
        "KRÅKRØHAMN"
    ],
    [
        "9454",
        "ÅNSTAD"
    ],
    [
        "9455",
        "ENGENES"
    ],
    [
        "9456",
        "ENGENES"
    ],
    [
        "9470",
        "GRATANGEN"
    ],
    [
        "9471",
        "GRATANGEN"
    ],
    [
        "9475",
        "BORKENES"
    ],
    [
        "9476",
        "BORKENES"
    ],
    [
        "9479",
        "HARSTAD"
    ],
    [
        "9480",
        "HARSTAD"
    ],
    [
        "9481",
        "HARSTAD"
    ],
    [
        "9482",
        "HARSTAD"
    ],
    [
        "9483",
        "HARSTAD"
    ],
    [
        "9484",
        "HARSTAD"
    ],
    [
        "9485",
        "HARSTAD"
    ],
    [
        "9486",
        "HARSTAD"
    ],
    [
        "9487",
        "HARSTAD"
    ],
    [
        "9488",
        "HARSTAD"
    ],
    [
        "9489",
        "HARSTAD"
    ],
    [
        "9496",
        "HARSTAD"
    ],
    [
        "9497",
        "HARSTAD"
    ],
    [
        "9498",
        "HARSTAD"
    ],
    [
        "9501",
        "ALTA"
    ],
    [
        "9502",
        "ALTA"
    ],
    [
        "9503",
        "ALTA"
    ],
    [
        "9504",
        "ALTA"
    ],
    [
        "9505",
        "ALTA"
    ],
    [
        "9506",
        "ALTA"
    ],
    [
        "9507",
        "ALTA"
    ],
    [
        "9508",
        "ALTA"
    ],
    [
        "9509",
        "ALTA"
    ],
    [
        "9510",
        "ALTA"
    ],
    [
        "9511",
        "ALTA"
    ],
    [
        "9512",
        "ALTA"
    ],
    [
        "9513",
        "ALTA"
    ],
    [
        "9514",
        "ALTA"
    ],
    [
        "9515",
        "ALTA"
    ],
    [
        "9516",
        "ALTA"
    ],
    [
        "9517",
        "ALTA"
    ],
    [
        "9518",
        "ALTA"
    ],
    [
        "9519",
        "KVIBY"
    ],
    [
        "9520",
        "KAUTOKEINO"
    ],
    [
        "9521",
        "KAUTOKEINO"
    ],
    [
        "9522",
        "KAUTOKEINO"
    ],
    [
        "9523",
        "KAUTOKEINO"
    ],
    [
        "9524",
        "KAUTOKEINO"
    ],
    [
        "9525",
        "MAZE"
    ],
    [
        "9527",
        "KAUTOKEINO"
    ],
    [
        "9528",
        "KAUTOKEINO"
    ],
    [
        "9529",
        "KAUTOKEINO"
    ],
    [
        "9531",
        "KVALFJORD"
    ],
    [
        "9532",
        "HAKKSTABBEN"
    ],
    [
        "9533",
        "KONGSHUS"
    ],
    [
        "9536",
        "KORSFJORDEN"
    ],
    [
        "9537",
        "TVERRELVDALEN"
    ],
    [
        "9538",
        "ALTA"
    ],
    [
        "9540",
        "TALVIK"
    ],
    [
        "9541",
        "ALTA"
    ],
    [
        "9545",
        "LANGFJORDBOTN"
    ],
    [
        "9550",
        "ØKSFJORD"
    ],
    [
        "9580",
        "BERGSFJORD"
    ],
    [
        "9582",
        "NUVSVÅG"
    ],
    [
        "9583",
        "LANGFJORDHAMN"
    ],
    [
        "9584",
        "SØR-TVERRFJORD"
    ],
    [
        "9585",
        "SANDLAND"
    ],
    [
        "9586",
        "LOPPA"
    ],
    [
        "9587",
        "SKAVNAKK"
    ],
    [
        "9590",
        "HASVIK"
    ],
    [
        "9591",
        "HASVIK"
    ],
    [
        "9593",
        "BREIVIKBOTN"
    ],
    [
        "9595",
        "SØRVÆR"
    ],
    [
        "9600",
        "HAMMERFEST"
    ],
    [
        "9601",
        "HAMMERFEST"
    ],
    [
        "9602",
        "HAMMERFEST"
    ],
    [
        "9603",
        "HAMMERFEST"
    ],
    [
        "9609",
        "NORDRE SEILAND"
    ],
    [
        "9610",
        "RYPEFJORD"
    ],
    [
        "9611",
        "RYPEFJORD"
    ],
    [
        "9612",
        "FORSØL"
    ],
    [
        "9615",
        "HAMMERFEST"
    ],
    [
        "9616",
        "HAMMERFEST"
    ],
    [
        "9620",
        "KVALSUND"
    ],
    [
        "9621",
        "KVALSUND"
    ],
    [
        "9624",
        "REVSNESHAMN"
    ],
    [
        "9650",
        "AKKARFJORD"
    ],
    [
        "9651",
        "LANGSTRAND"
    ],
    [
        "9657",
        "KÅRHAMN"
    ],
    [
        "9664",
        "SANDØYBOTN"
    ],
    [
        "9670",
        "TUFJORD"
    ],
    [
        "9672",
        "INGØY"
    ],
    [
        "9690",
        "HAVØYSUND"
    ],
    [
        "9691",
        "HAVØYSUND"
    ],
    [
        "9692",
        "MÅSØY"
    ],
    [
        "9700",
        "LAKSELV"
    ],
    [
        "9709",
        "PORSANGMOEN"
    ],
    [
        "9710",
        "INDRE BILLEFJORD"
    ],
    [
        "9711",
        "LAKSELV"
    ],
    [
        "9712",
        "LAKSELV"
    ],
    [
        "9713",
        "RUSSENES"
    ],
    [
        "9714",
        "SNEFJORD"
    ],
    [
        "9715",
        "KOKELV"
    ],
    [
        "9716",
        "BØRSELV"
    ],
    [
        "9717",
        "VEIDNESKLUBBEN"
    ],
    [
        "9722",
        "SKOGANVARRE"
    ],
    [
        "9730",
        "KARASJOK"
    ],
    [
        "9731",
        "KARASJOK"
    ],
    [
        "9732",
        "KARASJOK"
    ],
    [
        "9733",
        "KARASJOK"
    ],
    [
        "9734",
        "KARASJOK"
    ],
    [
        "9735",
        "KARASJOK"
    ],
    [
        "9736",
        "KARASJOK"
    ],
    [
        "9737",
        "KARASJOK"
    ],
    [
        "9740",
        "LEBESBY"
    ],
    [
        "9742",
        "KUNES"
    ],
    [
        "9750",
        "HONNINGSVÅG"
    ],
    [
        "9751",
        "HONNINGSVÅG"
    ],
    [
        "9760",
        "NORDVÅGEN"
    ],
    [
        "9763",
        "SKARSVÅG"
    ],
    [
        "9764",
        "NORDKAPP"
    ],
    [
        "9765",
        "GJESVÆR"
    ],
    [
        "9768",
        "REPVÅG"
    ],
    [
        "9770",
        "MEHAMN"
    ],
    [
        "9771",
        "SKJÅNES"
    ],
    [
        "9772",
        "LANGFJORDNES"
    ],
    [
        "9773",
        "NERVEI"
    ],
    [
        "9775",
        "GAMVIK"
    ],
    [
        "9782",
        "DYFJORD"
    ],
    [
        "9790",
        "KJØLLEFJORD"
    ],
    [
        "9800",
        "VADSØ"
    ],
    [
        "9801",
        "VADSØ"
    ],
    [
        "9802",
        "VESTRE JAKOBSELV"
    ],
    [
        "9803",
        "VADSØ"
    ],
    [
        "9804",
        "VADSØ"
    ],
    [
        "9810",
        "VESTRE JAKOBSELV"
    ],
    [
        "9811",
        "VADSØ"
    ],
    [
        "9815",
        "VADSØ"
    ],
    [
        "9820",
        "VARANGERBOTN"
    ],
    [
        "9826",
        "SIRMA"
    ],
    [
        "9840",
        "VARANGERBOTN"
    ],
    [
        "9841",
        "TANA"
    ],
    [
        "9842",
        "TANA"
    ],
    [
        "9843",
        "TANA"
    ],
    [
        "9844",
        "TANA"
    ],
    [
        "9845",
        "TANA"
    ],
    [
        "9846",
        "TANA"
    ],
    [
        "9900",
        "KIRKENES"
    ],
    [
        "9901",
        "KIRKENES"
    ],
    [
        "9910",
        "BJØRNEVATN"
    ],
    [
        "9911",
        "JARFJORD"
    ],
    [
        "9912",
        "HESSENG"
    ],
    [
        "9914",
        "BJØRNEVATN"
    ],
    [
        "9915",
        "KIRKENES"
    ],
    [
        "9916",
        "HESSENG"
    ],
    [
        "9917",
        "KIRKENES"
    ],
    [
        "9925",
        "SVANVIK"
    ],
    [
        "9930",
        "NEIDEN"
    ],
    [
        "9935",
        "BUGØYNES"
    ],
    [
        "9950",
        "VARDØ"
    ],
    [
        "9951",
        "VARDØ"
    ],
    [
        "9960",
        "KIBERG"
    ],
    [
        "9980",
        "BERLEVÅG"
    ],
    [
        "9981",
        "BERLEVÅG"
    ],
    [
        "9982",
        "KONGSFJORD"
    ],
    [
        "9990",
        "BÅTSFJORD"
    ],
    [
        "9991",
        "BÅTSFJORD"
    ]
];

export {postkode};