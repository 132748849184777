import React, { Component } from "react";
import SettingsMenu from '../Settings/SettingsMenu';

import './../clients.css';
import LoggedInDocuments from "../Documents/LoggedInDocuments";
import ClientStats from "./ClientStats";
import ClientOptions from "./ClientOptions";

class ClientCard extends Component {
    constructor () {
        super();
        this.state = {
        tab: {
            details: true,
            documents: false,
            stats: false,
            options: false
        }
      }
    }
    
    closeMenu = () => {
      const { clientController } = {...this.props};
      clientController.close();
    }
  
    openTab = (selected) => {
      let tab = {
        details: false,
        structure: false,
        documents: false,
        stats: false,
        options: false
      }
      tab[selected]=true;
      this.setState({tab});
    }
  

  render (){
    let {client, documents, documentOptions, openFullPreview, lang, editSetting, showPopup} = this.props;
    const {tab} = this.state;
    const menu = ['details','stats','documents','options'];
    const backgroundImage = 'client.svg';
    const isEmpty = documents.length > 0 ? false : true;
    return (  
    <div className="client-background">
        <div className="client-window">
            <div className='popup-topbeam' style={{zIndex: 10, backgroundImage: 'url(./'+backgroundImage}}><h1>{client.clientname}</h1>
            {<div className='popup-close-button' onClick={this.closeMenu}>X</div>}</div>
                <div className="client-card">    
                  {<div>
                    <p>
                      {client.address + ', ' + client.postnr + ' ' + client.post}
                    </p>
                  <ClientStats
                      lang={lang}
                     documents={documents} />
                  <ClientOptions 
                    isEmpty={isEmpty}
                    lang={lang} 
                    editSetting={editSetting} 
                    cid={client.cid}
                    removed={client.deleted}
                    archived={client.archived}
                    showPopup={showPopup}
                  />
                  </div>
                  }
                  {<LoggedInDocuments
                    lang={lang}
                    documents={documents} 
                    documentOptions={documentOptions}
                    openFullPreview={openFullPreview}
                    view={'cards'} /> }    
                </div>
        </div>
    </div>
    );
  }
}

export default ClientCard;
