import React, { Component } from "react";



class StatsKnob extends Component {
  

  render (){
    let {value, username, count, type, limit, limitExpected} = {...this.props};
    let setClass = username === 'project-total' ? 'stats-knob-selected' : 'stats-knob';
      return (  
      <div className={setClass} style={{borderColor: limitExpected && limitExpected > 0 && username === 'project-total' ? '#00FF00' : !limitExpected ? 'initial' : '#FF0000'}}>
        <div className="clickable-panel"></div>
        <div className="stats-knob-value">
           <p>{value + type}{limit ? ' / ' + limit + 'h': ''}</p> 
        </div>
        <div className="stats-knob-option">
           <p>{username !== 'project-total' ? username : 'TOTAL'}</p> 
        </div>
        
      </div>
    );
  }
}

export default StatsKnob;
