import React, { Component } from "react";
import { chooseLanguage } from "../Lang/langpack";

class SettingsMenu extends Component {
  constructor () {
	  super();
    }

    openTab = e => {
        const selected = e.target.id;
        this.props.openTab(selected);
    }

  render (){
    const {menu, tab,lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      document: chooseLanguage('document',  langinterface),
      account: chooseLanguage('account',  langinterface), 
    }
    const translationMenu = [translation.document, translation.account];
    return (  
      <div className="settings-menu-wrapper">
        {menu && menu.map((item, index)=>(
            <div key={index}  className={tab[item] ? "settings-menu-item-selected" : "settings-menu-item"}>
                <div className="clickable-panel" id={item} onClick={this.openTab}>
                </div>
                <p>{translationMenu[index]}</p>
            </div>
        ))}

        </div>
    );
  }
}

export default SettingsMenu;
