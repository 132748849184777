import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';
import ProjectName from '../FormComponents/ProjectName';
import { chooseLanguage } from "../Lang/langpack";

class TimelistBasis extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.saveBasis = this.saveBasis.bind(this);
  }

  componentDidMount() {
  }

  saveBasis(event){
    let record = event.target.id,
      value = event.target.value;
      this.props.writeBasisToState(record, value);
  }

  render (){
    const {lang, projects} = this.props;
    const {langinterface} = lang;
    const translation = {
      workerName: chooseLanguage('workerName', langinterface),
      projectName: chooseLanguage('projectName', langinterface),
    }
    return (

      <Form.Group id='document-fieldset' as={Row} className="justify-content-center">
        <Form.Label column xs={12} lg={6}><h4>{translation.projectName}</h4></Form.Label>
        <Col xs={12} lg={6}>
        <ProjectName lang={lang} suggestions={projects} writeBasisToState={this.props.writeBasisToState} isValid={this.props.isValid} loggedprojectname={this.props.loggedprojectname}/>
 
         {!this.props.isValid.projectName && <div>
            Please input Project Name.
          </div>}
        </Col>
        <Form.Label column xs={12} lg={6}><h4>{translation.workerName}</h4></Form.Label>
        <Col xs={12} lg={6}>
        <Form.Control id='loggedname' type="text" placeholder={translation.workerName} value={this.props.loggedname} onChange={this.saveBasis} required/>
        {!this.props.isValid.username && <div>
            Please input Name.
          </div>}
        </Col>
      </Form.Group>
    );
  }
}

export default TimelistBasis;
