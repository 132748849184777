import React, { Component } from "react";
import {Tabs, Tab} from 'react-bootstrap';
import SettingKnob from "./SettingKnob";
import { chooseLanguage } from "../Lang/langpack";


class AccountSettings extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  render (){
    const {editSetting, username, craft} = this.props;
    console.log(craft);
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      username: chooseLanguage('username',  langinterface),
      password: chooseLanguage('password',  langinterface),
      email: chooseLanguage('email',  langinterface),  
      accountSettings: chooseLanguage('accountSettings',  langinterface),
      craft: chooseLanguage('craft', langinterface),
      crafts: chooseLanguage('crafts', langinterface),
      craftValues: chooseLanguage('crafts', 'EN'),
    }
    const selectedCraft = translation.craftValues.findIndex((craftValue)=>craft === craftValue);
    console.log(selectedCraft);
    return (
        <div className="settings-document-settings">
          <h3>{translation.accountSettings}</h3>
          <div className="knob-panel">
              <SettingKnob type='text' editSetting={editSetting} setting={{value: username, setting: 'username', option: translation.username}}/>
              <SettingKnob type='craft' editSetting={editSetting} setting={{value: translation.crafts[selectedCraft], setting: 'craft', option: translation.craft}}/>
              <SettingKnob type='password' editSetting={editSetting} setting={{value: '', setting: 'password', option: translation.password}}/>
              <SettingKnob type='email' editSetting={editSetting} setting={{value: '', setting: 'email', option: translation.email}}/>
          </div>
        </div>
    );
  }
}

export default AccountSettings;
