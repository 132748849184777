import React, { Component } from "react";


class MessageList extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

 
  componentDidMount() {

  }

  render (){
    return (
      <div id='message-list'>
      
        </div>  
    );
  }
}

export default MessageList;
