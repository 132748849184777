import React, { Component } from "react";
import { Form} from 'react-bootstrap';
import { chooseLanguage } from "../Lang/langpack";


class PopupNewProject extends Component {
  constructor () {
	  super();
	  this.state = {
      newProjectName: ''
    }
    this.saveProjectName = this.saveProjectName.bind(this);
    this.createNewProject = this.createNewProject.bind(this);
  }

  componentDidMount() {
  }

  saveProjectName(event) {
    let value = event.target.value;
    this.setState({
      newProjectName: value
    })
  }
  
  createNewProject(){
    const newProjectName = this.state.newProjectName;
    this.props.createNewProject(newProjectName);
  }

  render (){
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      newProject: chooseLanguage('newProject',  langinterface),
    }
    return (
        <div className="popup-new-project">
          <div>
            <Form.Control style={{width: '60%', display: 'inline'}} id='newProjectName' type="text" value={this.state.newProjectName !== '' ? this.state.newProjectName : ''}  placeholder={translation.newProject} onChange={this.saveProjectName} required/>
            <img src='./plus.svg' 
            id='newProject' 
            onClick={this.createNewProject}
            alt={translation.newProject}
            style={{width: '3vw', display: 'inline', marginLeft: '1.3vw', cursor: 'pointer', filter: 'invert(1)'}}/>
          </div>
        </div>
    );
  }
}

export default PopupNewProject;
