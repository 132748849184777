import React, { Component } from "react";
import SettingKnob from "../Settings/SettingKnob";
import { chooseLanguage } from "../Lang/langpack";


class ProjectTimeLimits extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }


  render (){
    const {workhours, editSetting, settings, lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      in: chooseLanguage('in',  langinterface),
      out: chooseLanguage('out',  langinterface),
      timeLimits: chooseLanguage('timeLimits',  langinterface), 
      projectLimit: chooseLanguage('projectLimit', langinterface), 
    }
    return ( 
      <div className="project-section">
          <p className="project-section-header">{translation.timeLimits}</p>
          <div className="knob-panel">
            <SettingKnob type='time' editSetting={editSetting} setting={{value: workhours.in, setting: 'projectin', option: translation.in}}/>
            <SettingKnob type='time' editSetting={editSetting} setting={{value: workhours.out, setting: 'projectout', option: translation.out}}/>
            <SettingKnob type='limit' editSetting={editSetting} setting={{value: settings.limit, setting: 'limit', option: translation.projectLimit}}/>
          </div>
      </div>  
    );
  }
}

export default ProjectTimeLimits;
