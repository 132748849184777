import React, { Component } from "react";
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import ClientData from './../FormComponents/ClientData';
import UserCompanyData from "./UserCompanyData";
import TilbudDatesModule from '../FormComponents/TilbudDatesModule';
import {serverRouteParser, requestObjectGenerator} from '../ServerRoutes/serverRoute';
import DocumentExportOptions from "../FormComponents/DocumentExportOptions";
import InvoiceRow from "./InvoiceRow";
import InvoiceHeader from "./InvoiceHeader";
import { postkode } from "./postkode";
import ClientDataLocked from "../FormComponents/ClientDataLocked";
import InvoiceRowMobile from "./InvoiceRowMobile";
import ProjectName from "../FormComponents/ProjectName";
import NewInvoiceWizardStepsHeader from "./NewInvoiceWizardStepsHeader";
import WizardStepClient from "./WizardStepClient";
import WizardStepProject from "./WizardStepProject";
import OfferPreview from "./OfferPreview";
import { chooseLanguage } from "../Lang/langpack";

class NewOfferWizard extends Component {
 
  render (){
    let {arrayLength, step, stepOptions,  tilbudPreview, client, clients, projects, editClientData, loggedprojectname, isValid, fakturadetails, tilbuddue, tilbudissue, position, writeClientToState, writeBasisToState, moveDown, moveUp, addNewRow, content, saveDate, savePost, deletePosition, writeToState, sendFormToExecute, lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      back: chooseLanguage('back', langinterface),
      next: chooseLanguage('next', langinterface),
      skip: chooseLanguage('skip', langinterface),
    }
    return (
      <Container id="tilbud" fluid>
          <NewInvoiceWizardStepsHeader lang={lang} step={step} stepOptions={stepOptions}/>
          {step === 1 && <Row>
            <Col xs={12} lg={12}>
            {<WizardStepClient
              lang={lang}
              savePost={savePost} 
              client={client} 
              writeClientToState={writeClientToState}
              suggestions={clients}
            />}
            </Col>
          </Row>}
          {step === 2 && <Row>
            <Col xs={12} lg={12}>
            {<WizardStepProject
              lang={lang}
              projects={projects} 
              isValid={isValid}
              loggedprojectname={loggedprojectname} 
              writeBasisToState={writeBasisToState}
            />}
            </Col>
          </Row>} 
          <Row>
            
          </Row>
          {step === 3 && position.length > 0 && 
            <Row>
                <Col xs={12} lg={12}>
                  <TilbudDatesModule 
                      lang={lang}
                      tilbuddue={tilbuddue} 
                      tilbudissue={tilbudissue} 
                      isValid={isValid}
                      saveDate={saveDate}
                      />
                </Col>
            </Row>
          }
          {

          //window.innerHeight < window.innerWidth &&<InvoiceHeader/>
          }
          {
            //window.innerHeight < window.innerWidth && position.map((position, index)=>(<InvoiceRow moveDown={MoveDown} moveUp={MoveUp} addNewRow={addNewRow} content={content[index]} deletePosition={deletePosition} writeToState={writeToState} key={index} position={position} arrayLength={position.length} index={index.toString()}/>))
          }
          {step === 4 && position.map((position, index)=>(<InvoiceRowMobile lang={lang} moveDown={moveDown} moveUp={moveUp} addNewRow={addNewRow} content={content[index]} deletePosition={deletePosition} writeToState={writeToState} key={index} position={position} arrayLength={arrayLength} index={index.toString()}/>))}
          {
          step === 5 && <OfferPreview lang={lang} document={tilbudPreview} sendFormToExecute={sendFormToExecute}/>
          }
          {step === 5 &&
            <div id='document-options-preview'>
            <DocumentExportOptions lang={lang} sendFormToExecute={sendFormToExecute}/>
          </div>
          }
          {step !== 5 &&
            <div id='document-options-preview'>
            <div id='new-document-export-options' className="knob-panel">
            <div className="option-knob" style={{display: step === 1 && "none"}}>
              <div className="clickable-panel" name="save" onClick={stepOptions.back}>
              </div>
              <p>{translation.back}</p>
              <div  className="document-export-ico"
                style={{
                backgroundImage: 'url("/arrow.svg")',
                filter: "invert(1)",
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                transform: 'rotate(180deg)',
                cursor: 'pointer'}}/>
            </div>
            <div className="option-knob"  style={{display: step !== 2 && "none"}} >
              <div className="clickable-panel" name="save" onClick={stepOptions.next}>
              </div>
              <p>{translation.skip}</p>
              <div  className="document-export-ico"
                style={{
                filter: "invert(1)",
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer'}}/>
            </div>
            <div className="option-knob"  style={{display: step === 5 && "none"}} >
              <div className="clickable-panel" name="save" onClick={stepOptions.next}>
              </div>
              <p>{translation.next}</p>
              <div  className="document-export-ico"
                style={{
                backgroundImage: 'url("/arrow.svg")',
                filter: "invert(1)",
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer'}}/>
            </div>
          </div>
          </div>
          }
      </Container>
    );
  }
}

export default NewOfferWizard;
