import React, { Component } from "react";
import { chooseLanguage } from "../Lang/langpack";

class NewInvoiceWizardStepsHeader extends Component {

  render (){
    const {step, stepOptions, lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      client: chooseLanguage('client', langinterface),
      project: chooseLanguage('project', langinterface),
      dates: chooseLanguage('dates', langinterface),
      document: chooseLanguage('document', langinterface),
      preview: chooseLanguage('preview', langinterface),
    }
      return (
        <div id='new-invoice-steps'>
          <div className={step === 1 ? 'step-done' : 'step-waiting'} onClick={()=>stepOptions.choose(1)}>
            <p></p>
          </div>
          <div className={step === 2 ? 'step-done' : 'step-waiting'} onClick={()=>stepOptions.choose(2)}>
            <p></p>
          </div>
          <div className={step === 3 ? 'step-done' : 'step-waiting'} onClick={()=>stepOptions.choose(3)}>
            <p></p>
          </div>
          <div className={step === 4 ? 'step-done' : 'step-waiting'} onClick={()=>stepOptions.choose(4)}>
            <p></p>
          </div>
          <div className={step === 5 ? 'step-done' : 'step-waiting'} onClick={()=>stepOptions.choose(5)}>
            <p></p>
          </div>
          <p onClick={stepOptions.choose}>
            {translation.client}
          </p>
          <p>
          {translation.project}
          </p>
          <p>
            {translation.dates}
          </p>
          <p>
            {translation.document}
          </p>
          <p>
            {translation.preview}
          </p>
        </div>
    );
  }
}

export default NewInvoiceWizardStepsHeader;
