import React, { Component } from "react";
import NewTimelist from "./NewTimelist";
import MinimizeButton from "./../FormComponents/MinimizeButton";
import CloseButton from "./../FormComponents/CloseButton";
import NewTimelistWizard from "./NewTimelistWizard";
import CreatorButton from "../FormComponents/CreatorButton";
import HelpButton from "../FormComponents/HelpButton";
import { localizeDateStr, dateToSlashString } from "../Features/dateFeatures";
import { postkode } from "./postkode";
import {chooseLanguage} from "./../Lang/langpack";
import DaysList from "./DaysList";

class NewTimelistContainer extends Component {
    constructor () {
        super();
        this.state = {
          minimized: false,
          step: 1,
          mode: 'creator',
          newRowIncreseDate: true,
          clientData: false,
          client: {
            clientname: "",
            address: "",
            postnr: "",
            post: "",
            email: "",
            mva: "",
          },
          did: false,
          days: [0],
          extra: [],
          files: [],
          km: [],
          loggedDaysArray: [],
          loggedday: [],
          loggedin: [],
          loggedout: [],
          loggedpaidbreak: [],
          loggedbreak: [],
          loggeddaydescription: [],
          loggedprojectname: '',
          pid: null,
          loggedname: '',
          timelistnr: 1,
          isValid: {projectName: true, username: true},
          stapled: [],
          editClientData: false,
          importDaysWindow: false,
      }
      this.minimizeDocument = this.minimizeDocument.bind(this);
      this.closeDocument = this.closeDocument.bind(this);
      this.maximizeDocument = this.maximizeDocument.bind(this);
      this.writeToState = this.writeToState.bind(this);
      this.writeBasisToState = this.writeBasisToState.bind(this);
      this.deleteDay = this.deleteDay.bind(this);
      this.sendFormToExecute = this.sendFormToExecute.bind(this);
      this.createStartValues = this.createStartValues.bind(this);
      this.addNewRow = this.addNewRow.bind(this);
      this.MoveUp = this.MoveUp.bind(this);
      this.MoveDown = this.MoveDown.bind(this);
      this.fillEditValues = this.fillEditValues.bind(this);
    }

    importDaysWindowOpen = () => {
      this.setState({
        importDaysWindow: true,
      })
    }

    importDaysWindowClose = () => {
      this.setState({
        importDaysWindow: false,
      })
    }
  
    saveDate = (value, position) => {
      let {loggedDaysArray, loggedday} = {...this.state};
      loggedday[position] = dateToSlashString(value);
      value = new Date(value);
      value = value.getTime();
      loggedDaysArray[position] = value;
      console.log(value, position)
      this.setState({
        loggedDaysArray, loggedday
      })
    }
  
    editClientData = () => {
      let editClientData = this.state.editClientData;
      editClientData = !editClientData;
      this.setState({editClientData})
    }
  
    savePost = (postnr) => 
    {
        let stringPostNr = new String(postnr);
        console.log(stringPostNr)
        let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === postnr);
        console.log(selectedCity);
        let client = {...this.state.client};
        client['postnr'] = postnr;
        client['post'] = selectedCity && selectedCity[0][1] ? selectedCity[0][1] : stringPostNr.length < 4 ? '' : client['post'];
        this.setState({client});
     
    }
  
    writeClientToState = (record, value) => {
      console.log("Record: " +  record + " Value: " + value);
      const clients = this.props.clients;
      const findClient = clients.filter((client)=>client.clientname === value);
      if(!findClient[0])
      {
        let client = {...this.state.client};
        client[record] = value;
        this.setState({
          client,
        })
      }
      if(findClient[0] && findClient.length === 1)
      {
        let client = findClient[0];
        this.setState({
          client,
        })
      }
      if(findClient[0] && findClient.length > 1)
      {
        //
      }
  
  }
  
  
    writeToState(record, position, value){
        let newValue= [...this.state[record]];
        newValue[position] = value;
        this.setState({
          [record]: newValue
        })
    }
  
    writeBasisToState(record, value)
    {
      this.setState({
        [record]: value
      })
    }
  
    sendFormToExecute(method) {
      let {
        client,
        clientData,
        loggedname,
        timelistnr,
        loggedprojectname,
        did,
        pid,
        loggedday,
        loggedin,
        loggedout,
        loggedpaidbreak,
        loggedbreak,
        loggeddaydescription,
        extra,
        km,
        files,
        stapled,
      } = {...this.state};
      const {days, deleteImportedDays} = this.props;
      let alreadyImported = days.map((day, index)=>{
        if (loggedday.indexOf(day.loggedday[0]) !== -1 && loggedin.indexOf(day.loggedin[0]) !== -1 && loggedout.indexOf(day.loggedout[0]) !== -1 && loggeddaydescription.indexOf(day.loggeddaydescription[0]) !== -1)
        { 
          return index;
        } 
        else
        { 
          return false;
        } 
      });
      alreadyImported = alreadyImported.reverse();
      deleteImportedDays(alreadyImported);
      const {generate, save} = {...this.props.documentOptions};
      if (loggedprojectname === null && loggedname === null)
      {
        this.setState({
          isValid: {
            username: false,
            projectName: false
          }
        })
      }
      else if (loggedprojectname === null)
      {
        this.setState({
          isValid: {
            username: true,
            projectName: false
          }
        })
      }
      else if (loggedname === null)
      {
        this.setState({
          isValid: {
            projectName: true,
            username: false
          }
        })
      }
      if (loggedname !== null && loggedprojectname !== null)
      {
        const timelist = {
          client: client,
          did: did,
          pid: pid,
          days: loggedday,
          in: loggedin,
          out: loggedout,
          breakpaid: loggedpaidbreak,
          breaktime: loggedbreak,
          daydescription: loggeddaydescription,
          project: loggedprojectname,
          username: loggedname,
          timelistnr: timelistnr, 
          extra: extra,
          km: km,
          files: files,
          stapled: stapled,
        }
        //console.log(method);
        method === 'save' && save('timelist', timelist);
        method === 'create' && generate('timelist', timelist);
      }
    }
  
    deleteDay(index)
    {
      //console.log('delete: '+index);
      let loggedDaysArray = this.state.loggedDaysArray,    
      loggedday = this.state.loggedday,
      loggedin = this.state.loggedin,
      loggedout = this.state.loggedout,
      loggedpaidbreak = this.state.loggedpaidbreak,
      loggedbreak = this.state.loggedbreak,
      loggeddaydescription = this.state.loggeddaydescription;
  
      loggedDaysArray.splice(index, 1);
      loggedday.splice(index, 1);
      loggedin.splice(index, 1);
      loggedout.splice(index, 1);
      loggedpaidbreak.splice(index, 1);
      loggedbreak.splice(index, 1);
      loggeddaydescription.splice(index, 1);
  
      this.setState({
        loggedDaysArray: loggedDaysArray,
        loggedday: loggedday,
        loggedin: loggedin,
        loggedout: loggedout,
        loggedpaidbreak: loggedpaidbreak,
        loggedbreak: loggedbreak,
        loggeddaydescription: loggeddaydescription,
      })
    }
    MoveUp(index)
    {
      console.log('movingUp...')
      function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };
      let loggedDaysArray = this.state.loggedDaysArray,    
      loggedday = this.state.loggedday,
      loggedin = this.state.loggedin,
      loggedout = this.state.loggedout,
      loggedpaidbreak = this.state.loggedpaidbreak,
      loggedbreak = this.state.loggedbreak,
      loggeddaydescription = this.state.loggeddaydescription,
      extra = this.state.extra,
      km = this.state.km,
      files = this.state.files;
        console.log('we\'re inside...')
        array_move(loggedDaysArray, index, index-1)    
        array_move(loggedday, index, index-1)
        array_move(loggedin, index, index-1)
        array_move(loggedout, index, index-1)
        array_move(loggedpaidbreak, index, index-1)
        array_move(loggedbreak, index, index-1)
        array_move(loggeddaydescription, index, index-1)
        array_move(extra, index, index-1);
        array_move(km, index, index-1);
        array_move(files, index, index-1);
      this.setState({
        loggedDaysArray: loggedDaysArray,
        loggedday: loggedday,
        loggedin: loggedin,
        loggedout: loggedout,
        loggedpaidbreak: loggedpaidbreak,
        loggedbreak: loggedbreak,
        loggeddaydescription: loggeddaydescription,
        extra: extra,
        km: km,
        files: files,
      })
    }
    MoveDown(index)
    {
      console.log('movingDown...')
      function array_move(arr, old_index, new_index) {
  
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };
      let loggedDaysArray = this.state.loggedDaysArray,    
      loggedday = this.state.loggedday,
      loggedin = this.state.loggedin,
      loggedout = this.state.loggedout,
      loggedpaidbreak = this.state.loggedpaidbreak,
      loggedbreak = this.state.loggedbreak,
      loggeddaydescription = this.state.loggeddaydescription,
      extra = this.state.extra,
      km = this.state.km,
      files = this.state.files;
        console.log('we\'re inside...')
        array_move(loggedDaysArray, index, 1+index)    
        array_move(loggedday, index, 1+index)
        array_move(loggedin, index, 1+index)
        array_move(loggedout, index, 1+index)
        array_move(loggedpaidbreak, index, 1+index)
        array_move(loggedbreak, index, 1+index)
        array_move(loggeddaydescription, index, 1+index)
        array_move(extra, index, 1+index);
        array_move(km, index, 1+index);
        array_move(files, index, 1+index);
      this.setState({
        loggedDaysArray: loggedDaysArray,
        loggedday: loggedday,
        loggedin: loggedin,
        loggedout: loggedout,
        loggedpaidbreak: loggedpaidbreak,
        loggedbreak: loggedbreak,
        loggeddaydescription: loggeddaydescription,
        extra: extra,
        km: km,
        files: files,
      })
    }
    
    addNewRow() {
      const pid = this.state.pid;
      const selectedProject = pid && this.props.projects.filter((project, index) => {
        return project.pid === pid 
      })
      const projectDefaultValues = selectedProject && selectedProject[0] ? {...selectedProject[0].details.workhours} : false;
      let defaultValues = projectDefaultValues ? projectDefaultValues : this.props.timelistdetails ? this.props.timelistdetails : false;
      defaultValues.breaktime = defaultValues.breaktime ? defaultValues.breaktime : this.props.timelistdetails.breaktime;
      const {newRowIncreseDate} = this.state;
      function localizeDateStr(date_to_convert_str) 
      {
        var date_to_convert = new Date(date_to_convert_str);
        var dateArray = [date_to_convert.getDate(), date_to_convert.getMonth()+1, date_to_convert.getFullYear(), date_to_convert.getHours(), date_to_convert.getMinutes(), date_to_convert.getSeconds()];
        let day = dateArray[0].toString();
        let month = dateArray[1].toString();
        dateArray[0] = day.length === 1 ? '0'+day : day;
        dateArray[1] = month.length === 1 ? '0'+month : month;
        var dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];             
        return dateString; 
      }
        const days = this.state.days;
        days.push(days.length);
        let loggedDaysArray = this.state.loggedDaysArray;
        let loggedday = this.state.loggedday;
        let loggedin = this.state.loggedin;
        let loggedout = this.state.loggedout;
        let loggedpaidbreak = this.state.loggedpaidbreak;
        let loggedbreak = this.state.loggedbreak;
        let loggeddaydescription = this.state.loggeddaydescription;
        let extra = this.state.extra,
        km = this.state.km,
        files = this.state.files;
        let dte = new Date(loggedDaysArray[loggedDaysArray.length-1]);
        let dayDirection = newRowIncreseDate ? +1 : +-1;
        let day = dte.setDate(dte.getDate() + dayDirection);
        loggedDaysArray.push(day);
        loggedday.push(localizeDateStr(day));
        loggedin.push(!defaultValues ? '08:00' : defaultValues.in.length === 5 ? defaultValues.in : '0'+defaultValues.in);
        loggedout.push(defaultValues ? defaultValues.out : '16:30');
        loggedpaidbreak.push(false);
        loggedbreak.push(defaultValues ? defaultValues.breaktime : '30');
        loggeddaydescription.push('');
        extra.push(false);
        km.push(false);
        files.push([]);
  
  
       // console.log(days)
        this.setState({
          days,
          loggedDaysArray,
          loggedday,
          loggedin,
          loggedout,
          loggedpaidbreak,
          loggedbreak,
          loggeddaydescription,
        })
    }
  
    fillEditValues(){
      const {creator} = this.props;
      const mode = creator ? 'creator' : 'page';
      let editedDocument = {...this.props.editedDocument};
      let client = editedDocument.client ? editedDocument.client : {
        clientname: "",
        address: "",
        postnr: "",
        post: "",
        email: "",
        mva: "",
      }; 
      let clientData = client.clientname !== "" || client.address ? true : false;
      let loggedday = [].concat(editedDocument.days);
      let days = loggedday.map((x,index)=>{return index});
      //console.log('loggedDays: ' + loggedday);
      let loggedDaysArray = editedDocument.days.map(x => Date.parse(x.slice(5) + '-' + x.slice(2,5) +  '-' + x.slice(0,2)));
      //console.log('loggedDaysArray: ' + loggedDaysArray);
      let loggedin = [].concat(editedDocument.in);
      let loggedout = [].concat(editedDocument.out);
      let loggedpaidbreak = [].concat(editedDocument.breakpaid);
      let loggedbreak = [].concat(editedDocument.breaktime);
      let loggeddaydescription = [].concat(editedDocument.daydescription);
      let loggedprojectname = ''+editedDocument.project;
      let loggedname = ''+editedDocument.username;
      let extra = [].concat(editedDocument.extra);
      let {stapled} = editedDocument;
      extra = !extra || extra.length < loggedpaidbreak.length ? loggedpaidbreak : extra;
      let km = [].concat(editedDocument.km);
      km = !km || km.length < loggedpaidbreak.length ? loggedpaidbreak : km;
      let files = [].concat(editedDocument.files);
      files = !files || files.length < loggedpaidbreak.length ? loggedpaidbreak : files;
      let {pid} = {...editedDocument};
      let edit = this.props.edit;
      let timelistnr = typeof edit === 'string' ? editedDocument.timelistnr : this.props.timelistdetails.timelistnr;
      let isValid = {projectName: true, username: true};
      let did = typeof edit === 'string' ? edit : false;
      this.setState({
          mode,
          client,
          clientData,
          did,
          pid,
          loggedDaysArray,
          days,
          extra,
          km,
          files,
          loggedday,
          loggedin,
          loggedout,
          loggedpaidbreak,
          loggedbreak,
          loggeddaydescription,
          loggedprojectname,
          loggedname,
          isValid,
          timelistnr,
          stapled: stapled ? stapled : [],
      })
     }
  
  
    createStartValues() {
      const {username, creator} = this.props;
      const mode = creator ? 'creator' : 'page';
      const days = this.state.days;
      const savedDays = this.props.days;
      const defaultValues = this.props.timelistdetails ? this.props.timelistdetails : false;
      let dates = [];
      let loggedday = [];
      let loggedin = [];
      let loggedout = [];
      let loggedpaidbreak = [];
      let loggedbreak = [];
      let loggeddaydescription = [];
      let extra = [];
      let km = [];
      let files = [];
      let timelistnr = defaultValues.timelistnr;
      const importDaysWindow = savedDays && savedDays.length > 0 ? true : false;
      days.map(function (x, index){
        let dte = new Date();
        function localizeDateStr(date_to_convert_str) 
        {
          var date_to_convert = new Date(date_to_convert_str);
          var dateArray = [date_to_convert.getDate(), date_to_convert.getMonth()+1, date_to_convert.getFullYear(), date_to_convert.getHours(), date_to_convert.getMinutes(), date_to_convert.getSeconds()];
          let day = dateArray[0].toString();
          let month = dateArray[1].toString();
          dateArray[0] = day.length === 1 ? '0'+day : day;
          dateArray[1] = month.length === 1 ? '0'+month : month;
          var dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];             
          return dateString; 
        }
        //console.log(dte);
        let day = dte.setDate(dte.getDate() - x);
        loggedday.push(localizeDateStr(day));
        loggedin.push(!defaultValues ? '08:00' : defaultValues.in.length === 5 ? defaultValues.in : '0'+defaultValues.in);
        loggedout.push(defaultValues ? defaultValues.out : '16:30');
        loggedpaidbreak.push(defaultValues ? defaultValues.breakpaid : false);
        extra.push(false);
        loggedbreak.push(defaultValues ? defaultValues.breaktime : '30');
        loggeddaydescription.push('');
        km.push(false);
        files.push([]);
        dates.push(day);
    })
      if (dates.length === days.length)
      {
          this.setState({
            mode,
            loggedDaysArray: dates,
            extra,
            km,
            files,
            loggedday,
            loggedin,
            loggedout,
            loggedpaidbreak,
            loggedbreak,
            loggeddaydescription,
            loggedprojectname: '',
            loggedname: username,
            pid: null,
            timelistnr,
            isValid: {projectName: true, username: true},
            importDaysWindow,
          })
      }
      };	
  
    showClientData = () => {
      this.setState({
        clientData: true
      })
    }
  
  
    componentDidMount() {
      if (this.props.edit)
      {
        this.fillEditValues();
      }
      else
      {
        this.createStartValues();
      }
    }

    closeDocument(){
      this.props.showPopup('confirm', chooseLanguage('closePopup',this.props.lang.langinterface), () => this.props.closeDocument('timelist'));
    }

    minimizeDocument(){
      this.setState({
          minimized: true,
         })
    }
    maximizeDocument(){
     this.setState({
      minimized: false,
     })
    }
    
    nextStep = () => {
        let {step} = this.state;
        step = step < 5 ? ++step : step;
        this.setState({
            step: step,
        })
    }

    backStep = () => {
        let {step} = this.state;
        step = step > 1 ? --step : step;
        this.setState({
            step: step,
        })
    }

    chooseStep = (step) => {
        this.setState({
            step: step,
        })
    }

    creatorModeOn = () => {
      const {modifyInterfaceSetting} = this.props.editSetting;
      modifyInterfaceSetting('creator', true);
        this.setState({
         mode: 'creator',
        })
       }

    pageModeOn = () => {
      const {modifyInterfaceSetting} = this.props.editSetting;
      modifyInterfaceSetting('creator', false);
        this.setState({
         mode: 'page',
        })
       }
    
    importDays = (daysToImport) => {
      console.log(daysToImport);
      let {loggedDaysArray, extra, km, files, loggedday, loggedin, loggedout, loggedbreak, loggedpaidbreak, loggeddaydescription, days} = this.state;
     daysToImport.map((day, index) => {
        loggedDaysArray = day.loggedDaysArray.concat(loggedDaysArray);
        extra = day.extra.concat(extra);
        km = day.km.concat(km);
        files = day.files.concat(files);
        loggedday = day.loggedday.concat(loggedday);
        loggedin = day.loggedin.concat(loggedin);
        loggedout = day.loggedout.concat(loggedout);
        loggedbreak = day.loggedbreak.concat(loggedbreak);
        loggedpaidbreak = day.loggedpaidbreak.concat(loggedpaidbreak);
        loggeddaydescription = day.loggeddaydescription.concat(loggeddaydescription);
        this.setState({
          loggedDaysArray,
          extra,
          km,
          files,
          loggedday,
          loggedin,
          loggedout,
          loggedbreak,
          loggedpaidbreak,
          loggeddaydescription,
        })
      })
    }

 
    render (){
        const props = {...this.props};
        const {edit, days} = props;
        let {clientData, pid, client, editClientData, loggedprojectname, isValid, loggedDaysArray, extra, km, files, loggedday, loggedin, loggedout, loggedbreak, loggedpaidbreak, loggeddaydescription, stapled, loggedname, timelistnr} = this.state;
        const {minimized, step, mode, importDaysWindow} = this.state;
        let today = new Date();
        let projects = [];
        this.props.projects.map((project, index)=>{
          if(!project.settings.archived || (project.settings.archived && !project.settings.archived.status) )
          {
            if(!project.settings.deleted || (project.settings.deleted && !project.settings.deleted.status) )
            {
              console.log(project.name + ': accepted')
              projects.push([project.name, project.pid]);
            }
          }
        });
        let clients = [];
        props.clients && props.clients.map((client, index)=>{
          if(!client.archived || (client.archived && !client.archived.status) )
          {
            if(!client.deleted || (client.deleted && !client.deleted.status) )
            {
              console.log(client.clientname + ': accepted')
              clients.push(client.clientname);
            }
          }
        });
        const {clientname, address, postnr, post} = {...client};
        const timelistPreview = {
          createdate: today,
          acceptdate: today,
          content: {              
            extra: extra,
            km: km,
            files: files,
            days: loggedday,
            in: loggedin,
            out: loggedout,
            breakpaid: loggedpaidbreak, 
            breaktime: loggedbreak,
            daydescription: loggeddaydescription,
            project: loggedprojectname,
            username: loggedname,
            timelistnr: timelistnr,
          },
          stapled: stapled,
          did: false,
          pid: pid,
          type: 'timelist'
        }
        const {lang} = props;
        const {langinterface} = lang;
        const translation = {
          timelist: chooseLanguage('timelist', langinterface),
        }
        
        return (
        <>
            <div 
            style={{visibility: minimized ? 'hidden' : 'visible'}}
            >
                <div id="document-background">
                    <div id='timelist-document'>
                        <div id='top-beam' className="timelist-top-beam">
                        <h1>{translation.timelist}{' #'}{timelistnr}</h1>
                            <MinimizeButton minimizeAction={this.minimizeDocument} />
                            <HelpButton/>                                
                            <CreatorButton mode={mode} buttonAction={mode === 'creator' ? this.pageModeOn : this.creatorModeOn} color={1}/>
                            <CloseButton closeAction={this.closeDocument}/>
                        </div>
                    {
                      importDaysWindow && (step===4 || mode === 'page') && <DaysList importDays={this.importDays} importDaysWindowController={{open: this.importDaysWindowOpen, close: this.importDaysWindowClose}} lang={lang} days={days}/>
                    }
                    {
                        mode === 'page' && <NewTimelist 
                        extra  = {extra}
                        km = {km}
                        files = {files}
                        loggedin = {loggedin}
                        sendFormToExecute = {this.sendFormToExecute}
                        showClientData = {this.showClientData}
                        savePost = {this.savePost}
                        loggedout = {loggedout}
                        loggedday = {loggedday}
                        deleteDay = {this.deleteDay}
                        saveDate = {this.saveDate}
                        addNewRow = {this.addNewRow}
                        moveDown = {this.MoveDown}
                        moveUp = {this.MoveUp}
                        loggedpaidbreak = {loggedpaidbreak}
                        loggedbreak = {loggedbreak}
                        loggeddaydescription = {loggeddaydescription}
                        loggedDaysArray = {loggedDaysArray}
                        clients = {clients}
                        client = {client}
                        projects = {projects}
                        clientData = {clientData}
                        editClientData = {editClientData}
                        loggedprojectname = {loggedprojectname}
                        loggedname = {loggedname}
                        writeBasisToState = {this.writeBasisToState}
                        writeClientToState = {this.writeClientToState}
                        writeToState = {this.writeToState}
                        isValid = {isValid}
                        timelistnr = {timelistnr}
                        generateDocument={props.generateDocument}
                        minimizeDocument={this.minimizeDocument} 
                        closeDocument={this.closeDocument} 
                        edit={props.edit} 
                        editedDocument={props.editedDocument} 
                        timelistdetails={props.timelistdetails}
                        documentOptions={props.documentOptions}
                        lang={lang}
                        importDaysWindowOpen={this.importDaysWindowOpen}
                    />
                    }                  
                    {
                        mode === 'creator' && <NewTimelistWizard
                        extra  = {extra}
                        km = {km}
                        files = {files}
                        loggedin = {loggedin}
                        sendFormToExecute = {this.sendFormToExecute}
                        showClientData = {this.showClientData}
                        savePost = {this.savePost}
                        loggedout = {loggedout}
                        loggedday = {loggedday}
                        deleteDay = {this.deleteDay}
                        saveDate = {this.saveDate}
                        addNewRow = {this.addNewRow}
                        moveDown = {this.MoveDown}
                        moveUp = {this.MoveUp}
                        loggedpaidbreak = {loggedpaidbreak}
                        loggedbreak = {loggedbreak}
                        loggeddaydescription = {loggeddaydescription}
                        loggedDaysArray = {loggedDaysArray}
                        clients = {clients}
                        client = {client}
                        projects = {projects}
                        clientData = {clientData}
                        editClientData = {editClientData}
                        loggedprojectname = {loggedprojectname}
                        loggedname = {loggedname}
                        writeBasisToState = {this.writeBasisToState}
                        writeClientToState = {this.writeClientToState}
                        writeToState = {this.writeToState}
                        isValid = {isValid}
                        step={step}
                        timelistPreview = {timelistPreview}
                        timelistnr = {timelistnr}
                        generateDocument={props.generateDocument}
                        minimizeDocument={this.minimizeDocument} 
                        closeDocument={this.closeDocument} 
                        edit={props.edit} 
                        editedDocument={props.editedDocument} 
                        timelistdetails={props.timelistdetails}
                        documentOptions={props.documentOptions}
                        stepOptions={{
                            choose: this.chooseStep,
                            back: this.backStep,
                            next: this.nextStep,
                        }
                        }
                        lang={lang}
                        importDaysWindowOpen={this.importDaysWindowOpen}
                        />
                    }
                    </div>
                </div>
            </div>
            <div 
                id='timelist-minimized'
                onClick={this.maximizeDocument} 
                style={{visibility: minimized ? 'visible' : 'hidden'}}
            >
            <p>{translation.timelist}{' #'}{timelistnr}</p>
            </div>
        </>
    );
  }
}

export default NewTimelistContainer;
