import React, { Component } from "react";
import ProjectLocation from "./ProjectLocation";
import ProjectPeople from "./ProjectPeople";
import ProjectTimeLimits from "./ProjectTimeLimits";
import ProjectMainPicture from "./ProjectMainPicture";
import { chooseLanguage } from "../Lang/langpack";


class ProjectAbout extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }



  render (){
    let { details, editSetting, pid, project, lang } = {...this.props};
    const { address, bgdescription, others, owner, picture, workhours } = this.props.details;
    const {settings} = project;
    editSetting['pid'] = pid;

    return (  
      <>
        {details && <div id='project-settings'>
          <div id='project-about' className='project-details'><ProjectTimeLimits lang={lang} settings={settings} editSetting={editSetting} workhours={workhours}/></div>
          
          {
            /*<div id='project-location' className='project-details'><ProjectLocation editSetting={editSetting} address={address}/></div>
          <div id='project-people' className='project-details'><ProjectPeople editSetting={editSetting} owner={owner} others={others}/></div>
          <div id='project-main-picture' className="project-details"><ProjectMainPicture editSetting={editSetting} picture={picture} bgdescription={bgdescription}/></div>
          */}
        </div>}        
      </>
    );
  }
}

export default ProjectAbout;
