import React, { Component } from "react";
import ClientItem from "../Clients/ClientItem";

import './../clients.css';
import { chooseLanguage } from "../Lang/langpack";
import ClientMenu from "./ClientMenu";

class ClientList extends Component {
  constructor () {
    super();
    this.state = {
    tab: {
        active: true,
        archived: false,
        deleted: false,
    },
    menu: ['active', 'archived', 'deleted']
  }
}
  closeMenu = () => {
    this.props.closeMenu('clients');
  }

  openTab = (tab) => {
    let tabs = {
      active: false,
      archived: false,
      deleted: false,
    }
    tabs[tab] = true;
    this.setState({
      tab: tabs,
    })
  }

  render (){
    let {clients} =  this.props;
    let {menu, tab} = this.state;
    let clientsObject = {
      archived: clients.filter((client, index) => (client.archived && client.archived.status)),
      deleted: clients.filter((client, index) => (client.deleted && client.deleted.status)),
      clients: clients.filter((client, index) => ((!client.archived || !client.archived.status) && (!client.deleted || !client.deleted.status))),
    };
    const backgroundImage = 'clients.svg';
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      emptyList: chooseLanguage('emptyList', langinterface),
      clients: chooseLanguage('clients', langinterface),
    }
    return (
      <div className="popup-background">
        <div className="client-window">
          <div className='popup-topbeam' style={{backgroundImage: 'url(./'+backgroundImage}}><h1>{translation.clients}</h1>
          {<div className='popup-close-button' onClick={this.closeMenu}>X</div>}</div>
          <ClientMenu menu={menu} tab={tab} lang={lang} openTab={this.openTab}/>
          {tab.active && <div id='client-fulllist'>
            {clientsObject.clients.length > 0 && clientsObject.clients.map((client, index) => <ClientItem key={index}  index={index} clientController={this.props.clientController} client={client}/>)}
            {clientsObject.clients.length === 0 && <div id='empty-list'>{translation.emptyList}</div>}
          </div>}  
          {tab.archived && <div id='client-fulllist'>
            {clientsObject.archived.length > 0 && clientsObject.archived.map((client, index) => <ClientItem key={index}  index={index} clientController={this.props.clientController} client={client}/>)}
            {clientsObject.archived.length === 0 && <div id='empty-list'>{translation.emptyList}</div>}
          </div>}  
          {tab.deleted && <div id='client-fulllist'>
            {clientsObject.deleted.length > 0 && clientsObject.deleted.map((client, index) => <ClientItem key={index}  index={index} clientController={this.props.clientController} client={client}/>)}
            {clientsObject.deleted.length === 0 && <div id='empty-list'>{translation.emptyList}</div>}
          </div>}  
        </div>
      </div>

    );
  }
}

export default ClientList;
