import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';
import AddressComponent from "./AddressComponent";
import AutocompleteComponent from "./AutocompleteComponent";
import HelpButton from "./HelpButton";
import { chooseLanguage } from "../Lang/langpack";

class ClientData extends Component {
  constructor () {
	  super();
	  this.state = {
          mva: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.saveBasis = this.saveBasis.bind(this);
  }

  componentDidMount() {

  }

  saveBasis(event){
    let record = event.currentTarget.id,
      value = event.currentTarget.value;
      console.log(record+' ' +value);
      this.props.writeClientToState(record, value);
  }

  handleChange(){
      this.setState({
          mva: true,
      })
  }

  render (){
      const mva = this.state.mva || this.props.client.mva;
      const {suggestions, lang} = this.props;
      const {langinterface} = lang;
      const translation = {
        name: chooseLanguage('name', langinterface),
        mva: chooseLanguage('mva', langinterface),
        address: chooseLanguage('address', langinterface),
      }
    return (
      <>
      <Form.Group id='document-fieldset' as={Row} className="text-right justify-content-md-center">
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={3} xs={12}><h4>{translation.name}</h4></Form.Label>
        <Col lg={9} xs={12}>
          {!suggestions && <Form.Control id='clientname' type="text" placeholder={translation.name} onChange={this.saveBasis} required/>}
          {suggestions && <AutocompleteComponent value={this.props.client.clientname} record='clientname' saveBasis={this.props.writeClientToState} placeholder={translation.name} suggestions={suggestions}/>}
        </Col>
        {!mva && <Form.Label className="text-center d-lg-block" column lg={3} xs={12}><h4>MVA</h4></Form.Label>}
        {!mva && <Col lg={9} xs={12}><Form.Control type='checkbox' name={'mva-on'} onChange={this.handleChange}></Form.Control></Col>}
        {mva &&
        <>
        <Form.Label className="text-center d-lg-block" column lg={3} xs={12}><h4>{translation.mva}</h4></Form.Label>
        <Col lg={9} xs={12}>
        <Form.Control id='mva' type="text" placeholder={translation.mva} value={this.props.client.mva ? this.props.client.mva : ''} onChange={this.saveBasis} maxLength={9} required/>
        </Col>
        </>
        }
        <Form.Label className="text-center d-lg-block" 
          style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} 
         
          column 
          lg={3} xs={12}>
          <h4>{translation.address}</h4>
        </Form.Label>
        <Col lg={9} xs={12}>
        <Form.Control 
          id='address' 
          type="text" 
          placeholder={translation.address}  
          onChange={this.saveBasis} 
          value={this.props.client.address}
          required/>
        </Col>
        <AddressComponent 
          error={this.props.error} 
          postnr={this.props.client.postnr}
          post={this.props.client.post}
          savePost={this.props.savePost}
          lang={lang}
          />
      </Form.Group>
    </>
    );
  }
}

export default ClientData;
