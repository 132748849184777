import React, { Component } from "react";
import {Row, Col} from 'react-bootstrap';
import DocumentRowOptions from "../FormComponents/DocumentRowOptions";
import DescriptionComponent from "../FormComponents/DescriptionComponent";
import NetPrice from "../FormComponents/NetPrice";
import Amount from "../FormComponents/Amount";
import Vat from "../FormComponents/Vat";
import GrossValue from "../FormComponents/GrossValue";
import { chooseLanguage } from "../Lang/langpack";


class InvoiceRowMobile extends Component {
  render (){
    const props = {...this.props};
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      description: chooseLanguage('description', langinterface),
      netprice: chooseLanguage('netprice', langinterface),
      amount: chooseLanguage('amount', langinterface),
      mva: chooseLanguage('mva', langinterface),    
      gross: chooseLanguage('gross', langinterface),  

    }
    return (
        <Row id="document-fieldset">
            <Col className='timelist-row' xs='12' lg='4'>
              <h4>{translation.description}</h4>
                <DescriptionComponent
                  placeholder={translation.description}
                  value={props.content.description} 
                  saveValue={props.writeToState} 
                  record={'description'}
                  index={props.index} />
            </Col>
            <Col className='timelist-row text-center' xs='12' lg='2'>
                <h4>{translation.netprice}</h4>
                <NetPrice
                  placeholder={translation.netprice}
                  defaultNetPrice={props.defaultNetPrice} 
                  netPrice={props.content.netprice} 
                  saveValue={props.writeToState} 
                  index={props.index} />
            </Col>
            <Col className='timelist-row' xs='12' lg='2'>
                <h4>{translation.amount}</h4>
               <Amount 
                  multiplier={props.content.multiplier} 
                  saveValue={props.writeToState} 
                  index={props.index}/>
            </Col>
            <Col className='timelist-row text-center' xs='12' lg='1'>
                <h4>{translation.mva}</h4>
                <Vat 
                  vat = {props.content.vat} 
                  saveValue={props.writeToState} 
                  index={props.index} />   
            </Col>
            <Col className='timelist-row text-center' xs='12' lg='2'>
                <h4>{translation.gross}</h4>
                <GrossValue 
                  vat = {props.content.vat} 
                  netPrice = {props.content.netprice} 
                  multiplier = {props.content.multiplier}/>
            </Col>
            <Col className='timelist-row' xs='12' lg='1'>
                <DocumentRowOptions 
                  arrayLength={this.props.arrayLength} 
                  moveUp={this.props.moveUp} 
                  moveDown={this.props.moveDown} 
                  addNewRow={this.props.addNewRow} 
                  deletePosition={props.deletePosition} 
                  index={props.index} />
            </Col>
          </Row>
    );
  }
}

export default InvoiceRowMobile;
