import React, { Component } from "react";
import { Form} from 'react-bootstrap';


class AddNewProject extends Component {
  constructor () {
	  super();
	  this.state = {
      newProjectName: ''
    }
    this.saveProjectName = this.saveProjectName.bind(this);
    this.createNewProject = this.createNewProject.bind(this);
  }

  componentDidMount() {
  }

  saveProjectName(event) {
    let value = event.target.value;
    this.setState({
      newProjectName: value
    })
  }
  
  createNewProject(){
    const newProjectName = this.state.newProjectName;
    this.props.closeNewProjectForm();
    this.props.createNewProject(newProjectName);
  }

  render (){

    return (
          <div id='add-new-project'>

            <Form.Control style={{width: '80%', display: 'inline'}} autoFocus id='newProjectName' type="text" value={this.state.newProjectName !== '' ? this.state.newProjectName : ''}  placeholder='Create new project' onChange={this.saveProjectName} required/>
            <img src='./plus.svg' 
            id='newProject' 
            onClick={this.createNewProject}
            alt='new project'
            style={{width: '10%', display: 'inline', margin: '1rem 0.5rem', cursor: 'pointer', filter: 'invert(1)'}}/>
          </div>
    );
  }
}

export default AddNewProject;
