import React, { Component } from "react";
import {Col, Row, Form, Button} from 'react-bootstrap';
import './../settings.css';
import CompanySettings from "./CompanySettings";
import { chooseLanguage } from "../Lang/langpack";


class SettingWizard extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }

  
  render (){
    const {lang, editSetting, fakturadetails, closeMenu, openMenu} = this.props;
    const {langinterface} = lang;
    const translation = {
      settings: chooseLanguage('settings',  langinterface),
      
    }
    let backgroundImage = 'settings.svg';
    return (  
      <div className="settings-background">
         <div className="settings-window">
            <div className='popup-topbeam' style={{zIndex: 10, backgroundImage: 'url(./'+backgroundImage}}><h1>{translation.settings}</h1>
            {<div className='popup-close-button' onClick={()=>closeMenu('settingWizard')}>X</div>}</div>
          <CompanySettings 
            lang={lang} 
            editSetting={editSetting} 
            fakturadetails={fakturadetails}
          />
        </div>
      </div>
    );
  }
}

export default SettingWizard;
