import React, { Component } from "react";
import DocumentCard from './DocumentCard';

class DocumentListCard extends Component {
  constructor () {
	  super();
	  this.state = {
    }

  }

  render (){
    let documents = this.props.documents ? this.props.documents : [];
    const {lang} = this.props;
    //console.log(documents);
    return (
        <div id='document-card-list'>
          {documents.map((document, index) => (<DocumentCard lang={lang} documentOptions={this.props.documentOptions} key={index} document={document} deleteDocument={this.props.documentOptions.delete} editDocument={this.props.documentOptions.edit} regenerateDocument={this.props.documentOptions.regenerate} openFullPreview={this.props.openFullPreview}/>))}
        </div>  
    );
  }
}

export default DocumentListCard;
