import React, { Component } from "react";
import DocumentOptions from "./DocumentOptions";
import './../timeline.css';
import { dateToSlashString } from "../Features/dateFeatures";
import StapledDocuments from "./StapledDocuments";
import DocumentExportOptions from "../FormComponents/DocumentExportOptions";
import { chooseLanguage } from "../Lang/langpack";
class InvoicePreview extends Component {
  constructor () {
	  super();
	  this.state = {
      exportOptions: false,
    }
    this.fullPreview = this.fullPreview.bind(this);
  }

 
  componentDidMount() {

  }

  getYearOfDate(date){
    ////console.log("data do konwersji: " + date);
    let dte= new Date(date.slice(6,10), +date.slice(3,5)-1, date.slice(0,2));
    ////console.log(dte);
    const fullYear = dte.getFullYear();
    ////console.log(fullYear);
    return fullYear;
  }


  getYear(date){
    let dte = new Date(date);
    return dte.getFullYear();
  }
  
  fullPreview(event){
    let did = event.target.id;
    //console.log(did);
    this.props.openFullPreview(this.props.document);
  }

  getWeekOfDate(date){
    //console.log(date);
    Date.prototype.getWeek = function() {
      var date = new Date(this.getTime());
      ////console.log(date);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
      ////console.log(date);
      var week1 = new Date(date.getFullYear(), 0, 4);
      return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }
    let dte= new Date(date.slice(6,10), +date.slice(3,5)-1, date.slice(0,2));
    let week = dte.getWeek();
    return week;
  }


  sumUpFaktura = (content) => {
    //console.log(content);
    let totalPrice = 0;
		let mvaCalculation = [0,0,0,0];
		let mvaBase = [0,0,0,0]
		content.map((position, index)=>
		{
			let basePrice = +position.netprice;
			let amount = +position.multiplier;
			let sumPrice = basePrice * amount;
			let mva = +position.vat;
			let mvaPrice = sumPrice * (mva/100);
			let total = sumPrice + mvaPrice;
			totalPrice = totalPrice + total;
			switch (mva) {
				case 0: 
					mvaCalculation[0] = mvaCalculation[0]+mvaPrice;
					mvaBase[0] = mvaBase[0] + sumPrice;
				break;
				case 12: 
					mvaCalculation[1] = mvaCalculation[1]+mvaPrice;
					mvaBase[1] = mvaBase[1] + sumPrice;
				break;				
				case 15: 
					mvaCalculation[2] = mvaCalculation[2]+mvaPrice;
					mvaBase[2] = mvaBase[2] + sumPrice;
				break;			
				case 25: 
					mvaCalculation[3] = mvaCalculation[3]+mvaPrice;
					mvaBase[3] = mvaBase[3] + sumPrice;
				break;			
			}
    });
    const result = {totalPrice, mvaCalculation, mvaBase};
    return result;
  }


  sumUpTimelist = (intimeArray, outtimeArray, breakpaidArray, breaktimeArray, kmArray, extraArray) => {
    let sumUpTimer = 0;
    let sumUpExtraTimer = 0;
		let sum = {
      regular: {
        hours: 0,
        minutes: 0,
        km: 0,
      },
      extra: {
        hours: 0,
        minutes: 0,
        km: 0,
      },     
    };
    breakpaidArray && breakpaidArray.map((isPaid, i)=>{
      let start = new Date('2001-01-01T' + intimeArray[i] + ':00');
			let end = new Date ('2001-01-01T' + outtimeArray[i] + ':00');
			////console.log(start.toString() + ' ' + end.toString());
			let difference = end - start;
			difference = difference / 60 / 60 /1000;
      let km = kmArray && kmArray[i] ? parseInt(kmArray[i]) : 0;
			////console.log('full time: ' + difference);
			if(!isPaid)
			{
				let breakDec = breaktimeArray[i]/60;
				////console.log('break: ' + breakDec);
				difference = difference - breakDec;
			}
      if(extraArray && extraArray[i])
      {
        sum.extra.km =  sum.extra.km + km;
        sumUpExtraTimer = sumUpExtraTimer + difference;        
      }
      if((extraArray && !extraArray[i]) || !extraArray)
      {
        sum.regular.km = sum.regular.km + km;
        sumUpTimer = sumUpTimer + difference;
      }
    })
    
    sum.regular.hours = Math.floor(sumUpTimer / 1);
		sum.regular.minutes = sumUpTimer % 1;
		sum.regular.minutes = sum.regular.minutes * 60;
		sum.regular.minutes = sum.regular.minutes.toString();
		if (sum.regular.minutes.length === 1)
		{
			sum.regular.minutes = '0' + sum.regular.minutes;
		}
    sum.extra.hours = Math.floor(sumUpExtraTimer / 1);
		sum.extra.minutes = sumUpExtraTimer % 1;
		sum.extra.minutes = sum.extra.minutes * 60;
		sum.extra.minutes = sum.extra.minutes.toString();
		if (sum.extra.minutes.length === 1)
		{
			sum.extra.minutes = '0' + sum.extra.minutes;
		}

    return sum;
  }


  sumUpDay = (intime, outtime, breakpaid, breaktime) => {
    let start = new Date('2001-01-01T' + intime + ':00');
    let end = new Date ('2001-01-01T' + outtime + ':00');
    //console.log(start.toString() + ' ' + end.toString());
    let difference = end - start;
    difference = difference / 60 / 60 /1000;
    let hours, minutes;
    //console.log('full time: ' + difference);
    if(breakpaid === false)
    {
      let breakDec = breaktime/60;
      //console.log('break: ' + breakDec);
      difference = difference - breakDec;
    }
    hours = Math.floor(difference / 1);
    minutes = difference % 1;
    minutes = minutes * 60;
    minutes = minutes.toString();
    if (minutes.length === 1)
    {
      minutes = '0' + minutes;
      this.sumUpTimelist(difference);
    }
    return ''+hours+':'+minutes;
  }

  breaktimeToString = (breaktime) => {
    switch (breaktime){
      case '0': 
        return '0:00';
      break;
      case '15': 
        return '0:15';
      break;
      case '30': 
        return '0:30';
      break;
      case '45': 
      return '0:45';
      break;
      case '60': 
        return '1:00';
      break;
      case '120': 
        return '2:00';
      break;
      default: 
       return '';
    }
  }

  numberWithSpaces = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
  }

  invoicePreview(documentFile){
    let type = documentFile.type;
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      fakturaIssueDate: chooseLanguage('fakturaIssueDate', langinterface),
      fakturaDueDate: chooseLanguage('fakturaDueDate', langinterface),
      description: chooseLanguage('description', langinterface),
      netprice: chooseLanguage('netprice', langinterface),
      amount: chooseLanguage('amount', langinterface),
      mva: chooseLanguage('mva', langinterface),    
      gross: chooseLanguage('gross', langinterface),  
      inkasovarseldato: chooseLanguage('inkasovarseldato', langinterface),
      varseldato: chooseLanguage('varseldato', langinterface),
      total: chooseLanguage('total', langinterface),
      vatBase: chooseLanguage('vatBase', langinterface),
    }
    ////console.log(type);
    let sumUp, totalPrice, mvaBase, mvaCalculation;
        sumUp = this.sumUpFaktura(documentFile.content.content);
        mvaBase= sumUp.mvaBase;
        mvaCalculation= sumUp.mvaCalculation;
        totalPrice = sumUp.totalPrice;
        const varsel = documentFile.content.varsel && documentFile.content.varsel;
        const varsel_lvl = varsel && varsel.length;
        const varselDato = varsel && varsel[varsel_lvl-1]; 
        const fakturaPreview = 
        <> 
          <div id='faktura-basic-data'>  
            <div id='faktura-data-preview' >
              <div>{documentFile.content.client.clientname}</div>
              <div>{documentFile.content.client.address}</div>
              <div>{documentFile.content.client.postnr + ' ' + documentFile.content.client.post}</div>
              <div>{documentFile.content.client.mva ? 'NO'+documentFile.content.client.mva+'MVA' : ''}</div> 
            </div>   
            <div id='faktura-dates-preview' >
              <div>{translation.fakturaIssueDate+': ' + dateToSlashString(documentFile.content.fakturaissue)}</div>
              <div>{translation.fakturaDueDate+': ' + dateToSlashString(documentFile.content.fakturadue)}</div>         
              {varsel_lvl < 3 && varsel_lvl > 0 && <div>{translation.varselDate + ': ' + dateToSlashString(varselDato)}</div>}        
              {varsel_lvl === 3 && <div>{translation.inkasovarseldato + ': ' + dateToSlashString(varselDato)}</div>}                    
            </div>  
          </div>
            <div id='faktura-content-preview' >
              <div></div>
              <div>{translation.description}</div>
              <div>{translation.netprice}</div>
              <div>{translation.amount}</div>
              <div>{translation.mva}</div> 
              <div>{translation.gross}</div>
            </div>
          {documentFile.content.content.map((line, index)=>(
            <div id='faktura-content-preview' key={index}>
              <div>{index+1+'.'}</div>
              <div>{line.description}</div>
              <div>{this.numberWithSpaces((+line.netprice).toFixed(2))}</div>
              <div>{this.numberWithSpaces((+line.multiplier).toFixed(2))}</div>
              <div>{line.vat+'%'}</div>
              <div>{this.numberWithSpaces((+line.netprice*+line.multiplier + (+line.netprice*+line.multiplier)*(+line.vat/100)).toFixed(2))}</div>
            </div>
          ))}
            <div id='timelist-sumup-mini'>
              <div>{translation.total+': '}</div>
              <div>{''+this.numberWithSpaces(totalPrice.toFixed(2))}</div>
              {mvaBase[0]>0 &&
              <>
              <div>{ translation.vatBase + ' 0%: '}</div><div>{this.numberWithSpaces(mvaBase[0].toFixed(2))}</div>
              <div>{translation.mva + ' 0%: '}</div><div>{this.numberWithSpaces(mvaCalculation[0].toFixed(2))}</div>
              </>
              }
              {mvaBase[1]>0 &&
              <>
              <div>{translation.vatBase + ' 12%: '}</div><div>{this.numberWithSpaces(mvaBase[1].toFixed(2))}</div>
              <div>{translation.mva + ' 12%: '}</div><div>{this.numberWithSpaces(mvaCalculation[1].toFixed(2))}</div>
              </>
              }
              {mvaBase[2]>0 &&
              <>
              <div>{translation.vatBase + ' 15%: '}</div><div>{this.numberWithSpaces(mvaBase[2].toFixed(2))}</div>
              <div>{translation.mva + ' 15%: '}</div><div>{this.numberWithSpaces(mvaCalculation[2].toFixed(2))}</div>
              </>
              }
              {mvaBase[3]>0 &&
              <>
              <div>{translation.vatBase + ' 25%: '}</div><div>{this.numberWithSpaces(mvaBase[3].toFixed(2))}</div>
              <div>{translation.mva + ' 25%: '}</div><div>{this.numberWithSpaces(mvaCalculation[3].toFixed(2))}</div>
              </>
              }
            </div>
        </>;
        return fakturaPreview;   
    } 

  hovered = () => {
    let {exportOptions} = this.state;
    exportOptions = true;
    this.setState({exportOptions});
  }

  unhovered = () => {
    let {exportOptions} = this.state;
    exportOptions = false;
    this.setState({exportOptions});
  }

  DatesDifference = (dateToCompare) => {
    const date1 = new Date();
    const date2 = new Date(dateToCompare);
    console.log(date1);
    console.log(date2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
  }

  render (){
    const documentFile = this.props.document ? this.props.document : {};
    const {sendFormToExecute, lang} = this.props;
    return (
      <div id='document-preview' 
      onMouseEnter={this.hovered}
      onMouseLeave={this.unhovered}
      style={{
        width: '100%',
        left: '0px',
        top: '0px',
        position: 'relative'}} 
      pid={documentFile.pid} 
      did={documentFile.did} 
      week={this.props.weekNo} 
      year={this.getYear(documentFile.createdate)} >
      <div id={documentFile.did} style={{zIndex: 9, width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}} onClick={this.fullPreview}></div>
          <div id='document-file' className={documentFile && Object.keys(documentFile).length ? 'show-document' : 'hide-document'}>
          {
             documentFile && Object.keys(documentFile).length && this.invoicePreview(documentFile)
          }
          </div>  
        </div>  
    );
  }
}

export default InvoicePreview;

