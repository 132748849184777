import React, { Component, Fragment } from "react";
import { Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import { chooseLanguage } from "../Lang/langpack";

class ProjectName extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: ""
    };
  }

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion[0].toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    let record = e.target.name,
      value = e.target.value,
      pid = userInput.length === 0 ? null : e.target.id;
      console.log('pid: '+ pid);
      this.props.writeBasisToState('pid', pid);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
      pid,
    }, this.props.writeBasisToState(record, value));
  };

  onClick = e => {
    let record = 'loggedprojectname',
    value = e.currentTarget.innerText,
    pid = e.currentTarget.id;
    this.props.writeBasisToState('pid', pid);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      pid,
    }, this.props.writeBasisToState(record, value));
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    // User pressed the enter key
    if (e.keyCode === 13) {
      let record =  'loggedprojectname',
      value = filteredSuggestions[activeSuggestion];
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      }, this.props.writeBasisToState(record, value));
    } 
    //user pressed the backspace
    else if (e.keyCode === 8 || e.keyCode === 46) {
      const pid =  null;
      this.props.writeBasisToState('pid', pid);
    }

    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} id={suggestion[1]} onClick={onClick}>
                  {suggestion[0]}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <></>
        );
      }
    }
    const {lang} = this.props;
      const {langinterface} = lang;
      const translation = {
        projectName: chooseLanguage('projectName', langinterface),
      }

    return (
      <Fragment>
        <Form.Control 
          name='loggedprojectname' 
          id={this.state.pid}
          type="text" 
          placeholder={translation.projectName}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput ? userInput : this.props.loggedprojectname}
          required
        />
         {!this.props.isValid.projectName && <div>
            Please input Project Name.
          </div>}
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default ProjectName;
