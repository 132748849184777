import React, { Component } from "react";
import AddNewClient from "./AddNewClient";
import { chooseLanguage } from "../Lang/langpack";


class NewClientIco extends Component {
  constructor () {
	  super();
	  this.state = { 
    }
    this.openNewClientForm = this.openNewClientForm.bind(this);
  }

  openNewClientForm(){
    this.props.clearList();
    this.props.showPopup('newClient', '');
  }

 
  componentDidMount() {

  }

  render (){
    const type = this.props.type ? this.props.type : false; 
    const {lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      newClient: chooseLanguage('newClient',  langinterface),
    }
    return (
      <div id='client-ico-set'>
            {
                <div>
                <div onClick={this.openNewClientForm} style={{backgroundImage: 'url(/new_client.svg', width: '3rem', height: '3rem', margin: '1rem 1rem 0 1rem', cursor: 'pointer'}}></div>
                <div className='ico-label'>{translation.newClient}</div>
                </div>
            }
        </div>  
    );
  }
}

export default NewClientIco;
