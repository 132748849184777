import React, { Component } from "react";
import { chooseLanguage } from "../Lang/langpack";



class ClientOptions extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }


  removeClient = () => {
    const {lang, editSetting, cid} = this.props;
    //console.log('removing clinet')
    const {modifyClientSetting} = editSetting;
    const today = new Date();
    modifyClientSetting (cid, 'remove', 
    {
      removalDate: today,
      status: true,
    });
  }

  archiveClient = () => {
    const {lang, editSetting, cid} = this.props;
    //console.log('archiving clinet')
    const {modifyClientSetting} = editSetting;
    const today = new Date();
    modifyClientSetting (cid, 'archive', 
    {
      archivingDate: today,
      status: true,
    });
  }

  unremoveClient = () => {
    const {lang, editSetting, cid} = this.props;
    //console.log('unremoving clinet')
    const {modifyClientSetting} = editSetting;
    const today = new Date();
    modifyClientSetting (cid, 'remove', 
    {
      removalDate: today,
      status: false,
    });
  }
  unarchiveClient = () => {
    const {lang, editSetting, cid} = this.props;
    //console.log('unarchving clinet')
    const {modifyClientSetting} = editSetting;
    const today = new Date();
    modifyClientSetting (cid, 'archive', 
    {
      archivingDate: today,
      status: false,
    });
  }

  render (){
    const {lang,  archived, removed, isEmpty, showPopup} = this.props;
    const isArchived = archived ? archived.status : false;
    const isRemoved = removed ? removed.status : false;
    const {langinterface} = lang;
    const translation = {
      clientOptions: chooseLanguage('clientOptions',  langinterface),
      archiveClient: chooseLanguage('archiveClient',  langinterface),
      removeClient: chooseLanguage('removeClient',  langinterface), 
      backToActiveClients: chooseLanguage('backToActiveClients', langinterface),
      removeClientPermanently: chooseLanguage('removeClientPermanently', langinterface),
      archiveClientConfirm: chooseLanguage('archiveClientConfirm',  langinterface),
      removeClientConfirm: chooseLanguage('removeClientConfirm',  langinterface), 
      removeClientPermanentlyConfirm: chooseLanguage('removeClientPermanentlyConfirm', langinterface),
    }
    return (  
        <div className="project-details">
          <div className="project-section">
          <p className="project-section-header">{translation.clientOptions}</p>
              {
                <div className="project-section-no-details">
                  {!isArchived && !isRemoved && <p onClick={()=>showPopup('confirm', translation.archiveClientConfirm, this.archiveClient)}>{translation.archiveClient}</p>}
                  {!isArchived && !isRemoved && isEmpty && <p onClick={()=>showPopup('confirm', translation.removeClientConfirm, this.removeClient)}>{translation.removeClient}</p>}
                </div>
              }
              {
                <div className="project-section-no-details">
                  {isArchived && !isRemoved && <p onClick={this.unarchiveClient}>{translation.backToActiveClients}</p>}
                  {!isArchived && isRemoved && <p onClick={this.unremoveClient}>{translation.backToActiveClients}</p>}
                </div>                
              }
              {
                <div className="project-section-no-details">
                  {isEmpty && isRemoved && <p onClick={()=>showPopup('confirm', translation.removeClientPermanentlyConfirm, this.removeClientPermanently)}>{translation.removeClientPermanently}</p>}
                </div>                
              }
          </div>         
        </div>
    );
  }
}

export default ClientOptions;
