import React, { Component } from "react";

class MinimizeButton extends Component {
  render (){
    return (        
        <div id='minimize-button' onClick={this.props.minimizeAction} className="system-button"><p>__</p></div>
    );
  }
}
export default MinimizeButton;
