import React, { Component } from "react";

class CloseButton extends Component {
  render (){
    return (        
        <div id='close-button' onClick={this.props.closeAction} className="system-button"><p>X</p></div>
    );
  }
}
export default CloseButton;
