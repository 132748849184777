import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';

class Amount extends Component {
  
  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }

  render (){
    const {index, multiplier} = this.props;
    return (
        <Form.Control
         id={index}
         name='multiplier' 
         type="number" 
         value={multiplier} 
         placeholder='Amount' 
         onChange={this.handleChange} 
         required/>
    );
  }
}

export default Amount;
