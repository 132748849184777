import React, { Component } from "react";
import {Container, Card, DropdownButton, Dropdown} from 'react-bootstrap';
import { chooseLanguage } from "../Lang/langpack";


class DocumentOptions extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.editDocument = this.editDocument.bind(this);
    this.repeatDocument = this.repeatDocument.bind(this);    
    this.deleteDocument = this.deleteDocument.bind(this);
    this.regenerateDocument = this.regenerateDocument.bind(this);
  }

  componentDidMount() {
  }

  editDocument(event) {
    const {closeFullPreview} = this.props;
    closeFullPreview && closeFullPreview();
    let did = event.target.id;
    this.props.documentOptions.edit(did);
  }

  repeatDocument(event) {
    const {closeFullPreview} = this.props;
    closeFullPreview && closeFullPreview();
   let repeat = this.props.documentOptions.repeat;
    let did = event.target.id;
    console.log(did);
    repeat(did);
  }


  deleteDocument(event) {
    let target = event.target;
    let did = target.id;
    this.props.documentOptions.delete(did);
  }

  varselFaktura = (event) => {
    let target = event.target;
    let did = target.id;
    this.props.documentOptions.varsel(did);
  }

    tilbudToFaktura = (event) => {
    let target = event.target;
    let did = target.id;
    this.props.documentOptions.tilbudToFaktura(did);
  }


    timelistToFaktura = (event) => {
    let target = event.target;
    let did = target.id;
    this.props.documentOptions.timelistToFaktura(did);
  }

  varselPopup = (event) => {
    const {langinterface} = this.props.lang;
    this.props.showPopup('confirm', chooseLanguage('varselConfirm', langinterface), () => this.varselFaktura(event));
  }

  kreditnotaPopup = (event) => {
    const {langinterface} = this.props.lang;
    this.props.showPopup('confirm', chooseLanguage('kreditnotaConfirm', langinterface), () => this.kreditnotaDocument(event));
  }

  regenerateDocument =(event) => {
    let target = event.target;
    let did = target.id;
    this.props.documentOptions.regenerate(did);
  }

    printVarsel = (event) => {
    let target = event.target;
    let did = target.id;
    this.props.documentOptions.regenerate(did, 'varsel');
  }

  kreditnotaDocument = (event)  => {
    let target = event.target;
    let did = target.id;
    this.props.documentOptions.kreditnota(did);
  }

  documentDisplay = (event) => {
    const {openFullPreview} = this.props;
    let target = event.target;
    let did = target.id;
    console.log('open: ' + did);
    openFullPreview(did);
  }

  
  render (){
    let doc = this.props.document;
    let type = doc.type;
    let content = doc.content;
    const {stapled, lang} = this.props;
    const kreditnota = stapled ? stapled.filter((doc)=>(doc && doc.type === 'kreditnota')) : [];
    const faktura = stapled ? stapled.filter((doc)=>(doc && doc.type === 'faktura')) : [];
    const tilbud = stapled ? stapled.filter((doc)=>(doc && doc.type === 'tilbud')) : [];
    const timelist = stapled ? stapled.filter((doc)=>(doc && doc.type === 'timelist')) : [];
    const {varsel} = this.props;
    const {langinterface} = lang;
    const translation = {
      faktura: chooseLanguage('faktura', langinterface),
      timelist: chooseLanguage('timelist', langinterface),
      kreditnota: chooseLanguage('kreditnota', langinterface),
      tilbud: chooseLanguage('tilbud', langinterface),
      repeat: chooseLanguage('repeat', langinterface),
      edit: chooseLanguage('edit', langinterface),
      delete: chooseLanguage('delete', langinterface),
      print: chooseLanguage('print', langinterface),
      varsel: chooseLanguage('varsel', langinterface),
    }

    //console.log(varsel);
    return ( 
      <div id='new-document-export-options' className="knob-panel" style={{gridTemplateColumns: 'auto auto auto auto auto'}}>
        <div className="option-knob">
          <div className="clickable-panel" name="create" id={doc.did} onClick={this.regenerateDocument}>
          </div>
          <p>{translation.print}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/export_pdf.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>
        {/*
        <div className="option-knob">
          <div className="clickable-panel" name="share" id={doc.did}>
          </div>
          <p>Share</p>
          <div  className="document-export-ico"
              style={{
              backgroundImage: 'url("/export_share.svg")',
              backgroundSize: 'contain',
              backgroundPosition: '50% 0%',
              backgroundRepeat: 'no-repeat',
              cursor: 'pointer'}}/>
        </div>*/
        }
        {type !== 'kreditnota' && <div className="option-knob">
          <div className="clickable-panel" id={doc.did} onClick={this.repeatDocument} name="repeat">
          </div>
          <p>{translation.repeat}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/repeat.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {type !== 'faktura' && type !== 'kreditnota' && <div className="option-knob">
          <div className="clickable-panel" id={doc.did} onClick={this.editDocument} name="edit">
          </div>
          <p>{translation.edit}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/edit.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {type !== 'faktura'  && type !== 'kreditnota' &&       
        <div className="option-knob">
          <div className="clickable-panel" id={doc.did} onClick={this.deleteDocument} name="delete">
          </div>
          <p>{translation.delete}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/abort.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {faktura.length > 0 &&
        <div className="option-knob">
          <div className="clickable-panel" id={faktura && faktura[0] && faktura[0].did} onClick={this.documentDisplay} name="kreditnota">
          </div>
          <p>{translation.faktura}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/faktura.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {type === 'faktura' && kreditnota.length === 0 &&     
        <div className="option-knob">
          <div className="clickable-panel" id={doc.did} onClick={this.kreditnotaPopup} name="kreditnota">
          </div>
          <p>{translation.kreditnota}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/faktura_kreditnota.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {type === 'faktura' && kreditnota.length > 0 &&     
        <div className="option-knob">
          <div className="clickable-panel" id={kreditnota && kreditnota[0] && kreditnota[0].did} onClick={this.documentDisplay} name="kreditnota">
          </div>
          <p>{translation.kreditnota}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/kreditnota.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {(kreditnota.length === 0 && ((type === 'faktura' && !varsel.varselLvl ) || (type === 'faktura' && varsel && ((varsel.varselLvl < 2 && varsel.expiry >= 7) || (varsel.varselLvl === 2 && varsel.expiry >= 14))))) && <div className="option-knob">
          <div className="clickable-panel" id={doc.did} name="varsel" onClick={this.varselPopup}>
          </div>
          <p>{translation.varsel}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/faktura_varsel.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {type === 'faktura' && varsel && ((varsel.varselLvl < 3 && varsel.expiry <= 14) || (varsel.varselLvl === 3)) &&          
        <div className="option-knob">
          <div className="clickable-panel" id={doc.did} name="varsel" onClick={this.printVarsel}>
          </div>
          <p>{translation.print}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/varsel.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {type === 'tilbud' && faktura.length === 0 &&    
        <div className="option-knob">
          <div className="clickable-panel" id={doc.did} name="faktura" onClick={this.tilbudToFaktura}>
          </div>
          <p>{translation.faktura}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/tilbud_faktura.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {tilbud.length > 0 &&
        <div className="option-knob">
          <div className="clickable-panel" id={tilbud && tilbud[0] && tilbud[0].did} onClick={this.documentDisplay} name="kreditnota">
          </div>
          <p>{translation.tilbud}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/tilbud.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {type === 'timelist' && faktura.length === 0 &&      
        <div className="option-knob">
          <div className="clickable-panel" id={doc.did} name="faktura" onClick={this.timelistToFaktura}>
          </div>
          <p>{translation.faktura}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/timelist_faktura.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}
        {timelist.length > 0 &&
        <div className="option-knob">
          <div className="clickable-panel" id={timelist && timelist[0] && timelist[0].did} onClick={this.documentDisplay} name="kreditnota">
          </div>
          <p>{translation.timelist}</p>
          <div  className="document-export-ico"
            style={{
            backgroundImage: 'url("/timelist.svg")',
            backgroundSize: 'contain',
            backgroundPosition: '50% 0%',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'}}/>
        </div>}

      </div>      
    );
  }
}

export default DocumentOptions;
