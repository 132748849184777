import React, { Component } from "react";
import NewInvoice from "./NewInvoice";
import MinimizeButton from "./../FormComponents/MinimizeButton";
import CloseButton from "./../FormComponents/CloseButton";
import NewInvoiceWizard from "./NewInvoiceWizard";
import HelpButton from "../FormComponents/HelpButton";
import CreatorButton from "../FormComponents/CreatorButton";
import { postkode } from "./postkode";
import { chooseLanguage } from "../Lang/langpack";

class NewInvoiceContainer extends Component {
  constructor () {
    super();
    this.state = {
      step: 1,
      minimized: false,
      mode: 'creator',
      did: false,
      stapled: [],
      client: {
        clientname: "",
        address: "",
        postnr: "",
        post: "",
        email: "",
        mva: "",
      },
      loggedprojectname: '',
      pid: null,
      fakturaissue: null,
      fakturadue: null,
      fakturanr: 0,
      content: [{
        description: "",
        netprice: 0,
        multiplier: 1,
        vat: 0,
      }],
      position: [0],
      editClientData: false,
      isValid: {projectName: true},
  }
  this.minimizeDocument = this.minimizeDocument.bind(this);
  this.closeDocument = this.closeDocument.bind(this);
  this.maximizeDocument = this.maximizeDocument.bind(this);
  this.writeToState = this.writeToState.bind(this);
  this.deletePosition = this.deletePosition.bind(this);
  this.sendFormToExecute = this.sendFormToExecute.bind(this);
  this.addNewRow = this.addNewRow.bind(this);
  this.MoveUp = this.MoveUp.bind(this);
  this.MoveDown = this.MoveDown.bind(this);
  this.fillEditValues = this.fillEditValues.bind(this);
  this.writeClientToState = this.writeClientToState.bind(this); 
  this.createStartValues = this.createStartValues.bind(this);   
}

editClientData = () => {
  let editClientData = this.state.editClientData;
  editClientData = !editClientData;
  this.setState({editClientData})
}

savePost = (postnr) => 
{
    let stringPostNr = new String(postnr);
    console.log(stringPostNr)
    let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === postnr);
    console.log(selectedCity);
    let client = {...this.state.client};
    client['postnr'] = postnr;
    client['post'] = selectedCity && selectedCity[0][1] ? selectedCity[0][1] : stringPostNr.length < 4 ? '' : client['post'];
    this.setState({client});
}

saveDate = (value, record) =>
{
  const {paymentdue} = this.props.fakturadetails;
  if (record === 'fakturaissue')
  {
    let dte = new Date(value);
    const fakturaissue = new Date(value);
    const fakturadue = record === 'fakturaissue' && paymentdue ? dte.setDate(dte.getDate() + paymentdue) :  dte.setDate(dte.getDate()+14);
    this.setState({
      fakturadue,fakturaissue
    })
  }
  if (record === 'fakturadue')
  {   
    this.setState({
     [record] : new Date(value)
    })
  }
}

writeToState(record, position, value){
    console.log("Record: " +  record + " Position: " + position + " Value: " + value);
      let content = this.state.content;
      let newValue= content[position];
      console.log(content);
      console.log('newValue: ' + newValue);
      newValue[record] = value;
      content[position] = newValue;
      this.setState({
        content,
      })
}

writeClientToState(record, value){
    console.log("Record: " +  record + " Value: " + value);
    const clients = this.props.clients;
    const findClient = clients.filter((client)=>client.clientname === value);
    if(!findClient[0])
    {
      let client = {...this.state.client};
      client[record] = value;
      this.setState({
        client,
      })
    }
    if(findClient[0] && findClient.length === 1)
    {
      let client = findClient[0];
      this.setState({
        client,
      })
    }
    if(findClient[0] && findClient.length > 1)
    {
      //
    }

}

sendFormToExecute(method) {
  console.log('sending ...');
  let {did,
    loggedprojectname,
    pid,
    client,
    fakturaissue,
    fakturadue,
    content,
    fakturanr,
    stapled} = {...this.state};
  const {generate, save} = {...this.props.documentOptions};
  let fakturadetails = this.props.fakturadetails ? this.props.fakturadetails : {};
  console.log(client);
  const faktura = {
      did: did,
      project: loggedprojectname,
      pid: pid,
      client: {...client},
      fakturaissue: fakturaissue,
      fakturadue: fakturadue,
      content: content,
      address: fakturadetails.address,
      companyname: fakturadetails.companyname,
      fakturanr: fakturanr,
      kontonr: fakturadetails.kontonr,
      mva: fakturadetails.mva,
      post: fakturadetails.post,
      postnr: fakturadetails.postnr,  
      stapled: stapled
    }
    console.log(method);
    method === 'save' && save('faktura', faktura);
    method === 'create' && generate('faktura', faktura);
  }
  
deletePosition(index)
{
  let content = this.state.content;
  let position = this.state.position;
  content.splice(index, 1);
  position.splice(index, 1);

  this.setState({
    content,
    position,
  })
}
MoveUp(index)
{
  //console.log('movingUp...')
  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

};
  let content = this.state.content;
  array_move(content, index, index-1)    
  this.setState({
    content,
  })
}
MoveDown(index)
{
  //console.log('movingDown...')
  function array_move(arr, old_index, new_index) {

    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

};
let content = this.state.content;
  array_move(content, index, 1+index)    
  this.setState({
    content,
  })
}
addNewRow() {
  let {fakturadetails} = {...this.props};
  const vat = fakturadetails.vat ?  +fakturadetails.vat : 25;
  const position = this.state.position;
  let content = this.state.content;
  const invoiceRow = {
    description: "",
    netprice: 0,
    multiplier: 1,
    vat,
  };
  position.push(position.length);
  content.push(invoiceRow);

  // console.log(days)
  this.setState({
    position,
    content
  })
}

fillEditValues(){
  const {creator} = this.props;
  const mode = creator ? 'creator' : 'page';
  const DateIsInvalid = (date) => {
    const dateToCheck = new Date(date);
    return typeof(dateToCheck) === 'undefined' || isNaN(dateToCheck.getTime());
  };
  let {content, client, fakturadue, fakturaissue, project, loggedprojectname, stapled, pid} = this.props.editedDocument;
  let newContent = content.map((x,index) => {return {...x}});
  let {edit} = this.props;
  let fakturanr = typeof edit === 'string' ? this.props.editedDocument.fakturanr : this.props.fakturadetails.fakturanr;
  let {fakturadetails} = {...this.props};
  let dte = new Date(fakturaissue);
  let due = fakturadetails.paymentdue ? fakturadetails.paymentdue : 14;
  fakturadue = !DateIsInvalid(fakturadue) ? fakturadue : dte.setDate(dte.getDate() + 14);
  fakturadue = new Date(fakturadue);
  let position = content.map((x,index) => {return index});
  let did = typeof edit === 'string' ? edit : false;
  this.setState({
    mode,
      did,
    position,
    content: newContent,
    client: client,
    fakturadue,
    fakturaissue,
    fakturanr,
    stapled: stapled ? stapled : [],
    pid,
    loggedprojectname: project ? project : loggedprojectname ? loggedprojectname : '',
  })
}

writeBasisToState = (record, value) =>
{
  this.setState({
    [record]: value
  })
}

createStartValues() {
  const {creator} = this.props;
  const mode = creator ? 'creator' : 'page';
  let {fakturadetails} = {...this.props};
  const vat = fakturadetails.vat ?  +fakturadetails.vat : 25;
  let dte = new Date();
  let fakturaissue = new Date();
  let fakturanr =  fakturadetails && fakturadetails.fakturanr;
  let due = fakturadetails.paymentdue ? fakturadetails.paymentdue : 14;
  let fakturadue = dte.setDate(dte.getDate() + due);
  fakturadue = new Date(fakturadue);
  const content = [{
    description: "",
    netprice: 0,
    multiplier: 1,
    vat,
  }];
  this.setState({
    fakturadue, fakturaissue, fakturanr, content, mode,
  })
}

componentDidMount() {
  if (this.props.edit)
  {
    this.fillEditValues();
  }
  else
  {
    this.createStartValues();
  }
}
nextStep = () => {
    let {step} = this.state;
    step = step < 5 ? ++step : step;
    this.setState({
        step: step,
    })
}

backStep = () => {
    let {step} = this.state;
    step = step > 1 ? --step : step;
    this.setState({
        step: step,
    })
}

chooseStep = (step) => {
    this.setState({
        step: step,
    })
} 
  closeDocument(){
    this.props.showPopup('confirm', chooseLanguage('closePopup',this.props.lang.langinterface), () => this.props.closeDocument('faktura'));
  }
  minimizeDocument(){
    this.setState({
        minimized: true,
       })
  }

  maximizeDocument(){
   this.setState({
    minimized: false,
   })
  }

  creatorModeOn = () => {
    const {modifyInterfaceSetting} = this.props.editSetting;
    modifyInterfaceSetting('creator', true);
    this.setState({
      mode: 'creator',
    })
   }

  pageModeOn = () => {
    const {modifyInterfaceSetting} = this.props.editSetting;
    modifyInterfaceSetting('creator', false);
    this.setState({
      mode: 'page',
    })
  }


  render (){
    const props = {...this.props};
    const fakturanr = typeof edit === 'string' ? props.editedDocument.fakturanr : props.fakturadetails.fakturanr;
    const minimized = this.state.minimized;
    const {step, mode} = this.state; 

    const today = new Date();
    let projects = [];
    props.projects && props.projects.map((project, index)=>{
      if(!project.settings.archived || (project.settings.archived && !project.settings.archived.status) )
      {
        if(!project.settings.deleted || (project.settings.deleted && !project.settings.deleted.status) )
        {
          console.log(project.name + ': accepted')
          projects.push([project.name, project.pid]);
        }
      }
    });
    let client = this.state.client ? this.state.client : {};
    let {editClientData, loggedprojectname, isValid, fakturadue, fakturaissue, content, position} = this.state;
    let clients = [];
    props.clients && props.clients.map((client, index)=>{
      if(!client.archived || (client.archived && !client.archived.status) )
      {
        if(!client.deleted || (client.deleted && !client.deleted.status) )
        {
          console.log(client.clientname + ': accepted')
          clients.push(client.clientname);
        }
      }
    });
    const invoicePreview = {
        createdate: today,
        acceptdate: today,
        content: {              
          client: {...client},
          fakturaissue: fakturaissue,
          fakturadue: fakturadue,
          content: content, 
          fakturanr: fakturanr,
        },
        stapled: this.state.stapled,
        did: false,
        pid: this.state.pid,
        type: 'faktura'
      }
      const {lang} = props;
      const {langinterface} = lang;
      const translation = {
        faktura: chooseLanguage('faktura', langinterface),
      }
    return (
    <>
        <div
        style={{visibility: minimized ? 'hidden' : 'visible'}}
        >
            <div id="document-background">
                <div id='invoice-document'>
                    <div id='top-beam' className="faktura-top-beam">
                      <h1>{translation.faktura}{' #'}{fakturanr}</h1>
                        <MinimizeButton minimizeAction={this.minimizeDocument} />
                        <HelpButton/>                                
                        <CreatorButton mode={mode} buttonAction={mode === 'creator' ? this.pageModeOn : this.creatorModeOn} color={0}/>
                        <CloseButton closeAction={this.closeDocument}/>
                    </div>
                {
                  mode === 'page' && <NewInvoice 
                    client = {client}
                    arrayLength={content.length}
                    clients = {clients}
                    projects = {projects}
                    editClientData = {editClientData}
                    loggedprojectname = {loggedprojectname}
                    isValid = {isValid}
                    fakturadue = {fakturadue}
                    fakturaissue = {fakturaissue}
                    position = {position}
                    writeClientToState = {this.writeClientToState}
                    writeBasisToState = {this.writeBasisToState}
                    moveDown = {this.MoveDown}
                    moveUp = {this.MoveUp}
                    addNewRow = {this.addNewRow}
                    content = {content}
                    saveDate = {this.saveDate}
                    savePost = {this.savePost}
                    deletePosition = {this.deletePosition}
                    writeToState = {this.writeToState}
                    sendFormToExecute = {this.sendFormToExecute}
                    minimizeDocument={this.minimizeDocument} 
                    closeDocument={this.closeDocument}
                    fakturadetails={props.fakturadetails}
                    edit={props.edit} 
                    editedDocument={props.editedDocument} 
                    generateDocument={props.generateDocument}
                    documentOptions={props.documentOptions}
                    lang={lang}
  
                />
                }

                {mode === 'creator' && <NewInvoiceWizard
                    stepOptions={{
                      choose: this.chooseStep,
                      back: this.backStep,
                      next: this.nextStep,
                    }
                    }
                    client = {client}
                    arrayLength={content.length}
                    clients = {clients}
                    projects = {projects}
                    editClientData = {editClientData}
                    loggedprojectname = {loggedprojectname}
                    isValid = {isValid}
                    invoicePreview = {invoicePreview}
                    fakturadue = {fakturadue}
                    fakturaissue = {fakturaissue}
                    position = {position}
                    writeClientToState = {this.writeClientToState}
                    writeBasisToState = {this.writeBasisToState}
                    moveDown = {this.MoveDown}
                    moveUp = {this.MoveUp}
                    addNewRow = {this.addNewRow}
                    content = {content}
                    saveDate = {this.saveDate}
                    savePost = {this.savePost}
                    deletePosition = {this.deletePosition}
                    writeToState = {this.writeToState}
                    sendFormToExecute = {this.sendFormToExecute}
                    step={step}
                    minimizeDocument={this.minimizeDocument} 
                    closeDocument={this.closeDocument}
                    fakturadetails={props.fakturadetails}
                    edit={props.edit} 
                    editedDocument={props.editedDocument} 
                    generateDocument={props.generateDocument}
                    documentOptions={props.documentOptions}
                    lang={lang}
                />}
                </div>
            </div>
        </div>
        <div 
        id='faktura-minimized'
        onClick={this.maximizeDocument} 
        style={{visibility: minimized ? 'visible' : 'hidden'}}
        >
        <p>{translation.faktura}{' #'}{fakturanr}</p>
        </div>
    </>
    );
  }
}

export default NewInvoiceContainer;
