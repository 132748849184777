import React, { Component } from "react";
import { Container, Row, Form, Col} from 'react-bootstrap';
import TimelistHeader from './TimelistHeader';
import TimelistRow from './TimelistRow';
import TimelistBasis from './TimelistBasis';
import {serverRouteParser, requestObjectGenerator} from '../ServerRoutes/serverRoute';
import { localizeDateStr, dateToSlashString } from "../Features/dateFeatures";
import DocumentExportOptions from "../FormComponents/DocumentExportOptions";
import TimelistRowMobile from "./TimelistRowMobile";
import ClientData from "../FormComponents/ClientData";
import ClientDataLocked from "../FormComponents/ClientDataLocked";
import { chooseLanguage } from "../Lang/langpack";


class NewTimelist extends Component {
  render (){
    let {extra, km, files, loggedin, timelistnr, timelistdetails, documentOptions, sendFormToExecute, showClientData, savePost, loggedout, loggedday, deleteDay, saveDate, addNewRow, moveDown, moveUp, loggedpaidbreak, loggedbreak, loggeddaydescription, loggedDaysArray, clients, client, projects, clientData, editClientData, loggedprojectname, loggedname, writeBasisToState, writeClientToState, writeToState, isValid, lang} = this.props;
    const showKm = timelistdetails.km;
    const {clientname, address, postnr, post} = {...client};
   const {langinterface} = lang;
   const translation = {
    clientData: chooseLanguage('clientData', langinterface)
   }
    return (
      <Container id='timelist' fluid>
          <Row>
          {!clientData && <Form.Label column xs={12} lg={2}><h4>{translation.clientData}</h4></Form.Label>}
            <Col xs={12} lg={!clientData ? 4 : 6}>
            {!clientData && <Form.Control type='checkbox' name={'toggle-client'} onChange={showClientData}></Form.Control>}
            {(clientData && (!clientname || !address || !postnr || !post || editClientData )) && <ClientData lang={lang}
              savePost={savePost} 
              client={client} 
              writeClientToState={writeClientToState}
              suggestions={clients}
            />}
              {(clientname && address && postnr && post && !editClientData) && <ClientDataLocked editClientData={editClientData} client={client}/>}
              {editClientData && 
              <div className="edit-accept">
                <img src='./ok.svg' 
                      id='accept'  
                      onClick={editClientData}
                      alt='accept'
                      style={{width: '5vw',cursor: 'pointer'}}/>
              </div>}
            </Col>
            <Col>
              {loggedDaysArray.length > 0 && <TimelistBasis lang={lang} projects={projects} isValid={isValid} loggedprojectname={loggedprojectname} loggedname={loggedname} writeBasisToState={writeBasisToState}/>}
            </Col>
          </Row>
          {

          //window.innerHeight < window.innerWidth && <TimelistHeader/>
          }
          {loggedDaysArray.map((day, index)=>(<TimelistRow 
            lang={lang}
            saveDate={saveDate} 
            moveDown={moveDown} 
            moveUp={moveUp} 
            addNewRow={addNewRow} 
            timelist={{
              extra: extra[index],
              km: km[index],
              files: files[index],
              day: loggedday[index],
              in: loggedin[index],
              out: loggedout[index],
              paidbreak: loggedpaidbreak[index], 
              breakValue: loggedbreak[index],
              daydescription: loggeddaydescription[index],
            }}
            showKm={showKm}
            deleteDay={deleteDay} 
            writeToState={writeToState} 
            key={index} 
            day={day} 
            arrayLength={loggedDaysArray.length} 
            index={index.toString()}/>))}
          {loggedDaysArray.length > 0 && 
            <div id='document-options-full-preview'>
              <DocumentExportOptions lang={lang} sendFormToExecute={sendFormToExecute}/>
            </div>
            }
      </Container>
    );
  }
}

export default NewTimelist;
