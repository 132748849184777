import React, { Component } from "react";
import ListWindow from "../Lists/ListWindow";


class MenuBarSimplyfy extends Component {

  
  constructor () {
    super();
    this.state = {
      list: {
        clients: false,
        projects: false,
        tasks: false,
        search: false,
      }
    }
    this.showPopup = this.showPopup.bind(this);
    this.viewList = this.viewList.bind(this);
    this.clearList = this.clearList.bind(this);
    this.createNewDocument = this.createNewDocument.bind(this);
    this.showMainMenu = this.showMainMenu.bind(this);
  }
  createNewDocument(){
    this.clearList();
    this.props.openMenu('document');
  }

  openSettings = () => {
    this.props.openMenu('settings');
  }
  
  showPopup(event) {
    let type = event.target.id;
    let content = event.target.getAttribute('alt');
    this.props.showPopup(type, content);
  }


  viewList(event){
    let type = event.target.id;
    let list = this.state.list;
    list = {[type]: !list[type]};
    //console.log(list);
    this.setState({list});
    }

  clearList(){
    this.setState({
      list: {
        clients: false,
        projects: false,
        tasks: false,
        search: false,
      }
    })
  }

  showMainMenu() {
    this.props.openMenu('main');
  }

 
  render (){
    return (
        <div id='menu-hamburger'>
{/*          <div className='menu-item' >
          <div 
            className="clickable-panel" 
            id='search'  
            onClick={this.viewList}>
          </div>
            <img src={'./search.svg'}
            style={{
              filter: 'invert(1)'}}
              id='search' 
              alt='search'
            />
            {this.state.list.search && 
            <>
              <ListWindow
              projectController={this.props.projectController}
              openFullPreview={this.props.openFullPreview}
              showPopup={this.props.showPopup}
              editDocument={this.props.editDocument} 
              repeatDocument={this.props.repeatDocument} 
              clearList={this.clearList} 
              displaySearchResult={this.props.displaySearchResult}
              uid={this.props.uid} 
              type={'search'} 
              projects={this.props.projects}
              clients={this.props.clients}
              documents={this.props.documents}
              closeWindow={this.viewList}
              />
              <div id='list-window-background' onClick={this.viewList}>
                
                </div>

            </>
            }
          <p>Search</p>
        </div>
            */}
          <div className='menu-item'>
            <img src='./menu.svg' 
              id='menu' 
              onClick={this.showMainMenu}
              alt='menu'
              />
          <p>Menu</p>
        </div>
      </div>
     
    );
  }
}

export default MenuBarSimplyfy;
