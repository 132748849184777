import React, { Component } from "react";
//import { Row, Col, Form} from 'react-bootstrap';
import './../menu.css';
import './../popup.css';
import { chooseLanguage } from "../Lang/langpack";

class DocumentMenu extends Component {
  constructor () {
	  super();
	  this.state = {
          menu: [
            {ico: "tilbud.svg", link: 'tilbud', title: "Tilbud"}, 
              {ico: "faktura.svg", link: 'faktura', title: "Faktura"},
              {ico: "timelist.svg", link: 'timelist', title: "Timelist"}, 
            ]
    }
    this.setHidden = this.setHidden.bind(this);
    this.setVisible = this.setVisible.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.createNewDocument = this.createNewDocument.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  setLang = () => {
    const {langinterface} = this.props.lang;
    const header = chooseLanguage('createNewDocument', langinterface);
    const docType = chooseLanguage('docType', langinterface);

    const menu = [
      {ico: "tilbud.svg", link: 'tilbud', title: docType[0]}, 
      {ico: "faktura.svg", link: 'faktura', title: docType[1]},
      {ico: "timelist.svg", link: 'timelist', title: docType[2]}, 
    ];
    this.setState({menu, header});
   }

  createNewDocument(event){
    const type = event.target.getAttribute('name');
    //console.log(type);
    this.props.createNewDocument(type);
  }


  closeMenu(){
    this.props.closeMenu('document');
  }

  componentDidMount() {
    this.setLang();
    this.setHidden();
  }

  componentWillUnmount() {
    this.setVisible();
  }
  
setHidden () {
      document.body.style.overflow = "hidden";
}
setVisible () {
  document.body.style.overflow = "auto";
}

handleViewChange(event) {
    let id = event.target.id;
    this.props.handleViewChange(id);
  }

  render (){
/*

{activeView === 'documents' && <img src='./tilbud.svg' 
           id='newOffer'  
            onClick={this.props.newOffer}
            alt='new offer'
            style={{width: '3.5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>}
            {activeView === 'documents' && <img src='./timelist.svg' 
            id='newTimelist' 
            onClick={this.props.newTimelist}
            alt='new timelist'
            style={{width: '3.5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>}
            {activeView === 'documents' && <img src='./faktura.svg' 
            id='newFaktura'  
            onClick={this.props.newInvoice}
            alt='new invoice'
            style={{width: '3.5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>}

new user
new project
confirm
edit
error
info
settings
ad
*/
const type = this.props.type;
const content = this.props.content;
const backgroundImage = 'documents.svg';
    return (
        <div className="popup-background" onClick={this.closeMenu}>
            <div className="document-menu-window">
                      <div className='popup-topbeam' style={{backgroundImage: 'url(./'+backgroundImage}}><h1>{this.state.header}</h1></div>
                      {<div className='popup-close-button' onClick={this.props.closeMenu}>X</div>}

                    <div className="document-menu-grid">
                        {this.state.menu.map((item, index)=>(<div key={index} class="document-menu-item">
                        <div name={item.link} onClick={this.createNewDocument} className="clickable-panel"></div><img alt={item.title} src={'./'+item.ico}/><p>{item.title}</p></div>))}
                    </div>
            </div>
        </div>
    );
  }
}

export default DocumentMenu;
