import React, { Component } from "react";
import {  Row, Col} from 'react-bootstrap';
import ClientData from "../FormComponents/ClientData";



class WizardStepClient extends Component {


  render (){ 
    let {suggestions, client, savePost, writeClientToState, lang} = this.props;
    const {clientname, address, postnr, post} = {...client};
    return (
            <ClientData
              savePost={savePost} 
              client={client} 
              writeClientToState={writeClientToState}
              suggestions={suggestions}
              lang={lang}
            />
    );
  }
}

export default WizardStepClient;
