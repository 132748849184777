import React, { Component } from "react";
import { Form} from 'react-bootstrap';
import ClientData from "./../FormComponents/ClientData";
import { serverRouteParser } from "../ServerRoutes/serverRoute";
import { postkode } from "../Documents/postkode";


class PopupNewClient extends Component {
  constructor () {
	  super();
	  this.state = {
      client: {
        clientname: "",
        address: "",
        postnr: "",
        post: "",
        email: "",
        mva: "",
      },
      error: false,
    }
    this.createNewClient = this.createNewClient.bind(this);
    this.writeClientToState = this.writeClientToState.bind(this);
    this.writePost = this.writePost.bind(this);
  }

  componentDidMount() {
  }

  createNewClient(){
    const client = {...this.state.client};
    if (client.clientname && client.address && client.postnr && client.post)
    {
      this.props.createNewClient(client);
    }
    else
    {
      this.setState({
        error: true,
      })
    }
  }

  writePost(postnr)
  {
      let stringPostNr = new String(postnr);
      //console.log(stringPostNr)
      let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === postnr);
      //console.log(selectedCity);
      let client = {...this.state.client};
      client['postnr'] = postnr;
      client['post'] = selectedCity && selectedCity[0][1] ? selectedCity[0][1] : stringPostNr.length < 4 ? '' : client['post'];
      this.setState({client});
   
  }

  savePost = (postnr) => 
  {
      let stringPostNr = new String(postnr);
      //console.log(stringPostNr)
      let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === postnr);
      //console.log(selectedCity);
      let client = {...this.state.client};
      client['postnr'] = postnr;
      client['post'] = selectedCity && selectedCity[0][1] ? selectedCity[0][1] : stringPostNr.length < 4 ? '' : client['post'];
      this.setState({client});
  }

  


  writeClientToState(record, value){
    console.log("Record: " +  record + " Value: " + value);
   let client = {...this.state.client};
    client[record] = value;
     this.setState({
      client,
    })
    
}

  render (){
    const {lang} = this.props;
    return (
        <div className="popup-new-client">
          <div>
          <ClientData lang={lang} savePost={this.savePost} client={this.state.client} error={this.state.error} writeClientToState={this.writeClientToState}/>
          </div>
          <div className="popup-new-client-buttons">
            <img src='./ok.svg' 
            id='accept'  
            onClick={this.createNewClient}
            alt='accept'
            style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
            <img src='./abort.svg' 
            id='abort'  
            onClick={this.props.closePopup}
            alt='accept'
            style={{width: '5vw', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>
          </div> 
        </div>
    );
  }
}

export default PopupNewClient;
