import React, { Component } from "react";
import {Form} from 'react-bootstrap';


class DescriptionComponent extends Component {
  handleChange = (e) => {
    const {id, value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, id, value );
  }
  
  render (){
    
    let {index, value, record, placeholder} = {...this.props};
    placeholder = placeholder ? placeholder : 'description';
    return (
        <Form.Control 
          id={index} 
          name={record} 
          placeholder={placeholder}
          value={value} 
          as="textarea" 
          rows={3} 
          maxLength={125}
          onChange={this.handleChange} />
    );
  }
}

export default DescriptionComponent;
