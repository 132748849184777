import React, { Component } from "react";
import { Row, Col, Form, Button} from 'react-bootstrap';
import ToggleComponent from "../FormComponents/ToggleComponent";
import { chooseLanguage } from "../Lang/langpack";


class LogInPanel extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.saveBasis = this.saveBasis.bind(this);
  }

  componentDidMount() {
  }

  saveBasis(event){
    let record = event.target.id,
      value = event.target.value;
      this.props.writeToState(record, value);
  }

  logIn() {
    
  }

  

  render (){
    const {showPopup, lang, rememberUser, saveSettingToState, openRegister} = this.props;
    const {langinterface} = lang;
    const translation = {
      email: chooseLanguage('email', langinterface),
      password: chooseLanguage('password', langinterface),
      rememberUser: chooseLanguage('rememberUser', langinterface),
      logIn: chooseLanguage('logIn', langinterface),
      register: chooseLanguage('register', langinterface),
    }
    return (
      <>
        <Col lg={12}>
        <Form.Control id='tll' type="email" placeholder={translation.email} onChange={this.saveBasis} required/>
        </Col>
        <Col lg={12}>
          <Form.Control id='tlp' type="password" placeholder={translation.password} onChange={this.saveBasis} required/>
        </Col>
        <Col>
        {!this.props.loginValid && <div>
            Please input Correct Login & Password
          </div>}
        </Col>
        <Col lg={12}>
          <ToggleComponent translation={translation.rememberUser} record={'rememberUser'} value={rememberUser} saveValue={saveSettingToState}/>
        </Col>

        <Col lg={12}>
          <Button id='login' as={Col} onClick={this.props.logIn} variant='light'>{translation.logIn}</Button>
          <Button id='register' as={Col} onClick={openRegister} variant='dark'>{translation.register}</Button>
        </Col>
      </>
    );
  }
}

export default LogInPanel;
