import React, { Component } from "react";
import { Button } from 'react-bootstrap';


class NewRowButton extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.newRow = this.newRow.bind(this);
  }

  componentDidMount() {
  }

  newRow(event) {
    const index = event.target.id;
    this.props.addNewRow(index);

  }


  render (){
    let {index} = {...this.props};

    return (
        <Button id={index} onClick={this.newRow} variant='secondary'>            
        <img src='./plus.svg' 
        id='newRow' 
        alt='new Row'
        style={{height: '15px', width: "15px", display: 'inline', margin: '0 0', cursor: 'pointer', filter: 'invert(1)'}}/>
</Button>
    );
  }
}

export default NewRowButton;
