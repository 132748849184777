
import React, { Component } from "react";
import NewDayRow from "../Documents/NewDayRow";
import { dateToSlashString } from "../Features/dateFeatures";

class PopupNewDay extends Component {
  constructor () {
	  super();
	  this.state = {
      days: [0],
      loggedDaysArray: [],
      extra: [],
      km: [],
      files: [],
      loggedday: [],
      loggedin: [],
      loggedout: [],
      loggedpaidbreak: [],
      loggedbreak: [],
      loggeddaydescription: [],
    }
  }
  createStartValues() {
    const {username} = this.props;
    const days = this.state.days;
    const defaultValues = this.props.timelistdetails ? this.props.timelistdetails : false;
    let dates = [];
    let loggedday = [];
    let loggedin = [];
    let loggedout = [];
    let loggedpaidbreak = [];
    let loggedbreak = [];
    let loggeddaydescription = [];
    let extra = [];
    let km = [];
    let files = [];
    let timelistnr = defaultValues.timelistnr;
    days.map(function (x, index){
      let dte = new Date();
      function localizeDateStr(date_to_convert_str) 
      {
        var date_to_convert = new Date(date_to_convert_str);
        var dateArray = [date_to_convert.getDate(), date_to_convert.getMonth()+1, date_to_convert.getFullYear(), date_to_convert.getHours(), date_to_convert.getMinutes(), date_to_convert.getSeconds()];
        let day = dateArray[0].toString();
        let month = dateArray[1].toString();
        dateArray[0] = day.length === 1 ? '0'+day : day;
        dateArray[1] = month.length === 1 ? '0'+month : month;
        var dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];             
        return dateString; 
      }
      //console.log(dte);
      let day = dte.setDate(dte.getDate() - x);
      loggedday.push(localizeDateStr(day));
      loggedin.push(!defaultValues ? '08:00' : defaultValues.in.length === 5 ? defaultValues.in : '0'+defaultValues.in);
      loggedout.push(defaultValues ? defaultValues.out : '16:30');
      loggedpaidbreak.push(defaultValues ? defaultValues.breakpaid : false);
      extra.push(false);
      loggedbreak.push(defaultValues ? defaultValues.breaktime : '30');
      loggeddaydescription.push('');
      km.push(false);
      files.push([]);
      dates.push(day);
  })
    if (dates.length === days.length)
    {
        this.setState({
            loggedDaysArray: dates,
            extra,
            km,
            files,
            loggedday,
            loggedin,
            loggedout,
            loggedpaidbreak,
            loggedbreak,
            loggeddaydescription,
        })
    }
    };

    saveDate = (value, position) => {
      let {loggedDaysArray, loggedday} = {...this.state};
      loggedday[position] = dateToSlashString(value);
      value = new Date(value);
      value = value.getTime();
      loggedDaysArray[position] = value;
      console.log(value, position)
      this.setState({
        loggedDaysArray, loggedday
      })
    }

    writeToState = (record, position, value) => {
      let newValue= [...this.state[record]];
      newValue[position] = value;
      this.setState({
        [record]: newValue
      })
  }

  saveDay= () => {
    const timelist={...this.state};
    const {addDay} = this.props;
    addDay(timelist);
  }

componentDidMount(){
  this.createStartValues();
}

  render (){
    const {lang, abort, timelistdetails} = this.props;
    const showKm = timelistdetails.km;
    const {extra, km, files, loggedDaysArray, loggedday, loggedin, loggedout,loggedpaidbreak, loggedbreak, loggeddaydescription} = this.state;
    const index=0;
    return (
      <div id='popup-new-day'>
        <NewDayRow 
        showKm={showKm}
        index={index.toString()}
        lang={lang}
        writeToState={this.writeToState}
        saveDate={this.saveDate} 
        timelist={{
            extra: extra[0],
            km: km[0],
            files: files[0],
            day: loggedDaysArray[0],
            in: loggedin[0],
            out: loggedout[0],
            paidbreak: loggedpaidbreak[0], 
            breakValue: loggedbreak[0],
            daydescription: loggeddaydescription[0],
          }} 
        />
        <div className="popup-buttons">
          {<img src='./ok.svg' 
            id='accept'
            onClick={this.saveDay}
            alt='accept'
            style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>}
          {<img src='./abort.svg' 
            id='abort' 
            onClick={abort}
            alt='abort'
            style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>}
        </div>
      </div>  
    );
  }
}

export default PopupNewDay;

