import React, { Component } from "react";
import RegisterUserContainer from "../PageNavigation/RegisterUserContainer";
//import { Row, Col, Form} from 'react-bootstrap';


class PopupNewUser extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){
    const {createNewUser, closePopup, lang} = this.props;
    return (
        <div className="popup-new-user">
          <RegisterUserContainer lang={lang} createNewUser={createNewUser} />
        </div>
    );
  }
}

export default PopupNewUser;
