import React, { Component } from "react";
import DocumentItem from "./DocumentItem";


class DocumentList extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

 
  componentDidMount() {

  }

  render (){
    const documents = this.props.documents ? this.props.documents : [];
    return (
      <div id='document-list'>
        {documents.length > 0 && documents.map((document, index) => <DocumentItem document={document}/>)}
      </div>  
    );
  }
}

export default DocumentList;
