import React, { Component } from "react";
import {Col, Row} from 'react-bootstrap';
import $ from 'jquery';

import DocumentPreview from "./DocumentPreview";
import TimelineDayContainer from "./TimelineDayContainer";
import { chooseLanguage } from "../Lang/langpack";

class TimelineAxisMobile extends Component {
  constructor () {
	  super();
	  this.state = {
      step: 0,
      previewFile: false,
    }
    this.stepBack = this.stepBack.bind(this);
    this.stepForward = this.stepForward.bind(this);
    this.previewFile = this.previewFile.bind(this);
    this.fullPreview = this.fullPreview.bind(this);
    this.blankPreview = this.blankPreview.bind(this);
    this.blankPreviewFile = this.blankPreviewFile.bind(this);
    
  }
  stepBack(){
    let step = 0;
    this.setState({
      step
    }, window.scrollTo(0,0));
  }
  stepForward(){
    let step = this.state.step;
    step = step + 360;
    this.setState({
      step
    })
  }


  countTimelineWeek = (step) => {
    //console.log('step: ' + step);
    Date.prototype.getWeek = function() {
      var date = new Date(this.getTime());
      //console.log(date);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
      //console.log(date);
      var week1 = new Date(date.getFullYear(), 0, 4);
      return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }
    let {view} = this.props;
    let weekDay = [];
    let weekNo = [];
    let addedValue = view === 'calendar' ? 33 : 360;
    for (let i=step+addedValue; i>=0; i--)
    {
      let dte =new Date();
      dte.setDate(dte.getDate() - i);
      dte.getWeek();
      let day = dte.getDate();
      day = ''+day;
      day = day.length < 2 ? '0'+day : day;
      let month = +dte.getMonth();
      month = month + 1;
      month = ''+month;
      month = month.length < 2 ? '0'+month : month;
      //console.log(step-i);
      weekDay[i] = day+'/'+month+'/'+dte.getFullYear();
      weekNo[i] = dte.getWeek();
    }
    return {weekNo, weekDay};
  }
    /*
 countTimelineWeek(step){
    //console.log('step: ' + step);
    Date.prototype.getWeek = function() {
      var date = new Date(this.getTime());
      //console.log(date);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
      //console.log(date);
      var week1 = new Date(date.getFullYear(), 0, 4);
      return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }
    let weekDay = [];
    let weekNo = [];
    let weekDayReverse = [];
    let weekNumberReverse = [];

    for (let i=step+32; i>step; i--)
    {
      let dte =new Date();
      dte.setDate(dte.getDate() - i);
      dte.getWeek();
      let day = dte.getDate();
      day = ''+day;
      day = day.length < 2 ? '0'+day : day;
      let month = +dte.getMonth();
      month = month + 1;
      month = ''+month;
      month = month.length < 2 ? '0'+month : month;
      //console.log(-step+i);
      weekDayReverse[32+step-i] = day+'/'+month+'/'+dte.getFullYear();
      weekNumberReverse[32+step-i] = dte.getWeek();
    }
    for (let i=0; i<32; i++)
    {
      weekDay[31-i] = weekDayReverse[i];
      weekNo[31-i] = weekNumberReverse[i];
    }
    //console.log(weekDayReverse);
    //console.log(weekNumberReverse);
    return {weekNo, weekDay};
  }
  */

  getWeekOfDate(date){
    //console.log(date);
    Date.prototype.getWeek = function() {
      var date = new Date(this.getTime());
      //console.log(date);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
      //console.log(date);
      var week1 = new Date(date.getFullYear(), 0, 4);
      return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }
    let dte= new Date(date.slice(6,10), +date.slice(3,5)-1, date.slice(0,2));
    let week = dte.getWeek();
    return week;

  }


  
  isToday(day){
    let dateToEqual = new Date(day.slice(6,10), +day.slice(3,5)-1, day.slice(0,2));
    let day1 = dateToEqual.getDate();
    day1 = ''+day1;
    day1 = day1.length < 2 ? '0'+day1 : day1;
    let month1 = +dateToEqual.getMonth();
    month1 = month1 + 1;
    month1 = ''+month1;
    month1 = month1.length < 2 ? '0'+month1 : month1;
    let year1 = dateToEqual.getFullYear();

    let dateOfDocument = new Date ();
    let day2 = dateOfDocument.getDate();
    day2 = ''+day2;
    day2 = day2.length < 2 ? '0'+day2 : day2;
    let month2 = +dateOfDocument.getMonth();
    month2 = month2 + 1;
    month2 = ''+month2;
    month2 = month2.length < 2 ? '0'+month2 : month2;
    let year2 = dateOfDocument.getFullYear();

    const result = day1 === day2 && month1 === month2 && year1 === year2 ? true : false;
    return result;
  }

  dateComparsion(dateToComapare, timelineDate)
  {
    let dateToEqual = new Date(timelineDate.slice(6,10), +timelineDate.slice(3,5)-1, timelineDate.slice(0,2));
    let day1 = dateToEqual.getDate();
    day1 = ''+day1;
    day1 = day1.length < 2 ? '0'+day1 : day1;
    let month1 = +dateToEqual.getMonth();
    month1 = month1 + 1;
    month1 = ''+month1;
    month1 = month1.length < 2 ? '0'+month1 : month1;
    let year1 = dateToEqual.getFullYear();

    if (typeof dateToComapare === 'object')
    {
      //console.log('Object: ' + dateToComapare)
      for(let i = dateToComapare.length-1; i>=0; i--)
      {
        let tempDoc = dateToComapare[i];
        //console.log(dateToComapare[i]);
        let dateOfDocument = new Date(tempDoc.slice(6,10), +tempDoc.slice(3,5)-1, tempDoc.slice(0,2));
        let day2 = dateOfDocument.getDate();
        day2 = ''+day2;
        day2 = day2.length < 2 ? '0'+day2 : day2;
        let month2 = +dateOfDocument.getMonth();
        month2 = month2 + 1;
        month2 = ''+month2;
        month2 = month2.length < 2 ? '0'+month2 : month2;
        let year2 = dateOfDocument.getFullYear();
        const result = day1 === day2 && month1 === month2 && year1 === year2 ? true : false;
        if(result === true || i === 0)
        {
          //console.log(day1 + ' | ' + day2+ ' / ' + month1 + ' | ' + month2 + ' / ' + year1  + ' | ' + year2+ ' = ' + result);
          return result;
        }    
      }     
    }
    else
    {   
        let dateOfDocument = new Date (dateToComapare);
        let day2 = dateOfDocument.getDate();
        day2 = ''+day2;
        day2 = day2.length < 2 ? '0'+day2 : day2;
        let month2 = +dateOfDocument.getMonth();
        month2 = month2 + 1;
        month2 = ''+month2;
        month2 = month2.length < 2 ? '0'+month2 : month2;
        let year2 = dateOfDocument.getFullYear();
        const result = day1 === day2 && month1 === month2 && year1 === year2 ? true : false;
        //console.log(day1 + ' | ' + day2+ ' / ' + month1 + ' | ' + month2 + ' / ' + year1  + ' | ' + year2+ ' = ' + result);
        return result;

    }
    
  }

  compareTwoStringDate(date1,date2){
    let dateToCompare1 = new Date(date1.slice(6,10), +date1.slice(3,5)-1, date1.slice(0,2));
    let day1 = dateToCompare1.getDate();
    day1 = ''+day1;
    day1 = day1.length < 2 ? '0'+day1 : day1;
    let month1 = +dateToCompare1.getMonth();
    month1 = month1 + 1;
    month1 = ''+month1;
    month1 = month1.length < 2 ? '0'+month1 : month1;
    let year1 = dateToCompare1.getFullYear();


    let dateToCompare2 = new Date(date2.slice(6,10), +date2.slice(3,5)-1, date2.slice(0,2));
    let day2 = dateToCompare2.getDate();
    day2 = ''+day2;
    day2 = day2.length < 2 ? '0'+day2 : day2;
    let month2 = +dateToCompare2.getMonth();
    month2 = month2 + 1;
    month2 = ''+month2;
    month2 = month2.length < 2 ? '0'+month2 : month2;
    let year2 = dateToCompare2.getFullYear();
    const result = day1 === day2 && month1 === month2 && year1 === year2 ? true : false;
    //console.log(day1 + ' | ' + day2+ ' / ' + month1 + ' | ' + month2 + ' / ' + year1  + ' | ' + year2+ ' = ' + result);
    return result;
  }

  previewFile(event){
    let did = event.target.getAttribute('did');
    did = did ? did : event.target.parentNode.getAttribute('did');
    //const pid = event.target.getAttribute('pid');
    let week = event.target.getAttribute('week'); 
    week = week ? week : event.target.parentNode.getAttribute('week');
    let year = event.target.getAttribute('year');
    year = year ? year : event.target.parentNode.getAttribute('year');
    //console.log(did);   
    const { hud } = this.props;
    const document = this.props.documents && Object.keys(this.props.documents).length && this.props.documents[year] && this.props.documents[year][week] && this.props.documents[year][week].filter(document => document.did === did);
    //console.log(document);  
    hud.previewFile.open(document[0]);
  }


  blankPreview(){
    setTimeout(this.blankPreviewFile, 3)
  }

  fullPreview(event){
    let did = event.target.id;
    did = did ? did : event.target.parentNode.id;
    this.props.openFullPreview(did);
  }

  blankPreviewFile(){
    this.setState({
      previewFile: false
    }) 
  }


  componentDidMount() {
    $(document).scroll(function () {
      var y = $(this).scrollTop();
      y = y+10;
      //console.log(y);
      $('#timeline-canva-mobile').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            if (y > 2100)
              {$('#timeline-canva-mobile').height('fit-content');}
            else 
              {$('#timeline-canva-mobile').height('fit-content');}
          $('#timeline-top-arrow').fadeIn();
        } else {
            $('#timeline-top-arrow').fadeOut();
        }
      });      
    })}


  render (){
    const {documents} = this.props;

    let timeline = [];
    let years = [];
    let iteration = 0;
    if(Object.keys(documents).length)
    {
      for (const [year, yearValue] of Object.entries(documents)) {
        timeline.push([]);
        years.push(year);
        iteration++;
      }
    }

    let weekNo = this.countTimelineWeek(this.state.step);
    //console.log(weekNo);
    //let selectedYear = weekNo.weekDay[weekNo.weekDay.length-1].slice(6,10);
    let {view, lang} = this.props;
    const {langinterface} = lang; 
    const translation = {
      documents: chooseLanguage('documents', langinterface),
      week: chooseLanguage('week', langinterface),
      day: chooseLanguage('day', langinterface),
    }

    return (  
        <div id='timeline-canva-mobile'>
          <div id='timeline-box'>
              <div id='timeline-week-mobile'>

                  {view === 'calendar' && <div id='timeline-day-mobile-header' >
                   <div id='timeline-week-header'>{translation.week}</div>
                    <div id='timeline-date-header'>{translation.day}</div>
                    <div id='timeline-documents-mobile-header'>{translation.documents}</div>                    
                </div>}

                {weekNo.weekDay.map((day,index)=>(
                  <TimelineDayContainer 
                  key={index}
                  fullPreview={this.fullPreview}
                  day={day} 
                  docs={Object.keys(documents).length > 0 && documents[''+day.slice(6,10)] && documents[''+day.slice(6,10)][weekNo.weekNo[index]] } 
                  weekNo={weekNo.weekNo[index]}
                  index={index}
                  previewFile={this.previewFile}
                  view={view}
                  lang={lang}
                  />
                ))}
              <div id='timeline-bottom-arrow' onClick={this.stepForward}></div>
              <div id='timeline-top-arrow' onClick={this.stepBack}></div>
              </div>
          </div>
            <div id='timeline-axis'></div>
        </div>
    );
  }
}

export default TimelineAxisMobile;
         /*<div id='timeline-day-mobile' style={{color:  this.isSunday(day) ? '#FF0000' : '#000', fontWeight: this.isToday(day) ? '600' : '400'}}>
                    <div id='week-no-mobile' style={{ borderTop: weekNo.weekNo[index] !== weekNo.weekNo[index-1] ? '#FFF 1px solid' : 'none'}}>{weekNo.weekNo[index] !== weekNo.weekNo[index-1] ? weekNo.weekNo[index] : ''}</div>
                    <div id='timeline-date'>{day}</div>
                    <div id='timeline-documents-mobile'>{
                      Object.keys(documents).length > 0 && documents[''+day.slice(6,10)] && documents[''+day.slice(6,10)][weekNo.weekNo[index]] ?
                    documents[''+day.slice(6,10)][weekNo.weekNo[index]].map((document,idx)=>(this.dateComparsion(this.props.groupByCreateDate ? document.createdate : document.content.days, day) ? this.docIco(document, idx, weekNo.weekNo[index], day) : '')) : ''}</div>                    
                </div>*/
                /*weekNo.weekNo.map((week,index)=>(
              <div className="timeline-documents-point">
                <div className="timeline-scale-point" 
                  style={{borderLeft: weekNo.weekNo[index] === weekNo.weekNo[index-1] ? 'none' : '#000 solid 1px'}}>
                    {weekNo.weekNo[index] !== weekNo.weekNo[index-1] ? 
                      <p style={{display: 'inline'}}>{week}</p> :
                      Object.keys(documents).length > 0 && documents[''+selectedYear][weekNo.weekNo[index]] ?
                      docIco : ''}
                </div>
              </div>))*/