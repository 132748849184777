import React, { Component } from "react";
import {Tabs, Tab} from 'react-bootstrap';
import SettingKnob from "./SettingKnob";
import { chooseLanguage } from "../Lang/langpack";


class AppSettings extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }


  render (){
    const {editSetting, lang} = {...this.props};
    const {langinterface} = lang;
    const translation = {
      interfaceLang: chooseLanguage('interfaceLang',  langinterface),
      jobblederSettings: chooseLanguage('jobblederSettings',  langinterface),
    }
    const setting={value: langinterface, setting: 'interfacelang', option: translation.interfaceLang};
    return (
      <div className="settings-document-settings">
          <h3>{translation.jobblederSettings}</h3>
          <div className="knob-panel">
              <SettingKnob type='lang' editSetting={editSetting} setting={setting}/>
              {/*
                <SettingKnob type='lang' editSetting={editSetting} setting={{value: this.props.lang.doc, setting: 'doclang', option: 'Document Language'}}/>
                <SettingKnob type='toggle' editSetting={editSetting} setting={{value: 'Dark Mode', setting: 'darkmode', option: 'Layout'}}/>
              */}
          </div>
        </div>
    );
  }
}

export default AppSettings;
